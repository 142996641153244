import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import UsuarioAPI from '../../Services/API/UsuarioAPI';

import { RotasURL } from 'RoutesURL';

const TabelaUsuario = (lista) => {
  const [idExclusao, setIdExclusao] = useState();

  useEffect(() => {
    // //console.log(lista);
  }, []);
  
  function changeId(id){
    //console.log(id);
    setIdExclusao(id);
  }

  function excluirUsuario(){
    //console.log("Deletando usuario");
    UsuarioAPI.excluirUsario(idExclusao).then(()=>{
      alert("Usuário deletado com sucesso");
      lista.obterListaDeUsuarios();
    }).catch(()=>{
      alert("Erro ao deletar o usuário");
    })
  }

  return (
        <>
          {/* Pode virar componente */}
          <table id="tblsetor" className="table table-responsive">
            <thead>
              <tr>
                <td>NOME</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {
                lista && lista.lista.map((item, index) => {
                  //console.log(item)
                  return(
                    <tr className="linhaheight" key={index}>
                      <td>{item.nv_login}</td>
                      <td style={{ textAlign: 'right' }}>
                        <Link to={{pathname: RotasURL.usuario.novo, state: {item}}}>
                          <button type="button" className="templatemo-black-button">
                            <i className="fas fa-edit fa-fw"></i>
                          </button>
                        </Link>
                        <button 
                          type="button" 
                          className="templatemo-black-button"
                          data-toggle="modal"
                          data-target="#modalExcluir"
                          onClick={()=>changeId(item.in_usuario_id)}
                        >
                          <i className="fas fa-trash fa-fw"></i>
                        </button>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>

          <div
                className="modal"
                id="modalExcluir"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                {/*Header*/}
                <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Confirmar a exclusão do usuário?
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
                </div>
                {/*Footer*/}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-dismiss="modal"
                    // onClick={() => deletePost(App.in_empresa_id)}
                  >
                    Cancelar
                  </button>
                  <button
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => excluirUsuario()}
                  >
                    Confirmar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
    )
}

export default TabelaUsuario;