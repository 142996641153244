import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './incluirPessoaSchema';
import InputMask from 'react-input-mask';

import { validaCPF } from 'Services/Auth/ValidaCpf';

import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import { NavLink } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';

const IncluirPessoaPage = (props) => {
  const [listaAtualPessoa, setListaAtualPessoa] = useState();
  const [atualizando, setAtualizando] = useState(true);
  const [idPessoa, setIdPessoa] = useState();
  const [pessoa, setPessoa] = useState();
  const [pCheck, setPCheck] = useState(false);
  const [mCheck, setMCheck] = useState(false);
  const [aCheck, setACheck] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();

  useEffect(() => {
    obterListaDePessoas();
  }, []);

  useEffect(() => {
    if (listaAtualPessoa) {
      if (!props.location.state) {
        setAtualizando(false);
        setIdPessoa(listaAtualPessoa[0].in_pessoafisica_id);
        //console.log('vazio');
        //console.log(props);
      } else {
        atualizaPessoa();
        //console.log(props);
      }
    }
  }, [listaAtualPessoa]);

  function changePCheck(event) {
    if (event.target.value == 'false') setPCheck(true);
    else setPCheck(false);
  }

  function changeMCheck(event) {
    if (event.target.value == 'false') setMCheck(true);
    else setMCheck(false);
  }

  function changeACheck(event) {
    if (event.target.value == 'false') setACheck(true);
    else setACheck(false);
  }

  const onSubmit = (dataJSON) => {
    try{
      const data = dataJSON;
      if (pCheck) {
        data.in_paciente = 1;
      }
      if (mCheck) {
        data.in_medico = 1;
      }
      if (aCheck) {
        data.in_assistente = 1;
      }

      if (data.dt_nascimento_pessoafisica == '') {
        data.dt_nascimento_pessoafisica = null;
      }

      // data.in_pessoafisica_id = idPessoa;
      data.nv_cpf_pessoafisica = data.nv_cpf_pessoafisica.replaceAll('.', '');
      data.nv_cpf_pessoafisica = data.nv_cpf_pessoafisica.replaceAll('-', '');
      data.nv_cpf_pessoafisica = data.nv_cpf_pessoafisica.replaceAll(',', '');
      data.nv_cpf_pessoafisica = data.nv_cpf_pessoafisica.trim();
      validaCPF(data.nv_cpf_pessoafisica);

      data.nv_cep_pessoafisica = data.nv_cep_pessoafisica.replaceAll('-', '');
      data.nv_cep_pessoafisica = data.nv_cep_pessoafisica.trim();

      if (atualizando) data.in_pessoafisica_id = pessoa.in_pessoafisica_id;

      PessoaFisicaAPI.salvarPessoa(data)
        .then((resposta) => {
          // //console.log(resposta);
          // //console.log('ok');
          history.push(RotasURL.pessoaFisica.index);
        })
        .catch((err) => {
          //console.log(err);
        });
    }catch(error){
      //console.log(error);
    }
  };

  async function obterListaDePessoas() {
    try {
      let lista = await PessoaFisicaAPI.obterListaPessoa();
      setListaAtualPessoa(lista);
      ////console.log(lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  // function changeId(event) {
  //   setIdEmpresa(event.target.value);
  // }

  function atualizaPessoa() {
    let data = props.location.state.item;
    setPCheck(props.location.state.item.in_paciente == 1);
    setMCheck(props.location.state.item.in_medico == 1);
    setACheck(props.location.state.item.in_assistente == 1);
    setPessoa(props.location.state.item);
    setIdPessoa(props.location.state.item.in_pessoafisica_id);
    setAtualizando('true');
    setValue('nv_nome_pessoafisica', data.nv_nome_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_cpf_pessoafisica', data.nv_cpf_pessoafisica, {
      shouldValidate: false,
    });
    setValue('nv_genero_pessoafisica', data.nv_genero_pessoafisica, {
      shouldValidate: true,
    });
    setValue('in_pessoafisica_id', data.in_pessoafisica_id, {
      shouldValidate: true,
    });
    setValue('nv_telefone_pessoafisica', data.nv_telefone_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_email_pessoafisica', data.nv_email_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_cep_pessoafisica', data.nv_cep_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_logradouro_pessoafisica', data.nv_logradouro_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_numero_pessoafisica', data.nv_numero_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_complemento_pessoafisica', data.nv_complemento_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_bairro_pessoafisica', data.nv_bairro_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_cidade_pessoafisica', data.nv_cidade_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_uf_pessoafisica', data.nv_uf_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_crm_pessoafisica', data.nv_crm_pessoafisica, {
      shouldValidate: true,
    });
  }

  return (
    <>
      {/*Título da Página*/}
      <h2 className="margin-bottom-10">
        <NavLink to={RotasURL.pessoaFisica.index}>
          <i className="fas fa-arrow-alt-circle-left fa-fw" />
        </NavLink>
        {atualizando ? 'Atualizar Pessoa' : 'Nova Pessoa'}
        <div className="btn-home">
          <NavLink to={RotasURL.pa_main}>
            <i className="fas fa-home fa-fw" />
          </NavLink>
        </div>
        {/*Home*/}
      </h2>

      {/* {(pessoa || !atualizando) && ( */}
      <form className="templatemo-login-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="row form-group">
          <div className="col-lg-6 col-md-6 ">
            <label htmlFor="inputFirstName">Nome Completo</label>{' '}
            {/*Nome Completo*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_nome_pessoafisica}
              {...register('nv_nome_pessoafisica', {})}
            />
            <p className="error-message">
              {errors.nv_nome_pessoafisica?.message}
            </p>
            <div className="formLine">
              <i className="fas fa-search-plus" />
            </div>
          </div>
          <div className="col-lg-3 col-md-3 ">
            <label htmlFor="inputFirstName">CPF</label> {/*CPF*/}
            <InputMask
              className="form-control"
              placeholder="000.000.000-00"
              mask={'999.999.999-99'}
              defaultValue={pessoa && pessoa.nv_cpf_pessoafisica}
              {...register('nv_cpf_pessoafisica', {})}
            />
            <p className="error-message">
              {errors.nv_cpf_pessoafisica?.message}
            </p>
            <div className="formLine"></div>
          </div>
          <div className="col-lg-3 col-md-3 ">
            <label htmlFor="inputFirstName">Gênero</label> {/*Gênero*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_genero_pessoafisica}
              {...register('nv_genero_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          {/* <div className="col-lg-3 col-md-3 ">
            <label htmlFor="control-label templatemo-block">Função</label> */}
          {/*Perfis*/}
          {/* <div className="custom-select">
              <select
                className="form-control"
                defaultValue={pessoa && pessoa.in_tipo_pessoafisica_id}
                {...register('in_tipo_pessoafisica_id', {})}
              >
                <option value={1}>---</option>
                <option value={2}>Médico(a)</option>
                <option value={3}>Enfermeiro(a)</option>
                <option value={4}>Téc. Enfermagem</option>
              </select>
            </div>
            <div className="formLine"></div>
          </div> */}
          <div className="col-lg-1 col-md-1 ">
            <label htmlFor="inputFirstName">DDD</label> {/*Telefone*/}
            <InputMask
              className="form-control"
              placeholder="(00)"
              mask={pessoa ? '(99)' : ''}
              defaultValue={pessoa && pessoa.nv_ddd_pessoafisica}
              {...register('nv_ddd_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-2 col-md-2 ">
            <label htmlFor="inputFirstName">Telefone</label> {/*Telefone*/}
            <InputMask
              className="form-control"
              placeholder="00000-0000"
              mask={pessoa ? '99999-9999' : ''}
              defaultValue={pessoa && pessoa.nv_telefone_pessoafisica}
              {...register('nv_telefone_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-4 col-md-4 ">
            <label htmlFor="inputFirstName">Email</label> {/*Email*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_email_pessoafisica}
              {...register('nv_email_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-5 col-md-5 ">
            <label htmlFor="inputFirstName">Logradouro</label> {/*Logradouro*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_logradouro_pessoafisica}
              {...register('nv_logradouro_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-3 col-md-3 ">
            <label htmlFor="inputFirstName">Número</label> {/*Número*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_numero_pessoafisica}
              {...register('nv_numero_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-5 col-md-5 ">
            <label htmlFor="inputFirstName">Complemento</label>{' '}
            {/*Complemento*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_complemento_pessoafisica}
              {...register('nv_complemento_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-4 col-md-4 ">
            <label htmlFor="inputFirstName">CEP</label> {/*CEP*/}
            <InputMask
              className="form-control"
              placeholder="00000-000"
              mask={pessoa ? '99999-999' : ''}
              defaultValue={pessoa && pessoa.nv_cep_pessoafisica}
              {...register('nv_cep_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-6 col-md-6 ">
            <label htmlFor="inputFirstName">Bairro</label> {/*Bairro*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_bairro_pessoafisica}
              {...register('nv_bairro_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-4 col-md-4 ">
            <label htmlFor="inputFirstName">Cidade</label> {/*Cidade*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_cidade_pessoafisica}
              {...register('nv_cidade_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-2 col-md-2 ">
            <label htmlFor="control-label templatemo-block">UF</label>{' '}
            {/*Estados*/}
            <div className="custom-select">
              <select
                className="form-control"
                defaultValue={pessoa && pessoa.nv_uf_pessoafisica}
                {...register('nv_uf_pessoafisica', {})}
              >
                <option value="AC">---</option>
                <option value="AC">AC</option>
                <option value="AL">AL</option>
                <option value="AM">AM</option>
                <option value="AP">AP</option>
                <option value="BA">BA</option>
                <option value="CE">CE</option>
                <option value="DF">DF</option>
                <option value="ES">ES</option>
                <option value="GO">GO</option>
                <option value="MA">MA</option>
                <option value="MG">MG</option>
                <option value="MS">MS</option>
                <option value="MT">MT</option>
                <option value="PA">PA</option>
                <option value="PB">PB</option>
                <option value="PE">PE</option>
                <option value="PI">PI</option>
                <option value="PR">PR</option>
                <option value="RJ">RJ</option>
                <option value="RN">RN</option>
                <option value="RO">RO</option>
                <option value="RR">RR</option>
                <option value="RR">RS</option>
                <option value="SC">SC</option>
                <option value="SE">SE</option>
                <option value="SP">SP</option>
                <option value="TO">TO</option>
              </select>
            </div>
            <div className="formLine"></div>
          </div>
          <div className="col-lg-4 col-md-4 ">
            <label htmlFor="inputFirstName">CRM / COREN</label> {/*CRM*/}
            <input
              className="form-control"
              placeholder=""
              type="text"
              defaultValue={pessoa && pessoa.nv_crm_pessoafisica}
              {...register('nv_crm_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-2 col-md-2 ">
            <label htmlFor="inputFirstName">Nascimento</label> {/*Nascimento*/}
            <input
              className="form-control"
              type="date"
              placeholder="Nascimento"
              defaultValue={
                pessoa &&
                pessoa.dt_nascimento_pessoafisica &&
                pessoa.dt_nascimento_pessoafisica.substring(0, 10)
              }
              {...register('dt_nascimento_pessoafisica', {})}
            />
            <div className="formLine"></div>
          </div>
          <div className="col-lg-1 col-md-1 ">
            <label htmlFor="control-label templatemo-block">Ativo</label>{' '}
            {/*Estados*/}
            <div className="custom-select">
              <select
                className="form-control"
                defaultValue={pessoa && pessoa.nv_ativo}
                {...register('nv_ativo', {})}
              >
                <option value={1}>Sim</option>
                <option value={2}>Não</option>
              </select>
            </div>
            <div className="formLine"></div>
          </div>
          <div className="col-lg-5 col-md-5 form-group">
            <br></br>
            <br></br>
            <div className="col-lg-4 col-md-4 form-group form-check">
              <input
                value={pCheck}
                onChange={changePCheck}
                defaultValue={pessoa && pessoa.in_paciente}
                type="checkbox"
                id="check1"
                name="check"
                checked={pCheck}
              />
              <label htmlFor="check1">&nbsp;Paciente</label>
            </div>
            <div className="col-lg-4 col-md-4 form-group form-check">
              <input
                value={mCheck}
                onChange={changeMCheck}
                defaultValue={pessoa && pessoa.in_medico}
                type="checkbox"
                id="check2"
                name="check"
                checked={mCheck}
              />
              <label htmlFor="check2">&nbsp;Médico</label>
            </div>
            <div className="col-lg-4 col-md-4 form-group form-check">
              <input
                value={aCheck}
                onChange={changeACheck}
                defaultValue={pessoa && pessoa.in_assistente}
                type="checkbox"
                id="check3"
                name="check"
                checked={aCheck}
              />
              <label htmlFor="check3">&nbsp;Assistente</label>
            </div>
          </div>
        </div>
        <div className="form-group text-left">
          <button type="reset" className="templatemo-white-button">
            Limpar
          </button>
          <button type="submit" className="templatemo-blue-button">
            Salvar
          </button>
        </div>
      </form>
      {/* )} */}
    </>
  );
};
export default IncluirPessoaPage;
