import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom/cjs/react-router-dom.min';
import TabelaAcomodacao from '../../Components/Tabela/TabelaAcomodacao';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';

import { RotasURL } from 'RoutesURL';

const AcomodacaoPage = () => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtual, setListaAtual] = useState([]);
  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();

  const [filtro, setFiltro] = useState('');

  const history = useHistory();  

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  const obterListaDeAcomodacoes = async () => {
    try {
      let lista = await AcomodacaoAPI.obterListaAcomodacao();
      setListaInicial(lista);
    } catch (error) {
      console.error(error);
      history.push({ pathname: RotasURL.login });
      // throw error;
    }
  };

  useEffect(() => {
    obterListaDeAcomodacoes();
    //controlaPagina();
  }, []);

  useEffect(() => {
    ////console.log(filtro);
    filtraAcomodacao();
  }, [filtro, listaInicial]);

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    ////console.log(numeroRegistros);
    ////console.log(listaAtual.length);
    // //console.log('pos filtro');
    // //console.log(listaFiltrada);
    controlaPagina();
  }, [numeroRegistros, paginaAtual, listaFiltrada]);

  useEffect(() => {
    setPaginaAtual(() => 1);
  }, [numeroRegistros]);

  function filtraAcomodacao() {
    //console.log(listaInicial);
    let listaTemp = listaInicial.filter((acomodacao) =>
      acomodacao.nv_nome_acomodacao.toLowerCase().includes(filtro.toLowerCase())
    );
    setListaFiltrada(listaTemp);
  }

  function controlaPagina() {
    // //console.log('controla page');
    // //console.log(listaFiltrada);
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;
    if (numeroRegistros)
      var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtual(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtual(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  function FiltroChange(event) {
    const valor = event.target.value;
    setFiltro(valor);
    ////console.log(filtro);
  }

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  return (
    <>
      {/* <!--Título da Página--> */}
      <h2 className="margin-bottom-10">
        Acomodação
        <div className="btn-home">
          <a href="index.html" title="Home">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
        {/*<!--Home-->*/}
      </h2>
      {/* <!--Botão--> */}
      <div>
        <NavLink to={RotasURL.acomodacao.nova}>
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
          >
            Nova Acomodação
          </button>
        </NavLink>
      </div>
      <br />
      <div id="tblacomodacao_wrapper" className="dataTables_wrapper no-footer">
        {/* <!-- Registros por página -->
          // Pode virar componente */}
        <div
          className="dataTables_length"
          id="tblacomodacao_length"
          style={{ float: 'left' }}
        >
          <label>
            Mostrando
            <select
              name="tblacomodacao_length"
              aria-controls="tblacomodacao"
              className="input-select"
              value={numeroRegistros}
              onChange={registrosChange}
            >
              <option selected disabled hidden>
                -
              </option>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{' '}
            registros por página
          </label>
        </div>

        <div
          id="tblacomodacao_filter"
          className="dataTables_filter"
          style={{ float: 'right' }}
        >
          <label>
            Localizar:{' '}
            <input
              type="search"
              name="filtro"
              className="input-search"
              placeholder=""
              value={filtro}
              onChange={FiltroChange}
              aria-controls="tblacomodacao"
            />
          </label>
        </div>

        <TabelaAcomodacao
          lista={listaAtual}
          obterListaDeAcomodacoes={obterListaDeAcomodacoes}
        />

        <div className="row form-group">
          <div
            className="dataTables_info info_paginas"
            id="tblacomodacao_info"
            role="status"
            aria-live="polite"
          >
            {paginaAtual > 1 && (
              <button
                className="templatemo-white-button"
                onClick={diminuiPagina}
              >
                &lt;
              </button>
            )}
            Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
            {paginaAtual < maxPagina && (
              <button className="templatemo-white-button" onClick={somarPagina}>
                &gt;
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AcomodacaoPage;
