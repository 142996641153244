import React, { useEffect, useState } from 'react';

import LayoutOperacional from '../../Components/Layout/Operacional';
import TabelaLiberacao from '../../Components/Tabela/TabelaLiberacao';

const AltaPage = () => {
  return (
    <>
      <h2 className="margin-bottom-10">
        Liberação de Leitos
        <div className="btn-home">
          <a href="po_main">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      <div className="row">
        <TabelaLiberacao />
      </div>
    </>
  );
};

export default AltaPage;
