import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LayoutAdministrativo from '../../Components/Layout/Administrativo';
import TabelaPessoaUsuario from '../../Components/Tabela/TabelaPessoaUsuario';

import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import { RotasURL } from 'RoutesURL';

const EscolherPessoaFisicaPage = () => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtual, setListaAtual] = useState([]);

  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();

  const [filtro, setFiltro] = useState('');

  const history = useHistory();

  useEffect(() => {
    //console.log('obtendo lista de pessoas');
    obterListaDePessoas();
  }, []);

  useEffect(() => {
    filtraPessoa();
  }, [filtro, listaInicial]);

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    ////console.log(numeroRegistros);
    ////console.log(listaAtual.length);
    //console.log('pos filtro');
    //console.log(listaFiltrada);
    controlaPagina();
  }, [numeroRegistros, paginaAtual, listaFiltrada, listaInicial]);

  const obterListaDePessoas = async () => {
    try {
      let lista = await PessoaFisicaAPI.obterListaPessoa();
      setListaInicial(lista);
      //console.log(lista);
    } catch (error) {
      console.error(error);
      history.push({ pathname: RotasURL.login });
      // throw error;
    }
  };

  function filtraPessoa() {
    //console.log(listaInicial);
    let listaTemp = listaInicial.filter((setor) =>
      setor.nv_nome_pessoafisica.toLowerCase().includes(filtro.toLowerCase())
    );
    setListaFiltrada(listaTemp);
  }

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  function FiltroChange(event) {
    const valor = event.target.value;
    setFiltro(valor);
  }

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  function controlaPagina() {
    //console.log('controla page');
    //console.log(listaFiltrada);
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;
    var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtual(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtual(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }

  return (
    <>
        <h2 className="margin-bottom-10">
          Escolha uma Pessoa Física
          <div className="btn-home">
            <a href="/">
              <i className="fas fa-home fa-fw" />
            </a>
          </div>
        </h2>

        <div id="tblsetor_wrapper" className="dataTables_wrapper no-footer">
          {/* <!-- Registros por página -->
          // Pode virar componente */}
          <div
            className="dataTables_length"
            id="tblsetor_length"
            style={{ float: 'left' }}
          >
            <label>
              Mostrando
              <select
                name="tblsetor_length"
                aria-controls="tblsetor"
                className="input-select"
                value={numeroRegistros}
                onChange={registrosChange}
              >
                <option selected disabled hidden>
                  -
                </option>
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{' '}
              registros por página
            </label>
          </div>

          <div
            id="tblsetor_filter"
            className="dataTables_filter"
            style={{ float: 'right' }}
          >
            <label>
              Localizar:{' '}
              <input
                type="search"
                name="filtro"
                className="input-search"
                placeholder=""
                value={filtro}
                onChange={FiltroChange}
                aria-controls="tblsetor"
              />
            </label>
          </div>

          <TabelaPessoaUsuario
            lista={listaAtual}
            obterListaDePessoas={obterListaDePessoas}
          />

          <div className="row form-group">
            <div
              className="dataTables_info info_paginas"
              id="tblsetor_info"
              role="status"
              aria-live="polite"
            >
              {paginaAtual > 1 && (
                <button
                  className="templatemo-white-button"
                  onClick={diminuiPagina}
                >
                  &lt;
                </button>
              )}
              Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
              {paginaAtual < maxPagina && (
                <button
                  className="templatemo-white-button"
                  onClick={somarPagina}
                >
                  &gt;
                </button>
              )}
            </div>
          </div>
        </div>
    </>
  );
};

export default EscolherPessoaFisicaPage;
