import React, { useEffect, useState, useRef } from 'react';
import LayoutOperacional from '../../Components/Layout/Operacional';
import { NavLink } from 'react-router-dom';
import StepScript from './StepScript';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import TabelaPessoaConsulta from '../../Components/Tabela/TabelaPessoaConsulta';
import TabelaMedicaAssistente from '../../Components/Tabela/TabelaMedicoAssistente';
import { schema } from './pessoaSchema';
import { set, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SetorAPI from '../../Services/API/SetorAPI';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import { validaCPF } from 'Services/Auth/ValidaCpf';
import AdmissaoAPI from 'Services/API/AdmissaoAPI';
import ContatosAPI from 'Services/API/ContatosAPI';
import UsuarioAPI from 'Services/API/UsuarioAPI';
import { Button } from '@mui/material';
import { RotasURL } from 'RoutesURL';
import InputMask from 'react-input-mask';
import LoadingIcons from 'react-loading-icons';

const OVERLAY_TEXTO_PADRAO = "Carregando ...";

const AdmissaoPage = () => {
  const [update, setUpdate] = useState(true);
  const [showPageOverlay, setShowPageOverlay] = useState(false);
  const [pageOverlayText, setPageOverlayText] = useState(OVERLAY_TEXTO_PADRAO)

  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAcomodacao, setListaAcomodacao] = useState([]);
  const [listaAcomodacaoFiltrada, setListaAcomodacaoFiltrada] = useState([]);
  const [filtro, setFiltro] = useState('');
  const [dados, setDados] = useState();
  const [nascimento, setNascimento] = useState('');
  const [admissao, setAdmissao] = useState('');
  const [contatos, setContatos] = useState([
    { Nome: '', CPF: '', DDD: '', Tel: '', Tipo: '' },
  ]);
  const [alergias, setAlergias] = useState([
    { tipo_alergia: null, agente_causador: '' },
  ]);
  const [diagnosticos, setDiagnosticos] = useState(['']);
  const [listaSetor, setListaSetor] = useState([]);
  const [idSetor, setIdSetor] = useState();
  const [leito, setLeito] = useState();

  const [nomePaciente, setNomePaciente] = useState();
  const [cpfPaciente, setCpfPaciente] = useState();
  const [cpfValidado, setCpfValidado] = useState(false);
  const [nameMedico, setNameMedico] = useState();
  const [crmMedico, setCrmMedico] = useState();

  const NomePacienteSearch = useRef(null);
  const PacienteCPFField = useRef(null);
  const SelectSetor = useRef(null);
  const mainForm = useRef();
  const nv_cpf_pessoafisica = useRef();
  const nv_ddd_pessoafisica = useRef();
  const nv_telefone_pessoafisica = useRef();

  const [idPessoa, setIdPessoa] = useState(true);
  const [idMedico, setIdMedico] = useState(true);

  const [dataHoje, setDataHoje] = useState();

  const [statusChamado, setStatusChamado] = useState(true);

  const [flagUsouLocalizarPaciente, setFlagUsouLocalizarPaciente] =
    useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  //variavel para identificar foco do usuario e permitir atalhos do teclado para edição de tabelas
  let foco = '';

  //Traz os efeitos de transição do step
  useEffect(() => {
    StepScript();
    obterListaSetor();
    obterListaAcomodacao();
    obterDataAtual();
  }, []);

  //responsavel pelo atalho de teclado para tabelas
  useEffect(() => {
    const listener = (event) => {
      if (foco != '' && event.code === 'Enter') {
        event.preventDefault();
        adicionarCampo();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  useEffect(() => {
    let tempListaAcomodacao = listaAcomodacao.filter(
      (item) => item.in_setor_id == idSetor
    );
    setListaAcomodacaoFiltrada(tempListaAcomodacao);
  }, [idSetor]);

  useEffect(() => {
    console.log(admissao);
  }, [admissao]);

  function adicionarCampo() {
    eval(foco);
  }

  function onFocus(event) {
    let id = event.target.id;
    let func = 'adicionarCampo' + id + '();';
    foco = func;
  }

  function onBlur(event) {
    foco = '';
  }

  function obterDataAtual() {
    var today = new Date();
    console.log(today);
    let hoje =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1 < 10
        ? '0' + (today.getMonth() + 1)
        : today.getMonth() + 1) +
      '-' +
      (today.getDate() < 10 ? '0' + today.getDate() : today.getDate());

    console.log(hoje);
    // setAdmissao(hoje);
    setDataHoje(hoje);
  }

  const obterPessoa = async () => {
    try {
      let lista = await PessoaFisicaAPI.obterListaPessoa();
      setListaInicial(lista);
      //console.log(lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    obterPessoa();
    //controlaPagina();
  }, []);

  useEffect(() => {
    if (filtro == '') {
      setListaFiltrada(listaInicial);
    } else {
      filtraPessoa();
    }
    //console.log(filtro.length);
  }, [filtro]);

  function filtraPessoa() {
    //console.log(listaInicial);
    let listaTemp = listaInicial.filter(
      (pessoa) =>
        (pessoa.nv_nome_pessoafisica
          .toLowerCase()
          .includes(filtro.toLowerCase()) &&
          pessoa.nv_ativo == 1) ||
        (pessoa.nv_cpf_pessoafisica?.includes(filtro.toLowerCase()) &&
          pessoa.nv_ativo == 1)
    );
    //console.log(listaTemp);
    setListaFiltrada(listaTemp);
  }

  function FiltroChange(event) {
    const valor = event.target.value;
    setFiltro(valor);
  }

  function FiltroChange2(event) {
    const valor = event.target.value;
    setFiltro(valor);
  }

  const nameClick = (data) => {
    console.log('#@# ', data);

    setFlagUsouLocalizarPaciente(true);

    setDados(data);
    setIdPessoa(data.in_pessoafisica_id);
    setNomePaciente(data.nv_nome_pessoafisica);
    
    setValue('nv_nome_pessoafisica', data.nv_nome_pessoafisica, {
      shouldValidate: true,
    });
    if (data.nv_cpf_pessoafisica) {
      setValue('nv_cpf_pessoafisica', data.nv_cpf_pessoafisica, {
        shouldValidate: true,
      });
    }
    setValue('nv_ddd_pessoafisica', data.nv_ddd_pessoafisica, {
      shouldValidate: true,
    });
    setValue('nv_telefone_pessoafisica', data.nv_telefone_pessoafisica, {
      shouldValidate: true,
    });
    
    if (data.dt_nascimento_pessoafisica !== null) {
      setNascimento(
        data.dt_nascimento_pessoafisica?.substring(8, 10) +
          '/' +
          data.dt_nascimento_pessoafisica?.substring(5, 7) +
          '/' +
          data.dt_nascimento_pessoafisica?.substring(0, 4)
      );
    } else {
      setNascimento(null);
    }
    
    setCpfPaciente(null);
    if(data.nv_cpf_pessoafisica != ""){
      setCpfPaciente(data.nv_cpf_pessoafisica);
    }

    setCpfValidado(false);
    cpfOnBlur(data.nv_cpf_pessoafisica);

    if (data.nv_cpf_pessoafisica !== null) {
      setCpfPaciente(
        trataInput(
          data.nv_cpf_pessoafisica.replaceAll('-', '').replaceAll('.', '')
        )
      );

      setCpfValidado(true);
    }

    //console.log(data);
  };

  const nameClick2 = (data) => {
    setDados(data);
    setIdMedico(data.in_pessoafisica_id);
    setNameMedico(data.nv_nome_pessoafisica);
    setCrmMedico(data.nv_crm_pessoafisica);
  };

  function adicionarCampoContato(event) {
    let novoContato = { Nome: '', CPF: '', DDD: '', Tel: '', Tipo: '' };
    setContatos([...contatos, novoContato]);
    setUpdate(!update);
  }

  function excluirCampoContato(index) {
    let tempContatos = contatos;
    tempContatos.splice(index, 1);
    //console.log(tempContatos);
    setContatos(tempContatos);
    setUpdate(!update);
  }

  const changeFormContato = (index, event) => {
    //console.log(contatos);
    let data = [...contatos];
    data[index][event.target.name] = trataInput(event.target.value);
    setContatos(data);
    setUpdate(!update);
  };

  function adicionarCampoAlergia(event) {
    let novaAlergia = { tipo_alergia: null, agente_causador: '' };
    setAlergias([...alergias, novaAlergia]);
    setUpdate(!update);
  }

  function excluirCampoAlergia(index) {
    let tempAlergias = alergias;
    tempAlergias.splice(index, 1);
    //console.log(tempAlergias);
    setAlergias(tempAlergias);
    setUpdate(!update);
  }

  const changeFormAlergia = (index, event) => {
    //console.log(alergias);
    let data = [...alergias];
    data[index][event.target.name] = event.target.value;
    setAlergias(data);
    setUpdate(!update);
  };

  function adicionarCampoDiagnostico(event) {
    foco = "adicionarCampoDiagnostico"
    
    setDiagnosticos([...diagnosticos, '']);
    setUpdate(!update);
  }

  function excluirCampoDiagnostico(index) {
    diagnosticos.splice(index, 1);
    setUpdate(!update);
  }

  const changeFormDiagnostico = (index, event) => {
    //console.log(diagnosticos);
    let data = [...diagnosticos];
    data[index] = event.target.value;
    setDiagnosticos(data);
    setUpdate(!update);
  };

  function onChangeNascimento(event) {
    setNascimento(event.target.value);
    //console.log(event.target.value);
  }

  function onChangeAdmissao(event) {
    setAdmissao(event.target.value);
  }

  function botaoContatos(index) {
    if (index == contatos.length - 1) {
      return (
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 btn-resp-vert">
          <button
            type="button"
            className="templatemo-black-button btn-plus-steps"
            onClick={adicionarCampoContato}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 btn-resp-vert">
          <button
            type="button"
            className="templatemo-black-button btn-plus-steps"
            onClick={excluirCampoContato}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function botaoAlergia(index) {
    if (index == alergias.length - 1) {
      // console.log(alergias[index])
      return (
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 btn-resp-vert">
          <button
            type="button"
            disabled={alergias[index].tipo_alergia == 0}
            style={
              alergias[index].tipo_alergia == 0
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            className="templatemo-black-button btn-plus-steps"
            onClick={adicionarCampoAlergia}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 btn-resp-vert">
          <button
            type="button"
            className="templatemo-black-button btn-plus-steps"
            onClick={excluirCampoAlergia}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function botaoDiagnostico(index) {
    if (index == diagnosticos.length - 1) {
      return (
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 btn-resp-vert">
          <button
            type="button"
            className="templatemo-black-button btn-plus-steps"
            onClick={adicionarCampoDiagnostico}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div className="col-lg-1 col-md-1 col-sm-1 col-xs-2 btn-resp-vert">
          <button
            type="button"
            className="templatemo-black-button btn-plus-steps"
            onClick={excluirCampoDiagnostico}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  // function handleStartNewPaciente() {
  //   console.log(NomePacienteSearch.current.value);

  //   setValue('nv_nome_pessoafisica', NomePacienteSearch.current.value, {
  //     shouldValidate: true,
  //   });
  // }

  function changeId(event) {
    setIdSetor(event.target.value);
  }

  function handleNameMedico(event) {
    setNameMedico(trataInput(event.target.value));
  }

  function handleCrmMedico(event) {
    setCrmMedico(event.target.value);
  }

  function handleNomePaciente(event) {
    setNomePaciente(trataInput(event.target.value));
  }

  //------- Funções etapa 04 ---------

  async function obterListaSetor() {
    try {
      let lista = await SetorAPI.obterListaSetor();
      setListaSetor(lista);
      //console.log('Setores: ', lista);
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  async function obterListaAcomodacao() {
    try {
      let lista = await AcomodacaoAPI.obterListaAcomodacao();
      let listaFilter = lista.filter((leito) => leito.in_atendimento_id == 0);
      setListaAcomodacao(listaFilter);
      //console.log('Acomodações: ', lista);
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  function changeLeito(item) {
    //console.log(item);
    setLeito(item);
  }

  function trataInput(input) {
    try {
      //separa cada palavra da string em letra minúscula
      let inputSeparado = input?.toLowerCase().split(' ');

      //para cada palavra da string
      for (let i = 0; i < inputSeparado.length; i++) {
        //pega a primeira letra da palavra como maiúscula e concatena o resto da palavra
        inputSeparado[i] =
          inputSeparado[i].charAt(0).toUpperCase() +
          inputSeparado[i].substring(1);
      }

      //retorna as palavras juntas novamente
      return inputSeparado.join(' ');
    } catch {
      //console.log('test');
    }
  }

  function habilitaCamposPaciente() {
    if (idPessoa == true) return 'disabled';
    if (idPessoa == null) return '';
    if (parseInt(idPessoa) > 0) return '';
    return '';
  }

  function onOverlay() {
    setShowPageOverlay(true)
  }

  function offOverlay() {
    setShowPageOverlay(false)
  }

  const onSubmit = async (dataJSON) => {
    console.log('##@ ', dataJSON);
    console.log('##@ ', leito);

    if (!idSetor) {
      setStatusChamado(false);
      alert('Por favor, escolha um setor');
      return;
    }
    if (!leito) {
      setStatusChamado(false);
      alert('Por favor, escolha um leito.');
      return;
    }
    if (!admissao) {
      setStatusChamado(false);
      alert('Data de admissão não pode ser vazia');
      return;
    }
    if (admissao > dataHoje) {
      setStatusChamado(false);
      alert('Data de admissão maior que data atual');
      return;
    }

    onOverlay();
    
    await onSubmitInterno(dataJSON).then((r) => {
      offOverlay();
      //console.log('Retorno da Submit: ', r);
    });
  };

  const onSubmitInterno = async (dataJSON) => {
    try {
      // console.log(dataJSON);

      const pessoa = dataJSON;
      pessoa.in_paciente = '1';
      pessoa.dt_nascimento_pessoafisica = nascimento;
      pessoa.nv_nome_pessoafisica = nomePaciente;

      // validaCPF(cpfPF);
      handlePessoaCPF(cpfPaciente);

      pessoa.nv_cpf_pessoafisica = cpfPaciente;

      // //console.log({ pessoa })

      let data = {
        in_paciente_id: '',
        in_medicoassistente_id: 0,
        in_acomodacao_id: leito.in_acomodacao_id,
        dt_data_inicio: admissao,
      };
      let medico = {
        nv_nome_pessoafisica: nameMedico,
        nv_crm_pessoafisica: crmMedico,
        in_medicoassistente_id: '1',
      };

      setStatusChamado(true);
      if (idPessoa) {
        data.in_paciente_id = idPessoa;
        pessoa.in_pessoafisica_id = idPessoa;
        pessoa.in_paciente = 1;

        PessoaFisicaAPI.salvarPessoa(pessoa); // Salva alteracoes na pessoa
        console.log('pessoa identificada');
      } else {
        let idPessoaTemp = await PessoaFisicaAPI.salvarPessoa(pessoa);
        console.log(idPessoaTemp);
        console.log("Pessoa Cadastrada");
        data.in_paciente_id = idPessoaTemp;
      }

      contatos.map(async (item) => {
        //console.log(item.Nome);
        if (item.Nome != '') {
          let tempData = {
            in_pessoa_id: data.in_paciente_id,
            nv_nome_contato: 'string',
            nv_ddd_contato: 'st',
            nv_telefone_contato: 'string',
            nv_email_contato: '',
            nv_cpf: '',
            nv_tipo_contato: '',
          };
          tempData.nv_tipo_contato = item.Tipo;
          tempData.nv_nome_contato = item.Nome;
          tempData.nv_ddd_contato = item.DDD;
          tempData.nv_telefone_contato = item.Tel;
          tempData.nv_cpf = item.CPF.replaceAll('.', '');
          tempData.nv_cpf = tempData.nv_cpf.replaceAll('-', '');

          await validaCPF(tempData.nv_cpf);

          //console.log('### Contato', tempData);

          //console.log(tempData);
          ContatosAPI.salvarContato(tempData)
            .then((resposta) => {
              //console.log(resposta);
            })
            .catch((err) => {
              //console.log(err);
            });
        }
      });

      if (idMedico) {
        data.in_medicoassistente_id = idMedico;
      } else {
        if (nameMedico === undefined || crmMedico === undefined) {
          data.in_medicoassistente_id = 0;
        } else {
          medico.in_assistente = 1;

          let idMedicoTemp = await PessoaFisicaAPI.salvarPessoa(medico);
          data.in_medicoassistente_id = idMedicoTemp;

          console.log(" ##! ", data);

          // if (idMedicoTemp > 0) {
          //   if (medico.nv_crm_pessoafisica.length > 0) {
          //     let usuarioAssistente = {
          //       nv_login: medico.nv_crm_pessoafisica,
          //       nv_senha: '1234',
          //       in_pessoa_id: data.in_medicoassistente_id,
          //       in_perfil_id: 5, // Medico Operacional
          //     };

          //     await UsuarioAPI.salvaUsuario(usuarioAssistente)
          //       .then((resposta) => {

          //       })
          //       .catch((err) => {

          //       });
          //   }
          // }
        }
      }

      //console.log('#@# ', data);

      let atendimento = 0;
      
      if (data.in_medicoassistente_id === true) {
        data.in_medicoassistente_id = 0;
      }
      // if (!admissao) {
      //   throw 'Data de admissão vazia';
      //   setStatusChamado(false);
      // }
      // if (admissao > dataHoje) {
      //   throw 'Data após data atual';
      //   setStatusChamado(false);
      // }

      await AdmissaoAPI.salvarAdmissao(data)
        .then((resposta) => {
          //console.log(resposta);
          atendimento = resposta;
        })
        .catch((err) => {
          //console.log(err);
        });

      //console.log('Atendimento: ', atendimento);


      // if (atendimento !== undefined && atendimento > 0) {
        // let usuarioPaciente = {
        //   nv_login: pessoa.nv_cpf_pessoafisica,
        //   nv_senha: '1234',
        //   in_pessoa_id: data.in_paciente_id,
        //   in_perfil_id: 18,
        // };

        // //console.log('#@# 1) ', usuarioPaciente);

        // await UsuarioAPI.salvaUsuario(usuarioPaciente)
        //   .then((resposta) => {

        //   })
        //   .catch((err) => {

        //   });
      // }

      alergias.map(async (item) => {
        let tempData = {
          in_atendimento_id: atendimento,
          in_tipoalergia_id: item.tipo_alergia,
          nv_agentecausador: item.agente_causador,
        };

        if (item.tipo_alergia == 0) {
          tempData.nv_agentecausador = 'Nega Alergia';
        }
        
        if (tempData.nv_agentecausador.trim() !== ""){
          await AdmissaoAPI.salvarAlergia(tempData)
        }

      });

      diagnosticos.map((item) => {
        let tempData = {
          in_atendimento_id: atendimento,
          nv_descricao_diagnostico: item,
        };
        console.log(tempData);
        AdmissaoAPI.salvarDiagnostico(tempData)
          .then((resposta) => {
            //console.log(resposta);
          })
          .catch((err) => {
            //console.log(err);
          });
      });
      
      await AcomodacaoAPI.atualizarAcomodacao(
        leito.in_acomodacao_id,
        atendimento
      )
        .then((resposta) => {
          //console.log(resposta);
          return true;
        })
        .catch((err) => {
          setStatusChamado(false);
          //console.log(err);
        });

      if (statusChamado) {
        window.location.reload();
        alert('O cadastro foi finalizado com sucesso.');
      }
    } catch (e) {
      setStatusChamado(false);
      console.log(e);
      alert(`O cadastro não pode ser finalizado devido a um erro no preenchimento: ${e}`);
      setStatusChamado(true);
    }
  };

  async function handlePessoaCPF(cpf) {
    try {
      await validaCPF(cpf);
    } catch (error) {
      alert(`CPF ${cpf} Inválido`);
      throw error;
    }
  }

  function cpfPacienteChange(event) {
    setCpfPaciente(
      trataInput(event.target.value.replaceAll('-', '').replaceAll('.', ''))
    );
  }

  let avoidLoopErrorFlag = false;
  async function cpfOnBlur(cpfText = '') {
    let cpf = cpfText;
    if (cpfText == '' || cpfText == undefined) {
      cpf = cpfPaciente;
    }

    console.log('### CPF ', cpf);

    if (!avoidLoopErrorFlag) {
      // console.log('Passou aqui 1');
      try {
        setCpfValidado(false);

        await validaPaciente(cpf)
          .then((ehPacienteValido) => {
            avoidLoopErrorFlag = false;
            if (ehPacienteValido) setCpfValidado(true);
          })
          .catch((error) => {
            avoidLoopErrorFlag = true;
            setCpfValidado(false);
            // console.log(error.message);
            alert(error.message);
            throw error;
          });
      } catch (error) {
        avoidLoopErrorFlag = true;
        // console.log(error);

        // alert(error);
        setCpfValidado(false);
      }
    } else {
      // console.log('Passou aqui 2');
      nv_cpf_pessoafisica.current?.focus();
      // avoidLoopErrorFlag = false;
    }

    // if (await validaPaciente(cpf)) {
    //   setCpfValidado(true);
    // } else {
    //   setCpfValidado(false);
    // }
    // console.log('blur');
  }

  function cpfOnFocus() {
    setCpfValidado(false);
  }

  async function validaPaciente(cpfText) {
    console.log('@@@', cpfText);

    let leito = await AcomodacaoAPI.obterAcomodacaoPorCpf(cpfText);
    // console.log(leito);
    if (leito) {
      alert(
        'Paciente encontra-se internado em outro leito: ' +
          (leito.nv_nome_acomodacao ?? '')
      );
      return false;
    }

    try {
      let isValidCPF = await validaCPF(cpfText);

      if (isValidCPF) {
        console.log('CPF Validado');
        console.log('Usou a busca? ', flagUsouLocalizarPaciente);

        // Se CPF foi preenchido pela localizacao, nao precisa checar novamente
        if (flagUsouLocalizarPaciente) {
          setFlagUsouLocalizarPaciente(false);
          return true;
        }

        let pessoaJaExiste = await PessoaFisicaAPI.obterPessoaPorCPF(cpfText)
          .then((retorno) => {
            if (retorno) {
              alert(
                'Já existe paciente com este CPF. Favor utilizar a opção "LOCALIZAR PACIENTE".'
              );
              return true;
            }

            return false;
          })
          .catch((err) => console.log(err));

        console.log('### Pessoa ja Existe) ', pessoaJaExiste);

        if (pessoaJaExiste) return false;
      }
    } catch (error) {
      throw new Error('CPF Inválido');

      // alert('CPF Inválido');
      // console.log(error);
      // return false;
    }

    return true;
  }

  function PageOverlay(){
    return (
      <div id="overlay" class="pageOverlay" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <LoadingIcons.TailSpin stroke="#fff" transform="scale(1.5)" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ fontSize: '20px', color: '#fff' }}>
            { pageOverlayText }
          </span>
      </div>
    )
  }

  return (
    <div>
      { showPageOverlay && <PageOverlay /> }

      <h2 className="margin-bottom-10">
        Admissão de Paciente {/*Título da Página*/}
        <div className="btn-home">
          <NavLink to={RotasURL.po_main} title="Home">
            <i className="fas fa-home fa-fw" />
          </NavLink>
        </div>
        {/*Home*/}
      </h2>
      {/*ETAPAS*/}
      {/* MultiStep Form */}
      <div>
        <div style={{ minHeight: '800px', overflow: 'hidden' }}>
          <form id="msform" ref={mainForm} onSubmit={handleSubmit(onSubmit)}>
            {/* progressbar */}
            <ul id="progressbar">
              <li className="active">Paciente</li>
              <li>Médico Assistente</li>
              <li>Alergias e Diagnótico</li>
              <li>Definição de Leito</li>
            </ul>
            {/* ETAPA 1 - PACIENTE */}
            <fieldset>
              <h2 className="fs-title">Paciente</h2>
              <div className="form-group text-center">
                <button
                  type="button"
                  className="templatemo-blue-button pe-2"
                  data-toggle="modal"
                  data-target="#modalPaciente"
                  onClick={() => setFlagUsouLocalizarPaciente(true)}
                >
                  Localizar paciente
                  <i className="fas fa-search" />
                </button>
                <button
                  type="reset"
                  onClick={() => {
                    setIdPessoa(null);
                    setNomePaciente(null);
                    setNascimento(null);
                    setFlagUsouLocalizarPaciente(false);
                    document
                      .querySelector("#msform input[name='nome']")
                      .focus();
                  }}
                  className="templatemo-blue-button pe-2"
                >
                  Novo paciente
                  <i className="fas fa-plus-circle fa-fw" />
                </button>
              </div>
              <div className="row form-group">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <input
                    className="form-control"
                    // disabled={idPessoa}
                    disabled={habilitaCamposPaciente()}
                    type="text"
                    name="nome"
                    placeholder="Nome"
                    value={nomePaciente}
                    onChange={handleNomePaciente}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                  <InputMask
                    mask="999.999.999-99"
                    className="form-control"
                    disabled={habilitaCamposPaciente()}
                    type="text"
                    name="cpf"
                    placeholder="CPF"
                    value={cpfPaciente}
                    // ref={PacienteCPFField}
                    ref={nv_cpf_pessoafisica}
                    onChange={cpfPacienteChange}
                    onBlur={cpfOnBlur}
                    onFocus={cpfOnFocus}
                    {...register('nv_cpf_pessoafisica', {
                      onChange: (e) => cpfPacienteChange(e),
                      onBlur: (e) => cpfOnBlur(),
                      onFocus: (e) => cpfOnFocus(e),
                    })}
                  />
                </div>
                <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3">
                  <input
                    className="form-control"
                    disabled={habilitaCamposPaciente()}
                    type="text"
                    name="ddd"
                    placeholder="DDD"
                    {...register('nv_ddd_pessoafisica', {})}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-6 col-xs-9">
                  <input
                    className="form-control"
                    disabled={habilitaCamposPaciente()}
                    type="text"
                    name="tel"
                    placeholder="Telefone"
                    {...register('nv_telefone_pessoafisica', {})}
                  />
                </div>
                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <input
                    className="form-control"
                    disabled={habilitaCamposPaciente()}
                    type={idPessoa || !nascimento ? 'text' : 'date'}
                    //Verifica se existe alguma data preenchida, se nao, atribui o campo o tipo date quando selecionado e text quando deselecionado
                    onFocus={(e)=>{if(!nascimento) e.target.type='date'}}
                    onBlur={(e)=>{if(!nascimento) e.target.type='text'; console.log("blur", nascimento)}}
                    name="dt_nasc"
                    placeholder="Nascimento"
                    //onFocus={(e) => (e.target.type="date")}
                    value={nascimento}
                    onChange={onChangeNascimento}
                    // {...register('dt_nascimento_pessoafisica', {})}
                  />
                </div>
              </div>
              {/*CONTATOS */}
              <h2 className="fs-title">Contatos</h2>
              <div>
                {contatos.map((item, index) => {
                  return (
                    <div className="row resposive-btn">
                      <div className="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                        <div className="row">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              name="Nome"
                              placeholder="Nome"
                              value={item.Nome}
                              onChange={(event) =>
                                changeFormContato(index, event)
                              }
                            />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <InputMask
                              mask="999.999.999-99"
                              className="form-control"
                              type="text"
                              name="CPF"
                              placeholder="CPF"
                              value={item.CPF}
                              onBlur={() => {
                                handlePessoaCPF(item.CPF);
                              }}
                              onChange={(event) =>
                                changeFormContato(index, event)
                              }
                            />
                          </div>
                          <div className="col-lg-1 col-md-1 col-sm-2 col-xs-3">
                            <input
                              className="form-control"
                              type="text"
                              name="DDD"
                              placeholder="DDD"
                              value={item.DDD}
                              onChange={(event) =>
                                changeFormContato(index, event)
                              }
                            />
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-6 col-xs-9">
                            <input
                              className="form-control"
                              type="text"
                              name="Tel"
                              placeholder="Telefone"
                              value={item.Tel}
                              onChange={(event) =>
                                changeFormContato(index, event)
                              }
                            />
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              name="Tipo"
                              placeholder="Pai, mãe, amigo..."
                              value={item.Tipo}
                              onChange={(event) =>
                                changeFormContato(index, event)
                              }
                            />
                          </div>
                        </div>
                      </div>

                      {botaoContatos(index)}
                    </div>
                  );
                })}
              </div>
              {/*#*/}

              {/*Checa se o cpf é valido/ja cadastrado e renderiza o botao apropriado*/}
              {cpfValidado ? (
                <button
                  type="button"
                  name="next"
                  className="next action-button"
                  defaultValue="Próximo"
                  onClick={() => setFiltro('')}
                >
                  Próximo
                </button>
              ) : (
                <button
                  type="button"
                  disabled="disabled"
                  name="next"
                  style={{ backgroundColor: '#ccc' }}
                  className="next action-button"
                  defaultValue="É necessário paciente para prosseguir"
                >
                  É necessário paciente para prosseguir
                </button>
              )}
            </fieldset>
            {/* ETAPA 2 - MÉDICO */}
            <fieldset>
              <h2 className="fs-title">Médico Assistente</h2>
              {/* <h3 class="fs-subtitle">Your presence on the social network</h3> */}
              <div className="form-group text-center">
                <button
                  type="button"
                  className="templatemo-blue-button pe-2"
                  data-toggle="modal"
                  data-target="#modalMedico"
                >
                  Localizar médico
                  <i className="fas fa-search" />
                </button>
                <button
                  type="reset"
                  onClick={() => setIdMedico(null)}
                  className="templatemo-blue-button pe-2"
                >
                  Novo médico
                  <i className="fas fa-plus-circle fa-fw" />
                </button>
              </div>
              <div className="row form-group">
                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 form-group">
                  <input
                    className="form-control"
                    disabled={idMedico}
                    type="text"
                    name="nome_medico"
                    placeholder="Nome do médico"
                    value={nameMedico}
                    onChange={handleNameMedico}
                  />
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 form-group">
                  <input
                    className="form-control"
                    disabled={idMedico}
                    type="text"
                    name="crm"
                    placeholder="CRM"
                    value={crmMedico}
                    onChange={handleCrmMedico}
                  />
                </div>
              </div>
              <input
                type="button"
                name="previous"
                className="previous action-button-previous"
                defaultValue="Anterior"
              />
              <input
                type="button"
                name="next"
                className="next action-button"
                defaultValue="Próximo"
              />
            </fieldset>
            {/* ETAPA 3 - ALERGIA */}
            <fieldset>
              {/* <h3 class="fs-subtitle">Insira abaixo o tipo de alergia e o agente causador</h3> */}
              <div className="row form-group">
                {/*ALERGIA */}
                <h2 className="fs-title">Alergia</h2>
                <div>
                  {alergias.map((item, index) => {
                    return (
                      <div className="row resposive-btn">
                        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <select
                              className="form-control"
                              type="text"
                              id="Alergia"
                              onFocus={onFocus}
                              onBlur={onBlur}
                              name="tipo_alergia"
                              placeholder="Tipo Alergia"
                              value={item.tipo_alergia}
                              onChange={(event) =>
                                changeFormAlergia(index, event)
                              }
                            >
                              <option value={null}>(selecione)</option>
                              <option value="1">Alimentos</option>
                              <option value="2">Medicamentos</option>
                              <option value="3">Outros</option>
                              <option value="0">Nega Alergia</option>
                            </select>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              disabled={item.tipo_alergia == 0}
                              name="agente_causador"
                              value={item.agente_causador}
                              onChange={(event) =>
                                changeFormAlergia(index, event)
                              }
                            />
                          </div>
                        </div>

                        {botaoAlergia(index)}
                      </div>
                    );
                  })}
                </div>
                {/*DIAGNÓSTICO */}
                <h2 className="fs-title">Diagnóstico</h2>
                <div>
                  {diagnosticos.map((item, index) => {
                    return (
                      <div className="row resposive-btn">
                        <div className="col-lg-11 col-md-11 col-sm-11 col-xs-10">
                          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <input
                              className="form-control"
                              type="text"
                              id="Diagnostico"
                              onFocus={onFocus}
                              onBlur={onBlur}
                              name="diagnostico"
                              placeholder="Diagnóstico do paciente"
                              value={item}
                              onChange={(event) =>
                                changeFormDiagnostico(index, event)
                              }
                            autofocus/>
                          </div>
                        </div>

                        {botaoDiagnostico(index)}
                      </div>
                    );
                  })}
                </div>
              </div>
              <input
                type="button"
                name="previous"
                className="previous action-button-previous"
                defaultValue="Anterior"
              />
              <input
                type="button"
                name="next"
                className="next action-button"
                defaultValue="Próximo"
              />
            </fieldset>
            {/* ETAPA 4 - LEITO */}
            <fieldset>
              <h2 className="fs-title">Definição de Leito</h2>
              {/* <h3 class="fs-subtitle">Fill in your credentials</h3> */}
              <div className="row form-group">
                <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 form-group">
                  <select
                    className="form-control"
                    name="Setor"
                    value={idSetor}
                    onChange={changeId}
                  >
                    <option>---</option>
                    {listaSetor.map((setor) => {
                      return (
                        <option value={setor.in_setor_id}>
                          {setor.nv_nome_setor}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-lg-1 col-md-3 col-sm-4 col-xs-12">
                  <strong>Admissão:</strong>
                </div>

                <div className="col-lg-3 col-md-3 col-sm-4 col-xs-12">
                  <input
                    className="form-control"
                    disabled={habilitaCamposPaciente()}
                    type="date"
                    // name="dt_nasc"
                    placeholder="Admissão"
                    onFocus={(e) => (e.target.type = 'date')}
                    onBlur={(e) => admissao == '' && (e.target.type = 'text')}
                    value={admissao}
                    onChange={onChangeAdmissao}
                    // {...register('dt_nascimento_pessoafisica', {})}
                  />
                </div>

                <div className="col-lg-12"></div>
                <div>
                  {listaAcomodacaoFiltrada &&
                    listaAcomodacaoFiltrada.map((item) => {
                      return (
                        <div className="col-lg-2 col-md-3 col-sm-4 col-xs-6 form-group">
                          <div
                            className={
                              leito == item ? 'box-leito-2' : 'box-leito'
                            }
                            onClick={() => changeLeito(item)}
                          >
                            <i className="fas fa-bed" />{' '}
                            {item.nv_nome_acomodacao}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
              <input
                type="button"
                name="previous"
                className="previous action-button-previous"
                defaultValue="Anterior"
              />
              <input
                type="button"
                className="action-button"
                value="Admitir"
                onClick={(e) => {
                  // try {
                  //   handleSubmit(onSubmit)();
                  // } catch (error) {
                  //   alert(error);
                  // }

                  let dadosParaEnviar = {
                    nv_cpf_pessoafisica: getValues('nv_cpf_pessoafisica'),
                    nv_ddd_pessoafisica: getValues('nv_ddd_pessoafisica'),
                    nv_telefone_pessoafisica: getValues(
                      'nv_telefone_pessoafisica'
                    ),
                  };
                  console.log('*** ', dadosParaEnviar);
                  onSubmit(dadosParaEnviar);
                }}
              />
            </fieldset>
          </form>
        </div>
      </div>
      {/* /.MultiStep Form */}

      {/*MODAL PACIENTES*/}
      {/* Modal: modalCart */}
      <div
        className="modal "
        id="modalPaciente"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*Header*/}
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Localizar Paciente
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {/*Body*/}
            <div className="modal-body">
              {/*Input Pesquisa Animado*/}
              <div className="input-animado">
                <input
                  type="text"
                  className="input-search-modal"
                  id="input-search-modal"
                  ref={NomePacienteSearch}
                  value={filtro}
                  //onBlur={(e)=>setFiltro('')}
                  onChange={FiltroChange}
                />
                <label className="search" htmlFor="input-search-modal" />
              </div>
              <br />
              <br />
              <br />
              <TabelaPessoaConsulta
                lista={listaFiltrada}
                nameClick={nameClick}
              />
            </div>
            {/*Footer*/}
            <div className="modal-footer">
              <button
                type="reset"
                className="btn btn-primary"
                data-dismiss="modal"
                // onClick={handleStartNewPaciente}
                onClick={() => {
                  setIdPessoa(null);
                  setNomePaciente(null);
                  setNascimento(null);
                  setFlagUsouLocalizarPaciente(false);
                  document.querySelector("#msform input[name='nome']").focus();
                }}
              >
                Cadastrar Novo Paciente
              </button>
              <button
                type="button"
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* Modal: modalCart */}

      {/*MODAL MÉDICOS*/}
      <div
        className="modal "
        id="modalMedico"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*Header*/}
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Localizar Médico Assistente
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {/*Body*/}
            <div className="modal-body">
              {/*Input Pesquisa Animado*/}
              <div className="input-animado">
                <input
                  type="text"
                  className="input-search-modal"
                  id="input-search-modal"
                  value={filtro}
                  onChange={FiltroChange2}
                />
                <label className="search" htmlFor="input-search-modal" />
              </div>
              <TabelaMedicaAssistente
                lista={listaFiltrada}
                nameClick2={nameClick2}
              />
            </div>
            {/*Footer*/}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdmissaoPage;
