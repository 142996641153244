import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PerfilAPI from '../../Services/API/PerfilAPI';

import { RotasURL } from 'RoutesURL';

const TabelaPerfil = (lista) => {
  const [idExclusao, setIdExclusao] = useState();
  const [listaDinamica, setListaDinamica] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setListaDinamica(lista);
    //console.log(listaDinamica);
  }, [lista]);

  function idChange(id) {
    const idExclusao = id;
    setIdExclusao(idExclusao);
    //console.log(idExclusao);
  }

  function excluirPerfil() {
    //console.log(`deletando perfil: ${idExclusao}`);
    PerfilAPI.excluirPerfil(idExclusao)
      .then((resposta) => {
        // //console.log(resposta);
        //console.log('deletado');
        lista.obterListaDePerfis();
      })
      .catch((err) => {
        //console.log(err);
        history.push({ pathname: RotasURL.login });
      });
  }

  return (
    <>
      {/* Pode virar componente */}
      <table id="tblsetor" className="table table-responsive">
        <thead>
          <tr>
            <td>NOME</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {listaDinamica.lista?.map((item, index) => {
            return (
              <tr className="linhaheight" key={index}>
                <td>{item.nv_nome_perfil}</td>
                <td style={{ textAlign: 'right' }}>
                  <Link to={{ pathname: RotasURL.perfil.novo, state: { item } }}>
                    <button type="button" className="templatemo-black-button">
                      <i className="fas fa-edit fa-fw"></i>
                    </button>
                  </Link>

                  <button
                    type="button"
                    className="templatemo-black-button"
                    data-toggle="modal"
                    data-target="#modalExcluir"
                    onClick={() => idChange(item.in_perfil_id)}
                  >
                    <i className="fas fa-trash fa-fw"></i>
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div
        className="modal "
        id="modalExcluir"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*Header*/}
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Confirmar a exclusão do perfil?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {/*Footer*/}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-dismiss="modal"
                // onClick={() => deletePost(App.in_empresa_id)}
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => excluirPerfil()}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabelaPerfil;
