import React, { useEffect, useState } from 'react';
import RotinaMedicaAPI from 'Services/API/RotinaMedicaAPI';
import PessoaFisicaAPI from 'Services/API/PessoaFisicaAPI';
import { useHistory } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';

const TabelaRotinaMedica = (props) => {
  const [idExclusao, setIdExclusao] = useState();
  const [listaDinamica, setListaDinamica] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [status, setStatus] = useState(['Iniciado', 'Finalizado', 'Cancelado']);
  const [update, setUpdate] = useState(true);
  const history = useHistory();

  useEffect(() => {
    setListaDinamica(props.lista);
    // obterListaDePessoas();
    //console.log(props.lista);
  }, [props]);

  useEffect(() => {
    obterListaDePessoas();
  }, []);

  function idChange(id) {
    const idExclusao = id;
    setIdExclusao(idExclusao);
    // //console.log(idExclusao);
  }

  async function obterListaDePessoas() {
    let pessoas = await PessoaFisicaAPI.obterListaPessoa();
    console.log("!!@ ", pessoas)
    //console.log(pessoas)
    setPessoas(pessoas);
  }

  function verRotina(item) {
    // //console.log(item);

    let updatedURL = RotasURL.rotinaMedica.details.replace(
      ':idAtendimento',
      item.in_atendimento_id
    );
    updatedURL = updatedURL.replace(':idRotina', item.gd_rotina_id);

    history.push({
      pathname: updatedURL,
      // state: {
      //   atendimento: item.in_atendimento_id,
      //   idRotina: item.gd_rotina_id,
      // },
    });
  }

  async function excluirRotina() {
    // //console.log(`deletando rotina: ${idExclusao}`);
    let data = await RotinaMedicaAPI.consultarRotina(idExclusao);
    if (data.in_status_ronda == 1) {
      data.in_status_ronda = 0;
      //console.log(data);
      try {
        await RotinaMedicaAPI.salvarRotina(data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    } else {
      data.in_status_ronda = 3;
      //console.log(data);
      try {
        await RotinaMedicaAPI.salvarRotina(data);
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
    window.location.reload();
  }

  function editar(item) {
    history.push({
      pathname: '/po_rotina_nova',
      state: { continuar: 1, atendimento: item.in_atendimento_id },
    });
  }

  function lerDt(dt, mostrarHora) {
    let ano = dt.substring(0, 4);
    let mes = dt.substring(5, 7);
    let dia = dt.substring(8, 10);
    let hora = dt.substring(11, 16);
    if (mostrarHora) {
      return dia + '/' + mes + '/' + ano + ' ' + hora;
    } else {
      return dia, '/', mes, '/', ano;
    }
  }

  return (
    <>
      {/* Pode virar componente */}
      <table id="tblsetor" className="table table-responsive">
        <thead>
          <tr>
            <td>DATA</td>
            <td>MÉDICO RESPONSÁVEL</td>
            <td>STATUS</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {pessoas[0] &&
            listaDinamica[0] &&
            listaDinamica.map((item, index) => {
              let medico = '';
              try {
                medico = pessoas.filter(
                  (pessoa) =>
                    pessoa.in_pessoafisica_id == item.in_medico_responsavel_id
                )[0].nv_nome_pessoafisica;
              } catch {
                medico = 'não definido';
              }
              return (
                <tr className="linhaheight" key={index}>
                  <td>{lerDt(item.dt_ronda, true)}</td>
                  <td>{medico}</td>
                  <td>{status[item.in_status_ronda - 1]}</td>
                  <td style={{ textAlign: 'right' }}>
                    {item.in_status_ronda == 1 ? (
                      <button
                        type="button"
                        // onClick={() => editar(item)}
                        onClick={() => verRotina(item)}
                        className="templatemo-black-button"
                      >
                        <i className="fas fa-edit fa-fw"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => verRotina(item)}
                        className="templatemo-black-button"
                      >
                        <i className="fas fa-eye fa-fw"></i>
                      </button>
                    )}

                    {item.in_status_ronda == 3 ? (
                      <button
                        type="button"
                        className="templatemo-black-button-2"
                      >
                        <i className="fas fa-trash fa-fw"></i>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="templatemo-black-button"
                        data-toggle="modal"
                        onClick={() => idChange(item.gd_rotina_id)}
                        data-target="#modalExcluir"
                      >
                        <i className="fas fa-trash fa-fw"></i>
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div
        className="modal "
        id="modalExcluir"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {/*Header*/}
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Confirmar a exclusão do Rotina?
              </h4>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {/*Footer*/}
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => excluirRotina()}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabelaRotinaMedica;
