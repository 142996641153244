import React, { useEffect, useState, useRef } from 'react';
import RotinaMedicaAPI from 'Services/API/RotinaMedicaAPI';
import AtendimentoAPI from 'Services/API/AtendimentoAPI';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './NovaRotinaSchema';
import { useHistory, useParams, Link } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';
import LoadingIcons from 'react-loading-icons';
import { saveAs } from 'file-saver';
import Accordion from 'react-bootstrap/Accordion';
import RadioButtonAdultoPediatrico from './components/RadioButtonAdultoPediatrico';

//variavel para identificar foco do usuario e permitir atalhos do teclado para edição de tabelas
let foco = '';

const OVERLAY_TEXTO_PADRAO = 'Carregando ...';

const NovaRotinaMedicaPage2 = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });

  const history = useHistory();

  //parametros para gerar a rotina
  const { idAtendimento, idRotina } = useParams();
  //respostas da rotina
  const [rotina, setRotina] = useState();
  //informações internas da rotina
  const [rotinaMedicaInfos, setRotinaMedicaInfos] = useState(null);
  //data de hoje em formato aaaa/mm/dd para impedir seleção de datas invalidas
  const [hojeFormatado, setHojeFormatado] = useState();
  //auxilia o funcionamento do timer, de preferencia não precisa ser um state   <------- melhoria
  const [timerHelper, setTimerHelper] = useState(true);
  //ajuda com atualização em algumas tabelas onde o state não muda
  const [update, setUpdate] = useState(true);
  //indica que a inicialização da rotina foi concluida e permite a rendereização dos elementos
  const [pronto, setPronto] = useState(false);
  //indica que a rotina está no mode de sómente leitura
  const [read, setRead] = useState(true);
  //indica se tem algum antibiotico com a data ultrapassada
  const [alertaAntibiotico, setAlertaAntibiotico] = useState(false);

  //const [alteracoes, setAlteracoes] = useState(false);

  //cabeçalho da rotina
  const [nome, setNome] = useState();
  const [atendimento, setAtendimento] = useState(null);
  const [alergias, setAlergias] = useState();
  const [nascimento, setNascimento] = useState();
  const [idade, setIdade] = useState();
  const [diasInternacao, setDiasInternacao] = useState();
  const [tipoAdultoPediatrico, setTipoAdultoPediatrico] = useState();

  //informações do paciente
  const [peso, setPeso] = useState('1');
  const [diagnosticosInternacao, setDiagnosticosInternacao] = useState([]);
  const [diagnosticosSecundario, setDiagnosticosSecundario] = useState([
    { descricao: '' },
  ]);
  const [diagnosticosInvasao, setDiagnosticosInvasao] = useState([
    { descricao: '', dataInvasoes: '' },
  ]);
  const [sinalBh, setSinalBh] = useState('+');
  const [bh, setBh] = useState('');
  const [intercorrencia, setIntercorrencia] = useState('');

  //datas dos exames Hematológicos
  const [dataHematimetria, setDataHematimetria] = useState(null);
  const [dataCoagulograma, setDataCoagulograma] = useState(null);

  //seletores parar mostrar ou omitir respequitivas informações
  const [febre, setFebre] = useState();
  const [oxiRadio, setOxiRadio] = useState();
  const [aminasRadio, setAminasRadio] = useState();
  const [drogasRadio, setDrogasRadio] = useState();
  const [culturasRadio, setCulturasRadio] = useState();
  const [diaRadio, setDiaRadio] = useState();
  const [antibioticosRadio, setAntibioticosRadio] = useState();
  const [showPageOverlay, setShowPageOverlay] = useState(true);
  const [pageOverlayText, setPageOverlayText] = useState(OVERLAY_TEXTO_PADRAO);

  //lista de drogas cadastradas
  const [drogas, setDrogas] = useState([]);
  //lista de dispositivos/suporte respiratorio cadastradas
  const [dispositivos, setDispositivos] = useState([
    { SuporteVentilatorio: '', Dispositivos: [] },
  ]);
  //lista de especialidades cadastradas
  const [listaEspecialidades, setListaEspecialidades] = useState([]);

  //variaveis para cadastro de novos medicamentos
  const [descricaoMedicamento, setDescricaoMedicamento] = useState();
  const [tipoMedicamento, setTipoMedicamento] = useState();
  const [diluicaoMedicamento, setDiluicaoMedicamento] = useState();
  const [valorMedicamento, setValorMedicamento] = useState();
  const [unidadeMedicamento, setUnidadeMedicamento] = useState();

  //dados das tabelas expansivas
  const [aminas, setAminas] = useState([
    {
      droga: '',
      diluicao: '',
      velocidade: '',
      dose: '',
      valor: '',
      unidade: 'µg/kg/h',
    },
  ]);
  const [neuro, setNeuro] = useState([
    {
      droga: '',
      diluicao: '',
      velocidade: '',
      dose: '',
      valor: '',
      unidade: '',
    },
  ]);
  const [escala, setEscala] = useState([
    {
      escala: '',
      valor: '',
    },
  ]);
  const [antibioticos, setAntibioticos] = useState([
    {
      tipo: '',
      dataInicio: '',
      previsaoDias: '',
      diaInicial: '1',
      diasTotal: '',
    },
  ]);
  const [oxi, setOxi] = useState([
    { suporte: '', dispositivo: '', detalhes: '' },
  ]);
  const [gasometria, setGasometria] = useState([
    { descricao: '', data: '', hora: '' },
  ]);
  const [culturas, setCulturas] = useState([
    { descricao: '', data: '', tipo: '', resultado: '' },
  ]);
  const [dia, setDia] = useState([{ tipo: '', detalhes: '' }]);
  const [dieta, setDieta] = useState([{ tipo: '', dieta: '', observacao: '' }]);
  const [metas, setMetas] = useState([{ meta: '' }]);

  //estados que recebem os Exames/Monitorização
  const [examesResumo, setExamesResumo] = useState('');
  const [examesCardio, setExamesCardio] = useState('');
  const [examesRespiratorio, setExamesRespiratorio] = useState('');
  const [examesNeurologico, setExamesNeurologico] = useState('');
  const [examesHemato, setExamesHemato] = useState('');
  const [examesInfeccioso, setExamesInfeccioso] = useState('');
  const [examesRenal, setExamesRenal] = useState('');
  const [examesNutricional, setExamesNutricional] = useState('');
  const [examesOutros, setExamesOutros] = useState('');

  //estados responsáveis pelo download e upload de arquivos
  const [subindoArquivo, setSubindoArquivo] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [listaAnexo, setListaAnexo] = useState([]);

  // const [condutasCardio, setCondutasCardio] = useState([
  //   {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   },
  // ]);
  // const [condutasRespiratorio, setCondutasRespiratorio] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasNeurologico, setCondutasNeurologico] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasHemato, setCondutasHemato] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasInfeccioso, setCondutasInfeccioso] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasRenal, setCondutasRenal] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasNutricional, setCondutasNutricional] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasOutros, setCondutasOutros] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);
  // const [condutasResumo, setCondutasResumo] = useState([
  //   { descricao: '', data: '', dt_finalizada: '', especialista: '' },
  // ]);

  //variavel para auxiliar na leitura das respostas das conduta
  const [respostaConduta, setRespostaConduta] = useState();

  //variaveis para auxilio na previsão de alta
  const [dataAlta, setDataAlta] = useState();
  const [prescricaoAlta, setPrescricaoAlta] = useState();

  //-----------------------------useEffects()-----------------------------------
  //ocorre no primeiro render da pagina
  useEffect(() => {
    let d = new Date();
    let hojeTempDate = `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(
      -2
    )}-${d.getDate()}`;
    setHojeFormatado(hojeTempDate);
    obterDataInicial(idAtendimento);
    setAtendimento(idAtendimento);
    obterListaEspecialidades();
    obterAlta(idAtendimento);

    obterCabecalho();
    obterMedicamentos();
    obterDispositivosRespiratorios();

    obterRotinaMedicaOnload(idRotina)
      .then(() => {
        obterRespostasRotinaOnload(idRotina)
          .then(() => {
            obterAnexosAtendimento(idAtendimento);
            removePageOverlay();
          })
          .catch((err) => {
            throw err;
          });
      })
      .catch((err) => {
        alert(err);
        history.push({ pathname: RotasURL.rotinaMedica.index });
      });
  }, []);

  //ocorre ao final da função inicializar, gera a tabela resumo e inicia a atualização automática
  useEffect(() => {
    gerarCondutasResumo();
    gerarExamesResumo();
    checarAlertaAntibiotico();
    if (!read && pronto) {
      timerAtualizacao();
    }
    if (atendimento) obterDiagnosticos();
  }, [read, pronto]); // <---------- melhorias, testar sem o read

  // useEffect(() => {
  //   if (atendimento) obterDiagnosticos();
  // }, [pronto]);

  //responsavel pelo atalho de teclado para tabelas
  useEffect(() => {
    const listener = (event) => {
      if (foco != '' && event.code === 'Enter') {
        event.preventDefault();
        adicionarCampo();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  });

  //------------------------------Atulização automatica da rotina ---------------------------

  const [count, setCount] = useState(0);
  const countRef = useRef(count);
  countRef.current = count;

  function removePageOverlay() {
    setShowPageOverlay(false);
  }

  function timerAtualizacao() {
    setTimerHelper(
      setInterval(() => {
        //console.log(`Atualizando rotina`);
        //console.log(countRef.current);
        setCount(countRef.current + 1);
      }, 15000)
    );
  }

  useEffect(() => {
    atualizaRotina();
  }, [count]);

  useEffect(() => {
    return () => clearInterval(timerHelper);
  }, [timerHelper]);

  //Função de atualização da rotina
  async function atualizaRotina() {
    if (rotinaMedicaInfos?.in_status_ronda === 1) {
      onOverlay();
      //console.log('Rotina ainda não finalizada. Atualizando...');
      //gerarCondutasResumo();
      let data = getValues();
      let dataName = Object.keys(data);
      //console.log(dataName)
      let dataFinal = '{';
      dataName.map((item) => {
        Object.keys(data[item]).map((nome) => {
          dataFinal =
            dataFinal +
            ('"' +
              item +
              '.' +
              nome +
              '": ' +
              JSON.stringify(data[item][nome]) +
              ',');
        });
      });
      dataFinal = dataFinal.slice(0, -1);
      dataFinal = dataFinal + '}';

      //console.log(dataFinal)
      let dataFinalFinal = JSON.parse(dataFinal);

      dataFinalFinal['INTERN.TIPO_ADULTO_PEDIATRICO'] = tipoAdultoPediatrico;

      dataFinalFinal['INTERN.DIAS_INTERNACAO_CTI'] =
        JSON.stringify(diasInternacao);
      dataFinalFinal['IDENT.PESO_KG'] = peso;
      dataFinalFinal['INT24.FEBRE'] = febre;
      dataFinalFinal['INT24H.BH'] = sinalBh + bh;
      dataFinalFinal['INTERN.DIAGNOSTICO_INTERNACAO'] = JSON.stringify(
        diagnosticosInternacao
      );
      dataFinalFinal['INTERN.DIAGNOSTICOS_SECUNDARIOS'] = JSON.stringify(
        diagnosticosSecundario
      );
      dataFinalFinal['INTERN.INVASOES'] = JSON.stringify(diagnosticosInvasao);
      dataFinalFinal['CARDIO.AMINAS'] = JSON.stringify(aminas);
      dataFinalFinal['RESPIRATORIO.OXIGENIOTERAPIA'] = JSON.stringify(oxi);
      dataFinalFinal['RESPIRATORIO.GASOMETRIA'] = JSON.stringify(gasometria);
      dataFinalFinal['NEUROLOGICO.DROGAS'] = JSON.stringify(neuro);
      dataFinalFinal['NEUROLOGICO.ESCALA'] = JSON.stringify(escala);
      dataFinalFinal['INFECCIOSO.CULTURAS'] = JSON.stringify(culturas);
      // console.log("@@ Antibio ", antibioticos)
      dataFinalFinal['INFECCIOSO.ANTIBIOTICOS'] = JSON.stringify(antibioticos);
      dataFinalFinal['RENAL.DIALITICO'] = JSON.stringify(dia);
      dataFinalFinal['HEMATO.DATA'] = dataHematimetria;
      dataFinalFinal['HEMATO.DATA_COAGULOGRAMA'] = dataCoagulograma;
      dataFinalFinal['NUTRI.DIETAS'] = JSON.stringify(dieta);
      dataFinalFinal['METAS.METAS'] = JSON.stringify(metas);

      //console.log(condutasCardio);
      dataFinalFinal['CARDIO.EXAMES_MONITORIZACAO'] = examesCardio;
      dataFinalFinal['INFECCIOSO.EXAMES_MONITORIZACAO'] = examesInfeccioso;
      dataFinalFinal['RESPIRATORIO.EXAMES_MONITORIZACAO'] = examesRespiratorio;
      dataFinalFinal['NEUROLOGICO.EXAMES_MONITORIZACAO'] = examesNeurologico;
      dataFinalFinal['HEMATO.EXAMES_MONITORIZACAO'] = examesHemato;
      dataFinalFinal['RENAL.EXAMES_MONITORIZACAO'] = examesRenal;
      dataFinalFinal['NUTRI.EXAMES_MONITORIZACAO'] = examesNutricional;
      dataFinalFinal['OUTROS.EXAMES_MONITORIZACAO'] = examesOutros;
      dataFinalFinal['RESUMO.EXAMES_MONITORIZACAO'] = examesResumo;

      // console.log('!!! 1)', condutasCardio);
      // let condutasCardioToSave = condutasCardio.map((conduta) => {
      //   if (conduta.especialista !== undefined) {
      //     let procuraEspecialidade = listaEspecialidades.find(
      //       (especialidade) => especialidade.descricao === conduta.especialista
      //     );
      //     console.log('!!! ---- a) ', procuraEspecialidade);

      //     conduta.idParecerTipo = procuraEspecialidade.id.toString();
      //     conduta.tipo = procuraEspecialidade.tipo;

      //     conduta.idRotina = idRotina;
      //     conduta.idAtendimento = idAtendimento;
      //     conduta.grupo = 'CARDIO.PARECER_CONDUTAS';
      //   }

      //   return conduta;
      // });

      // console.log(
      //   '!!! 2a) condutasCardioToSave: ',
      //   JSON.stringify(condutasCardioToSave)
      // );
      // console.log('!!! 2b) ', condutasCardioToSave);

      // dataFinalFinal['CARDIO.PARECER_CONDUTAS'] =
      //   JSON.stringify(condutasCardioToSave);

      dataFinalFinal['CARDIO.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Cardio']
      );
      dataFinalFinal['INFECCIOSO.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Infeccioso']
      );
      dataFinalFinal['RESPIRATORIO.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Respiratorio']
      );
      dataFinalFinal['NEUROLOGICO.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Neurologico']
      );
      dataFinalFinal['HEMATO.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Hemato']
      );
      dataFinalFinal['RENAL.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Renal']
      );
      dataFinalFinal['NUTRI.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Nutricional']
      );
      dataFinalFinal['OUTROS.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Outros']
      );
      dataFinalFinal['RESUMO.PARECER_CONDUTAS'] = JSON.stringify(
        condutas['Resumo']
      );

      //   //console.log(AntibiosRef.current);
      // dataFinalFinal['INFECCIOSO.ANTIBIOTICOS'] = JSON.stringify(
      //   AntibiosRef.current
      // );
      let antibioticosInfeccioso = dataFinalFinal['INFECCIOSO.ANTIBIOTICOS'];
      // //console.log('@@@ A', antibioticosInfeccioso);
      // //console.log('@@@ B', AntibiosRef.current);

      // console.log('### 3)', { dataFinalFinal });

      // //console.log(prescricaoAlta);
      if (prescricaoAlta && dataAlta) {
        await AtendimentoAPI.salvarAltaMedica(
          atendimento,
          dataAlta,
          prescricaoAlta
        );
      }
      //voltar aqui
      await RotinaMedicaAPI.atualizarRotina(
        dataFinalFinal,
        rotinaMedicaInfos.gd_rotina_id
      ).then(() => {
        offOverlay();
      });
    }
  }

  async function obterDataInicial(id) {
    let d = new Date();
    let dataTemp = await AtendimentoAPI.consultarAtendimento(id);
    //console.log(new Date(dataTemp[0].dt_data_inicio).getDate());
    let conta = Math.round(
      (d - new Date(dataTemp[0].dt_data_inicio)) / (1000 * 60 * 60 * 24)
    );
    //console.log(d);
    if (conta == 0) {
      conta = 1;
    }
    setDiasInternacao(conta);
  }

  function adicionarCampo() {
    eval(foco);
  }

  function onFocus(event) {
    let id = event.target.id;
    if (id.substring(0, 8) == 'Condutas') {
      id = id.split('Condutas').pop();
      let func = 'adicionarCampoCondutas("' + id + '");';
      console.log(func);
      foco = func;
    } else {
      let func = 'adicionarCampo' + id + '();';
      foco = func;
    }
  }

  function onBlur(event) {
    foco = '';
  }

  //----------------------------------Gerenciador de condutas---------------------------------

  const [condutas, setCondutas] = useState({
    Cardio: [{ descricao: '', data: '', dt_finalizada: '', especialista: '' }],
    Respiratorio: [
      { descricao: '', data: '', dt_finalizada: '', especialista: '' },
    ],
    Neurologico: [
      { descricao: '', data: '', dt_finalizada: '', especialista: '' },
    ],
    Infeccioso: [
      { descricao: '', data: '', dt_finalizada: '', especialista: '' },
    ],
    Hemato: [{ descricao: '', data: '', dt_finalizada: '', especialista: '' }],
    Renal: [{ descricao: '', data: '', dt_finalizada: '', especialista: '' }],
    Nutricional: [
      { descricao: '', data: '', dt_finalizada: '', especialista: '' },
    ],
    Outros: [{ descricao: '', data: '', dt_finalizada: '', especialista: '' }],
    Resumo: [{ descricao: '', data: '', dt_finalizada: '', especialista: '' }],
  });

  function adicionarCampoCondutas(area) {
    let novaConduta = {
      descricao: '',
      data: '',
      dt_finalizada: '',
      especialista: '',
    };
    setCondutas({ ...condutas, [area]: [...condutas[area], novaConduta] });
  }

  const excluirCampoCondutas = (index, area) => {
    let tempCondutas = condutas[area];
    tempCondutas.splice(index, 1);
    setCondutas({ ...condutas, [area]: tempCondutas });
    setUpdate(!update);
    gerarCondutasResumo();
  };

  function changeIntercorrencia(event) {
    setIntercorrencia(event.target.value);
  }

  function changeDescricaoCondutas(event, posicao, area) {
    condutas[area].map((item, index) => {
      index == posicao && (item.descricao = event.target.value);
    });
    gerarCondutasResumo();
    setUpdate(!update);
  }

  function changeDataCondutas(event, posicao, area) {
    //console.log(event.target.value);
    condutas[area].map((item, index) => {
      index == posicao && (item.data = event.target.value);
    });
    gerarCondutasResumo();
    setUpdate(!update);
  }

  function changeEspecialistaCondutas(event, posicao, area) {
    condutas[area].map((item, index) => {
      index == posicao && (item.especialista = event.target.value);
    });
    gerarCondutasResumo();
    setUpdate(!update);
  }

  //botao responsavel por aumentar a tabela ou excluir o ultimo item
  function botaoCondutas(index, lista, area) {
    if (index == lista.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => adicionarCampoCondutas(area)}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoCondutas(index, area)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  //botao responsavel pela vizualização das respostas da conduta
  function botaoRespostaCondutas(item, finalizada) {
    if (finalizada) {
      return (
        <div>
          <a data-toggle="modal" data-target="#modalRespostaConduta">
            <button
              type="button"
              className="templatemo-white-button"
              style={{ backgroundColor: '#39566B' }}
              onClick={() => carregarRespostaCondutas(item)}
            >
              <i className="fas fa-edit fa-fw" />
            </button>
          </a>
        </div>
      );
    }
  }

  function carregarRespostaCondutas(item) {
    //console.log(item.resposta)
    //console.log(!item.dt_finalizada)
    if (item.resposta) {
      setRespostaConduta(item.resposta);
    } else {
      setRespostaConduta('Não há respostas para esta conduta.');
    }
  }

  //componente que gera a tabela, é chamado no render
  function gerarTabelaCondutas(area) {
    let condutasTemp = condutas[area];
    if (!condutasTemp[0]) {
      condutasTemp.push({
        descricao: '',
        data: '',
        dt_finalizada: '',
        especialista: '',
      });
    }
    return (
      <div>
        <table id="tblsetor" className="table table-responsive">
          <thead>
            <tr>
              <td style={{ color: '#FFFFFF' }}>Descrição</td>
              <td width={'70'}></td>
              <td width={'200'} style={{ color: '#FFFFFF' }}>
                Data/Hora
              </td>
              <td width={'200'} style={{ color: '#FFFFFF' }}>
                Especialista
              </td>
              <td width={'70'}></td>
            </tr>
          </thead>
          <tbody>
            {condutasTemp.map((item, index) => {
              return (
                <tr>
                  <td>
                    <input
                      onFocus={onFocus}
                      onBlur={onBlur}
                      disabled={
                        read || (item.dt_finalizada && item.dt_finalizada != '')
                      }
                      type="text"
                      style={
                        item.dt_finalizada && item.dt_finalizada != ''
                          ? { backgroundColor: '#90EE90' }
                          : {}
                      }
                      className="form-control"
                      id={'Condutas' + area}
                      value={item.descricao}
                      onChange={(props) =>
                        changeDescricaoCondutas(props, index, area)
                      }
                    />
                  </td>
                  <td>
                    {botaoRespostaCondutas(
                      item,
                      (item.dt_finalizada && item.dt_finalizada != '') ||
                        item.resposta
                    )}
                  </td>
                  <td>
                    <input
                      onFocus={onFocus}
                      onBlur={onBlur}
                      id={'Condutas' + area}
                      disabled={
                        read || (item.dt_finalizada && item.dt_finalizada != '')
                      }
                      type="date"
                      style={
                        item.dt_finalizada && item.dt_finalizada != ''
                          ? { backgroundColor: '#90EE90' }
                          : {}
                      }
                      className="form-control"
                      value={item.data}
                      onChange={(props) =>
                        changeDataCondutas(props, index, area)
                      }
                    />
                  </td>
                  <td>
                    <select
                      onFocus={onFocus}
                      onBlur={onBlur}
                      disabled={
                        read || (item.dt_finalizada && item.dt_finalizada != '')
                      }
                      type="text"
                      style={
                        item.dt_finalizada && item.dt_finalizada != ''
                          ? { backgroundColor: '#90EE90' }
                          : {}
                      }
                      className="form-control"
                      id={'Condutas' + area}
                      value={item.especialista}
                      onChange={(props) =>
                        changeEspecialistaCondutas(props, index, area)
                      }
                    >
                      <option value="">---</option>
                      {listaEspecialidades.map((item) => {
                        return (
                          <option value={item.id}>{item.descricao}</option>
                        );
                      })}
                    </select>
                  </td>
                  <td>{botaoCondutas(index, condutasTemp, area)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }

  //função responsavel por gerar a lista de condutas para o resumo, chamada sempre que há mudança
  //em alguma conduta , durante a atualização de conduta ou na inicialização
  function gerarCondutasResumo() {
    condutas['Resumo'].length = 0;
    let temp = [];
    condutas['Cardio'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Respiratorio'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Neurologico'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Infeccioso'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Renal'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Hemato'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Nutricional'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    condutas['Outros'].map((item) => {
      if (item.descricao != '') temp.push(item);
    });
    setCondutas({ ...condutas, Resumo: temp });
  }

  const obterRotinaMedicaOnload = async (pRotinaId) => {
    await RotinaMedicaAPI.consultarRotina(pRotinaId)
      .then((rotinaInfo) => {
        setIntercorrencia(rotinaInfo.nv_obs_plantonista);
        setRotinaMedicaInfos(() => rotinaInfo);
        if (rotinaInfo?.in_status_ronda === 1) setRead(false);
      })
      .catch(() => {
        throw new Error('Não foi possível encontrar Rotina');
      });
  };

  const obterRespostasRotinaOnload = async (pRotinaId) => {
    await RotinaMedicaAPI.consultarRespostas(pRotinaId)
      .then((rotinaResposta) => {
        setRotina(rotinaResposta);
        inicializar(rotinaResposta);
      })
      .catch((error) => {
        throw error;
      });

    return true;
  };

  const changeFiles = (event) => {
    //console.log(event.target.files);
    setSelectedFile(event.target.files);
  };

  async function obterAnexosAtendimento(pAtendimento) {
    let anexosResposta = await RotinaMedicaAPI.obterAnexosAtendimento(
      pAtendimento
    )
      .then((resposta) => {
        //console.log('Retorno dos Anexos: ', resposta);
        if (resposta === null) {
          setListaAnexo([]);
          //console.log('Anexo vazio');
        } else {
          setListaAnexo(resposta);
          //console.log('Anexos OK');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    //console.log(anexosResposta);
  }

  async function obterDiagnosticos() {
    try {
      //console.log(diagnosticosInternacao[0].descricao);
      if (!diagnosticosInternacao[0]) {
        //console.log(atendimento);
        let diagnosticos = await AtendimentoAPI.listarDiagnosticos(atendimento);
        let diagnosticoTemp = [];
        diagnosticos.map((item) => {
          diagnosticoTemp.push({ descricao: item.nv_descricao_diagnostico });
          //setDiagnosticosInternacao( [...diagnosticosInternacao, { descricao: item.nv_descricao_diagnostico }] );
        });
        diagnosticoTemp = diagnosticoTemp.reverse();
        //console.log(diagnosticoTemp);
        setDiagnosticosInternacao(diagnosticoTemp);
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function obterDispositivosRespiratorios() {
    try {
      let dispositivosTemp = await RotinaMedicaAPI.listarDispositivos();
      setDispositivos(dispositivosTemp);
    } catch {}
  }

  async function obterMedicamentos() {
    try {
      let medicamentos = await RotinaMedicaAPI.listarMedicamentos();

      // medicamentos.map((item) => {
      //   if (
      //     !drogas.find(
      //       (droga) => droga.droga_cadastrada == item.nv_medicamento_descricao
      //     )
      //   ) {
      //     drogas.push({
      //       id_droga: item.in_medicamento_id,
      //       tipo_droga: item.nv_tipo_descricao,
      //       droga_cadastrada: item.nv_medicamento_descricao,
      //       diluicao: item.lista_diluicoes,
      //     });
      //   }
      // });

      let newDrogasList = [];
      medicamentos.forEach((med) => {
        newDrogasList.push({
          id_droga: med.in_medicamento_id,
          tipo_droga: med.nv_tipo_descricao,
          droga_cadastrada: med.nv_medicamento_descricao,
          diluicao: med.lista_diluicoes,
        });
      });

      newDrogasList = newDrogasList.sort(
        sortArrayOfObjectsByProperty('droga_cadastrada', 'asc')
      );
      setDrogas(newDrogasList);
    } catch (error) {
      console.error(error);
      throw error;
    }
    //console.log(drogas);
  }

  function sortArrayOfObjectsByProperty(key, order = 'asc') {
    const sortOrder = order === 'asc' ? 1 : -1;
    return (a, b) => {
      const A =
        typeof a[key] === 'string'
          ? a[key].toUpperCase().normalize('NFD')
          : a[key];
      const B =
        typeof b[key] === 'string'
          ? b[key].toUpperCase().normalize('NFD')
          : b[key];
      if (A < B) {
        return sortOrder * -1;
      } else if (A > B) {
        return sortOrder * 1;
      } else {
        return 0;
      }
    };
  }

  async function obterListaEspecialidades() {
    const retorno = await RotinaMedicaAPI.listarEspecialidades();
    //console.log(retorno)
    setListaEspecialidades(retorno);
  }

  // async function excluirRotina(id) {
  //   let data = await RotinaMedicaAPI.consultarRotina(id);
  //   if (data.in_status_ronda == 1) {
  //     data.in_status_ronda = 0;
  //     //console.log(data);
  //     try {
  //       await RotinaMedicaAPI.salvarRotina(data);
  //     } catch (error) {
  //       console.error(error);
  //       throw error;
  //     }
  //   } else {
  //     data.in_status_ronda = 3;
  //     //console.log(data);
  //     try {
  //       await RotinaMedicaAPI.salvarRotina(data);
  //     } catch (error) {
  //       console.error(error);
  //       throw error;
  //     }
  //   }
  // }

  const obterCabecalho = async () => {
    let cabecalho = await RotinaMedicaAPI.cabecalhoRotina(idAtendimento);
    setNome(cabecalho.nome_paciente);
    setNascimento(cabecalho.data_nascimento);
    setIdade(cabecalho.idade);
    setAlergias(cabecalho.alergias);
  };

  function checarAlertaAntibiotico() {
    let temp = false;
    antibioticos.map((item) => {
      if (
        item.previsaoDias <
        Math.round(
          (new Date() - new Date(item.dataInicio)) / (1000 * 60 * 60 * 24)
        ) -
          item.diaInicial
      ) {
        temp = true;
      }
    });
    setAlertaAntibiotico(temp);
  }

  const baixarArquivo = (id, filename) => {
    //console.log(window);
    RotinaMedicaAPI.baixarAnexosAtendimento(id)
      .then((response) => {
        //console.log(response);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        saveAs(url, filename);
        // const link = document.createElement('a');
        // link.href = url;
        // link.setAttribute('download', filename);
        // document.body.appendChild(link);
        // link.click();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function adicionarMedicamento() {
    let data = {
      nv_medicamento_descricao: '',
      nv_tipo_descricao: '',
      diluicao: '',
    };
    data.nv_medicamento_descricao = descricaoMedicamento;
    data.nv_tipo_descricao = tipoMedicamento;
    let diluicao = { descricao: '', valor: '', unidade: '' };
    diluicao.descricao = diluicaoMedicamento;
    diluicao.valor = valorMedicamento;
    diluicao.unidade = unidadeMedicamento;
    //let tempDiluicao = JSON.stringify(diluicao)
    data.diluicao = diluicao;
    //console.log(data);
    RotinaMedicaAPI.salvarMedicamentos(data);
  }

  async function adicionarMedicamentoNovoAsync() {
    try {
      if (
        descricaoMedicamento === '' ||
        descricaoMedicamento == null ||
        descricaoMedicamento === undefined ||
        tipoMedicamento === '' ||
        tipoMedicamento == null ||
        tipoMedicamento === undefined
      ) {
        alert('Favor preencher todos os campos do medicamento.');
        return;
      }

      let data = {
        nv_descricao: '',
        in_tipomed_fk: '',
        in_ativo: 1,
      };
      data.nv_descricao = descricaoMedicamento;
      data.in_tipomed_fk = tipoMedicamento;

      setPageOverlayText('Cadastrando medicamento ...');
      setShowPageOverlay(true);
      let retorno = await RotinaMedicaAPI.salvarMedicamentosNovo(data);

      if (retorno != null) {
        await atualizaRotina();
        await obterMedicamentos();
        setShowPageOverlay(false);
        setPageOverlayText(OVERLAY_TEXTO_PADRAO);
        setTipoMedicamento('');
        setDescricaoMedicamento('');
      }
    } catch (error) {
      let msgError = `Não foi possível cadastrar a nova medicação \n adicionarMedicamentoNovoAsync \n ${error}`;
      alert(msgError);
      setShowPageOverlay(false);
      setPageOverlayText(OVERLAY_TEXTO_PADRAO);
    }
  }

  async function adicionarAntibiotico() {
    try {
      if (
        descricaoMedicamento === '' ||
        descricaoMedicamento == null ||
        descricaoMedicamento === undefined
      ) {
        alert('Favor preencher todos os campos do medicamento.');
        return;
      }

      let data = {
        // nv_medicamento_descricao: '',
        nv_descricao: '',
        // nv_tipo_descricao: 'Antibióticos',
        in_tipomed_fk: 5,
        // diluicao: '',
        in_ativo: 1,
      };
      // data.nv_medicamento_descricao = descricaoMedicamento;
      data.nv_descricao = descricaoMedicamento;

      setPageOverlayText('Cadastrando medicamento ...');
      setShowPageOverlay(true);
      let retorno = await RotinaMedicaAPI.salvarMedicamentosNovo(data);

      if (retorno != null) {
        await atualizaRotina();
        await obterMedicamentos();
        setShowPageOverlay(false);
        setPageOverlayText(OVERLAY_TEXTO_PADRAO);
        setDescricaoMedicamento('');
        setTipoMedicamento('');
      }
    } catch (error) {
      let msgError = `Não foi possível cadastrar o novo antibiótico \n adicionarAntibiotico \n ${error}`;
      alert(msgError);
      setShowPageOverlay(false);
      setPageOverlayText(OVERLAY_TEXTO_PADRAO);
    }
  }

  function adicionarDiluicao() {
    let data = { in_medicamento_id: '', diluicao: '' };
    data.in_medicamento_id = drogas.find(
      (item) => item.droga_cadastrada == descricaoMedicamento
    ).id_droga;
    let diluicao = { descricao: '', valor: '', unidade: '' };
    diluicao.descricao = diluicaoMedicamento;
    diluicao.valor = valorMedicamento;
    diluicao.unidade = unidadeMedicamento;
    //let tempDiluicao = JSON.stringify(diluicao)
    data.diluicao = diluicao;
    //console.log(diluicao);
    //console.log(data);
    RotinaMedicaAPI.salvarMedicamentos(data);
  }

  async function adicionarDiluicaoNovoAsync() {
    try {
      if (
        descricaoMedicamento === '' ||
        descricaoMedicamento == null ||
        descricaoMedicamento === undefined ||
        diluicaoMedicamento === '' ||
        diluicaoMedicamento == null ||
        diluicaoMedicamento === undefined ||
        valorMedicamento === '' ||
        valorMedicamento == null ||
        valorMedicamento === undefined ||
        unidadeMedicamento === '' ||
        unidadeMedicamento == null ||
        unidadeMedicamento === undefined
      ) {
        alert('Favor preencher todos os campos da diluição.');
        return;
      }

      let data = {
        in_medicamento_id: 0,
        nv_medicamento_descricao: '',
        in_tipomed_id: 0,
        is_ativo: 1,
      };

      data.nv_medicamento_descricao = descricaoMedicamento;

      data.in_medicamento_id = drogas.find(
        (item) => item.droga_cadastrada == descricaoMedicamento
      ).id_droga;

      let diluicao = { descricao: '', valor: '', unidade: '' };
      diluicao.descricao = `${diluicaoMedicamento} (${valorMedicamento}${unidadeMedicamento})`;
      diluicao.valor = valorMedicamento;
      diluicao.unidade = unidadeMedicamento;
      data.diluicao = diluicao;

      setPageOverlayText('Cadastrando diluição ...');
      setShowPageOverlay(true);
      let retorno = await RotinaMedicaAPI.salvarMedComDiluicao(data);

      if (retorno != null) {
        await atualizaRotina();
        await obterMedicamentos();
        setShowPageOverlay(false);
        setPageOverlayText(OVERLAY_TEXTO_PADRAO);
        setDescricaoMedicamento('');
        setDiluicaoMedicamento('');
        setValorMedicamento('');
        setUnidadeMedicamento('');
      }
    } catch (error) {
      console.warn(error);
      let msgError = `Não foi possível cadastrar a nova diluicao \n adicionarDiluicaoNovoAsync \n ${error}`;
      alert(msgError);
      setShowPageOverlay(false);
      setPageOverlayText(OVERLAY_TEXTO_PADRAO);
    }
  }

  function inicializar(data) {
    // console.log(' **# 1) Dados Inicial: ', data);

    if (data['CARDIO.AMINAS']) {
      let campo = JSON.parse(data['CARDIO.AMINAS']);
      let radio =
        JSON.parse(data['CARDIO.AMINAS'])[0].droga == '' ? null : 'nao';
      if (radio == 'nao')
        radio =
          JSON.parse(data['CARDIO.AMINAS'])[0].droga == 'nao' ? 'nao' : 'sim';
      //console.log(JSON.parse(data['CARDIO.AMINAS'])[0].droga);
      //console.log(radio);
      setAminas(campo);
      setAminasRadio(radio);
    }
    if (data['RESPIRATORIO.OXIGENIOTERAPIA']) {
      let campo = JSON.parse(data['RESPIRATORIO.OXIGENIOTERAPIA']);
      let radio =
        JSON.parse(data['RESPIRATORIO.OXIGENIOTERAPIA'])[0].suporte == ''
          ? null
          : 'nao';
      if (radio == 'nao')
        radio =
          JSON.parse(data['RESPIRATORIO.OXIGENIOTERAPIA'])[0].suporte == 'nao'
            ? 'nao'
            : 'sim';
      //console.log(radio);
      //console.log(campo);
      setOxi(campo);
      setOxiRadio(radio);
    }
    if (data['RESPIRATORIO.GASOMETRIA']) {
      let campo = JSON.parse(data['RESPIRATORIO.GASOMETRIA']);
      let dataInicial = campo[0].data;
      let itemFinal = campo[0];
      //console.log(campo);
      campo.map((item) => {
        if (item.descricao != '' && item.data > dataInicial) {
          dataInicial = item.data;
          itemFinal = item;
        }
      });
      //console.log(itemFinal);
      setGasometria([itemFinal]);
    }
    if (data['NEUROLOGICO.DROGAS']) {
      let campo = JSON.parse(data['NEUROLOGICO.DROGAS']);
      let radio =
        JSON.parse(data['NEUROLOGICO.DROGAS'])[0].droga == '' ? null : 'nao';
      if (radio == 'nao')
        radio =
          JSON.parse(data['NEUROLOGICO.DROGAS'])[0].droga == 'nao'
            ? 'nao'
            : 'sim';
      //console.log(radio);
      //console.log(campo);
      setNeuro(campo);
      setDrogasRadio(radio);
    }
    if (data['NEUROLOGICO.ESCALA']) {
      let campo = JSON.parse(data['NEUROLOGICO.ESCALA']);
      //console.log(campo);
      setEscala(campo);
    }
    if (data['INFECCIOSO.CULTURAS']) {
      let campo = JSON.parse(data['INFECCIOSO.CULTURAS']);
      let radio =
        JSON.parse(data['INFECCIOSO.CULTURAS'])[0].tipo == '' ? null : 'nao';
      if (radio == 'nao')
        radio =
          JSON.parse(data['INFECCIOSO.CULTURAS'])[0].tipo == 'nao'
            ? 'nao'
            : 'sim';
      //console.log(radio);
      //console.log(campo);
      setCulturas(campo);
      setCulturasRadio(radio);
    }
    if (data['INFECCIOSO.ANTIBIOTICOS']) {
      let campo = JSON.parse(data['INFECCIOSO.ANTIBIOTICOS']);
      let radio =
        JSON.parse(data['INFECCIOSO.ANTIBIOTICOS'])[0].tipo == ''
          ? null
          : 'nao';
      if (radio == 'nao')
        radio =
          JSON.parse(data['INFECCIOSO.ANTIBIOTICOS'])[0].tipo == 'nao'
            ? 'nao'
            : 'sim';
      //console.log(JSON.parse(data['INFECCIOSO.ANTIBIOTICOS'])[0].tipo);
      //console.log(radio);
      setAntibioticos(campo);
      setAntibioticosRadio(radio);
    }
    if (data['RENAL.DIALITICO']) {
      let campo = JSON.parse(data['RENAL.DIALITICO']);
      let radio =
        JSON.parse(data['RENAL.DIALITICO'])[0].tipo == '' ? null : 'nao';
      if (radio == 'nao')
        radio =
          JSON.parse(data['RENAL.DIALITICO'])[0].tipo == 'nao' ? 'nao' : 'sim';
      //console.log(radio);
      //console.log(campo);
      setDia(campo);
      setDiaRadio(radio);
    }
    if (data['HEMATO.DATA']) {
      let campo = data['HEMATO.DATA'];
      //console.log(campo);
      setDataHematimetria(campo);
    }
    if (data['HEMATO.DATA_COAGULOGRAMA']) {
      let campo = data['HEMATO.DATA_COAGULOGRAMA'];
      //console.log(campo);
      setDataCoagulograma(campo);
    }
    if (data['NUTRI.DIETAS']) {
      let campo = JSON.parse(data['NUTRI.DIETAS']);
      //console.log(campo);
      setDieta(campo);
    }
    if (data['METAS.METAS']) {
      let campo = JSON.parse(data['METAS.METAS']);
      //console.log(campo);
      setMetas(campo);
    }
    if (data['INTERN.DIAGNOSTICO_INTERNACAO']) {
      let campo = JSON.parse(data['INTERN.DIAGNOSTICO_INTERNACAO']);
      //console.log(campo);
      setDiagnosticosInternacao(campo);
    }
    if (data['INTERN.DIAGNOSTICOS_SECUNDARIOS']) {
      let campo = JSON.parse(data['INTERN.DIAGNOSTICOS_SECUNDARIOS']);
      //console.log(campo);
      setDiagnosticosSecundario(campo);
    }
    if (data['INTERN.INVASOES']) {
      let campo = JSON.parse(data['INTERN.INVASOES']);
      //console.log(campo);
      setDiagnosticosInvasao(campo);
    }
    if (data['INTERN.TIPO_ADULTO_PEDIATRICO']) {
      let campo = data['INTERN.TIPO_ADULTO_PEDIATRICO'];
      //console.log(campo);
      setTipoAdultoPediatrico(campo);
    } else {
      // console.log(' Tipo Atendimento (adulto/ped) está em branco.');
      // console.log(tipoAdultoPediatrico);
      if (tipoAdultoPediatrico == undefined) {
        setTipoAdultoPediatrico('Adulto');
      }
    }

    //---------------------- Setando condutas --------------------------

    let condutasCardioTemp = [];
    let condutasRespiratorioTemp = [];
    let condutasNeurologicoTemp = [];
    let condutasInfecciosoTemp = [];
    let condutasRenalTemp = [];
    let condutasHematoTemp = [];
    let condutasNutricionalTemp = [];
    let condutasOutrosTemp = [];
    let condutasResumoTemp = [];

    if (data['CARDIO.PARECER_CONDUTAS']) {
      condutasCardioTemp = JSON.parse(data['CARDIO.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasCardio(condutasCardioTemp);
    }
    if (data['INFECCIOSO.PARECER_CONDUTAS']) {
      condutasInfecciosoTemp = JSON.parse(data['INFECCIOSO.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasInfeccioso(condutasInfecciosoTemp);
    }
    if (data['NEUROLOGICO.PARECER_CONDUTAS']) {
      condutasNeurologicoTemp = JSON.parse(
        data['NEUROLOGICO.PARECER_CONDUTAS']
      );
      //console.log(campo);
      //setCondutasNeurologico(condutasNeurologicoTemp);
    }
    if (data['HEMATO.PARECER_CONDUTAS']) {
      condutasHematoTemp = JSON.parse(data['HEMATO.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasHemato(condutasHematoTemp);
    }
    if (data['NUTRI.PARECER_CONDUTAS']) {
      condutasNutricionalTemp = JSON.parse(data['NUTRI.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasNutricional(condutasNutricionalTemp);
    }
    if (data['OUTROS.PARECER_CONDUTAS']) {
      condutasOutrosTemp = JSON.parse(data['OUTROS.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasOutros(condutasOutrosTemp);
    }
    if (data['RENAL.PARECER_CONDUTAS']) {
      condutasRenalTemp = JSON.parse(data['RENAL.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasRenal(condutasRenalTemp);
    }
    if (data['RESPIRATORIO.PARECER_CONDUTAS']) {
      condutasRespiratorioTemp = JSON.parse(
        data['RESPIRATORIO.PARECER_CONDUTAS']
      );
      //console.log(condutasRespiratorioTemp);
      //setCondutasRespiratorio(condutasRespiratorioTemp);
    }
    if (data['RESUMO.PARECER_CONDUTAS']) {
      condutasResumoTemp = JSON.parse(data['RESUMO.PARECER_CONDUTAS']);
      //console.log(campo);
      //setCondutasResumo(condutasResumoTemp);
    }

    setCondutas({
      Cardio: condutasCardioTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Respiratorio: condutasRespiratorioTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Neurologico: condutasNeurologicoTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Infeccioso: condutasInfecciosoTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Hemato: condutasHematoTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Renal: condutasRenalTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Nutricional: condutasNutricionalTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Outros: condutasOutrosTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
      Resumo: condutasResumoTemp.filter((item) =>
        item.dt_finalizada
          ? 2 >
            Math.round(
              (new Date() - new Date(item.dt_finalizada)) /
                (1000 * 60 * 60 * 24)
            )
          : true
      ),
    });

    if (data['CARDIO.EXAMES_MONITORIZACAO']) {
      let campo = data['CARDIO.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesCardio(campo);
    }
    if (data['INFECCIOSO.EXAMES_MONITORIZACAO']) {
      let campo = data['INFECCIOSO.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesInfeccioso(campo);
    }
    if (data['NEUROLOGICO.EXAMES_MONITORIZACAO']) {
      let campo = data['NEUROLOGICO.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesNeurologico(campo);
    }
    if (data['HEMATO.EXAMES_MONITORIZACAO']) {
      let campo = data['HEMATO.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesHemato(campo);
    }
    if (data['NUTRI.EXAMES_MONITORIZACAO']) {
      let campo = data['NUTRI.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesNutricional(campo);
    }
    if (data['OUTROS.EXAMES_MONITORIZACAO']) {
      let campo = data['OUTROS.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesOutros(campo);
    }
    if (data['RENAL.EXAMES_MONITORIZACAO']) {
      let campo = data['RENAL.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesRenal(campo);
    }
    if (data['RESPIRATORIO.EXAMES_MONITORIZACAO']) {
      let campo = data['RESPIRATORIO.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesRespiratorio(campo);
    }
    if (data['RESUMO.EXAMES_MONITORIZACAO']) {
      let campo = data['RESUMO.EXAMES_MONITORIZACAO'];
      //console.log(campo);
      setExamesResumo(campo);
    }
    if (data['IDENT.PESO_KG']) {
      let campo = data['IDENT.PESO_KG'];
      //console.log(campo);
      setPeso(campo);
    }

    let campoFebre = data['INT24.FEBRE'];
    //console.log(campoFebreState);
    setFebre(campoFebre);

    if (data['INT24H.BH']) {
      let bhTemp = data['INT24H.BH'];
      //console.log(bhTemp);
      if (bhTemp && bhTemp.substring(0, 1) == '-') {
        setSinalBh('-');
      }
      //console.log(bhTemp);
      setBh(bhTemp.substring(1, bhTemp.length));
    }

    // arrumar todos seguindo o modelo do aminas

    // setAntibioticosRadio(data['INFECCIOSO.ANTIBIOTICOS'] ? 'sim' : 'nao');
    // if (data['INFECCIOSO.ANTIBIOTICOS']) {
    //   //console.log('*** SIM');
    //   //console.log(
    //     '*** ANTIBIOTICOS INFECCIOSOS: ',
    //     data['INFECCIOSO.ANTIBIOTICOS']
    //   );

    //   setAntibioticos(data['INFECCIOSO.ANTIBIOTICOS']);
    //   setTimeout(() => {
    //     //console.log('*** Lista Antibioticos: ', { antibioticos });
    //     setAntibioticos(data['INFECCIOSO.ANTIBIOTICOS']);

    //     if (antibioticos.length > 1) {
    //       setAntibioticosRadio(true);
    //       handleAntibioticosPrescritosRadio(true);
    //     } else {
    //       setAntibioticosRadio(false);
    //       handleAntibioticosPrescritosRadio(false);
    //     }
    //   }, 3000);
    // } else {
    //   //console.log('*** NAO');
    // }

    let dataName = Object.getOwnPropertyNames(data);

    dataName.map((item) => {
      setValue(item, data[item], {
        shouldValidate: true,
      });
    });
    setPronto(true);
  }

  //Botões para adição de medicamentos

  function BotaoNovoMedicamento() {
    return (
      <>
        <button type="submit" disabled />
        <a data-toggle="modal" data-target="#modalNovoMedicamento">
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
          >
            Cadastrar Novo Medicamento
          </button>
        </a>
      </>
    );
  }

  function BotaoNovoAntibiotico() {
    return (
      <>
        <a data-toggle="modal" data-target="#modalNovoAntibiotico">
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
          >
            Cadastrar Novo Antibiótico
          </button>
        </a>
      </>
    );
  }

  function BotaoNovaDiluicao() {
    return (
      <>
        <a data-toggle="modal" data-target="#modalNovaDiluicao">
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
          >
            Cadastrar Nova Diluição
          </button>
        </a>
      </>
    );
  }

  //Onchanges, provavelmente serão alterados

  function changePeso(event) {
    setPeso(event.target.value);

    if (parseInt(peso) > 0) {
      neuro.forEach((item, index) => {
        // console.log(item);
        changeDoseNeuro(index);
      });
    }
  }

  function changeBh(event) {
    //console.log(event.target.value);
    setBh(event.target.value);
  }

  function changeSinal(event) {
    //console.log(event.target.value);
    setSinalBh(event.target.value);
  }

  function changeDataAlta(event) {
    //console.log(event.target.value);
    setDataAlta(event.target.value);
  }

  function changePrescricaoAlta(event) {
    //console.log(event.target.value);
    setPrescricaoAlta(event.target.value);
  }

  function changeDescricaoMedicamento(event) {
    //console.log(event.target.value);
    setDescricaoMedicamento(event.target.value);
  }

  function changeTipoMedicamento(event) {
    //console.log(event.target.value);
    setTipoMedicamento(event.target.value);
  }

  function changeDiluicaoMedicamento(event) {
    //console.log(event.target.value);
    setDiluicaoMedicamento(event.target.value);
  }

  function changeValorMedicamento(event) {
    //console.log(event.target.value);
    setValorMedicamento(event.target.value);
  }

  function changeUnidadeMedicamento(event) {
    //console.log(event.target.value);
    setUnidadeMedicamento(event.target.value);
  }

  function changeFebre(event) {
    //console.log(event.target.value);
    setFebre(event.target.value);
    ////console.log(rotina[0]['INTERN.INVASOES']);
  }

  //Onchanges Complexos

  function changeOxi(event) {
    //console.log(event.target.value);
    setOxiRadio(event.target.value);
    if (event.target.value == 'nao')
      setOxi([{ suporte: 'nao', dispositivo: '', detalhes: '' }]);
    if (event.target.value == 'sim')
      setOxi([{ suporte: '', dispositivo: '', detalhes: '' }]);
  }

  function changeAminas(event) {
    //console.log(event.target.value);
    setAminasRadio(event.target.value);
    if (event.target.value == 'nao')
      setAminas([
        {
          droga: 'nao',
          diluicao: '',
          velocidade: '',
          dose: '',
          valor: '',
          unidade: '',
        },
      ]);
    if (event.target.value == 'sim')
      setAminas([
        {
          droga: '',
          diluicao: '',
          velocidade: '',
          dose: '',
          valor: '',
          unidade: 'µg/kg/h',
        },
      ]);
  }

  function changeDrogas(event) {
    //console.log(event.target.value);
    setDrogasRadio(event.target.value);
    if (event.target.value == 'nao')
      setNeuro([
        {
          droga: 'nao',
          diluicao: '',
          velocidade: '',
          dose: '',
          valor: '',
          unidade: 'µg/kg/h',
        },
      ]);
    if (event.target.value == 'sim')
      setNeuro([
        {
          droga: '',
          diluicao: '',
          velocidade: '',
          dose: '',
          valor: '',
          unidade: 'µg/kg/h',
        },
      ]);
  }

  function changeCulturas(event) {
    //console.log(event.target.value);
    setCulturasRadio(event.target.value);
    if (event.target.value == 'nao')
      setCulturas([{ descricao: '', data: '', tipo: 'nao', resultado: '' }]);
    if (event.target.value == 'sim')
      setCulturas([{ descricao: '', data: '', tipo: '', resultado: '' }]);
  }

  function changeDia(event) {
    //console.log(event.target.value);
    setDiaRadio(event.target.value);
    if (event.target.value == 'nao') setDia([{ tipo: 'nao', detalhes: '' }]);
    if (event.target.value == 'sim') setDia([{ tipo: '', detalhes: '' }]);
  }

  function changeAntibioticosRadioOption(event) {
    setAntibioticosRadio(event.target.value);
    //console.log(event.target.value);
    // setDiaRadio(event.target.value);
    if (event.target.value == 'nao')
      setAntibioticos([
        {
          tipo: 'nao',
          dataInicio: '',
          previsaoDias: '',
          diaInicial: '',
          diasTotal: '',
        },
      ]);
    if (event.target.value == 'sim')
      setAntibioticos([
        {
          tipo: '',
          dataInicio: '',
          previsaoDias: '',
          diaInicial: '1',
          diasTotal: '',
        },
      ]);
  }

  //Gerenciador da Area Resumo, precisa ser otimizado:

  function changeResumo(event) {
    setExamesResumo(event.target.value);
  }

  function gerarExamesResumo() {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
  }

  function changeExamesCardio(event) {
    setExamesResumo(
      event.target.value +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
    setExamesCardio(event.target.value);
  }

  function changeExamesRespiratorio(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        event.target.value +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
    setExamesRespiratorio(event.target.value);
  }

  function changeExamesNeurologico(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        event.target.value +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
    setExamesNeurologico(event.target.value);
  }

  function changeExamesHemato(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        event.target.value +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
    setExamesHemato(event.target.value);
  }

  function changeExamesInfeccioso(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        event.target.value +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
    setExamesInfeccioso(event.target.value);
  }

  function changeExamesRenal(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        event.target.value +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        examesOutros
    );
    setExamesRenal(event.target.value);
  }

  function changeExamesNutricional(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        event.target.value +
        '\n\n' +
        examesOutros
    );
    setExamesNutricional(event.target.value);
  }

  function changeExamesOutros(event) {
    setExamesResumo(
      examesCardio +
        '\n\n' +
        examesRespiratorio +
        '\n\n' +
        examesNeurologico +
        '\n\n' +
        examesInfeccioso +
        '\n\n' +
        examesRenal +
        '\n\n' +
        examesHemato +
        '\n\n' +
        examesNutricional +
        '\n\n' +
        event.target.value
    );
    setExamesOutros(event.target.value);
  }

  //Gerenciados de condutas obsoleto:

  // function adicionarCampoCondutasCardio() {
  //   let novoCondutasCardio = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   };
  //   setCondutasCardio([...condutasCardio, novoCondutasCardio]);
  //   //console.log([...condutasCardio, novoCondutasCardio]);
  // }

  // const excluirCampoCondutasCardio = (index) => {
  //   let tempCondutasCardio = condutasCardio;
  //   // //console.log(index);
  //   tempCondutasCardio.splice(index, 1);
  //   // //console.log(tempCondutasCardio);
  //   setCondutasCardio(tempCondutasCardio);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasCardio(event, posicao) {
  //   // //console.log('### 5) ', event.target.value);
  //   //console.log('### 6) ', condutasCardio);
  //   condutasCardio.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasCardio(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasCardio.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasCardio(event, posicao) {
  //   condutasCardio.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  //   //console.log('### 4)', condutasCardio);

  //   // //console.log('#!# ', event.target.value);
  //   // const procuraEspecialidade = listaEspecialidades.find(
  //   //   (especialidade) => especialidade.descricao === event.target.value
  //   // );
  //   // //console.log('### 1)', procuraEspecialidade);
  //   // const procuraConduta = condutasCardio[posicao];
  //   // //{ descricao: '', data: '', dt_finalizada: '', especialista: '' }
  //   // procuraConduta.idParecerTipo = procuraEspecialidade.id.toString();
  //   // procuraConduta.tipo = procuraEspecialidade.tipo;
  //   // procuraConduta.idRotina = idRotina;
  //   // procuraConduta.idAtendimento = idAtendimento;
  //   // procuraConduta.grupo = 'CARDIO.PARECER_CONDUTAS';
  //   // //console.log('#!# ', procuraConduta);
  //   // condutasCardio.map((item, index) => {
  //   //   index == posicao
  //   //     ? (item.id_tipo_especialista = procuraEspecialidade.id)
  //   //     : //console.log('erro');
  //   // });
  //   // condutasCardio.map((item, index) => {
  //   //   index == posicao
  //   //     ? (item.ds_tipo_especialista = procuraEspecialidade.tipo)
  //   //     : //console.log('erro');
  //   // });
  // }

  // function botaoCondutasCardio(index) {
  //   if (index == condutasCardio.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasCardio}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasCardio(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasRespiratorio() {
  //   let novoCondutasRespiratorio = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //   };
  //   setCondutasRespiratorio([
  //     ...condutasRespiratorio,
  //     novoCondutasRespiratorio,
  //   ]);
  //   //console.log([...condutasRespiratorio, novoCondutasRespiratorio]);
  // }

  // const excluirCampoCondutasRespiratorio = (index) => {
  //   let tempCondutasRespiratorio = condutasRespiratorio;
  //   //console.log(index);
  //   tempCondutasRespiratorio.splice(index, 1);
  //   //console.log(tempCondutasRespiratorio);
  //   setCondutasRespiratorio(tempCondutasRespiratorio);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasRespiratorio(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasRespiratorio.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasRespiratorio(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasRespiratorio.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasRespiratorio(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasRespiratorio.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasRespiratorio(index) {
  //   if (index == condutasRespiratorio.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasRespiratorio}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasRespiratorio(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasNeurologico() {
  //   let novoCondutasNeurologico = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //   };
  //   setCondutasNeurologico([...condutasNeurologico, novoCondutasNeurologico]);
  //   //console.log([...condutasNeurologico, novoCondutasNeurologico]);
  // }

  // const excluirCampoCondutasNeurologico = (index) => {
  //   let tempCondutasNeurologico = condutasNeurologico;
  //   //console.log(index);
  //   tempCondutasNeurologico.splice(index, 1);
  //   //console.log(tempCondutasNeurologico);
  //   setCondutasNeurologico(tempCondutasNeurologico);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasNeurologico(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasNeurologico.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasNeurologico(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasNeurologico.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasNeurologico(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasNeurologico.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasNeurologico(index) {
  //   if (index == condutasNeurologico.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasNeurologico}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasNeurologico(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasInfeccioso() {
  //   let novoCondutasInfeccioso = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   };
  //   setCondutasInfeccioso([...condutasInfeccioso, novoCondutasInfeccioso]);
  //   //console.log([...condutasInfeccioso, novoCondutasInfeccioso]);
  // }

  // const excluirCampoCondutasInfeccioso = (index) => {
  //   let tempCondutasInfeccioso = condutasInfeccioso;
  //   //console.log(index);
  //   tempCondutasInfeccioso.splice(index, 1);
  //   //console.log(tempCondutasInfeccioso);
  //   setCondutasInfeccioso(tempCondutasInfeccioso);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasInfeccioso(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasInfeccioso.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasInfeccioso(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasInfeccioso.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasInfeccioso(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasInfeccioso.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasInfeccioso(index) {
  //   if (index == condutasInfeccioso.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasInfeccioso}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasInfeccioso(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasRenal() {
  //   let novoCondutasRenal = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   };
  //   setCondutasRenal([...condutasRenal, novoCondutasRenal]);
  //   //console.log([...condutasRenal, novoCondutasRenal]);
  // }

  // const excluirCampoCondutasRenal = (index) => {
  //   let tempCondutasRenal = condutasRenal;
  //   //console.log(index);
  //   tempCondutasRenal.splice(index, 1);
  //   //console.log(tempCondutasRenal);
  //   setCondutasRenal(tempCondutasRenal);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasRenal(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasRenal.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasRenal(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasRenal.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasRenal(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasRenal.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasRenal(index) {
  //   if (index == condutasRenal.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasRenal}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasRenal(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasHemato() {
  //   let novoCondutasHemato = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   };
  //   setCondutasHemato([...condutasHemato, novoCondutasHemato]);
  //   //console.log([...condutasHemato, novoCondutasHemato]);
  // }

  // const excluirCampoCondutasHemato = (index) => {
  //   let tempCondutasHemato = condutasHemato;
  //   //console.log(index);
  //   tempCondutasHemato.splice(index, 1);
  //   //console.log(tempCondutasHemato);
  //   setCondutasHemato(tempCondutasHemato);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasHemato(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasHemato.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasHemato(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasHemato.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasHemato(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasHemato.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasHemato(index) {
  //   if (index == condutasHemato.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasHemato}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasHemato(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasNutricional() {
  //   let novoCondutasNutricional = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //   };
  //   setCondutasNutricional([...condutasNutricional, novoCondutasNutricional]);
  //   //console.log([...condutasNutricional, novoCondutasNutricional]);
  // }

  // const excluirCampoCondutasNutricional = (index) => {
  //   let tempCondutasNutricional = condutasNutricional;
  //   //console.log(index);
  //   tempCondutasNutricional.splice(index, 1);
  //   //console.log(tempCondutasNutricional);
  //   setCondutasNutricional(tempCondutasNutricional);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasNutricional(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasNutricional.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasNutricional(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasNutricional.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasNutricional(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasNutricional.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasNutricional(index) {
  //   if (index == condutasNutricional.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasNutricional}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasNutricional(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasOutros() {
  //   let novoCondutasOutros = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   };
  //   setCondutasOutros([...condutasOutros, novoCondutasOutros]);
  //   //console.log([...condutasOutros, novoCondutasOutros]);
  // }

  // const excluirCampoCondutasOutros = (index) => {
  //   let tempCondutasOutros = condutasOutros;
  //   //console.log(index);
  //   tempCondutasOutros.splice(index, 1);
  //   //console.log(tempCondutasOutros);
  //   setCondutasOutros(tempCondutasOutros);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasOutros(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasOutros.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeDataCondutasOutros(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasOutros.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function changeEspecialistaCondutasOutros(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasOutros.map((item, index) => {
  //     index == posicao && (item.especialista = event.target.value);
  //   });
  //   gerarCondutasResumo();
  //   setUpdate(!update);
  // }

  // function botaoCondutasOutros(index) {
  //   if (index == condutasOutros.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasOutros}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasOutros(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  // function adicionarCampoCondutasResumo() {
  //   let novoCondutasResumo = {
  //     descricao: '',
  //     data: '',
  //     dt_finalizada: '',
  //     especialista: '',
  //   };
  //   setCondutasResumo([...condutasResumo, novoCondutasResumo]);
  //   //console.log([...condutasResumo, novoCondutasResumo]);
  // }

  // const excluirCampoCondutasResumo = (index) => {
  //   let tempCondutasResumo = condutasResumo;
  //   //console.log(index);
  //   tempCondutasResumo.splice(index, 1);
  //   //console.log(tempCondutasResumo);
  //   setCondutasResumo(tempCondutasResumo);
  //   setUpdate(!update);
  // };

  // function changeDescricaoCondutasResumo(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasResumo.map((item, index) => {
  //     index == posicao && (item.descricao = event.target.value);
  //   });
  //   setUpdate(!update);
  // }

  // function changeDataCondutasResumo(event, posicao) {
  //   //console.log(event.target.value);
  //   condutasResumo.map((item, index) => {
  //     index == posicao && (item.data = event.target.value);
  //   });
  //   setUpdate(!update);
  // }

  // function botaoCondutasResumo(index) {
  //   if (index == condutasResumo.length - 1) {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={adicionarCampoCondutasResumo}
  //         >
  //           <i className="fas fa-plus-circle fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div>
  //         <button
  //           disabled={read}
  //           type="button"
  //           className="templatemo-white-button"
  //           style={
  //             read
  //               ? { backgroundColor: '#ccc', borderColor: '#ccc' }
  //               : {backgroundColor: '#39566B'}
  //           }
  //           onClick={() => excluirCampoCondutasResumo(index)}
  //         >
  //           <i className="fas fa-trash fa-fw" />
  //         </button>
  //       </div>
  //     );
  //   }
  // }

  function adicionarCampoDiagnosticosInternacao() {
    //console.log(diagnosticosInternacao);
    setDiagnosticosInternacao([...diagnosticosInternacao, { descricao: '' }]);
    //console.log([...diagnosticosInternacao, { descricao: '' }]);
  }

  const excluirCampoDiagnosticosInternacao = (index) => {
    let tempDiagnosticos = diagnosticosInternacao;
    //console.log(index);
    tempDiagnosticos.splice(index, 1);
    //console.log(tempDiagnosticos);
    setDiagnosticosInternacao(tempDiagnosticos);
    setUpdate(!update);
  };

  function changeDiagnosticosInternacao(event, posicao) {
    diagnosticosInternacao.map((item, index) => {
      index == posicao && (item.descricao = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoDiagnosticosInternacao(index) {
    if (index == diagnosticosInternacao.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoDiagnosticosInternacao}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoDiagnosticosInternacao(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoDiagnosticosSecundario() {
    setDiagnosticosSecundario([...diagnosticosSecundario, { descricao: '' }]);
    //console.log([...diagnosticosSecundario, '']);
  }

  const excluirCampoDiagnosticosSecundario = (index) => {
    let tempDiagnosticos = diagnosticosSecundario;
    //console.log(index);
    tempDiagnosticos.splice(index, 1);
    //console.log(tempDiagnosticos);
    setDiagnosticosSecundario(tempDiagnosticos);
    setUpdate(!update);
  };

  function changeDiagnosticosSecundario(event, posicao) {
    //console.log(event.target.value);
    diagnosticosSecundario.map((item, index) => {
      index == posicao && (item.descricao = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoDiagnosticosSecundario(index) {
    if (index == diagnosticosSecundario.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoDiagnosticosSecundario}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoDiagnosticosSecundario(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoDiagnosticosInvasao() {
    setDiagnosticosInvasao([
      ...diagnosticosInvasao,
      { descricao: '', dataInvasoes: '' },
    ]);
    console.log(diagnosticosInvasao);
  }

  const excluirCampoDiagnosticosInvasao = (index) => {
    let tempDiagnosticos = diagnosticosInvasao;
    //console.log(index);
    tempDiagnosticos.splice(index, 1);
    //console.log(tempDiagnosticos);
    setDiagnosticosInvasao(tempDiagnosticos);
    setUpdate(!update);
  };

  function changeDiagnosticosInvasao(event, posicao) {
    //console.log(event.target.value);
    diagnosticosInvasao.map((item, index) => {
      index == posicao && (item.descricao = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDataInvasao(event, posicao) {
    diagnosticosInvasao.map((item, index) => {
      if (index == posicao) {
        if (new Date(event.target.value) > new Date(hojeFormatado)) {
          item.dataInvasoes = hojeFormatado;
        } else {
          item.dataInvasoes = event.target.value;
        }
      }
    });
    setUpdate(!update);
  }

  function botaoDiagnosticosInvasao(index) {
    if (index == diagnosticosInvasao.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoDiagnosticosInvasao}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoDiagnosticosInvasao(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoAminas() {
    console.log('adicionado');
    let novaAmina = {
      droga: '',
      diluicao: '',
      velocidade: '',
      dose: '',
      valor: '',
      unidade: 'µg/kg/h',
    };
    setAminas([...aminas, novaAmina]);
    //console.log([...aminas, novaAmina]);
  }

  const excluirCampoAmina = (index) => {
    let tempAminas = aminas;
    //console.log(index);
    tempAminas.splice(index, 1);
    //console.log(tempAminas);
    setAminas(tempAminas);
    setUpdate(!update);
  };

  // function changeTipoAmina(event, posicao) {
  //   //console.log(event.target.value);
  //   aminas.map((item, index) => {
  //     index == posicao && (item.tipo = event.target.value);
  //   });
  //   setUpdate(!update);
  // }

  function changeDrogaAmina(event, posicao) {
    //console.log(event.target.value);
    aminas.map((item, index) => {
      index == posicao && (item.droga = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDiluicaoAmina(event, posicao) {
    let i = event.target.selectedIndex;
    let e = event.target.childNodes[i];
    //console.log(event.target.value);
    aminas.map((item, index) => {
      if (index == posicao) {
        item.diluicao = event.target.value;
        item.valor = e.getAttribute('id');
      }
    });
    //console.log(e.getAttribute('id'));
    changeDoseAmina(posicao);
    setUpdate(!update);
  }

  function changeVelocidadeAmina(event, posicao) {
    //console.log(event.target.value);
    aminas.map((item, index) => {
      index == posicao && (item.velocidade = event.target.value);
    });
    changeDoseAmina(posicao);
    setUpdate(!update);
  }

  function changeDoseAmina(posicao, valor = null) {
    //console.log(posicao);
    aminas.map((item, index) => {
      let unidade;
      let currentPeso = peso;

      switch (item.unidade) {
        case 'µg/kg/h':
          unidade = 1;
          break;
        case 'µg/h':
          unidade = peso;
          break;
        case 'µg/kg/min':
          unidade = 60;
          break;
        case 'µg/min':
          unidade = peso * 60;
          break;
        case 'mg/kg/h':
          // unidade = '1000';
          unidade = 1;
          break;
        case 'mg/h':
          // unidade = peso * 1000;
          unidade = 1;
          currentPeso = 1;
          break;
        case 'mg/kg/min':
          unidade = 60000;
          break;
        case 'mg/min':
          unidade = peso * 60000;
          break;
        default:
          break;
      }
      //console.log(item);

      let valorCalculado = valor;
      if (valor == null) {
        valorCalculado =
          Math.round(
            ((parseInt(item.valor, 10) * item.velocidade) /
              currentPeso /
              parseInt(unidade, 10)) *
              1e2
          ) / 1e2;
      }

      index == posicao && (item.dose = valorCalculado);
    });
    setUpdate(!update);
  }

  function changeUnidadeAmina(event, posicao) {
    //console.log(event.target.value);
    aminas.map((item, index) => {
      index == posicao && (item.unidade = event.target.value);
    });

    //TODO: PRECISA VER COMO SE COMPORTA COM ROTINA EM BRANCO
    if (tipoAdultoPediatrico != 'Pediatrico') {
      changeDoseAmina(posicao);
    }

    setUpdate(!update);
  }

  function botaoAminas(index) {
    if (index == aminas.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoAminas}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoAmina(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoOxi() {
    let novaOxi = { suporte: '', dispositivo: '', detalhes: '' };
    setOxi([...oxi, novaOxi]);
    //console.log([...oxi, novaOxi]);
  }

  const excluirCampoOxi = (index) => {
    let tempOxi = oxi;
    //console.log(index);
    tempOxi.splice(index, 1);
    //console.log(tempOxi);
    setOxi(tempOxi);
    setUpdate(!update);
  };

  function changeSuporteOxi(event, posicao) {
    //console.log(event.target.value);
    oxi.map((item, index) => {
      index == posicao && (item.suporte = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDispositivoOxi(event, posicao) {
    //console.log(event.target.value);
    oxi.map((item, index) => {
      index == posicao && (item.dispositivo = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDetalhesOxi(event, posicao) {
    //console.log(posicao);
    oxi.map((item, index) => {
      //console.log(item);
      index == posicao && (item.detalhes = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoOxi(index) {
    if (index == oxi.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoOxi}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoOxi(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoGasometria() {
    let novaGasometria = { descricao: '', data: '', hora: '' };
    setGasometria([...gasometria, novaGasometria]);
    //console.log([...gasometria, novaGasometria]);
  }

  const excluirCampoGasometria = (index) => {
    let tempGasometria = gasometria;
    //console.log(index);
    tempGasometria.splice(index, 1);
    //console.log(tempGasometria);
    setGasometria(tempGasometria);
    setUpdate(!update);
  };

  function changeDescricaoGasometria(event, posicao) {
    //console.log(event.target.value);
    gasometria.map((item, index) => {
      index == posicao && (item.descricao = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDataGasometria(event, posicao) {
    //console.log(event.target.value);
    gasometria.map((item, index) => {
      if (index == posicao) {
        if (new Date(event.target.value) > new Date(hojeFormatado)) {
          item.data = hojeFormatado;
        } else {
          item.data = event.target.value;
        }
      }
    });
    setUpdate(!update);
  }

  function changeHoraGasometria(event, posicao) {
    //console.log(event.target.value);
    gasometria.map((item, index) => {
      index == posicao && (item.hora = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoGasometria(index) {
    if (index == gasometria.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoGasometria}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoGasometria(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoNeuro() {
    let novaNeuro = {
      droga: '',
      diluicao: '',
      velocidade: '',
      dose: '',
      valor: '',
      unidade: 'µg/kg/h',
    };
    setNeuro([...neuro, novaNeuro]);
    //console.log([...neuro, novaNeuro]);
  }

  const excluirCampoNeuro = (index) => {
    let tempNeuro = neuro;
    //console.log(index);
    tempNeuro.splice(index, 1);
    //console.log(tempNeuro);
    setNeuro(tempNeuro);
    setUpdate(!update);
  };

  function changeDrogaNeuro(event, posicao) {
    //console.log(event.target.value);
    neuro.map((item, index) => {
      index == posicao && (item.droga = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDiluicaoNeuro(event, posicao) {
    let i = event.target.selectedIndex;
    let e = event.target.childNodes[i];
    //console.log(event.target.value);
    neuro.map((item, index) => {
      if (index == posicao) {
        item.diluicao = event.target.value;
        item.valor = e.getAttribute('id');
      }
    });
    //console.log(e.getAttribute('id'));
    changeDoseNeuro(posicao);
    setUpdate(!update);
  }

  function changeVelocidadeNeuro(event, posicao) {
    //console.log(event.target.value);
    neuro.map((item, index) => {
      index == posicao && (item.velocidade = event.target.value);
    });
    changeDoseNeuro(posicao);
    setUpdate(!update);
  }

  function changeDoseNeuro(posicao, valor = null) {
    // console.log(valor);

    neuro.map((item, index) => {
      let unidade;
      let currentPeso = peso;

      // console.log('*** Valor: ', item);

      switch (item.unidade) {
        case 'µg/kg/h':
          unidade = '1';
          break;
        case 'µg/h':
          unidade = peso;
          break;
        case 'µg/kg/min':
          unidade = '60';
          break;
        case 'µg/min':
          unidade = peso * 60;
          break;
        case 'mg/kg/h':
          // unidade = '1000';
          unidade = 1;
          break;
        case 'mg/h':
          // unidade = peso * 1000;
          unidade = 1;
          currentPeso = 1;
          break;
        case 'mg/kg/min':
          unidade = '60000';
          break;
        case 'mg/min':
          unidade = peso * 60000;
          break;
        default:
          break;
      }

      let valorCalculado = valor;
      if (valor == null) {
        // valorCalculado =
        //   (parseInt(item.valor, 10) * item.velocidade) /
        //   currentPeso /
        //   parseInt(unidade, 10);
        valorCalculado =
          Math.round(
            ((parseInt(item.valor, 10) * item.velocidade) /
              currentPeso /
              parseInt(unidade, 10)) *
              1e2
          ) / 1e2;
      }

      index == posicao && (item.dose = valorCalculado);
    });
    setUpdate(!update);
  }

  function changeUnidadeNeuro(event, posicao) {
    //console.log(event.target.value);
    neuro.map((item, index) => {
      index == posicao && (item.unidade = event.target.value);
    });

    //TODO: PRECISA VER COMO SE COMPORTA COM ROTINA EM BRANCO
    if (tipoAdultoPediatrico != 'Pediatrico') {
      changeDoseNeuro(posicao);
    }

    setUpdate(!update);
  }

  function botaoNeuro(index) {
    if (index == neuro.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoNeuro}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoNeuro(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoEscala() {
    let novaEscala = {
      escala: '',
      valor: '',
    };
    setEscala([...escala, novaEscala]);
  }

  const excluirCampoEscala = (index) => {
    let tempEscala = escala;
    //console.log(index);
    tempEscala.splice(index, 1);
    setEscala(tempEscala);
    setUpdate(!update);
  };

  function changeEscalaDescricao(event, posicao) {
    //console.log(event.target.value);
    escala.map((item, index) => {
      index == posicao && (item.escala = event.target.value);
    });
    setUpdate(!update);
  }

  function changeEscalaValor(event, posicao) {
    //console.log(event.target.value);
    escala.map((item, index) => {
      index == posicao && (item.valor = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoEscala(index) {
    if (index == escala.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoEscala}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoEscala(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoCulturas() {
    let novaCultura = { descricao: '', data: '', tipo: '', resultado: '' };
    setCulturas([...culturas, novaCultura]);
    // //console.log([...culturas, novaCultura]);
  }

  const excluirCampoCulturas = (index) => {
    let tempCultura = culturas;
    //console.log(index);
    tempCultura.splice(index, 1);
    //console.log(tempCultura);
    setCulturas(tempCultura);
    setUpdate(!update);
  };

  function changeDataCulturas(event, posicao) {
    // //console.log(event.target.value);
    culturas.map((item, index) => {
      index == posicao && (item.data = event.target.value);
    });
    setUpdate(!update);
  }

  function changeTipoCulturas(event, posicao) {
    //console.log('***', event, posicao);
    // //console.log(event.target.value);
    culturas.map((item, index) => {
      index == posicao && (item.tipo = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDescricaoCulturas(event, posicao) {
    // //console.log(event.target.value);
    culturas.map((item, index) => {
      index == posicao && (item.descricao = event.target.value);
    });
    setUpdate(!update);
  }

  function changeResultadoCulturas(event, posicao) {
    //console.log(event.target.value);
    culturas.map((item, index) => {
      index == posicao && (item.resultado = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoCulturas(index) {
    if (index == culturas.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoCulturas}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoCulturas(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoDia() {
    let novaDia = { tipo: '', detalhes: '' };
    setDia([...dia, novaDia]);
    //console.log([...dia, novaDia]);
  }

  const excluirCampoDia = (index) => {
    let tempDia = dia;
    //console.log(index);
    tempDia.splice(index, 1);
    //console.log(tempDia);
    setDia(tempDia);
    setUpdate(!update);
  };

  function changeTipoDia(event, posicao) {
    //console.log(event.target.value);
    dia.map((item, index) => {
      index == posicao && (item.tipo = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDetalhesDia(event, posicao) {
    //console.log(event.target.value);
    dia.map((item, index) => {
      index == posicao && (item.detalhes = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoDia(index) {
    if (index == dia.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoDia}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoDia(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function changeDataHematimetria(event) {
    if (new Date(event.target.value) > new Date(hojeFormatado)) {
      setDataHematimetria(hojeFormatado);
    } else {
      setDataHematimetria(event.target.value);
    }
  }

  function changeDataCoagulograma(event) {
    if (new Date(event.target.value) > new Date(hojeFormatado)) {
      setDataCoagulograma(hojeFormatado);
    } else {
      setDataCoagulograma(event.target.value);
    }
  }

  function adicionarCampoDieta() {
    let novaDieta = { tipo: '', dieta: '', observacao: '' };
    setDieta([...dieta, novaDieta]);
    //console.log([...dieta, novaDieta]);
  }

  const excluirCampoDieta = (index) => {
    let tempDieta = dieta;
    //console.log(index);
    tempDieta.splice(index, 1);
    //console.log(tempDieta);
    setDieta(tempDieta);
    setUpdate(!update);
  };

  function changeTipoDieta(event, posicao) {
    //console.log(event.target.value);
    dieta.map((item, index) => {
      index == posicao && (item.tipo = event.target.value);
    });
    setUpdate(!update);
  }

  function changeDietaDieta(event, posicao) {
    //console.log(posicao);
    dieta.map((item, index) => {
      //console.log(item);
      index == posicao && (item.dieta = event.target.value);
    });
    setUpdate(!update);
  }

  function changeObservacaoDieta(event, posicao) {
    //console.log(event.target.value);
    dieta.map((item, index) => {
      index == posicao && (item.observacao = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoDieta(index) {
    if (index == dieta.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoDieta}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoDieta(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function adicionarCampoMetas() {
    let novaMeta = { meta: '' };
    setMetas([...metas, novaMeta]);
    //console.log([...metas, novaMeta]);
  }

  const excluirCampoMetas = (index) => {
    let tempMetas = metas;
    //console.log(index);
    tempMetas.splice(index, 1);
    //console.log(tempMetas);
    setMetas(tempMetas);
    setUpdate(!update);
  };

  function changeMetas(event, posicao) {
    //console.log(event.target.value);
    metas.map((item, index) => {
      index == posicao && (item.meta = event.target.value);
    });
    setUpdate(!update);
  }

  function botaoMetas(index) {
    if (index == metas.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoMetas}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoMetas(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  function changeAntibioticoTipo(event, posicao) {
    antibioticos.map((item, index) => {
      index == posicao && (item.tipo = event.target.value);
    });
    setUpdate(!update);
  }

  // function changeAntibioticoDataInicio(event, posicao) {
  //   antibioticos.map((item, index) => {
  //     index == posicao
  //       ? (item.dataInicio = event.target.value)
  //       : //console.log('erro');
  //   });
  //   setUpdate(!update);
  // }

  function changeAntibioticoDataInicio(event, posicao) {
    antibioticos.map((item, index) => {
      if (index == posicao) {
        if (new Date(event.target.value) > new Date(hojeFormatado)) {
          item.dataInicio = hojeFormatado;
        } else {
          item.dataInicio = event.target.value;
        }
      }
    });

    antibioticos.map((item, index) => {
      if (index == posicao) {
        if (item?.diaInicial) {
          let dataReferencia = new Date().toISOString().slice(0, 10);
          item.diasTotal = calcularDiasTotalAntibiotico(
            item.diaInicial,
            item?.dataInicio,
            dataReferencia
          );
        } else {
          //console.log('nao existe');
        }
      }
    });

    setUpdate(!update);
  }

  function changeAntibioticoPrevisaoDias(event, posicao) {
    antibioticos.map((item, index) => {
      index == posicao && (item.previsaoDias = event.target.value);
    });
    checarAlertaAntibiotico();
    setUpdate(!update);
  }

  function changeAntibioticoDiaInicial(event, posicao) {
    antibioticos.map((item, index) => {
      index == posicao && (item.diaInicial = event.target.value);
    });

    antibioticos.map((item, index) => {
      if (index == posicao) {
        if (item?.diaInicial) {
          let dataReferencia = new Date().toISOString().slice(0, 10);
          item.diasTotal = calcularDiasTotalAntibiotico(
            item.diaInicial,
            item?.dataInicio,
            dataReferencia
          );
        } else {
          //console.log('nao existe');
        }
      }
    });

    setUpdate(!update);
  }

  function RecalcularAntibioticos() {
    antibioticos.map((item) => {
      if (item?.diaInicial) {
        let dataReferencia = new Date().toISOString().slice(0, 10);
        item.diasTotal = calcularDiasTotalAntibiotico(
          item.diaInicial,
          item?.dataInicio,
          dataReferencia
        );
      } else {
        // //console.log('nao existe');
      }
    });
  }

  function changeAntibioticoDiasTotal(event, posicao) {
    antibioticos.map((item, index) => {
      index == posicao && (item.diasTotal = event.target.value);
    });
    setUpdate(!update);
  }

  function calcularDiasTotalAntibiotico(diaZero, dataInicio, dataReferencia) {
    let dt_inicio = new Date(dataInicio);
    let dt_fim = new Date(dataReferencia);
    let dateDiff = dt_fim.getTime() - dt_inicio.getTime();
    let dias = Math.ceil(dateDiff / (1000 * 3600 * 24));

    dias = dias - parseInt(diaZero) + 1;
    return dias;
  }

  function adicionarCampoAntibioticos() {
    let novoAntibiotico = {
      tipo: '',
      dataInicio: '',
      previsaoDias: '',
      diaInicial: '1',
      diasTotal: '',
    };
    //console.log([...antibioticos, novoAntibiotico])
    setAntibioticos([...antibioticos, novoAntibiotico]);
  }

  function excluirCampoAntibioticos(index) {
    let tempAntibio = antibioticos;
    tempAntibio.splice(index, 1);
    setAntibioticos(tempAntibio);
    setUpdate(!update);
  }

  function botaoAntibiotico(index) {
    if (index == antibioticos.length - 1) {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={adicionarCampoAntibioticos}
          >
            <i className="fas fa-plus-circle fa-fw" />
          </button>
        </div>
      );
    } else {
      return (
        <div>
          <button
            disabled={read}
            type="button"
            className="templatemo-white-button"
            style={
              read
                ? { backgroundColor: '#ccc', borderColor: '#ccc' }
                : { backgroundColor: '#39566B' }
            }
            onClick={() => excluirCampoAntibioticos(index)}
          >
            <i className="fas fa-trash fa-fw" />
          </button>
        </div>
      );
    }
  }

  async function subirArquivos() {
    setSubindoArquivo(true);
    if (selectedFile) {
      Object.keys(selectedFile).map(async (key, index) => {
        //console.log(selectedFile[key]);
        await RotinaMedicaAPI.salvarAnexosAtendimento(
          atendimento,
          selectedFile[key]
        );
        // .then(() => {
        //   obterAnexosAtendimento(idAtendimento);
        // });
        setSubindoArquivo(false);
      });
    }
  }

  const onSubmit = async () => {
    setPageOverlayText('Finalizando rotina ...');
    setShowPageOverlay(true);

    let data = await RotinaMedicaAPI.consultarRotina(idRotina);
    data.in_status_ronda = 2;
    data.nv_obs_plantonista = intercorrencia;

    try {
      await subirArquivos();
      await atualizaRotina();
      await RotinaMedicaAPI.salvarRotina(data);
      setShowPageOverlay(false);
      setPageOverlayText(OVERLAY_TEXTO_PADRAO);
    } catch (error) {
      // console.error(error);
      let msgError = `Não foi possível finalizar a rotina \n onSubmit \n ${error}`;
      alert(msgError);
      setShowPageOverlay(false);
      setPageOverlayText(OVERLAY_TEXTO_PADRAO);
    }

    // history.goBack();
    history.push({ pathname: RotasURL.rotinaMedica.index });
  };

  async function obterAlta(id) {
    try {
      let altaTemp = await AtendimentoAPI.consultarAltaMedica(id);
      // //console.log(altaTemp);
      // //console.log(altaTemp[0].nv_prescricao_alta);
      // //console.log(altaTemp[0].dt_data_alta);
      setPrescricaoAlta(altaTemp[0].nv_prescricao_alta);
      setDataAlta(
        altaTemp[0].dt_data_alta && altaTemp[0].dt_data_alta.substring(0, 10)
      );
    } catch (error) {
      // //console.log(error);
      throw error;
    }
  }

  async function submitAlta() {
    try {
      let altaTemp = await AtendimentoAPI.consultarAltaMedica(atendimento);
      if (altaTemp) {
        await AtendimentoAPI.excluirAltaMedica(atendimento);
      }
      //console.log(atendimento);
      await AtendimentoAPI.salvarAltaMedica(
        atendimento,
        dataAlta,
        prescricaoAlta
      );
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  function onOverlay() {
    document.getElementById('loadingOverlay').style.display = 'flex';
  }

  function offOverlay() {
    document.getElementById('loadingOverlay').style.display = 'none';
    //console.log('atualizada');
  }

  function PageOverlay() {
    return (
      <div
        id="overlay"
        class="pageOverlay"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <LoadingIcons.TailSpin stroke="#fff" transform="scale(1.5)" />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: '20px', color: '#fff' }}>
          {pageOverlayText}
        </span>
      </div>
    );
  }

  return (
    <>
      <div id="loadingOverlay">
        <LoadingIcons.Puff stroke="#39566b" transform="scale(1.5)" />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{ fontSize: '20px', color: '#39566b' }}>
          {' '}
          Salvando Rotina{' '}
        </span>
      </div>

      {showPageOverlay && <PageOverlay />}

      <form
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
        //onChange={() => setAlteracoes(true)}
      >
        {/* <!--Título da Página--> */}
        <h2 className="margin-bottom-10">
          <Link
            to={RotasURL.rotinaMedica.atendimento.replace(
              ':idAtendimento',
              atendimento
            )}
          >
            <i className="fa fa-arrow-alt-circle-left fa-fw" />
          </Link>
          Rotina Médica
          <div className="btn-home">
            <a href="">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
        </h2>

        <div className="header-rotina-medica">
          <div className="header-rotina-medica__row">
            <h3> {nome} </h3>
            <strong> Atendimento: </strong> {atendimento}
          </div>
          <div className="header-rotina-medica__row">
            <strong> Data de Nascimento: </strong> {nascimento}
            <strong> Idade: </strong> {idade ? idade : ''}
          </div>
          <div className="header-rotina-medica__row">
            <strong> Alergias: </strong> {alergias ? alergias : ''}
          </div>
        </div>

        <>
          <div className="row form-group box-bg-white">
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Altura</label>
                <input
                  disabled={read}
                  type="number"
                  step="0.01"
                  className="form-control"
                  id="Altura"
                  placeholder=""
                  autoComplete="off"
                  defaultValue={rotina && rotina['IDENT.ALTURA']}
                  onInput={(e) => {
                    if (e.target.value > 3) {
                      e.target.value = 3;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                  {...register('IDENT.ALTURA', {})}
                />
                <span
                  className="form-control-label"
                  style={{ width: 'fit-content' }}
                >
                  Metros
                </span>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Peso</label>
                <input
                  disabled={read}
                  type="number"
                  className="form-control"
                  id=""
                  placeholder=""
                  autoComplete="off"
                  value={peso}
                  onInput={(e) => {
                    if (e.target.value > 999) {
                      e.target.value = 999;
                    }
                    if (e.target.value < 0) {
                      e.target.value = 0;
                    }
                  }}
                  onChange={(props) => changePeso(props)}
                />
                <span
                  className="form-control-label"
                  style={{ width: 'fit-content' }}
                >
                  Kg
                </span>
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 form-group text-end">
                <label>Dias de internação CTI</label>
                <input
                  disabled={true}
                  type="text"
                  className="form-control"
                  id=""
                  placeholder=""
                  autoComplete="off"
                  value={diasInternacao}
                  // defaultValue={rotina && rotina['INTERN.DIAS_INTERNACAO_CTI']}
                  // {...register('INTERN.DIAS_INTERNACAO_CTI', {})}
                />
              </div>
              <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                <RadioButtonAdultoPediatrico
                  tipoAdultoPediatrico={tipoAdultoPediatrico}
                  onChangeTipo={setTipoAdultoPediatrico}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-6 col-sm-6 form-group">
              <div>
                <table id="tblsetor" className="table table-responsive">
                  <thead>
                    <tr>
                      <td style={{ color: '#FFFFFF' }}>
                        Diagnóstico de internação
                      </td>
                      <td width={'70'}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {diagnosticosInternacao.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="DiagnosticosInternacao"
                              disabled={read}
                              type="text"
                              className="form-control"
                              value={item.descricao}
                              onChange={(props) =>
                                changeDiagnosticosInternacao(props, index)
                              }
                            />
                          </td>
                          <td>{botaoDiagnosticosInternacao(index)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 form-group">
              <div>
                <table id="tblsetor" className="table table-responsive">
                  <thead>
                    <tr>
                      <td style={{ color: '#FFFFFF' }}>
                        Diagnósticos secundários
                      </td>
                      <td width={'70'}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {diagnosticosSecundario.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="DiagnosticosSecundario"
                              disabled={read}
                              type="text"
                              className="form-control"
                              value={item.descricao}
                              onChange={(props) =>
                                changeDiagnosticosSecundario(props, index)
                              }
                            />
                          </td>
                          <td>{botaoDiagnosticosSecundario(index)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <div>
                <table id="tblsetor" className="table table-responsive">
                  <thead>
                    <tr>
                      <td style={{ color: '#FFFFFF' }}>Dias</td>
                      <td style={{ color: '#FFFFFF' }}>Invasões</td>
                      <td width={'200'} style={{ color: '#FFFFFF' }}>
                        Data/Hora
                      </td>
                      <td width={'70'}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {diagnosticosInvasao.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="DiagnosticosInvasao"
                              disabled={true}
                              type="text"
                              className="form-control"
                              value={
                                item.dataInvasoes
                                  ? Math.round(
                                      (new Date() -
                                        new Date(item.dataInvasoes)) /
                                        (1000 * 60 * 60 * 24)
                                    )
                                  : ''
                              }
                            />
                          </td>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="DiagnosticosInvasao"
                              disabled={read}
                              type="text"
                              className="form-control"
                              value={item.descricao}
                              onChange={(props) =>
                                changeDiagnosticosInvasao(props, index)
                              }
                            />
                          </td>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="DiagnosticosInvasao"
                              disabled={read}
                              type="date"
                              className="form-control"
                              value={item.dataInvasoes}
                              onChange={(props) =>
                                changeDataInvasao(props, index)
                              }
                            />
                          </td>
                          <td>{botaoDiagnosticosInvasao(index)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Intercorrências nas Últimas 24 horas:</h3>
            <div className="row ">
              <div className="col-lg-6 col-md-6 col-sm-6 form-group">
                <label>Diurese</label>
                <input
                  disabled={read}
                  type="text"
                  className="form-control"
                  id=""
                  placeholder=""
                  autoComplete="off"
                  defaultValue={rotina && rotina['INT24.DIURESE']}
                  {...register('INT24.DIURESE', {})}
                />
                <div className="form-control-label">
                  <div style={{ width: '88px' }}>
                    <select
                      width="10"
                      disabled={read}
                      className="form-control"
                      defaultValue={rotina && rotina['INT24.DIURESE_UNIDADE']}
                      {...register('INT24.DIURESE_UNIDADE', {})}
                    >
                      <option value="ml/h">ml/h</option>
                      <option value="ml/24h">ml/24h</option>
                    </select>
                  </div>
                </div>
              </div>

              {/* <div
                className="col-lg-1 col-md-1 col-sm-1 form-group"
                style={{ left: '-10px' }}
              >
                <label>&nbsp;</label>
                <div style={{ width: '88px' }}>
                  <select
                    width="10"
                    disabled={read}
                    className="form-control"
                    defaultValue={rotina && rotina['INT24.DIURESE_UNIDADE']}
                    {...register('INT24.DIURESE_UNIDADE', {})}
                  >
                    <option value="ml/h">ml/h</option>
                    <option value="ml/24h">ml/24h</option>
                  </select>
                </div>
              </div> */}

              <div
                className="col-lg-5 col-md-5 col-sm-5 form-group"
                style={{ marginLeft: '5px' }}
              >
                <label>BH</label>
                <input
                  style={{ paddingLeft: '65px' }}
                  disabled={read}
                  type="text"
                  className="form-control"
                  id=""
                  placeholder=""
                  autoComplete="off"
                  value={bh}
                  onChange={changeBh}
                  //defaultValue={rotina && rotina['INT24H.BH']}
                  //{...register('INT24H.BH', {})}
                />
                <span
                  className="form-control-label"
                  style={{ width: 'fit-content' }}
                >
                  ml/24h
                </span>
                <div className="form-bh-unidade">
                  <div style={{ width: '60px' }}>
                    <select
                      width="10"
                      disabled={read}
                      value={sinalBh}
                      onChange={changeSinal}
                      className="form-control"
                    >
                      <option value="+">+</option>
                      <option value="-">-</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <label>Febre:</label>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <input
                disabled={read}
                type="radio"
                className=""
                id="sim"
                value="sim"
                name="febre"
                onChange={changeFebre}
                checked={febre == 'sim'}
                autoComplete="off"
              />
              <label>Sim</label>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <input
                disabled={read}
                type="radio"
                className=""
                id="nao"
                value="nao"
                name="febre"
                onChange={changeFebre}
                checked={febre == 'nao'}
                autoComplete="off"
              />
              <label>Não</label>
            </div>
            <div>
              {febre == 'sim' ? (
                <div>
                  <div className="col-lg-3 col-md-3 col-sm-3 form-group">
                    <label>Episódios</label>
                    <input
                      type="number"
                      disabled={read}
                      className="form-control"
                      defaultValue={rotina && rotina['INT24.FEBRE_EPISODIOS']}
                      {...register('INT24.FEBRE_EPISODIOS', {})}
                      autoComplete="off"
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-3 form-group">
                    <label>Temperatura Máxima</label>
                    <input
                      style={{ paddingRight: '30px' }}
                      type="number"
                      step="0.1"
                      onInput={(e) => {
                        if (e.target.value > 50) {
                          e.target.value = 50;
                        }
                        if (e.target.value < 37) {
                          e.target.value = 37;
                        }
                      }}
                      disabled={read}
                      className="form-control"
                      defaultValue={rotina && rotina['INT24.FEBRE_MAXIMA']}
                      {...register('INT24.FEBRE_MAXIMA', {})}
                      autoComplete="off"
                    />
                    <span
                      className="form-control-label"
                      style={{ width: 'fit-content' }}
                    >
                      C°
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Intercorrências</label>
              <textarea
                style={{ resize: 'vertical', height: '110px' }}
                disabled={read}
                className="form-control textarea_diag"
                type="text"
                value={intercorrencia}
                onChange={changeIntercorrencia}
                autoComplete="off"
              />
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Sistema Cardiovascular:</h3>
            <div>
              <div className="row">
                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                  <label>Drogas em Infusão Contínua:</label>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                  <input
                    disabled={read}
                    type="radio"
                    className=""
                    id="sim"
                    value="sim"
                    name="aminas"
                    onChange={changeAminas}
                    checked={aminasRadio == 'sim'}
                    autoComplete="off"
                  />
                  <label>Sim</label>
                </div>
                <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                  <input
                    disabled={read}
                    type="radio"
                    className=""
                    id="nao"
                    value="nao"
                    name="aminas"
                    onChange={changeAminas}
                    checked={aminasRadio == 'nao'}
                    autoComplete="off"
                  />
                  <label>Não</label>
                </div>
              </div>

              {aminasRadio == 'sim' ? (
                <div>
                  <div
                    style={{
                      padding: '2px 2px',
                      color: '#00a99d',
                      fontStyle: 'italic',
                    }}
                  >
                    Drogas Aminas Vasoativas, Anti-hipertensivos e
                    Antiarrítmicos
                  </div>
                  <table id="tblsetor" className="table table-responsive">
                    <thead>
                      <tr>
                        <td width={'20%'} style={{ color: '#FFFFFF' }}>
                          Drogas Cadastradas
                        </td>
                        <td width={'*'} style={{ color: '#FFFFFF' }}>
                          Diluição
                        </td>
                        <td width={'15%'} style={{ color: '#FFFFFF' }}>
                          Velocidade de Infusão
                        </td>
                        <td width={'10%'} style={{ color: '#FFFFFF' }}>
                          Dose Calculada
                        </td>
                        <td style={{ color: '#FFFFFF' }}>Unidade</td>
                        <td width={'70'}></td>
                      </tr>
                    </thead>
                    <tbody>
                      {aminas.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <select
                                onFocus={onFocus}
                                disabled={read}
                                className="form-control"
                                id="Aminas"
                                value={item.droga}
                                onChange={(props) =>
                                  changeDrogaAmina(props, index)
                                }
                              >
                                <option value="">---</option>
                                {drogas
                                  .filter(
                                    (med) =>
                                      med.tipo_droga == 'Aminas Vasoativas' ||
                                      med.tipo_droga == 'Anti-hipertensivos' ||
                                      med.tipo_droga == 'Antiarrítmicos'
                                  )
                                  .map((droga, index) => {
                                    return (
                                      <option
                                        value={droga.droga_cadastrada}
                                        key={droga.droga_cadastrada}
                                      >
                                        {droga.droga_cadastrada}
                                      </option>
                                    );
                                  })}
                              </select>
                            </td>
                            <td>
                              <select
                                onFocus={onFocus}
                                // disabled={read}
                                disabled={
                                  tipoAdultoPediatrico == 'Pediatrico' || read
                                }
                                className="form-control"
                                id="Aminas"
                                value={item.diluicao}
                                onChange={(props) =>
                                  changeDiluicaoAmina(props, index)
                                }
                              >
                                <option value="">---</option>
                                {item.droga &&
                                drogas.filter(
                                  (droga) =>
                                    droga.droga_cadastrada == item.droga
                                )[0]
                                  ? drogas
                                      .filter(
                                        (droga) =>
                                          droga.droga_cadastrada == item.droga
                                      )[0]
                                      .diluicao.map((lista, index) => {
                                        return (
                                          <option
                                            value={lista.nv_descricao}
                                            id={
                                              lista.nv_unidade == 'mg/h'
                                                ? 1000 *
                                                  parseInt(lista.nv_valor, 10)
                                                : parseInt(lista.nv_valor, 10)
                                            }
                                            key={lista.nv_descricao}
                                          >
                                            {lista.nv_descricao}
                                          </option>
                                        );
                                      })
                                  : null}
                              </select>
                            </td>
                            <td>
                              <div className="form-control-td">
                                <input
                                  onFocus={onFocus}
                                  // disabled={read}
                                  disabled={
                                    tipoAdultoPediatrico == 'Pediatrico' || read
                                  }
                                  style={{ paddingRight: '65px' }}
                                  type="number"
                                  className="form-control"
                                  id="Aminas"
                                  value={item.velocidade}
                                  onInput={(e) => {
                                    if (e.target.value < 0) {
                                      e.target.value = 0;
                                    }
                                  }}
                                  onChange={(props) =>
                                    changeVelocidadeAmina(props, index)
                                  }
                                  autoComplete="off"
                                />
                                <span
                                  className="form-control-label"
                                  style={{ width: 'fit-content' }}
                                >
                                  ml/h
                                </span>
                              </div>
                            </td>
                            <td>
                              <input
                                onFocus={onFocus}
                                disabled={
                                  tipoAdultoPediatrico == 'Adulto' || read
                                }
                                type="number"
                                className="form-control"
                                id="Aminas"
                                value={item.dose}
                                onChange={(e) =>
                                  changeDoseAmina(index, e.target.value)
                                }
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <select
                                onFocus={onFocus}
                                disabled={read}
                                className="form-control"
                                id="Aminas"
                                value={item.unidade}
                                onChange={(props) =>
                                  changeUnidadeAmina(props, index)
                                }
                              >
                                {/* <option value="1">µg/kg/h</option>
                                <option value={peso}>µg/h</option>
                                <option value="60">µg/kg/min</option>
                                <option value={peso * 60}>µg/min</option>
                                <option value="1000">mg/kg/h</option>
                                <option value={peso * 1000}>mg/h</option>
                                <option value="60000">mg/kg/min</option>
                                <option value={peso * 60000}>mg/min</option> */}
                                <option value="">---</option>
                                <option value="µg/kg/h">µg/kg/h</option>
                                <option value="µg/h">µg/h</option>
                                <option value="µg/kg/min">µg/kg/min</option>
                                <option value="µg/min">µg/min</option>
                                <option value="mg/kg/h">mg/kg/h</option>
                                <option value="mg/h">mg/h</option>
                                <option value="mg/kg/min">mg/kg/min</option>
                                <option value="mg/min">mg/min</option>
                              </select>
                            </td>
                            <td>{botaoAminas(index)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="">
                    {!read ? <BotaoNovoMedicamento /> : null}
                    {!read ? <BotaoNovaDiluicao /> : null}
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <br />
            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <div>
                {pronto ? (
                  <Accordion
                    alwaysOpen={true}
                    defaultActiveKey={[
                      rotina &&
                      rotina['CARDIO.MED_HABITUAIS'] != '' &&
                      rotina['CARDIO.MED_HABITUAIS']
                        ? '0'
                        : '',
                      condutas['Cardio'][0]
                        ? condutas['Cardio'][0].descricao
                          ? '1'
                          : ''
                        : '',
                      examesCardio != '' ? '2' : '',
                    ]}
                  >
                    <div>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['CARDIO.MED_HABITUAIS']
                              }
                              {...register('CARDIO.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                    <div>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Cardio')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                    <div>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesCardio}
                              onChange={(props) => changeExamesCardio(props)}

                              // defaultValue={
                              //   rotina && rotina['CARDIO.EXAMES_MONITORIZACAO']
                              // }
                              // {...register('CARDIO.EXAMES_MONITORIZACAO', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  </Accordion>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Sistema Respiratório:</h3>
            <div>
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Oxigenioterapia:</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  id="sim"
                  value="sim"
                  name="oxi"
                  onChange={changeOxi}
                  checked={oxiRadio == 'sim'}
                />
                <label>Sim</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  id="nao"
                  value="nao"
                  name="oxi"
                  onChange={changeOxi}
                  checked={oxiRadio == 'nao'}
                />
                <label>Não</label>
              </div>
              {oxiRadio == 'sim' ? (
                <div>
                  <table id="tblsetor" className="table table-responsive">
                    <thead>
                      <tr>
                        <td style={{ color: '#FFFFFF' }}>
                          Suporte Ventilatório
                        </td>
                        <td style={{ color: '#FFFFFF' }}>Dispositivos</td>
                        <td style={{ color: '#FFFFFF' }}>Detalhes</td>
                        <td width={'70'}></td>
                      </tr>
                    </thead>
                    <tbody>
                      {oxi.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <select
                                onFocus={onFocus}
                                id="Oxi"
                                disabled={read}
                                className="form-control"
                                value={item.suporte}
                                onChange={(props) =>
                                  changeSuporteOxi(props, index)
                                }
                              >
                                <option value="">---</option>
                                {dispositivos.map((item) => {
                                  return (
                                    <option
                                      value={item.SuporteVentilatorio}
                                      key={item.SuporteVentilatorio}
                                    >
                                      {item.SuporteVentilatorio}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              <select
                                onFocus={onFocus}
                                id="Oxi"
                                disabled={read}
                                className="form-control"
                                value={item.dispositivo}
                                onChange={(props) =>
                                  changeDispositivoOxi(props, index)
                                }
                              >
                                <option value="">---</option>
                                {item.suporte &&
                                dispositivos.filter(
                                  (dispositivo) =>
                                    dispositivo.SuporteVentilatorio ==
                                    item.suporte
                                )[0]
                                  ? dispositivos
                                      .filter(
                                        (e) =>
                                          e.SuporteVentilatorio == item.suporte
                                      )[0]
                                      .Dispositivos.map((d, index) => {
                                        return (
                                          <option value={d} key={d}>
                                            {d}
                                          </option>
                                        );
                                      })
                                  : null}
                              </select>
                            </td>
                            <td>
                              <input
                                onFocus={onFocus}
                                id="Oxi"
                                disabled={read}
                                className="form-control"
                                value={item.detalhes}
                                onChange={(props) =>
                                  changeDetalhesOxi(props, index)
                                }
                              />
                            </td>
                            <td>{botaoOxi(index)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="row">
              <div></div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <div>
                {pronto ? (
                  <Accordion
                    alwaysOpen={true}
                    defaultActiveKey={[
                      rotina &&
                      rotina['RESPIRATORIO.MED_HABITUAIS'] != '' &&
                      rotina['RESPIRATORIO.MED_HABITUAIS']
                        ? '0'
                        : '',
                      condutas['Respiratorio'][0]
                        ? condutas['Respiratorio'][0].descricao
                          ? '1'
                          : ''
                        : '',
                      examesRespiratorio != '' ? '2' : '',
                    ]}
                  >
                    <div>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['RESPIRATORIO.MED_HABITUAIS']
                              }
                              {...register('RESPIRATORIO.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                    <div>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Respiratorio')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                    <div>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                              {/* <label>Gasometria</label> */}
                              <div>
                                <table
                                  id="tblsetor"
                                  className="table table-responsive"
                                >
                                  <thead>
                                    <tr>
                                      <td style={{ color: '#FFFFFF' }}>
                                        Gasometria
                                      </td>
                                      <td
                                        width={'100'}
                                        style={{ color: '#FFFFFF' }}
                                      >
                                        Data/Hora
                                      </td>
                                      <td width={'1-0'}></td>
                                      <td width={'70'}></td>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {gasometria.map((item, index) => {
                                      return (
                                        <tr>
                                          <td>
                                            <input
                                              disabled={read}
                                              type="text"
                                              className="form-control"
                                              id="Gasometria"
                                              value={item.descricao}
                                              onChange={(props) =>
                                                changeDescricaoGasometria(
                                                  props,
                                                  index
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              onFocus={onFocus}
                                              onBlur={onBlur}
                                              type="date"
                                              disabled={read}
                                              className="form-control"
                                              id="Gasometria"
                                              value={item.data}
                                              onChange={(props) =>
                                                changeDataGasometria(
                                                  props,
                                                  index
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </td>
                                          <td>
                                            <input
                                              onFocus={onFocus}
                                              onBlur={onBlur}
                                              type="time"
                                              disabled={read}
                                              className="form-control"
                                              id="Gasometria"
                                              value={item.hora}
                                              onChange={(props) =>
                                                changeHoraGasometria(
                                                  props,
                                                  index
                                                )
                                              }
                                              autoComplete="off"
                                            />
                                          </td>
                                          <td>{botaoGasometria(index)}</td>
                                        </tr>
                                      );
                                    })}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesRespiratorio}
                              onChange={(props) =>
                                changeExamesRespiratorio(props)
                              }
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  </Accordion>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Neurológico:</h3>

            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <label>Drogas:</label>
            </div>

            <div className="row">
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  id="sim"
                  value="sim"
                  name="drogas"
                  onChange={changeDrogas}
                  checked={drogasRadio == 'sim'}
                  autoComplete="off"
                />
                <label>Sim</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  id="nao"
                  value="nao"
                  name="drogas"
                  onChange={changeDrogas}
                  checked={drogasRadio == 'nao'}
                  autoComplete="off"
                />
                <label>Não</label>
              </div>
            </div>

            {drogasRadio == 'sim' ? (
              <div>
                <div
                  style={{
                    padding: '2px 2px',
                    color: '#00a99d',
                    fontStyle: 'italic',
                  }}
                >
                  Drogas Sedativos e Analgésicos
                </div>
                <div>
                  <table id="tblsetor" className="table table-responsive">
                    <thead>
                      <tr>
                        <td style={{ color: '#FFFFFF' }}>Drogas Cadastradas</td>
                        <td style={{ color: '#FFFFFF' }}>Diluição</td>
                        <td style={{ color: '#FFFFFF' }}>
                          Velocidade de Infusão
                        </td>
                        <td style={{ color: '#FFFFFF' }}>Dose Calculada</td>
                        <td style={{ color: '#FFFFFF' }}>Unidade</td>
                        <td width={'70'}></td>
                      </tr>
                    </thead>
                    <tbody>
                      {neuro.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <select
                                onFocus={onFocus}
                                onBlur={onBlur}
                                id="Neuro"
                                disabled={read}
                                className="form-control"
                                value={item.droga}
                                onChange={(props) =>
                                  changeDrogaNeuro(props, index)
                                }
                              >
                                <option value="">---</option>
                                {drogas
                                  .filter(
                                    (med) =>
                                      med.tipo_droga ==
                                      'Sedativos e Analgésicos'
                                  )
                                  .map((droga, index) => {
                                    // console.log(droga);
                                    return (
                                      <option
                                        value={droga.droga_cadastrada}
                                        key={index}
                                      >
                                        {droga.droga_cadastrada}
                                      </option>
                                    );
                                  })}
                              </select>
                            </td>
                            <td>
                              <select
                                onFocus={onFocus}
                                // disabled={read}
                                disabled={
                                  tipoAdultoPediatrico == 'Pediatrico' || read
                                }
                                className="form-control"
                                id="Neuro"
                                value={item.diluicao}
                                onChange={(props) =>
                                  changeDiluicaoNeuro(props, index)
                                }
                              >
                                <option value="">---</option>
                                {item.droga &&
                                drogas.filter(
                                  (droga) =>
                                    droga.droga_cadastrada == item.droga
                                )[0]
                                  ? drogas
                                      .filter(
                                        (droga) =>
                                          droga.droga_cadastrada == item.droga
                                      )[0]
                                      .diluicao.map((lista, index) => {
                                        // console.log(lista);
                                        return (
                                          <option
                                            value={lista.nv_descricao}
                                            id={
                                              lista.nv_unidade == 'mg/h'
                                                ? 1000 *
                                                  parseInt(lista.nv_valor, 10)
                                                : parseInt(lista.nv_valor, 10)
                                            }
                                            key={index}
                                          >
                                            {lista.nv_descricao}
                                          </option>
                                        );
                                      })
                                  : null}
                                {/* <option value="0">Sem diluição/option> */}
                              </select>
                            </td>
                            <td>
                              <div className="form-control-td">
                                <input
                                  onFocus={onFocus}
                                  // disabled={read || item.diluicao == 0}
                                  disabled={
                                    tipoAdultoPediatrico == 'Pediatrico' ||
                                    read ||
                                    item.diluicao == 0
                                  }
                                  style={{ paddingRight: '65px' }}
                                  type="number"
                                  className="form-control"
                                  id="Neuro"
                                  value={item.velocidade}
                                  onChange={(props) =>
                                    changeVelocidadeNeuro(props, index)
                                  }
                                  autoComplete="off"
                                />
                                <span
                                  className="form-control-label"
                                  style={{ width: 'fit-content' }}
                                >
                                  ml/h
                                </span>
                              </div>
                            </td>
                            <td>
                              <input
                                onFocus={onFocus}
                                // disabled={read}
                                disabled={
                                  tipoAdultoPediatrico == 'Adulto' || read
                                }
                                type="number"
                                className="form-control"
                                id="Neuro"
                                value={item.dose}
                                onChange={(e) =>
                                  item.diluicao == 0
                                    ? changeDoseNeuro(index, e.target.value)
                                    : null
                                }
                                autoComplete="off"
                              />
                            </td>
                            <td>
                              <select
                                onFocus={onFocus}
                                disabled={read}
                                className="form-control"
                                id="Neuro"
                                value={item.unidade}
                                onChange={(props) =>
                                  changeUnidadeNeuro(props, index)
                                }
                              >
                                {/* <option value="1">µg/kg/h</option>
                                <option value={peso}>µg/h</option>
                                <option value="60">µg/kg/min</option>
                                <option value={peso * 60}>µg/min</option>
                                <option value="1000">mg/kg/h</option>
                                <option value={peso * 1000}>mg/h</option>
                                <option value="60000">mg/kg/min</option>
                                <option value={peso * 60000}>mg/min</option> */}
                                <option value="">---</option>
                                <option value="µg/kg/h" selected>
                                  µg/kg/h
                                </option>
                                <option value="µg/h">µg/h</option>
                                <option value="µg/kg/min">µg/kg/min</option>
                                <option value="µg/min">µg/min</option>
                                <option value="mg/kg/h">mg/kg/h</option>
                                <option value="mg/h">mg/h</option>
                                <option value="mg/kg/min">mg/kg/min</option>
                                <option value="mg/min">mg/min</option>
                              </select>
                            </td>
                            <td>{botaoNeuro(index)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="">
                  {!read ? <BotaoNovoMedicamento /> : null}
                  {!read ? <BotaoNovaDiluicao /> : null}
                </div>
              </div>
            ) : (
              <></>
            )}

            {/* <div className="col-lg-3 col-md-3 col-sm-3 form-group">
              <label>Sedação</label>
              <input
                disabled={read}
                type="text"
                className="form-control"
                defaultValue={rotina && rotina['NEUROLOGICO.SEDACAO']}
                {...register('NEUROLOGICO.SEDACAO', {})}
                autoComplete="off"
              />
            </div> */}
            <div
              className="col-lg-12 col-md-12 col-sm-12 form-group"
              style={{ marginTop: '10px' }}
            >
              <table id="tblsetor" className="table table-responsive">
                <thead>
                  <tr>
                    <td style={{ color: '#FFFFFF' }}>Escala</td>
                    <td style={{ color: '#FFFFFF' }}>Valor</td>
                    <td width={'70'}></td>
                  </tr>
                </thead>
                <tbody>
                  {escala.map((item, index) => {
                    return (
                      <tr>
                        <td>
                          <input
                            onFocus={onFocus}
                            onBlur={onBlur}
                            id="Escala"
                            disabled={read}
                            className="form-control"
                            value={item.escala}
                            onChange={(props) =>
                              changeEscalaDescricao(props, index)
                            }
                          />
                        </td>
                        <td>
                          <input
                            onFocus={onFocus}
                            disabled={read}
                            className="form-control"
                            id="Escala"
                            value={item.valor}
                            onChange={(props) =>
                              changeEscalaValor(props, index)
                            }
                          />
                        </td>
                        <td>{botaoEscala(index)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {/* <div className="col-lg-3 col-md-3 col-sm-3 form-group">
              <label>Delirium</label>
              <input
                disabled={read}
                type="text"
                className="form-control"
                defaultValue={rotina && rotina['NEUROLOGICO.DELIRIUM']}
                {...register('NEUROLOGICO.DELIRIUM', {})}
                autoComplete="off"
              />
            </div>
            <div className="col-lg-3 col-md-3 col-sm-3 form-group">
              <label>Coma</label>
              <input
                disabled={read}
                type="text"
                className="form-control"
                defaultValue={rotina && rotina['NEUROLOGICO.COMA']}
                {...register('NEUROLOGICO.COMA', {})}
                autoComplete="off"
              />
            </div> */}

            <div className="row" style={{ marginTop: '6px' }}>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div>
                  {pronto ? (
                    <Accordion
                      alwaysOpen={true}
                      defaultActiveKey={[
                        rotina &&
                        rotina['NEUROLOGICO.MED_HABITUAIS'] != '' &&
                        rotina['NEUROLOGICO.MED_HABITUAIS']
                          ? '0'
                          : '',
                        condutas['Neurologico'][0]
                          ? condutas['Neurologico'][0].descricao
                            ? '1'
                            : ''
                          : '',
                        examesNeurologico != '' ? '2' : '',
                      ]}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['NEUROLOGICO.MED_HABITUAIS']
                              }
                              {...register('NEUROLOGICO.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Neurologico')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesNeurologico}
                              onChange={(props) =>
                                changeExamesNeurologico(props)
                              }
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>infeccioso:</h3>

            <div className="row">
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <label>Culturas</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  id="sim"
                  value="sim"
                  name="culturas"
                  onChange={changeCulturas}
                  checked={culturasRadio == 'sim'}
                  autoComplete="off"
                />
                <label>Sim</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  id="nao"
                  value="nao"
                  name="culturas"
                  onChange={changeCulturas}
                  checked={culturasRadio == 'nao'}
                  autoComplete="off"
                />
                <label>Não</label>
              </div>
              {culturasRadio == 'sim' ? (
                <div>
                  <div>
                    <table id="tblsetor" className="table table-responsive">
                      <thead>
                        <tr>
                          <td style={{ width: '70px', color: '#FFFFFF' }}>
                            Data da Cultura
                          </td>
                          <td style={{ width: '200px', color: '#FFFFFF' }}>
                            Material Colhido
                          </td>
                          <td style={{ color: '#FFFFFF' }}>Descrição</td>
                          <td style={{ color: '#FFFFFF' }}>Resultados</td>
                          <td width={'70'}></td>
                        </tr>
                      </thead>
                      <tbody>
                        {culturas.map((item, index) => {
                          return (
                            <tr>
                              <td>
                                <input
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  id="Culturas"
                                  disabled={read}
                                  type="date"
                                  className="form-control"
                                  value={item.data}
                                  onChange={(props) =>
                                    changeDataCulturas(props, index)
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <select
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  id="Culturas"
                                  disabled={read}
                                  className="form-control"
                                  value={item.tipo}
                                  onChange={(props) =>
                                    changeTipoCulturas(props, index)
                                  }
                                >
                                  <option value="">---</option>
                                  <option value="Sangue">Sangue</option>
                                  <option value="Urina">Urina</option>
                                  <option value="Secreção Respiratória">
                                    Secreção Respiratória
                                  </option>
                                  <option value="Outros">Outros</option>
                                </select>
                              </td>
                              <td>
                                <input
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  id="Culturas"
                                  disabled={read}
                                  type="text"
                                  className="form-control"
                                  value={item.descricao}
                                  onChange={(props) =>
                                    changeDescricaoCulturas(props, index)
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                <input
                                  onFocus={onFocus}
                                  onBlur={onBlur}
                                  id="Culturas"
                                  disabled={read}
                                  type="text"
                                  className="form-control"
                                  value={item.resultado}
                                  onChange={(props) =>
                                    changeResultadoCulturas(props, index)
                                  }
                                  autoComplete="off"
                                />
                              </td>
                              <td>{botaoCulturas(index)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <br />

            {/* ANTIBIÓTICO */}
            {/* <button
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                //console.log('@@@ 1', AntibiosRef);
                //console.log('@@@ 2', AntibiosRef.current);
              }}
            >
              TESTE REF
            </button> */}
            <div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <label>Antibióticos</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  value="sim"
                  name="radioAntibioticosBtn"
                  onChange={changeAntibioticosRadioOption}
                  checked={antibioticosRadio === 'sim'}
                />
                <label>Sim</label>
              </div>
              <div className="col-lg-1 col-md-1 col-sm-1 form-group">
                <input
                  disabled={read}
                  type="radio"
                  className=""
                  value="nao"
                  name="radioAntibioticosBtn"
                  onChange={changeAntibioticosRadioOption}
                  checked={antibioticosRadio === 'nao'}
                />
                <label>Não</label>
              </div>
              {antibioticosRadio === 'sim' ? (
                <div>
                  <div>
                    <table id="tblsetor" className="table table-responsive">
                      <thead>
                        <tr>
                          <td style={{ color: '#FFFFFF' }} width="50%">
                            Antibiótico
                          </td>
                          <td style={{ color: '#FFFFFF' }} width="15%">
                            Data Início
                          </td>
                          <td style={{ color: '#FFFFFF' }} width="10%">
                            Previsão (dias)
                          </td>
                          <td style={{ color: '#FFFFFF' }} width="10%">
                            d0/d1
                          </td>
                          <td style={{ color: '#FFFFFF' }} width="10%">
                            Dias
                          </td>
                          <td width="5%"></td>
                        </tr>
                      </thead>
                      <tbody>
                        {antibioticos.map((item, index) => {
                          // console.log(" @@## ", item.dataInicio);

                          let diasAntibioCalc =
                            Math.round(
                              (new Date() - new Date(item.dataInicio)) /
                                (1000 * 60 * 60 * 24)
                            ) - item.diaInicial;

                          let diasAntibio = isNaN(diasAntibioCalc)
                            ? 0
                            : diasAntibioCalc;

                          return (
                            <tr key={index}>
                              <td>
                                <select
                                  disabled={read}
                                  id="tipo"
                                  className="form-control"
                                  value={item.tipo}
                                  onChange={(props) =>
                                    changeAntibioticoTipo(props, index)
                                  }
                                >
                                  <option value="">---</option>
                                  {drogas
                                    .filter(
                                      (med) => med.tipo_droga == 'Antibióticos'
                                    )
                                    .map((droga, index) => {
                                      return (
                                        <option
                                          value={droga.droga_cadastrada}
                                          key={droga.droga_cadastrada}
                                        >
                                          {droga.droga_cadastrada}
                                        </option>
                                      );
                                    })}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="date"
                                  // name="dataInicio"
                                  disabled={read}
                                  id="dataInicio"
                                  className="form-control"
                                  value={item.dataInicio}
                                  onChange={(props) =>
                                    changeAntibioticoDataInicio(props, index)
                                  }
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  // name="previsaoDias"
                                  id="previsaoDias"
                                  disabled={read}
                                  className="form-control"
                                  value={item.previsaoDias}
                                  onChange={(props) =>
                                    changeAntibioticoPrevisaoDias(props, index)
                                  }
                                />
                              </td>
                              <td>
                                <select
                                  disabled={read}
                                  // id="diaInicial"
                                  className="form-control"
                                  value={item.diaInicial}
                                  onChange={(props) =>
                                    changeAntibioticoDiaInicial(props, index)
                                  }
                                >
                                  <option value="">---</option>
                                  <option value="1">D0</option>
                                  <option value="0">D1</option>
                                  {/* Invertido de proposito */}
                                </select>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  // name="dias"
                                  id="diasTotal"
                                  disabled={true}
                                  style={
                                    item.previsaoDias <
                                    Math.round(
                                      (new Date() - new Date(item.dataInicio)) /
                                        (1000 * 60 * 60 * 24)
                                    ) -
                                      item.diaInicial
                                      ? { backgroundColor: '#FFFF00' }
                                      : {}
                                  }
                                  className="form-control"
                                  value={diasAntibio}
                                  // onChange={(props) =>
                                  //   changeAntibioticoDiasTotal(props, index)
                                  // }
                                />
                              </td>
                              <td>{botaoAntibiotico(index)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div>{!read ? <BotaoNovoAntibiotico /> : null}</div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="row" style={{ marginTop: '6px' }}>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div>
                  {pronto ? (
                    <Accordion
                      alwaysOpen={true}
                      defaultActiveKey={[
                        rotina &&
                        rotina['INFECCIOSO.MED_HABITUAIS'] != '' &&
                        rotina['INFECCIOSO.MED_HABITUAIS']
                          ? '0'
                          : '',
                        condutas['Infeccioso'][0]
                          ? condutas['Infeccioso'][0].descricao
                            ? '1'
                            : ''
                          : '',
                        examesInfeccioso != '' ? '2' : '',
                      ]}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['INFECCIOSO.MED_HABITUAIS']
                              }
                              {...register('INFECCIOSO.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Infeccioso')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesInfeccioso}
                              onChange={(props) =>
                                changeExamesInfeccioso(props)
                              }
                              // defaultValue={
                              //   rotina && rotina['INFECCIOSO.EXAMES_MONITORIZACAO']
                              // }
                              // {...register('INFECCIOSO.EXAMES_MONITORIZACAO', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Renal/Metabólico:</h3>

            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <label>Dialítico</label>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <input
                disabled={read}
                type="radio"
                className=""
                id="sim"
                value="sim"
                name="dia"
                onChange={changeDia}
                checked={diaRadio == 'sim'}
              />
              <label>Sim</label>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 form-group">
              <input
                disabled={read}
                type="radio"
                className=""
                id="nao"
                value="nao"
                name="dia"
                onChange={changeDia}
                checked={diaRadio == 'nao'}
              />
              <label>Não</label>
            </div>
            {diaRadio == 'sim' ? (
              <div>
                <div>
                  <table id="tblsetor" className="table table-responsive">
                    <thead>
                      <tr>
                        <td width={'150'} style={{ color: '#FFFFFF' }}>
                          Tipo
                        </td>
                        <td style={{ color: '#FFFFFF' }}>Detalhes</td>
                        <td width={'70'}></td>
                      </tr>
                    </thead>
                    <tbody>
                      {dia.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <select
                                onFocus={onFocus}
                                onBlur={onBlur}
                                id="Dia"
                                disabled={read}
                                className="form-control"
                                value={item.tipo}
                                onChange={(props) =>
                                  changeTipoDia(props, index)
                                }
                              >
                                <option value="">---</option>
                                <option value="Peritoneal">Peritoneal</option>
                                <option value="Hemodiálise">Hemodiálise</option>
                              </select>
                            </td>
                            <td>
                              <input
                                onFocus={onFocus}
                                onBlur={onBlur}
                                id="Dia"
                                disabled={read}
                                type="text"
                                className="form-control"
                                value={item.detalhes}
                                onChange={(props) =>
                                  changeDetalhesDia(props, index)
                                }
                                autoComplete="off"
                              />
                            </td>
                            <td>{botaoDia(index)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <></>
            )}

            <div className="row" style={{ marginTop: '6px' }}>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div>
                  {pronto ? (
                    <Accordion
                      alwaysOpen={true}
                      defaultActiveKey={[
                        rotina &&
                        rotina['RENAL.MED_HABITUAIS'] != '' &&
                        rotina['RENAL.MED_HABITUAIS']
                          ? '0'
                          : '',
                        condutas['Renal'][0]
                          ? condutas['Renal'][0].descricao
                            ? '1'
                            : ''
                          : '',
                        examesRenal != '' ? '2' : '',
                      ]}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['RENAL.MED_HABITUAIS']
                              }
                              {...register('RENAL.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Renal')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesRenal}
                              onChange={(props) => changeExamesRenal(props)}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Hematológico:</h3>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Hematimetria</label>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Data</label>
                <input
                  disabled={read}
                  type="date"
                  className="form-control"
                  value={dataHematimetria}
                  onChange={changeDataHematimetria}
                  // defaultValue={rotina && rotina['HEMATO.DATA']}
                  // {...register('HEMATO.DATA', {})}
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Hematócrito</label>
                <input
                  disabled={read}
                  type="text"
                  className="form-control"
                  defaultValue={rotina && rotina['HEMATO.HEMATOCRITO']}
                  {...register('HEMATO.HEMATOCRITO', {})}
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Hemoglobina</label>
                <input
                  disabled={read}
                  type="text"
                  className="form-control"
                  defaultValue={rotina && rotina['HEMATO.HEMOGLOBINA']}
                  {...register('HEMATO.HEMOGLOBINA', {})}
                  autoComplete="off"
                />
              </div>
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <label>Plaquetas</label>
                <input
                  disabled={read}
                  type="text"
                  className="form-control"
                  defaultValue={rotina && rotina['HEMATO.PLAQUETA']}
                  {...register('HEMATO.PLAQUETA', {})}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Coagulograma</label>

              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                  <label>Data</label>
                  <input
                    disabled={read}
                    type="date"
                    className="form-control"
                    value={dataCoagulograma}
                    onChange={changeDataCoagulograma}
                    // defaultValue={rotina && rotina['HEMATO.DATA_COAGULOGRAMA']}
                    // {...register('HEMATO.DATA_COAGULOGRAMA', {})}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                  <label>TAP</label>
                  <input
                    disabled={read}
                    type="text"
                    className="form-control"
                    defaultValue={rotina && rotina['HEMATO.TAP']}
                    {...register('HEMATO.TAP', {})}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                  <label>INR</label>
                  <input
                    disabled={read}
                    type="text"
                    className="form-control"
                    defaultValue={rotina && rotina['HEMATO.INR']}
                    {...register('HEMATO.INR', {})}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                  <label>PTT</label>
                  <input
                    disabled={read}
                    type="text"
                    className="form-control"
                    defaultValue={rotina && rotina['HEMATO.PTT']}
                    {...register('HEMATO.PTT', {})}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                  <label>Fibrinogênio</label>
                  <input
                    disabled={read}
                    type="text"
                    className="form-control"
                    defaultValue={rotina && rotina['HEMATO.FIBRINOGENIO']}
                    {...register('HEMATO.FIBRINOGENIO', {})}
                    autoComplete="off"
                  />
                </div>
              </div>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <label>Outros</label>
                <textarea
                  disabled={read}
                  type="text"
                  className="form-control"
                  defaultValue={rotina && rotina['HEMATO.OUTROS']}
                  {...register('HEMATO.OUTROS', {})}
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row" style={{ marginTop: '6px' }}>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div>
                  {pronto ? (
                    <Accordion
                      alwaysOpen={true}
                      defaultActiveKey={[
                        rotina &&
                        rotina['HEMATO.MED_HABITUAIS'] != '' &&
                        rotina['HEMATO.MED_HABITUAIS']
                          ? '0'
                          : '',
                        condutas['Hemato'][0]
                          ? condutas['Hemato'][0].descricao
                            ? '1'
                            : ''
                          : '',
                        examesHemato != '' ? '2' : '',
                      ]}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['HEMATO.MED_HABITUAIS']
                              }
                              {...register('HEMATO.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Hemato')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesHemato}
                              onChange={(props) => changeExamesHemato(props)}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Nutricional:</h3>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Dietas</label>
              <div>
                <table id="tblsetor" className="table table-responsive">
                  <thead>
                    <tr>
                      <td style={{ color: '#FFFFFF' }}>Tipo de Dieta</td>
                      <td style={{ color: '#FFFFFF' }}>Dieta</td>
                      <td style={{ color: '#FFFFFF' }}>Observações</td>
                      <td width={'70'}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {dieta.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <select
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="Dieta"
                              disabled={read}
                              className="form-control"
                              value={item.tipo}
                              onChange={(props) =>
                                changeTipoDieta(props, index)
                              }
                            >
                              <option value="">---</option>
                              <option value="Zero">Zero</option>
                              <option value="Normal">Normal</option>
                              <option value="Branda">Branda</option>
                              <option value="Enteral">Enteral</option>
                              <option value="Parenteral">Parenteral</option>
                            </select>
                          </td>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="Dieta"
                              disabled={read}
                              type="text"
                              className="form-control"
                              value={item.dieta}
                              onChange={(props) =>
                                changeDietaDieta(props, index)
                              }
                              autoComplete="off"
                            />
                          </td>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="Dieta"
                              disabled={read}
                              type="text"
                              className="form-control"
                              value={item.observacao}
                              onChange={(props) =>
                                changeObservacaoDieta(props, index)
                              }
                              autoComplete="off"
                            />
                          </td>
                          <td>{botaoDieta(index)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="row" style={{ marginTop: '6px' }}>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div>
                  {pronto ? (
                    <Accordion
                      alwaysOpen={true}
                      defaultActiveKey={[
                        rotina &&
                        rotina['NUTRI.MED_HABITUAIS'] != '' &&
                        rotina['NUTRI.MED_HABITUAIS']
                          ? '0'
                          : '',
                        condutas['Nutricional'][0]
                          ? condutas['Nutricional'][0].descricao
                            ? '1'
                            : ''
                          : '',
                        examesNutricional != '' ? '2' : '',
                      ]}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['NUTRI.MED_HABITUAIS']
                              }
                              {...register('NUTRI.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Nutricional')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesNutricional}
                              onChange={(props) =>
                                changeExamesNutricional(props)
                              }
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Outros:</h3>

            <div className="row" style={{ marginTop: '6px' }}>
              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <div>
                  {pronto ? (
                    <Accordion
                      alwaysOpen={true}
                      defaultActiveKey={[
                        rotina &&
                        rotina['OUTROS.MED_HABITUAIS'] != '' &&
                        rotina['OUTROS.MED_HABITUAIS']
                          ? '0'
                          : '',
                        condutas['Outros'][0]
                          ? condutas['Outros'][0].descricao
                            ? '1'
                            : ''
                          : '',
                        examesOutros != '' ? '2' : '',
                      ]}
                    >
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Medicações Habituais
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              defaultValue={
                                rotina && rotina['OUTROS.MED_HABITUAIS']
                              }
                              {...register('OUTROS.MED_HABITUAIS', {})}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>Parecer/Condutas</Accordion.Header>
                        <Accordion.Body>
                          <div>{gerarTabelaCondutas('Outros')}</div>
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Exames/Monitorização
                        </Accordion.Header>
                        <Accordion.Body>
                          <div>
                            <textarea
                              style={{ resize: 'vertical' }}
                              disabled={read}
                              className="form-control textarea_diag"
                              type="text"
                              value={examesOutros}
                              onChange={(props) => changeExamesOutros(props)}
                            />
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Metas:</h3>

            <div>
              <div>
                <table id="tblsetor" className="table table-responsive">
                  <thead>
                    <tr>
                      <td style={{ color: '#FFFFFF' }}>Meta</td>
                      <td width={'70'}></td>
                    </tr>
                  </thead>
                  <tbody>
                    {metas.map((item, index) => {
                      return (
                        <tr>
                          <td>
                            <input
                              onFocus={onFocus}
                              onBlur={onBlur}
                              id="Metas"
                              disabled={read}
                              type="text"
                              className="form-control"
                              value={item.meta}
                              onChange={(props) => changeMetas(props, index)}
                              autoComplete="off"
                            />
                          </td>
                          <td>{botaoMetas(index)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Resumo:</h3>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <label>Parecer/Condutas</label>
              <div>
                <div>
                  <table id="tblsetor" className="table table-responsive">
                    <thead>
                      <tr>
                        <td style={{ color: '#FFFFFF' }}>Descrição</td>
                        <td width={'70'}></td>
                        <td width={'200'} style={{ color: '#FFFFFF' }}>
                          Data/Hora
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      {condutas['Resumo'].map((item, index) => {
                        //console.log(item.dt_finalizada || item.resposta)
                        if (
                          item.dt_finalizada
                            ? item.descricao != '' &&
                              2 >
                                Math.round(
                                  (new Date() - new Date(item.dt_finalizada)) /
                                    (1000 * 60 * 60 * 24)
                                )
                            : item.descricao != ''
                        ) {
                          return (
                            <tr>
                              <td>
                                <input
                                  disabled={true}
                                  type="text"
                                  style={
                                    item.dt_finalizada &&
                                    item.dt_finalizada != ''
                                      ? { backgroundColor: '#90EE90' }
                                      : {}
                                  }
                                  className="form-control"
                                  id=""
                                  value={item.descricao}
                                  autoComplete="off"
                                />
                              </td>
                              <td>
                                {botaoRespostaCondutas(
                                  item,
                                  (item.dt_finalizada &&
                                    item.dt_finalizada != '') ||
                                    item.resposta
                                )}
                              </td>
                              <td>
                                <input
                                  className="form-control"
                                  disabled={true}
                                  type="date"
                                  value={item.data}
                                  autoComplete="off"
                                />
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              <label>Exames/Monitorização</label>
              <textarea
                style={{ resize: 'vertical' }}
                disabled={read}
                className="form-control textarea_diag"
                type="text"
                value={examesResumo}
                onChange={(props) => changeResumo(props)}
                //{...register('RESUMO.PARECER_CONDUTAS', {})}
              />
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Previsão de Alta:</h3>
            <button type="submit" disabled />
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-2 form-group">
                <input
                  disabled={read}
                  className="form-control"
                  type="date"
                  value={dataAlta}
                  onChange={changeDataAlta}
                ></input>
              </div>

              <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                <textarea
                  disabled={read}
                  className="form-control"
                  type="text"
                  placeholder="Prescrição de Alta"
                  value={prescricaoAlta}
                  onChange={changePrescricaoAlta}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="row form-group box-bg-white">
            <h3>Midias:</h3>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <input
                disabled={read}
                type="file"
                multiple
                onChange={changeFiles}
              />
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12 form-group">
              <div className="grid">
                {listaAnexo.length > 0 &&
                  listaAnexo.map((anexo) => {
                    return (
                      <button
                        type="button"
                        className="arquivo"
                        onClick={() =>
                          baixarArquivo(
                            anexo.gd_rotinaupload_id,
                            anexo.nv_filename
                          )
                        }
                      >
                        {anexo.nv_filename}
                      </button>
                    );
                  })}
              </div>
            </div>
            {/* {!read ? (
              <div>
                <button
                  type="button"
                  disabled={subindoArquivo}
                  className="templatemo-green-button margin-bottom-10"
                  onClick={subirArquivos}
                >
                  Salvar Arquivos
                </button>
              </div>
            ) : null} */}
          </div>
          {/* Botao finalizar conduta */}
          <div>
            <Link
              to={RotasURL.rotinaMedica.atendimento.replace(
                ':idAtendimento',
                atendimento
              )}
            >
              <button className="btn btn-outline-primary">Voltar</button>
            </Link>
            {!read ? (
              alertaAntibiotico ? (
                <a data-toggle="modal" data-target="#modalAlertaAntibiotico">
                  <button disabled={read} className="btn btn-primary">
                    Confirmar?
                  </button>
                </a>
              ) : (
                <button
                  disabled={read}
                  type="submit"
                  className="btn btn-primary"
                >
                  Confirmar
                </button>
              )
            ) : null}
          </div>
        </>
      </form>

      <div
        className="modal"
        id="modalNovoMedicamento"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Cadastrar novo medicamento
              </h4>
              <button
                type="buttom"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row form-group box-bg-white">
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <label>Nome do Medicamento</label>
                  <input
                    type="text"
                    className="form-control"
                    value={descricaoMedicamento}
                    onChange={changeDescricaoMedicamento}
                    autoComplete="off"
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <label>Tipo de Medicamento</label>
                  <select
                    className="form-control"
                    value={tipoMedicamento}
                    onChange={changeTipoMedicamento}
                  >
                    <option value="">---</option>
                    <option value="1">Aminas Vasoativas</option>
                    <option value="6">Anti-Hipertensivos</option>
                    <option value="2">Bloqueadores Neuro-Musculares</option>
                    <option value="3">Sedativos e Analgésicos</option>
                    <option value="4">Antiarrítmicos</option>
                    <option value="5">Antibióticos</option>
                    <option value="7">Miscelânia</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                // onClick={adicionarMedicamento}
                onClick={adicionarMedicamentoNovoAsync}
                data-dismiss="modal"
              >
                Confirmar
              </button>
              <button className="btn btn-outline-primary" data-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        id="modalNovoAntibiotico"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Cadastrar Antibiótico
              </h4>
              <button
                type="buttom"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row form-group box-bg-white">
                <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                  <label>Nome do medicamento</label>
                  <input
                    type="text"
                    className="form-control"
                    value={descricaoMedicamento}
                    onChange={changeDescricaoMedicamento}
                    autoComplete="off"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={adicionarAntibiotico}
                data-dismiss="modal"
              >
                Confirmar
              </button>
              <button className="btn btn-outline-primary" data-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        id="modalNovaDiluicao"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Cadastrar nova diluição para medicamento
              </h4>
              <button
                type="buttom"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body">
              <span>Todos os campos são obrigatórios</span>
              <div className="form-group box-bg-white">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Medicamento</label>
                    <select
                      className="form-control"
                      value={descricaoMedicamento}
                      onChange={changeDescricaoMedicamento}
                      required={true}
                    >
                      <option value="">---</option>
                      {drogas.map((droga, index) => {
                        return (
                          <option
                            value={droga.droga_cadastrada}
                            key={droga.droga_cadastrada}
                          >
                            {droga.droga_cadastrada}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                    <label>Descrição Diluição</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder=""
                      value={diluicaoMedicamento}
                      onChange={changeDiluicaoMedicamento}
                      autoComplete="off"
                      required={true}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 form-group">
                    <label>Valor Diluição</label>
                    <input
                      type="number"
                      placeholder="100"
                      className="form-control"
                      value={valorMedicamento}
                      onChange={changeValorMedicamento}
                      autoComplete="off"
                      required={true}
                    />
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 form-group">
                    <label>Unidade</label>
                    <select
                      className="form-control"
                      value={unidadeMedicamento}
                      onChange={changeUnidadeMedicamento}
                      required={true}
                    >
                      <option value="">---</option>
                      <option value="µg/ml">µg/ml</option>
                      <option value="mg/ml">mg/ml</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                // onClick={adicionarDiluicao}
                onClick={adicionarDiluicaoNovoAsync}
                data-dismiss="modal"
              >
                Confirmar
              </button>
              <button className="btn btn-outline-primary" data-dismiss="modal">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal"
        id="modalRespostaConduta"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Resposta da Conduta
              </h4>
              <button
                type="buttom"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row form-group box-bg-white">
                <textarea
                  style={{ resize: 'vertical' }}
                  disabled={true}
                  className="form-control textarea_diag"
                  type="text"
                  defaultValue={
                    respostaConduta
                      ? JSON.parse(respostaConduta)
                      : respostaConduta
                  }
                />
              </div>
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-dismiss="modal">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal"
        id="modalAlertaAntibiotico"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Um antibiótico ativo ultrapassou a quantidade de dias previstos.
                Deseja continuar finalizando esta rotina?
              </h4>
            </div>
            <div className="modal-footer">
              <button className="btn btn-outline-primary" data-dismiss="modal">
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                type="submit"
                data-dismiss="modal"
                onClick={onSubmit}
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NovaRotinaMedicaPage2;
