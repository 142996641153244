export const validaCPF = async (pCPF) => {
  let soma = 0;
  let resto;

  let cpf = pCPF.replaceAll('.', '');
  cpf = cpf.replaceAll('-', '');

  //console.log('### ', cpf);
  if (cpf == '00000000000') throw 'CPF Invalido 1';
  if (cpf == '11111111111') throw 'CPF Invalido 1';
  if (cpf == '22222222222') throw 'CPF Invalido 1';
  if (cpf == '33333333333') throw 'CPF Invalido 1';
  if (cpf == '44444444444') throw 'CPF Invalido 1';
  if (cpf == '55555555555') throw 'CPF Invalido 1';
  if (cpf == '66666666666') throw 'CPF Invalido 1';
  if (cpf == '77777777777') throw 'CPF Invalido 1';
  if (cpf == '88888888888') throw 'CPF Invalido 1';
  if (cpf == '99999999999') throw 'CPF Invalido 1';

  for (let i = 0; i < 9; i++) {
    //console.log(parseInt(cpf.substring(i, i + 1)));
    soma = soma + parseInt(cpf.substring(i, i + 1)) * (10 - i);
    //console.log(soma);
  }
  resto = (soma * 10) % 11;
  if (resto == 10) resto = 0;
  //console.log(resto);
  if (resto != parseInt(cpf.substring(9, 10))) throw 'CPF Invalido 2';

  soma = 0;
  for (let i = 0; i < 10; i++) {
    soma = soma + parseInt(cpf.substring(i, i + 1)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto == 10) resto = 0;
  if (resto != parseInt(cpf.substring(10, 11))) throw 'CPF Invalido 3';

  return true;
};
