import * as yup from 'yup';

export const schema = yup.object().shape({
    in_informacao_id: yup.number().integer(),
    nv_descricao_informacao: yup.string().required('*Campo obrigatório.'),
    in_grupoinformacao_id: yup.number().integer(),
    in_tipocontrole_id: yup.number().integer(),
    nv_detalhe_informacao: yup.string().required('*Campo obrigatório.'),
    in_assistente: yup.number().integer(),
    in_familiar: yup.number().integer(),
    nv_ativo: yup.number().integer(),
    dt_data_inclusao: yup.string(),
    dt_data_alteracao: yup.string(),
    dt_data_exclusao: yup.string(),
    in_usu_inclusao: yup.number().integer(),
    in_usu_alteracao: yup.number().integer(),
    in_usu_exclusao: yup.number().integer(),
})