import { get, getWithParams, post, DELETE, DELETE_simples} from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Atendimento/items';
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const AtendimentoAPI = {
    
    obterAtendimentoPorId: async function (id){
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/consultar?pIdAtendimentoToConsultar=${id}&pUsuario=${usuarioLogado}`;

    const retorno = await get(url)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        //console.log('Error -> ', error);
        return null;
      });
    return retorno;
  },

    salvarAltaMedica: async function (id, data, prescricao){
        //console.log(id);
        //console.log(data);
        //console.log(prescricao);
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/salvaraltamedica?pIdAtendimento=${id}&pDtAlta=${data}&pPrescricao=${prescricao}&pUsuario=${usuarioLogado}`;
        const retorno = await post(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log('Error -> ', error);
                return null;
            });
        return retorno;
    },

    consultarAltaMedica: async function (id){
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/consultaraltamedica?pIdAtendimento=${id}&pUsuario=${usuarioLogado}`;
        const retorno = await get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log('Error -> ', error);
                return null;
            });
        return retorno;
    },

    listarAltaMedicaSemLiberacao: async function (){
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/listaraltamedicasemliberacao?pUsuario=${usuarioLogado}`;
        const retorno = await get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log('Error -> ', error);
                return null;
            });
        return retorno;
    },

    consultarAtendimento: async function (id){
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/consultar?pIdAtendimentoToConsultar=${id}&pUsuario=${usuarioLogado}`;
        const retorno = await get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log('Error -> ', error);
                return null;
            });
        return retorno;
    },

    excluirAltaMedica: async function (id){        
        try{
            obterUsuarioLogado();
            let token = getToken();
            let headers = {
                Authorization: `Bearer ${token}`,
            };

            const url = `/${base}/excluiraltamedica?pIdAtendimento=${id}&pUsuario=${usuarioLogado}`;
      
            const response = await DELETE_simples(url).then((response) => {
              //console.log(response);
              //console.log(`api deletado ${id}`)
              return response;
            })
        } catch(error){
            //console.log(error);
            return null
        }
    },

    listarDiagnosticos: async function (id){
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/listardiagnostico?pIdAtendimento=${id}&pUsuario=${usuarioLogado}&pAtivo=1`;
        const retorno = await get(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log('Error -> ', error);
                return null;
            });
        return retorno;
    },

    liberarAcomodacaoAltaMedica: async function (lista){
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`,
        };

        const url = `/${base}/liberaracomodacaoaltamedica?pListaIdAtendimento=${lista}&pUsuario=${usuarioLogado}`;
        const retorno = await post(url)
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                //console.log('Error -> ', error);
                return null;
            });
        return retorno;
    },

    listarAtendimentoObservacao: async function (idAtendimento) {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };
  
      const url = `/AtendimentoObservacao/items/listar?pAtendimento=${idAtendimento}&pUsuario=${usuarioLogado}`;
      const retorno = await get(url)
        .then((response) => {
          console.log('!!! ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          return null;
        });
      return retorno;
    }
}

export default AtendimentoAPI;
