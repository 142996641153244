import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';
import SetorAPI from '../../Services/API/SetorAPI';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import RotinaMedicaAPI from 'Services/API/RotinaMedicaAPI';
import { set } from 'react-hook-form';
import { RotasURL } from 'RoutesURL';
import LoadingIcons from 'react-loading-icons';

const plantaoFullObject = {
  defaultInfoList: [],
  lastRotinaId: '',
  rotinaFullAnswers: {},
};

const OVERLAY_TEXTO_PADRAO = "Carregando ...";

function formataDataConduta(data) {
  if (data === undefined) return 'não informado';

  let novaData =
    data.substring(8, 10) +
    '/' +
    data.substring(5, 7) +
    '/' +
    data.substring(0, 4);

  if (novaData == '//') return 'não informado';

  return novaData;
}

const PlantaoRotPage = () => {
  const [atendimentoState, setAtendimentoState] = useState(0);
  const [ultimaRotinaId, setUltimaRotinaId] = useState(null);
  const [infoDefaultState, setInfoDefaultState] = useState([]);
  const [rotinaRespostasTempState, setRotinaRespostasTempState] = useState({});
  const [infoComRespostaListState, setInfoComRespostaListState] = useState([]);

  const [infoGroups, setInfoGroups] = useState([]);
  const [infoGroupsArray, setInfoGroupsArray] = useState([]);
  const [condutasInfo, setCondutasInfo] = useState([]);

  const [condutaObj, setCondutaObj] = useState({});
  const [itemObj, setItemObj] = useState({});
  const [observacao, setObservacao] = useState('');
  const [rotinaInfo, setRotinaInfo] = useState(null);
  const [respostaConduta, setRespostaConduta] = useState();

  const [isPageLoading, setIsPageLoading] = useState(false);
  const [pageOverlayText, setPageOverlayText] = useState(OVERLAY_TEXTO_PADRAO);

  const [condutasUpdate, setCondutasUpdate] = useState(false);
  const { idAtendimento } = useParams();
  const obsPlantonistaRef = useRef();
  const history = useHistory();

  useEffect(() => {
    setAtendimentoState(idAtendimento);

    const fetchDefaultInfos = async () => {
      // listaRespostas = await RotinaMedicaAPI.obterRespostasUltimaRotina(idAtendimento);
      return await RotinaMedicaAPI.obterInformacoesDefault();
    };

    fetchDefaultInfos()
      .then((resposta) => {
        console.log('@@@ Fetch answers [InfoDefault]: ', resposta);
        setInfoDefaultState(resposta);
        plantaoFullObject['defaultInfoList'] = resposta;
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    // //console.log('@@@', { infoDefaultState });
    const fetchAnswers = async () => {
      return await RotinaMedicaAPI.obterRespostasUltimaRotina(idAtendimento);
    };

    if (infoDefaultState.length > 0) {
      fetchAnswers()
        .then((resposta) => {
          console.log('@@@ Fetch answers [Respostas]: ', resposta);

          let key = 'KEY#ULTIMAROTINA';
          let respostaRotinaId = resposta[key];
          delete resposta[key];

          let respostasTempObject = resposta;
          setRotinaRespostasTempState(respostasTempObject);
          setUltimaRotinaId(respostaRotinaId);
          plantaoFullObject['lastRotinaId'] = respostaRotinaId;
        })
        .catch((err) => console.error(err));
    }
  }, [infoDefaultState]);

  useEffect(() => {
    // //console.log('!*! ', { ultimaRotinaId });
    const fetchRotinaInfo = async () => {
      return await RotinaMedicaAPI.consultarRotina(ultimaRotinaId);
    };

    if (ultimaRotinaId !== null) {
      fetchRotinaInfo()
        .then((resposta) => {
          setRotinaInfo(resposta);
        })
        .catch((err) => console.error(err));
    }
  }, [ultimaRotinaId]);

  useEffect(() => {
    if (rotinaInfo !== null) {
      let observacao = rotinaInfo.nv_obs_plantonista;
      setObservacao(observacao);
    }
  }, [rotinaInfo]);

  useEffect(() => {
    // //console.log('@@@', { rotinaRespostasTempState });
    let listOfGroups = [];

    if (
      infoDefaultState.length > 0 &&
      Object.keys(rotinaRespostasTempState).length > 0
    ) {
      // //console.log('@@@ BORA CUPADI');

      infoDefaultState.forEach((info) => {
        // //console.log('@@@ INFO', { info });
        info['resposta'] = '';
        let respostadaInfo = rotinaRespostasTempState[info['nv_identificacao']];
        // //console.log('@@@ INFO RESPOSTA', { respostadaInfo });
        info['resposta'] = respostadaInfo;
        listOfGroups.push(info['nv_grupo']);

        let currentInfoGroup = info['nv_grupo']; // Internacao, por exemplo
        let rotinaFullAnswersKey = plantaoFullObject['rotinaFullAnswers']; // Acessa rotinaFullAnswers dentro de plantaoFullObject

        if (currentInfoGroup in rotinaFullAnswersKey) {
          let respostasDoGrupoAtual = rotinaFullAnswersKey[currentInfoGroup]; // pega as respostas do grupo "Internação"
          let infoAtualKey = info['nv_descricao']; // info atual no loop

          // if (infoAtualKey in respostasDoGrupoAtual) {
          //   respostasDoGrupoAtual[infoAtualKey] = respostadaInfo;
          //   plantaoFullObject['rotinaFullAnswers'][currentInfoGroup] = respostasDoGrupoAtual;
          // } else {
          //   // adicionar "info": "resposta" em respostasDoGrupoAtual
          //   respostasDoGrupoAtual[infoAtualKey] = respostadaInfo;
          //   plantaoFullObject['rotinaFullAnswers'][currentInfoGroup] = respostasDoGrupoAtual;
          // }

          respostasDoGrupoAtual[infoAtualKey] = respostadaInfo;
          plantaoFullObject['rotinaFullAnswers'][currentInfoGroup] =
            respostasDoGrupoAtual;
        } else {
          rotinaFullAnswersKey[currentInfoGroup] = {};
          let rotinaFullAnswersObject = rotinaFullAnswersKey[currentInfoGroup];
          rotinaFullAnswersObject[info['nv_descricao']] = respostadaInfo;
          rotinaFullAnswersKey[currentInfoGroup] = rotinaFullAnswersObject;
        }

        // //console.log('@@@ INFO', { info });
      });

      setInfoComRespostaListState(infoDefaultState);
      setInfoGroups(new Set(listOfGroups));

      reorganizarPlantaoFullObject();
    } else {
      //
    }
  }, [infoDefaultState, rotinaRespostasTempState]);

  useEffect(() => {
    if (infoComRespostaListState?.length > 0) {
      // //console.log('@@@  ', { infoComRespostaListState });
      // //console.log('@@@ ==> ', { infoGroups });

      let listaCondutas = [];
      infoComRespostaListState.map((item, index) => {
        if (
          item.resposta != null &&
          item.nv_descricao.toUpperCase().includes('CONDUTA') &&
          item.nv_grupo.toUpperCase() !== 'RESUMO'
        ) {
          // //console.log('*** ', { item });
          let conduta = {
            grupo: item.nv_grupo,
            identificacao: item.nv_identificacao,
            resposta: JSON.parse(item.resposta),
          };
          listaCondutas.push(conduta);
        }
      });

      setCondutasInfo(listaCondutas);
    }
  }, [infoComRespostaListState, condutasUpdate]);

  useEffect(() => {
    // //console.log('@@@ -> ', infoGroupsArray);
    if (infoGroups.size > 0) {
      setInfoGroupsArray([...infoGroups]);
      // //console.log('*** ', plantaoFullObject);
    }
  }, [infoGroups]);

  useEffect(() => {
    // //console.log('*** condutasInfo ', { condutasInfo });
  }, [condutasInfo]);

  function prepararFinalizaConduta(conduta, item) {
    if (ultimaRotinaId !== null) {
      // //console.log('%%% ', { conduta });
      // //console.log('%%% ', { item });

      if (conduta != null && item != null) {
        setCondutaObj(conduta);
        setItemObj(item);
        if (item.resposta){
          console.log(item.resposta)
          setRespostaConduta(JSON.parse(item.resposta));
        }else{
          setRespostaConduta('');
        }
      }
    }
  }

  async function finalizarCondutaRotina(finalizar) {
    if (ultimaRotinaId !== null) {
      // //console.log('$$$ 0) ', condutaObj);
      // //console.log('$$$ 1) ', ultimaRotinaId);
      // //console.log('$$$ 2) ', itemObj);

      let infoGrupo = condutaObj.identificacao;
      let infoDescricao = itemObj.descricao;

      let jsonPayload = {
        infoGrupo: infoGrupo,
        infoDescricao: infoDescricao,
      };
      
      console.log('$$$ ', { jsonPayload });
      console.log(respostaConduta)
      let respostaPreparada = JSON.stringify(respostaConduta)

      showPageOverlay('Salvando conduta...');
      await RotinaMedicaAPI.finalizarConduta(ultimaRotinaId, jsonPayload, respostaPreparada, finalizar)
        .then((response) => (finalizar == 'true')? history.go(0) : null)
        .catch((err) => console.error(err))
        // .finally(() => hidePageOverlay() );
    }
    window.location.reload(false);
  }

  function reorganizarPlantaoFullObject() {
    delete plantaoFullObject['defaultInfoList'];
    delete plantaoFullObject['lastRotinaId'];
    // console.clear();

    let respostas = plantaoFullObject['rotinaFullAnswers'];

    for (let grupoKey in respostas) {
      // //console.log('*** 0) ', grupoKey);
      let grupoItem = respostas[grupoKey];
      // //console.log('*** 1) ', grupoItem);

      Object.keys(grupoItem).map((campoKey) => {
        let novoArrayRespostaGrupo = [];
        let respostaDoCampo = respostas[grupoKey][campoKey];
        // //console.log('*** 2) ', campoKey);
        // //console.log('*** 3a) ', { respostaDoCampo });

        if (respostaDoCampo !== undefined && respostaDoCampo !== null) {
          // //console.log('*** 3b) ', { respostaDoCampo });
          if (respostaDoCampo.charAt(0) == '[') {
            novoArrayRespostaGrupo = Array.from(JSON.parse(respostaDoCampo));
            // //console.log('*** 4) ', novoArrayRespostaGrupo);
            respostaDoCampo = novoArrayRespostaGrupo;
          } else {
            // novoArrayRespostaGrupo = [].concat(respostaDoCampo);  // transforma string em um array (com 1 item só)
          }
        }

        respostas[grupoKey][campoKey] = respostaDoCampo;
        // const names = (v) => [].concat(v).map(name => name.toUpperCase())
      });
    }

    //console.log('### ', plantaoFullObject);
  }

  function changeRespostaConduta(event) {
    setRespostaConduta(event.target.value);
  }

  function handleObservacaoSubmit(e) {
    e.stopPropagation();
    showPageOverlay("Salvando intercorrência ... ");
    let plantonistaText = obsPlantonistaRef.current.value;
    let rotina = rotinaInfo;

    if (rotina?.nv_obs_plantonista !== undefined) {
      rotina.nv_obs_plantonista = plantonistaText;

      RotinaMedicaAPI.salvarRotina(JSON.stringify(rotina))
        .then(() => {
          //console.log('### ok');
          RotinaMedicaAPI.atualizaItemRotinaByItem(
            ultimaRotinaId,
            12,
            plantonistaText
          )
            // .then((res) => //console.log('### ', res))
            .catch((er) => {
              console.error(er)
              hidePageOverlay();
            })
            .finally(() => history.go(0));
        })
        .catch((err) => {
          console.error(err)
        });
    }

    hidePageOverlay();
  }

  function changeDose(){
    console.log('test')
  }

  function AntibioticoItemDiasComponent({item}) {
    console.log(" !!#! ", item)

    let diasAntibioCalc = Math.round(
      (new Date() - new Date(item.dataInicio)) /
        (1000 * 60 * 60 * 24)
    ) - item.diaInicial

    let diasAntibio = isNaN(diasAntibioCalc) ? 0 : diasAntibioCalc;

    return (
      <span>{` / Dias: ${diasAntibio}`}</span>      
    )
  }

  function InvasoesItemDiasComponent({item}) {

    let diasInvCalc = Math.round(
      (new Date() - new Date(item.dataInvasoes)) /
        (1000 * 60 * 60 * 24)
    )

    let diasInv = isNaN(diasInvCalc) ? 0 : diasInvCalc;

    return (
      <span>{`: ${item['dataInvasoes'].substring(8, 10) + '/' + item['dataInvasoes'].substring(5, 7) + '/' + item['dataInvasoes'].substring(0, 4)} (Dias: ${diasInv})`}</span>      
    )
  }

  function hidePageOverlay(){
    setPageOverlayText(OVERLAY_TEXTO_PADRAO);
    setIsPageLoading(true);
  }
  
  function showPageOverlay(defaultMsg = "Carregando ..."){
    setPageOverlayText(defaultMsg);
    setIsPageLoading(true);
  }
  
  function PageOverlay(){
    return (
      <div id="overlay" class="pageOverlay" style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
        <LoadingIcons.TailSpin stroke="#fff" transform="scale(1.5)" />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span style={{ fontSize: '20px', color: '#fff' }}>
          { pageOverlayText }
          </span>
      </div>
    )
  }

  // //console.log('*** ', plantaoFullObject);

  return (
    <>
      { isPageLoading && <PageOverlay /> }

      <h2 className="margin-bottom-10">
        <Link to={RotasURL.plantaoMedico.index}>
          <i className="fa fa-arrow-alt-circle-left fa-fw" />
        </Link>
        Plantão Médico
        <div className="btn-home">
          <a href="">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      {/*  Condutas e Observações */}
      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-home">
            <div className="box-intercorrencia-nova">
              <div className="title-box-rotina">Condutas</div>
              <div className="content-box-rotina">
                <div>
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <td>Descrição</td>
                        {/* <td>Data</td> */}
                        <td width={'20px'}>&nbsp;</td>
                        <td width={'40px'}>&nbsp;</td>
                      </tr>
                    </thead>
                    <tbody>
                      {condutasInfo.map((conduta) => {
                        if (conduta?.resposta.length > 0) {
                          return (
                            <>
                              {conduta?.resposta.map((item, index) => {
                                //console.log(item);
                                if (
                                  item.descricao.length > 0 &&
                                  (item.dt_finalizada?.length <= 1 ||
                                    !item.dt_finalizada)
                                ) {
                                  return (
                                    <tr key={index}>
                                      <td>{item.descricao}</td>
                                      {/* <td>{formataDataConduta(item.data)}</td> */}
                                      <td>
                                        {/* <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          finalizarCondutaRotina(
                                            conduta,
                                            item,
                                            index
                                          )
                                        }
                                      >
                                        Finalizar
                                      </button> */}
                                        <button
                                          className="templatemo-white-button"
                                          data-toggle="modal"
                                          data-target="#modalCondutaComentario"
                                          style={{backgroundColor: '#39566B'}}
                                          onClick={() =>
                                            prepararFinalizaConduta(
                                              conduta,
                                              item
                                            )
                                          }
                                        >
                                          <i className="fas fa-comment fa-fw"></i>
                                        </button>
                                      </td>
                                      <td>
                                        {/* <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          finalizarCondutaRotina(
                                            conduta,
                                            item,
                                            index
                                          )
                                        }
                                      >
                                        Finalizar
                                      </button> */}
                                        <button
                                          className="btn btn-danger"
                                          data-toggle="modal"
                                          data-target="#modalCondutaFinalizacao"
                                          onClick={() =>
                                            prepararFinalizaConduta(
                                              conduta,
                                              item
                                            )
                                          }
                                        >
                                          Finalizar
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-home">
            <div className="box-intercorrencia-nova">
              <div className="title-box-rotina">Intercorrências nas últimas 24h</div>
              <div className="content-box-rotina">
                <textarea
                      className="form-control"
                      type="text"
                      disabled='true'
                      style={{ resize: 'vertical', backgroundColor: 'white', borderColor : 'white' }}
                      defaultValue={observacao}
                    />
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="templatemo-green-button margin-bottom-10"
                  data-toggle="modal"
                  data-target="#modalObservacao"
                >
                  Intercorrências
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-home">
            <div className="box-intercorrencia-nova">
              <div className="title-box-rotina">Condutas Finalizadas</div>
              <div className="content-box-rotina">
                <div>
                  <table className="table table-responsive">
                    <thead>
                      <tr>
                        <td >Descrição</td>
                        {/* <td>Data</td> */}
                        <td width={'150px'}>Data de Finalização</td>
                        <td width={'20px'}>&nbsp;</td>
                      </tr>
                    </thead>
                    <tbody>
                      {condutasInfo.map((conduta) => {
                        // //console.log('ITEMCONDUTA => ', conduta);
                        if (conduta?.resposta.length > 0) {
                          return (
                            <>
                              {conduta?.resposta.map((item, index) => {
                                //console.log(item);
                                if (
                                  item.descricao.length > 0 &&
                                  item.dt_finalizada?.length > 1
                                ) {
                                  return (
                                    <tr key={index}>
                                      <td>{item.descricao}</td>
                                      {/* <td>{formataDataConduta(item.data)}</td> */}
                                      <td>
                                        {item.dt_finalizada.substring(10, 11) ==
                                        'T'
                                          ? item.dt_finalizada?.substring(
                                              8,
                                              10
                                            ) +
                                            '/' +
                                            item.dt_finalizada?.substring(
                                              5,
                                              7
                                            ) +
                                            '/' +
                                            item.dt_finalizada?.substring(
                                              0,
                                              4
                                            ) +
                                            ' ' +
                                            item.dt_finalizada?.substring(
                                              11,
                                              16
                                            )
                                          : item.dt_finalizada.substring(0, 10)}
                                      </td>
                                      <td>
                                        {/* <button
                                        className="btn btn-primary"
                                        onClick={() =>
                                          finalizarCondutaRotina(
                                            conduta,
                                            item,
                                            index
                                          )
                                        }
                                      >
                                        Finalizar
                                      </button> */}
                                        <button
                                          className="templatemo-black-button"
                                          data-toggle="modal"
                                          data-target="#modalCondutaVisualizar"
                                          onClick={() =>
                                            prepararFinalizaConduta(
                                              conduta,
                                              item
                                            )
                                          }
                                        >
                                          <i className="fas fa-eye fa-fw"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </>
                          );
                        } else {
                          return <></>;
                        }
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Ultima Rotina 2 */}
      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12" >
        <div className="plantao-rotina-container" style={{maxHeight: '85vh'}}>
          <h3 style={{ color: '#00a99d', fontWeight: 'bold', padding: '10px' }}>
            Última Rotina:
          </h3>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
            {plantaoFullObject['rotinaFullAnswers'] &&
              Object.keys(plantaoFullObject['rotinaFullAnswers']).map(
                (grupoKey, index) => {
                  let grupoItem =
                    plantaoFullObject['rotinaFullAnswers'][grupoKey];
                  return (
                    <>
                      <div
                        key={grupoKey}
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-home"
                      >
                        <div className="box-rotina-nova">
                          <div className="title-box-rotina">{grupoKey}</div>
                          <div className="content-box-rotina">
                            {Object.keys(grupoItem).map((campoKey) => {
                              let campoResposta =
                                plantaoFullObject['rotinaFullAnswers'][
                                  grupoKey
                                ][campoKey];
                              if (campoKey == "Unidade" || campoKey == "Intercorrências"){
                                // lista dos campos que não aparecem no plantao
                              } else {
                              return (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: '#f0f0f0',
                                      paddingTop: '3px',
                                      paddingBottom: '3px',
                                      paddingLeft: '1px',
                                      paddingRight: '1px',
                                      margin: '2px',
                                    }}
                                  >
                                    <span>{`${campoKey}: `}</span>
                                    <span>
                                      {Array.isArray(campoResposta) ? (
                                        <>
                                          <br></br>
                                          {campoResposta.map((item) => {
                                            try {
                                              if (
                                                !(item[Object.keys(item)[0]] == 'nao' || item[Object.keys(item)[0]] == '')
                                              ) {
                                                return (
                                                  <>
                                                    <span>
                                                      {Object.keys(item)[0] == 'data'?
                                                      '•  ' + item[Object.keys(item)[0]] ?.substring(8, 10) + '/' +
                                                      item[Object.keys(item)[0]] ?.substring(5, 7) + '/' +
                                                      item[Object.keys(item)[0]] ?.substring(0, 4)
                                                      :
                                                      '•  ' +item[Object.keys(item)[0]]
                                                      }
                                                    </span>
                                                      {
                                                        item['droga']? <span onClick={changeDose}>{` / Dose: ${item['dose']} ${item['unidade']? item['unidade'] : "(unidade não informada)"}`}</span> : 
                                                        item['escala']?  <span>{`: ${item['valor']}`}</span> : 
                                                        item['suporte']?  <span>{` / Detalhes: ${item['detalhes']}`}</span> : 
                                                        item['data']?  <span>{`: ${item['data'].substring(8, 10) + '/' + item['data'].substring(5, 7) + '/' + item['data'].substring(0, 4)}`}</span> :
                                                        item['data']==''?  <span>{`: N/A`}</span> :
                                                        item['dataInvasoes']? <InvasoesItemDiasComponent item={item} /> :
                                                        item['dataInvasoes']==''?  <span>{`: N/A`}</span> :
                                                        (item['diasTotal'] || item['diasTotal'] == 0)? <AntibioticoItemDiasComponent item={item} /> : null
                                                      }
                                                    <br></br>
                                                  </>
                                                );
                                              }
                                            } catch {}
                                          })}
                                        </>
                                      ) : (
                                        <>
                                          {campoKey == "Diurese"? <span>{campoResposta? `${campoResposta} ${plantaoFullObject['rotinaFullAnswers'][grupoKey]['Unidade']}` : ''}</span> : 
                                          campoKey == "BH"? <span>{campoResposta == '+' ? '' : campoResposta}</span> : 
                                          campoKey == "Data"? <span>{campoResposta? campoResposta?.substring(8, 10) + '/' + campoResposta ?.substring(5, 7) + '/' + campoResposta ?.substring(0, 4) : "Não informada"}</span> : 
                                          campoKey == "Data Coagulograma"? <span>{campoResposta? campoResposta ?.substring(8, 10) + '/' + campoResposta ?.substring(5, 7) + '/' + campoResposta ?.substring(0, 4) : "Não informada"}</span> : 
                                          campoKey == "Intercorrências"? <span></span> : 
                                          <span>{campoResposta}</span>}
                                        </>
                                      )}
                                    </span>
                                  </div>
                                </>
                              );}
                            })}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }
              )}
          </div>
        </div>
      </div>

      {/* Ultima Rotina 1 */}
      {/*       
      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
        <div className="plantao-rotina-container">
          <h3 style={{ color: '#00a99d', fontWeight: 'bold', padding: '10px' }}>
            Última Rotina:
          </h3>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            {infoGroupsArray.map((info, index) => {
              return (
                <>
                  <div key={index}>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-home">
                      <div className="box-rotina-nova">
                        <div className="title-box-rotina">{info}</div>
                        <div className="content-box-rotina">
                          {infoComRespostaListState.map((item) => {
                            // //console.log('+++', item);
                            if (
                              item.nv_grupo === info &&
                              item.resposta != null &&
                              !item.nv_identificacao
                                .toUpperCase()
                                .includes('CONDUTA')
                            ) {
                              // //console.log('+++', item);
                              let seRespostaLista = [];

                              if (item.resposta.charAt(0) == '[') {
                                seRespostaLista = Array.from(
                                  JSON.parse(item.resposta)
                                );
                                // //console.log('@@@ ', { seRespostaLista });
                              }

                              // //console.log('+++ ', { seRespostaLista });
                              return (
                                <>
                                  <div key={item}>
                                    <span>
                                      {item.nv_descricao}:{' '}
                                      <span>
                                        {seRespostaLista.length > 0 ? (
                                          <>
                                            {seRespostaLista.map((resposta) => {
                                              return (
                                                <p style={{ margin: 0 }}>
                                                  {' '}
                                                  {resposta.descricao}
                                                </p>
                                              );
                                            })}
                                          </>
                                        ) : (
                                          <>{item.resposta}</>
                                        )}
                                      </span>
                                    </span>
                                  </div>
                                </>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </div> */}

      {/* MODAIS */}
      <>
        <div
          className="modal"
          id="modalIntercorrencia"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Nova Intercorrência
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    type="text"
                    // name
                    placeholder="Escreva aqui..."
                    defaultValue={''}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="modalObservacao"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Intercorrências
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <form>
                <div className="modal-body">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      type="text"
                      rows={6}
                      placeholder="Escreva aqui..."
                      defaultValue={observacao}
                      ref={obsPlantonistaRef}
                      // ref={}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-dismiss="modal"
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={(event) => handleObservacaoSubmit(event)}
                  >
                    Confirmar
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="modalCondutaComentario"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modalCondutaComentario"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Adicionar Resposta
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <textarea
                    style={{ resize: 'vertical' }}
                    className="form-control textarea_diag"
                    type="text"
                    placeholder='Insira a resposta da conduta.'
                    value={respostaConduta}
                    onChange={(props) => changeRespostaConduta(props)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Fechar Janela
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => finalizarCondutaRotina('false')}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="modalCondutaVisualizar"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modalCondutaVisualizar"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Visualizar Resposta
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <textarea
                    style={{ resize: 'vertical' }}
                    disabled={true}
                    className="form-control textarea_diag"
                    type="text"
                    placeholder='Insira a resposta da conduta.'
                    value={respostaConduta}
                    onChange={(props) => changeRespostaConduta(props)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Fechar Janela
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="modalCondutaFinalizacao"
          tabIndex={-1}
          role="dialog"
          aria-labelledby="modalCondutaFinalizacao"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Finalizar Conduta
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <div>
                  <textarea
                    style={{ resize: 'vertical' }}
                    className="form-control textarea_diag"
                    type="text"
                    placeholder='Insira a resposta da conduta.'
                    value={respostaConduta}
                    onChange={(props) => changeRespostaConduta(props)}
                  />
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Fechar Janela
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  data-dismiss="modal"
                  onClick={() => finalizarCondutaRotina('true')}
                >
                  Finalizar Conduta
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};
export default PlantaoRotPage;
