import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Especialidade/items';
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
    let usuarioSession = getUser();
    //console.log(usuarioSession);
    if(usuarioSession){
        usuarioLogado = usuarioSession.in_usuario_id;
    }
}

const EspecialidadeAPI = {
    obterListaEspecialidade: async function(){
        try{
            obterUsuarioLogado();
            let token = getToken();
            let headers = {
                Authorization: `Bearer ${token}`
            }

            const url = `/${base}/listar?pAtivo=1&pUsuario=${usuarioLogado}`;

            const retorno = await get(url, headers).then((response) => {
                return response.data;
            }).catch((error) => {
                //console.log("Erro ao buscar Especialidades");
                throw error;
            })

            return retorno;
        }catch(error){
            //console.log("Erro ao buscar Especialidades");
            throw error;
        }
    },

    salvaEspecialidade: async function (especialidadeJSON){
        try{
            obterUsuarioLogado();
            let token = getToken();
            let headers = {
                Authorization: `Bearer ${token}`
            }

            const url = `/${base}/salvar?pUsuario=${usuarioLogado}`

            const response = await post(url, especialidadeJSON, null, headers)
                .then((response) => {
                    return response;
                }).catch((error) => {
                    throw error;
                })
        }catch(error){
            throw error;
        }

    },

    excluirEspecialidade: async function (idToDelete){
        try{
            obterUsuarioLogado();
            let token = getToken();
            let headers = {
                Authorization: `Bearer ${token}`
            }

            const url = `/${base}/excluir`

            const response = await DELETE(
                url,
                idToDelete,
                usuarioLogado,
                'Especialidade',
                headers
            ).then((response) => {
                //console.log(response);

            })
        }catch(error){
            //console.log(error);
            return null;
        }
    }
}

export default EspecialidadeAPI;