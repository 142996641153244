const USER = '@rondasys:user';
const TOKEN = '@rondasys:token';

export const storeLoginSession = (pUser, pToken) => {
  sessionStorage.setItem(USER, JSON.stringify(pUser));
  sessionStorage.setItem(TOKEN, JSON.stringify(pToken));
};

export const logOut = () => {
  sessionStorage.removeItem(USER);
  sessionStorage.removeItem(TOKEN);
};

export const isLoggedIn = () => {
  if (sessionStorage.getItem(TOKEN) && sessionStorage.getItem(USER)) {
    return true;
  }
  return false;
};

export const getToken = () => {
  if (isLoggedIn()) {
    const tokenObject = JSON.parse(sessionStorage.getItem(TOKEN));
    return tokenObject.accessToken;
  }
  return null;
};

export const getUser = () => {
  if (isLoggedIn()) {
    const user = JSON.parse(sessionStorage.getItem(USER));
    return user;
  }
  return null;
};
