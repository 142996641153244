import React, { useEffect, useState, useLayoutEffect } from 'react';
import TabelaSetor from '../../Components/Tabela/TabelaSetor';
import { useHistory, NavLink } from 'react-router-dom';

import SetorAPI from '../../Services/API/SetorAPI';
import { RotasURL } from 'RoutesURL';

const SetorPage = () => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtual, setListaAtual] = useState([]);
  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();

  const [filtro, setFiltro] = useState('');

  const history = useHistory();

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  const obterListaDeSetores = async () => {
    try {
      let lista = await SetorAPI.obterListaSetor();

      if (lista != null) {
        setListaInicial(lista);
      }
    } catch (error) {
      console.error(error);
      history.push({ pathname: '/login' });
      // throw error;
    }
  };

  useEffect(() => {
    obterListaDeSetores();
    //controlaPagina();
  }, []);

  useEffect(() => {
    ////console.log(filtro);
    filtraSetor();
  }, [filtro, listaInicial]);

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    ////console.log(numeroRegistros);
    ////console.log(listaAtual.length);
    //console.log('pos filtro');
    //console.log(listaFiltrada);
    controlaPagina();
  }, [numeroRegistros, paginaAtual, listaFiltrada]);

  useEffect(() => {
    setPaginaAtual(() => 1);
  }, [numeroRegistros]);

  function filtraSetor() {
    //console.log(listaInicial);
    let listaTemp = listaInicial.filter((setor) =>
      setor.nv_nome_setor.toLowerCase().includes(filtro.toLowerCase())
    );
    setListaFiltrada(listaTemp);
  }

  function controlaPagina() {
    //console.log('controla page');
    //console.log(listaFiltrada);
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;
    var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtual(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtual(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  function FiltroChange(event) {
    const valor = event.target.value;
    setFiltro(valor);
    ////console.log(filtro);
  }

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  return (
    <>
      {/* <!--Título da Página--> */}
      <h2 className="margin-bottom-10">
        Setor
        <div className="btn-home">
          <a href="index.html" title="Home">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
        {/*<!--Home-->*/}
      </h2>

      {/* <!--Botão--> */}
      <div>
        <NavLink to={RotasURL.setor.novo}>
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
          >
            Novo Setor
          </button>
        </NavLink>
      </div>

      <br />
      <div id="tblsetor_wrapper" className="dataTables_wrapper no-footer">
        {/* <!-- Registros por página -->
          // Pode virar componente */}
        <div
          className="dataTables_length"
          id="tblsetor_length"
          style={{ float: 'left' }}
        >
          <label>
            Mostrando
            <select
              name="tblsetor_length"
              aria-controls="tblsetor"
              className="input-select"
              value={numeroRegistros}
              onChange={registrosChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{' '}
            registros por página
          </label>
        </div>

        <div
          id="tblsetor_filter"
          className="dataTables_filter"
          style={{ float: 'right' }}
        >
          <label>
            Localizar:{' '}
            <input
              type="search"
              name="filtro"
              className="input-search"
              placeholder=""
              value={filtro}
              onChange={FiltroChange}
              aria-controls="tblsetor"
            />
          </label>
        </div>

        <TabelaSetor
          lista={listaAtual}
          obterListaDeSetores={obterListaDeSetores}
        />

        <div className="row form-group">
          <div
            className="dataTables_info info_paginas"
            id="tblsetor_info"
            role="status"
            aria-live="polite"
          >
            {paginaAtual > 1 && (
              <button
                className="templatemo-white-button"
                onClick={diminuiPagina}
              >
                &lt;
              </button>
            )}
            Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
            {paginaAtual < maxPagina && (
              <button className="templatemo-white-button" onClick={somarPagina}>
                &gt;
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SetorPage;
