import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';
const base = 'PessoaFisica/items';

let usuarioLogado = 0;
const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const PessoaFisicaAPI = {
  obterListaPessoa: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pAtivo=1&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // let statusCode = error.response.status;
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  obterPessoaPorId: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultar?pIdPessoaFisicaToConsultar=${id}&pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      //console.log('Catch: ', err);
      // return null;
      throw err;
    }
  },

  obterPessoaPorNome: async function (nome) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultarpornome?pPessoaFisicaToConsultar=${nome}&pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      //console.log('Catch: ', err);
      // return null;
      throw err;
    }
  },

  obterPessoaPorCPF: async function (cpf) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultarporcpf?pCPF=${cpf}&pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          return null;
          // throw error;
        });

      return retorno;
    } catch (err) {
      //console.log('Catch: ', err);
      return null;
      // throw err;
    }
  },

  salvarPessoa: async function (pessoaJson) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;

      // //console.log('url: ', url);
      // //console.log('Pessoa: ', JSON.stringify(pessoaJson));
      // //console.log('Headers: ', headers);

      const response = await post(url, pessoaJson, null, headers)
        .then((response) => {
          //console.log(response);
          return response.data;
        })
        .catch((err) => {
          throw err;
        });

      return response;
    } catch (error) {
      //console.log(error);
      return null;
      // throw error;
    }
  },

  excluirPessoa: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/excluir`;

      await DELETE(url, id, usuarioLogado(), 'PessoaFisica', headers)
        .then((response) => {
          // //console.log(response);
          // //console.log(`api deletado ${id}`);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      //console.log(error);
      // return null;
      throw error;
    }
  },
};

export default PessoaFisicaAPI;
