import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Perfil/items';

// const usuarioLogado = 1;
let usuarioLogado = 0;
const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const PerfilAPI = {
  obterListaPerfil: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pAtivo=1&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  obterListaTipoPerfil: async function (){
    try{
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listartipoperfil?pAtivo=1&pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          //console.log(response);
          return response.data;
        })
        .catch((err) => {
          //console.log(err);
          throw err;
        })
      return retorno;
    }catch(err){
      throw err;
    }
  },

  consultarPerfil: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultar?pIdPerfilToConsultar=${id}&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  salvarPerfil: async function (perfilJson) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;
      //console.log('Url Perfil Post: ', url);

      const response = await post(url, perfilJson, null, headers)
        .then((response) => {
          //console.log(response);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  excluirPerfil: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/excluir`;

      await DELETE(url, id, usuarioLogado, 'Perfil', headers)
        .then((response) => {
          // //console.log(response);
          // //console.log(`api deletado ${id}`);
          return response;
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      // //console.log(error);
      // return null;
      throw error;
    }
  },
};

export default PerfilAPI;
