import React, { useEffect, useState } from 'react';
import TabelaGrupo from '../../Components/Tabela/TabelaGrupo';

import GruposAPI from '../../Services/API/GruposAPI';

const GrupoPage = () => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtual, setListaAtual] = useState([]);
  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();

  const [filtro, setFiltro] = useState('');

  const obterListaDeGrupos = async () => {
    try {
      let lista = await GruposAPI.obterListaGrupo();
      setListaFiltrada(() => lista);
      setListaInicial(() => lista);
      setListaAtual(() => lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    obterListaDeGrupos();
  }, []);

  useEffect(() => {
    filtraGrupo();
    //console.log(filtro);
  }, [filtro, listaInicial]);

  useEffect(() => {
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;
    if (numeroRegistros)
      var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtual(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtual(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }, [numeroRegistros, paginaAtual, listaFiltrada]);

  useEffect(() => {
    setPaginaAtual(() => 1);
  }, [numeroRegistros]);

  function filtraGrupo() {
    //console.log(filtro);
    let listaTemp = listaInicial.filter((grupo) =>
      grupo.nv_nome_grupo.toLowerCase().includes(filtro.toLowerCase())
    );
    setListaFiltrada(listaTemp);
    //console.log(listaTemp);
  }

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  const FiltroChange = (event) => {
    const valor = event.target.value;
    setFiltro(valor);
  };

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  return (
    <>
      {/* <!--Título da Página--> */}
      <h2 className="margin-bottom-10">
        Grupo Ronda
        <div className="btn-home">
          <a href="index.html" title="Home">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
        {/*<!--Home-->*/}
      </h2>

      {/* <!--Botão--> */}
      <div>
        <a href="grupo_novo">
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
          >
            Novo Grupo
          </button>
        </a>
      </div>

      {/* </br> */}

      <div id="tblgrupo_wrapper" className="dataTables_wrapper no-footer">
        {/* <!-- Registros por página -->

          // Pode virar componente */}
        <div
          className="dataTables_length"
          id="tblgrupo_length"
          style={{ float: 'left' }}
        >
          <label>
            Mostrando
            <select
              name="tblgrupo_length"
              aria-controls="tblgrupo"
              className="input-select"
              value={numeroRegistros}
              onChange={registrosChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>{' '}
            registros por página
          </label>
        </div>

        <div
          id="tblgrupo_filter"
          className="dataTables_filter"
          style={{ float: 'right' }}
        >
          <label>
            Localizar:{' '}
            <input
              type="search"
              className="input-search"
              placeholder=""
              aria-controls="tblgrupo"
              value={filtro}
              onChange={FiltroChange}
            />
          </label>
        </div>

        <TabelaGrupo
          lista={listaAtual}
          obterListaDeGrupos={obterListaDeGrupos}
        />

        <div className="row form-group">
          <div
            className="dataTables_info info_paginas"
            id="tblgrupo_info"
            role="status"
            aria-live="polite"
          >
            {paginaAtual > 1 && (
              <button
                className="templatemo-white-button"
                onClick={diminuiPagina}
              >
                &lt;
              </button>
            )}
            Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
            {paginaAtual < maxPagina && (
              <button className="templatemo-white-button" onClick={somarPagina}>
                &gt;
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default GrupoPage;
