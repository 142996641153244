import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './empresaSchema';
import InputMask from 'react-input-mask';

import EmpresaAPI from '../../Services/API/EmpresaAPI';
import { NavLink } from 'react-router-dom';

import { RotasURL } from 'RoutesURL';

const NovaEmpresaPage = (props) => {
  const [listaAtualEmpresa, setListaAtualEmpresa] = useState();
  const [atualizando, setAtualizando] = useState(true);
  const [idEmpresa, setIdEmpresa] = useState();
  const [empresa, setEmpresa] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();

  useEffect(() => {
    obterListaDeEmpresas();
  }, []);

  useEffect(() => {
    if (listaAtualEmpresa) {
      if (!props.location.state) {
        setAtualizando(false);
        setIdEmpresa(listaAtualEmpresa[0].in_empresa_id);
        //console.log('vazio');
        //console.log(props);
      } else {
        atualizaEmpresa();
        //console.log(props);
      }
    }
  }, [listaAtualEmpresa]);

  const onSubmit = (dataJSON) => {
    const data = dataJSON;
    console.log(JSON.stringify(data));
    data.in_empresa_id = idEmpresa;

    let temp_cep = data.nv_cep_empresa;
    temp_cep = temp_cep.replace(/[^a-zA-Z0-9 ]/g, '');
    temp_cep = temp_cep.replace(/[^0-9\.]+/g, '');

    data.nv_cep_empresa = temp_cep;

    if (atualizando) data.in_empresa_id = empresa.in_empresa_id;
    EmpresaAPI.salvarEmpresa(data)
      .then((resposta) => {
        // //console.log(resposta);
        // //console.log('ok');
        // history.push('/empresa');
        history.push(RotasURL.empresa.index);
      })
      .catch((err) => {
        //console.log(err);
        // history.push({ pathname: '/login' });
        alert('Erro ao salvar empresa. Campo errado.');
        history.push({ pathname: RotasURL.empresa.index });
      });
  };

  async function obterListaDeEmpresas() {
    try {
      let lista = await EmpresaAPI.obterListaEmpresa();
      setListaAtualEmpresa(lista);
      ////console.log(lista);
    } catch (error) {
      // console.error(error);
      // throw error;
      history.push({ pathname: '/login' });
    }
  }

  // function changeId(event) {
  //   setIdEmpresa(event.target.value);
  // }

  function atualizaEmpresa() {
    setEmpresa(props.location.state.item);
    setIdEmpresa(props.location.state.item.in_empresa_id);
    setAtualizando('true');
  }

  return (
    <>
      {/*Título da Página*/}
      <h2 className="margin-bottom-10">
        <NavLink to={RotasURL.empresa.index}>
          <i className="fas fa-arrow-alt-circle-left fa-fw" />
        </NavLink>
        {atualizando ? 'Atualizar Empresa' : 'Cadastrar Empresa'}
        <div className="btn-home">
          <NavLink to={RotasURL.pa_main}>
            <i className="fas fa-home fa-fw" />
          </NavLink>
        </div>
        {/*Home*/}
      </h2>
      {(empresa || !atualizando) && (
        <form
          className="templatemo-login-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row form-group">
            <div className="col-lg-8 col-md-8 col-sm-6 form-group">
              <label htmlFor="inputFirstName">Nome do Hospital</label>{' '}
              {/*Nome do Hospital*/}
              <input
                type="text"
                className="form-control"
                placeholder="Nome do hospital"
                defaultValue={empresa && empresa.nv_nome_empresa}
                {...register('nv_nome_empresa', {})}
              />
              <p className="error-message">{errors.nv_nome_empresa?.message}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 form-group">
              <label htmlFor="inputLastName">Código Externo</label>{' '}
              {/*Código Externo*/}
              <input
                type="text"
                className="form-control"
                placeholder="Código Externo"
                defaultValue={empresa && empresa.nv_codexterno_empresa}
                {...register('nv_codexterno_empresa', {})}
              />
            </div>
          </div>

          <div className="row form-group">
            <div className="col-lg-8 col-md-8 col-sm-6 form-group">
              <label htmlFor="inputUsername">Endereço do Hospital</label>{' '}
              {/*Endereço do Hospital*/}
              <input
                type="text"
                className="form-control"
                placeholder="Endereço do Hospital"
                defaultValue={empresa && empresa.nv_endereco_empresa}
                {...register('nv_endereco_empresa', {})}
              />
              <p className="error-message">
                {errors.nv_endereco_empresa?.message}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 form-group">
              <div className="row form-group">
                <div className="col-lg-4 col-md-4 col-sm-6 form-group">
                  <label htmlFor="inputUsername">Número</label> {/*Número*/}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Número"
                    defaultValue={empresa && empresa.nv_numero_empresa}
                    {...register('nv_numero_empresa', {})}
                  />
                  <p className="error-message">
                    {errors.nv_numero_empresa?.message}
                  </p>
                </div>
                <div className="col-lg-8 col-md-8 col-sm-6 form-group">
                  <label htmlFor="inputUsername">Complemento</label>{' '}
                  {/*Complemento*/}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Complemento"
                    defaultValue={empresa && empresa.nv_complemento_empresa}
                    {...register('nv_complemento_empresa', {})}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row form-group">
            <div className="col-lg-2 col-md-2 col-sm-4 form-group">
              <label htmlFor="inputUsername">CEP</label> {/*CEP*/}
              <input
                type="text"
                className="form-control"
                placeholder="CEP"
                defaultValue={empresa && empresa.nv_cep_empresa}
                {...register('nv_cep_empresa', {})}
              />
              <p className="error-message">{errors.nv_cep_empresa?.message}</p>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4 form-group">
              <label htmlFor="inputUsername">Bairro</label> {/*Bairro*/}
              <input
                type="text"
                className="form-control"
                placeholder="Bairro"
                defaultValue={empresa && empresa.nv_bairro_empresa}
                {...register('nv_bairro_empresa', {})}
              />
              <p className="error-message">
                {errors.nv_bairro_empresa?.message}
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-4 form-group">
              <div className="row form-group">
                <div className="col-lg-9 col-md-9 col-sm-7 form-group">
                  <label htmlFor="inputUsername">Cidade</label> {/*Cidades*/}
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Cidade"
                    defaultValue={empresa && empresa.nv_cidade_empresa}
                    {...register('nv_cidade_empresa', {})}
                  />
                  <p className="error-message">
                    {errors.nv_cidade_empresa?.message}
                  </p>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-5 form-group">
                  <label htmlFor="control-label templatemo-block">UF</label>{' '}
                  {/*Estados*/}
                  <select
                    className="form-control"
                    defaultValue={empresa && empresa.nv_estado_empresa}
                    {...register('nv_estado_empresa', {})}
                  >
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AM">AM</option>
                    <option value="AP">AP</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MG">MG</option>
                    <option value="MS">MS</option>
                    <option value="MT">MT</option>
                    <option value="PA">PA</option>
                    <option value="PB">PB</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="PR">PR</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="RS">RS</option>
                    <option value="SC">SC</option>
                    <option value="SE">SE</option>
                    <option value="SP">SP</option>
                    <option value="TO">TO</option>
                  </select>
                  <p className="error-message">
                    {errors.nv_estado_empresa?.message}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-lg-2 col-md-2 col-sm-4 form-group">
              <label htmlFor="inputFirstName">Contato</label> {/*Contato*/}
              <input
                type="tel"
                className="form-control"
                placeholder="Contato"
                defaultValue={empresa && empresa.nv_contato_empresa}
                {...register('nv_contato_empresa', {})}
              />
              <p className="error-message">
                {errors.nv_contato_empresa?.message}
              </p>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-4 form-group">
              <label htmlFor="inputLastName">CGC</label> {/*CGC*/}
              <input
                type="text"
                className="form-control"
                placeholder="CGC"
                defaultValue={empresa && empresa.nv_cgc_empresa}
                {...register('nv_cgc_empresa', {})}
              />
              <p className="error-message">{errors.nv_cgc_empresa?.message}</p>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-4 form-group">
              <label htmlFor="inputLastName">CNES</label> {/*CNES*/}
              <input
                type="text"
                className="form-control"
                placeholder="CNES"
                defaultValue={empresa && empresa.nv_cnes_empresa}
                {...register('nv_cnes_empresa', {})}
              />
              <p className="error-message">{errors.nv_cnes_empresa?.message}</p>
            </div>
          </div>
          <div className="row form-group">
            <div className="col-lg-5 col-md-5 col-sm-5 form-group">
              <label htmlFor="inputLastName">Inscrição Municipal</label>{' '}
              {/*Inscrição Municipal*/}
              <input
                type="text"
                className="form-control"
                placeholder="Inscrição Municipal"
                defaultValue={empresa && empresa.nv_inscricaomunicipal_empresa}
                {...register('nv_inscricaomunicipal_empresa', {})}
              />
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 form-group">
              <label htmlFor="inputLastName">Inscrição Estadual</label>{' '}
              {/*Inscrição Estadual*/}
              <input
                type="text"
                className="form-control"
                placeholder="Inscrição Estadual"
                defaultValue={empresa && empresa.nv_inscricaoestadual_empresa}
                {...register('nv_inscricaoestadual_empresa', {})}
              />
            </div>
            <div className="col-lg-2 col-md-2 col-sm-2 form-group">
              <label htmlFor="control-label templatemo-block">Ativo</label>{' '}
              {/*Estados*/}
              <div className="custom-select">
                <select className="form-control" {...register('nv_ativo', {})}>
                  <option value={0}>Sim</option>
                  <option value={1}>Não</option>
                </select>
              </div>
            </div>
          </div>
          <div className="form-group text-left">
            <button type="reset" className="templatemo-white-button">
              Limpar
            </button>

            <button type="submit" className="templatemo-blue-button">
              Salvar
            </button>
          </div>
        </form>
      )}
    </>
  );
};;;
export default NovaEmpresaPage;
