import * as yup from 'yup';

export const schema = yup.object().shape({
  nv_nome_pessoafisica: yup.string().required('*Campo obrigatório.'),
  nv_cpf_pessoafisica: yup.string().required('*Campo obrigatório.'),
  nv_genero_pessoafisica: yup.string(),
  in_pessoafisica_id: yup.string(),
  nv_telefone_pessoafisica: yup.string(),
  nv_email_pessoafisica: yup.string(),
  nv_cep_pessoafisica: yup.string(),
  nv_logradouro_pessoafisica: yup.string(),
  nv_numero_pessoafisica: yup.string(),
  nv_complemento_pessoafisica: yup.string(),
  nv_bairro_pessoafisica: yup.string(),
  nv_cidade_pessoafisica: yup.string(),
  nv_uf_pessoafisica: yup.string(),
  nv_crm_pessoafisica: yup.string(),
  nv_ddd_pessoafisica: yup.string(),
  dt_nascimento_pessoafisica: yup.string(),
  nv_ativo: yup.string(),
  in_paciente: yup.string(),
  in_medico: yup.string(),
  in_assistente: yup.string(),
});
