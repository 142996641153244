export const RotasURL = {
  login: '/login',

  novaSenha: '/esqueceu_senha',

  empresa: {
    index: '/pa/empresa',
    nova: '/pa/empresa/create',
  },

  setor: {
    index: '/pa/setor',
    novo: '/pa/setor/create',
  },

  acomodacao: {
    index: '/pa/acomodacao',
    nova: '/pa/acomodacao/create',
  },

  especialidade: {
    index: '/pa/especialidade',
    novo: '/pa/especialidade/create'
  },

  perfil: {
    index: '/pa/perfil',
    novo: '/pa/perfil/create',
  },

  usuario: {
    index: '/pa/usuario',
    novo: '/pa/usuario/create',
  },

  pessoaFisica: {
    index: '/pa/PessoaFisica',
    novo: '/pa/PessoaFisica/create',
    escolher_pf: '/pa/PessoaFisica/choose',
  },

  admissaoPaciente: {
    index: '/po/admissao',
  },

  movimentacaoPaciente: {
    origem: '/po/movimentacao',
    destino: '/po/movimentacao2',
  },

  grupoRonda: {
    index: '/pa/grupos',
  },

  atributosRonda: {
    index: '/pa/infos',
  },

  rotinaMedica: {
    index: '/po/rotina/',
    lista: '/po/rotina/lista',
    nova: '/po/rotina/nova',

    atendimento: `/po/rotina/atendimento/:idAtendimento`,
    details: `/po/rotina/atendimento/:idAtendimento/d/:idRotina/`,
  },

  plantaoMedico: {
    index: '/po/plantonista/',
    atendimento: `/po/plantonista/atendimento/:idAtendimento`,
  },

  altaMedica: {
    index: '/po/altamedica/',
  },

  liberacaoLeito: {
    index: '/po/liberacao/',
  },

  po_main: '/po',

  pa_main: '/pa',

  index: '/',
};
