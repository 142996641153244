import React, { useEffect, useState } from 'react';

const TabelaPessoaConsulta = (props) => {
  const [lista, setLista] =useState([]);
  
  

  return (
    <>
      {/* Pode virar componente */}
      <table id="tblpessoa" className="table table-responsive">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Telefone</th>
            <th>Nascimento</th>
          </tr>
        </thead>
        <tbody>
          {props.lista.map((item, index) => {
            return (
              <tr
                data-dismiss="modal"
                key={index}
                className="linha-tabela"
                onClick={() => props.nameClick(item)}
              >
                <td>{item.nv_nome_pessoafisica}</td>
                <td>{item.nv_cpf_pessoafisica}</td>
                <td>{item.nv_telefone_pessoafisica}</td>
                <td>{item.dt_nascimento_pessoafisica? item.dt_nascimento_pessoafisica.substring(0, 10) : ''}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TabelaPessoaConsulta;
