import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Grupo/items';
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const GruposAPI = {
  obterListaGrupo: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pAtivo=1&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  obterGrupoPorId: async function (grupoId) {
    //https://exporondabackend.azurewebsites.net/api/Grupo/items/consultar?pIdGrupoToConsultar=8&pUsuario=1
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultar?pIdGrupoToConsultar=${grupoId}&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url)
        .then((response) => {
          // //console.log('Response -> ', response);
          // //console.log('Response -> ', response.data);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  salvarGrupo: async function (gruposJson) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;
      // //console.log('Url Grupos Post: ', url);

      const response = await post(url, gruposJson).then((response) => {
        //console.log(response);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  salvarInformacoesGrupo: async function (grupoId, gruposJson) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvaratributosdogrupo?pUsuario=${usuarioLogado}&pGrupoId=${grupoId}`;
      //console.log('Url salvaratributosdogrupo: ', url);

      const response = await post(url, gruposJson).then((response) => {
        //console.log(response);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  excluirGrupo: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/excluir`;

      const response = await DELETE(url, id, usuarioLogado, 'Grupo').then(
        (response) => {
          //console.log(response);
          //console.log(`api deletado ${id}`);
          return response;
        }
      );
    } catch (error) {
      //console.log(error);
      return null;
    }
  },
};

export default GruposAPI;
