import { get, getWithParams, post, DELETE} from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = '/Movimentacao/items';
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const MovimentacaoAPI = {
    salvarMovimentacao: async function (idAtendimento, acomodacaoOrigem, acomodacaoDestino, motivo){
        try{
            obterUsuarioLogado();
            let token = getToken();
            let headers = {
                Authorization: `Bearer ${token}`,
            };

            const url = `${base}/salvar?pUsuario=${usuarioLogado}&pIdAtendimento=${idAtendimento}&pIdAcomodacaoOrigem=${acomodacaoOrigem}&pIdAcomodacaoDestino=${acomodacaoDestino}&pMotivoMovimentacao=${motivo}`;
            //console.log("Tentando salvar: ", idAtendimento, acomodacaoOrigem, acomodacaoDestino, motivo);
            const response = await post(url).then((response) => {
                //console.log(response);
                return response;
            })}catch(error){
                //console.log("Erro ao salvar Movimentaçao: ", error);
                return null;
            }
    }
}

export default MovimentacaoAPI;