import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import TabelaPerfil from '../../Components/Tabela/TabelaPerfil';
import { RotasURL } from 'RoutesURL';
import PerfilAPI from '../../Services/API/PerfilAPI';

const PerfilPage = () => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtual, setListaAtual] = useState([]);
  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();

  const [filtro, setFiltro] = useState('');

  const history = useHistory();

  const obterListaDePerfis = async () => {
    try {
      let lista = await PerfilAPI.obterListaPerfil();
      setListaFiltrada(() => lista);
      setListaInicial(() => lista);
      setListaAtual(() => lista);
    } catch (error) {
      console.error(error);
      history.push({ pathname: RotasURL.index });
      // throw error;
    }
  };

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    obterListaDePerfis();
  }, []);

  useEffect(() => {
    filtraPerfil();
    //console.log(filtro);
  }, [filtro, listaInicial]);

  useEffect(() => {
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;
    if (numeroRegistros)
      var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtual(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtual(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }, [numeroRegistros, paginaAtual, listaFiltrada]);

  useEffect(() => {
    setPaginaAtual(() => 1);
  }, [numeroRegistros]);

  function filtraPerfil() {
    //console.log(filtro);
    let listaTemp = listaInicial.filter((perfil) =>
      perfil.nv_nome_perfil.toLowerCase().includes(filtro.toLowerCase())
    );
    setListaFiltrada(listaTemp);
    //console.log(listaTemp);
  }

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  const FiltroChange = (event) => {
    const valor = event.target.value;
    setFiltro(valor);
  };

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  return (
    <>
        {/* <!--Título da Página--> */}
        <h2 className="margin-bottom-10">
          Perfil
          <div className="btn-home">
            <a href="index.html" title="Home">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
          {/*<!--Home-->*/}
        </h2>

        {/* <!--Botão--> */}
        <div>
          <NavLink to={RotasURL.perfil.novo}>
            <button
              type="submit"
              className="templatemo-green-button margin-bottom-10"
            >
              Novo Perfil
            </button>
          </NavLink>
        </div>

        {/* </br> */}

        <div id="tblsetor_wrapper" className="dataTables_wrapper no-footer">
          {/* <!-- Registros por página -->

          // Pode virar componente */}
          <div
            className="dataTables_length"
            id="tblsetor_length"
            style={{ float: 'left' }}
          >
            <label>
              Mostrando
              <select
                name="tblsetor_length"
                aria-controls="tblsetor"
                className="input-select"
                value={numeroRegistros}
                onChange={registrosChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{' '}
              registros por página
            </label>
          </div>

          <div
            id="tblsetor_filter"
            className="dataTables_filter"
            style={{ float: 'right' }}
          >
            <label>
              Localizar:{' '}
              <input
                type="search"
                className="input-search"
                placeholder=""
                aria-controls="tblsetor"
                value={filtro}
                onChange={FiltroChange}
              />
            </label>
          </div>

          <TabelaPerfil
            lista={listaAtual}
            obterListaDePerfis={obterListaDePerfis}
          />

          <div className="row form-group">
            <div
              className="dataTables_info info_paginas"
              id="tblsetor_info"
              role="status"
              aria-live="polite"
            >
              {paginaAtual > 1 && (
                <button
                  className="templatemo-white-button"
                  onClick={diminuiPagina}
                >
                  &lt;
                </button>
              )}
              Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
              {paginaAtual < maxPagina && (
                <button
                  className="templatemo-white-button"
                  onClick={somarPagina}
                >
                  &gt;
                </button>
              )}
            </div>
          </div>
        </div>
    </>
  );
};

export default PerfilPage;
