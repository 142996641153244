import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Acomodacao/items';

// const usuarioLogado = 1;
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const AcomodacaoAPI = {
  obterListaAcomodacao: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pUsuario=${usuarioLogado}&pAtivo=1`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          // return null;
          throw error;
        });
      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  obterListaAcomodacaoPorSetor: async function (status, setorID) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      if (setorID == undefined) {
        return null;
      }
      const url = `/${base}/consultarporsetor?pStatusAcomodacao=${status}&pIdSetorToConsultar=${setorID}&pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log(url);
          return response.data;
        })
        .catch((error) => {
          throw error;
        });
      return retorno;
    } catch (error) {
      throw error;
    }
  },

  obterAcomodacaoPorCpf: async function(cpf){
    try{
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultar-leito-ocupado-por-cpf?pCPF=${cpf}&pUsuario=${usuarioLogado}`;

      let retorno = await get(url, headers).then((response) => {
        console.log(response.data);
        return response.data;
      }).catch((error) => {
        console.log(error);
        throw error;
      })

      return retorno;
    }catch(error){
      console.log(error);
      throw error;
    }
  },

  salvarAcomodacao: async function (acomodacaoJSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;
      ////console.log('Url Acomodacao Post: ', url);

      await post(url, acomodacaoJSON, null, headers)
        .then((response) => {
          //console.log(response);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      //console.log(error);
      // return null;
      throw error;
    }
  },

  atualizarAcomodacao: async function (idAcomodacao, idAtendimento) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/atualizaratendimento?pUsuario=${usuarioLogado}&pIdAcomodacaoToAtualizar=${idAcomodacao}&pIdAtendimento=${idAtendimento}`;
      ////console.log('Url Acomodacao Post: ', url);

      await post(url, null, null, headers)
        .then((response) => {
          //console.log(response);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      //console.log(error);
      // return null;
      throw error;
    }
  },

  excluirAcomodacao: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/excluir`;

      await DELETE(url, id, usuarioLogado, 'Acomodacao', headers)
        .then((response) => {
          // //console.log(response);
          // //console.log(`api deletado ${id}`);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      //console.log(error);
      throw error;
    }
  },

  obterTeste: function () {
    //console.log('teste');
  },
};
export default AcomodacaoAPI;
