import React, { useEffect, useState } from 'react';
import AtendimentoAPI from '../../Services/API/AtendimentoAPI';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';

const TabelaLiberacao = (lista) => {
  const [atendimentos, setAtendimentos] = useState([]);
  const [atendPaciente, setAtendPaciente] = useState([]);
  const [leitos, setLeitos] = useState([]);
  const [liberacao, setLiberacao] = useState([]);

  useEffect(() => {
    obterListaDeAtendimentos();
  }, []);

  useEffect(() => {
    if (Array.isArray(atendimentos)) {
      atendimentos.map((item, index) => {
        //console.log("ue atend");
      });
    }
  }, [atendimentos]);

  useEffect(() => {
    //console.log("ue atendpac", atendPaciente);
    //console.log(atendimentos);
    atendPaciente.map((item, index) => {
      item.then((value) => {
        //console.log(value);
      });
    });
  }, [atendPaciente]);

  async function obterListaDeAtendimentos() {
    try {
      let lista = await AtendimentoAPI.listarAltaMedicaSemLiberacao();
      setAtendimentos(lista);
      //console.log(lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  const changeCheck = (index) => {
    //console.log(liberacao);
    if (liberacao.some((id) => id == atendimentos[index].in_atendimento_id)) {
      liberacao.splice(
        liberacao.findIndex(
          (id) => id == atendimentos[index].in_atendimento_id
        ),
        1
      );
    } else {
      //console.log(atendimentos[index].in_atendimento_id);
      liberacao.push(atendimentos[index].in_atendimento_id);
    }
    //console.log(liberacao);
  };

  async function onSubmit() {
    try {
      await AtendimentoAPI.liberarAcomodacaoAltaMedica(liberacao);
      window.location.reload();
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  return (
    <>
      <table id="tblsetor" className="table table-responsive">
        <thead>
          <tr>
            <td></td>
            <td>Data</td>
            <td>Paciente</td>
            <td>Atendimento</td>
            <td>Leito</td>
            <td>Prescrição de alta</td>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(atendimentos) &&
            atendimentos.map((item, index) => {
              let lista = leitos.filter(
                (leito) => item.nv_nome_acomodacao == leito
              );
              if (!(lista.length > 0)) {
                leitos.push(item.nv_nome_acomodacao);
                console.log(item);
                return (
                  <tr className="linhaheight" key={index}>
                    <td style={{ textAlign: 'center' }}>
                      <input
                        onChange={() => changeCheck(index)}
                        type="checkbox"
                        id="check"
                        name="check"
                      />
                    </td>
                    <td>
                      {item.dt_data_alta.substring(8, 10) +
                        '/' +
                        item.dt_data_alta.substring(5, 7) +
                        '/' +
                        item.dt_data_alta.substring(0, 4)}
                    </td>
                    <td>{item.nv_nome_pessoafisica ?? ''}</td>
                    <td>{item.in_atendimento_id}</td>
                    <td>{item.nv_nome_acomodacao}</td>
                    <td>{item.nv_prescricao_alta ?? ''}</td>
                  </tr>
                );
              }
            })}
        </tbody>
      </table>

      <br></br>
      <br></br>

      <div className="form-group text-left">
        <button
          className="templatemo-blue-button"
          data-toggle="modal"
          data-target="#modalLiberar"
        >
          Liberar Leitos
        </button>
      </div>

      <div
        className="modal"
        id="modalLiberar"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Confirmar Liberação de Leito
              </h4>
              <button
                type="buttom"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body">
              Tem certeza que deseja liberar os leitos selecionados?
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button className="btn btn-primary" onClick={() => onSubmit()}>
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TabelaLiberacao;
