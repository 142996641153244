import { get, getWithParams, post } from './_http';

const base = 'acesso/items';

const AcessoAPI = {
  validaUsuario: async function (login, senha) {
    try {
      const url = `/${base}/validausuario?pLogin=${login}&pSenha=${senha}`;
      let retorno = null;

      await get(url)
        .then((response) => {
          //console.log('RESPOSTA DO BACKEND', response.data);
          retorno = response.data;
        })
        .catch((error) => {
          //console.log('error -> ', error);
        });

      return retorno;
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  esqueceuSenha: async function(email){
    const url = `/${base}/esqueci-senha?pEmail=${email}`;
    let retorno = null;
    await get(url).then((response) => {
      //console.log(response.data);
      retorno = response.data;
    }).catch((error) => {
      //console.log('Erro API esqueceu senha -> ', error)
    })
  }
};

export default AcessoAPI;
