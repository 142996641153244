import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { RotasURL } from 'RoutesURL';

import EspecialidadeAPI from 'Services/API/EspecialidadeAPI';

const CreateEspecialidadePage = (props) => {
    const [atualizando, setAtualizando] = useState(false);
    const [especialidade, setEspecialidade] = useState();

    const {
        setValue,
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    //Funções useEffect
    useEffect(() => {
        if(props.location.state){
            atualizaEspecialidade();
        };
    }, [])

    function atualizaEspecialidade(){
        let itemEspecialidade = props.location.state.item;
        console.log(itemEspecialidade);
        setEspecialidade(itemEspecialidade);
        setAtualizando(true);
    }

    const onSubmit = (dataJSON) => {
        let data = dataJSON;
        if(atualizando){
            data = especialidade;
            data.nome_Especialidade = dataJSON.nome_Especialidade;
        }
        data.nome_Especialidade = dataJSON.nome_Especialidade;
        console.log(data);
        EspecialidadeAPI.salvaEspecialidade(data).then(() => {
            alert("O cadastro foi finalizado com sucesso.");
        }).catch((error) => {
            console.log(error);
            alert("Erro durante o cadastro");
        })
    }

    return(
        <>
            {/* Header */}
            <h2 className='margin-bottom-10'>
                <Link to={RotasURL.especialidade.index}>
                    <i className="fa fa-arrow-alt-circle-left fa-fw"/>
                </Link>
                {atualizando ? 'Atualizar Especialidade' : 'Nova Especialidade'}

                <div className="btn-home">
                    <a href="">
                        <i className="fas fa-home fa-fw"/>
                    </a>
                </div>
            </h2>

            {/* Form */}
            <form className='templatemo-login-form' onSubmit={handleSubmit(onSubmit)}>
                <div className="row form-group">
                    <div className="col-lg-4 col-md-3 form-group">
                        <label>Especialidade</label>
                        <input
                            type="text"
                            className="form-control"
                            defaultValue={atualizando? especialidade.nome_Especialidade : undefined}
                            placeholder="Digite o nome"
                            {...register('nome_Especialidade', {})}
                        />
                    </div>
                </div>

                <div className="form-group text-left">
                    <button type="reset" className="templatemo-white-button">
                        Apagar
                    </button>
                    <button type="submit" className="templatemo-blue-button">
                        Salvar
                    </button>
                </div>
            </form>

        </>
    )
}

export default CreateEspecialidadePage;