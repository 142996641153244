import React from 'react';
import { useLocation } from 'react-router-dom';

const PaginaNaoEncontrada = () => {
  let location = useLocation();

  return (
    <div className="pagenotfound">
      <p>
        Não encontramos a página: <code>{location.pathname}</code>{' '}
      </p>
    </div>
  );
};

export default PaginaNaoEncontrada;
