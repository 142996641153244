import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AtributosAPI from '../../Services/API/AtributosAPI';
import GruposAPI from '../../Services/API/GruposAPI';

const TabelaAtributos = (lista) => {
  const [idExclusao, setIdExclusao] = useState();
  const [listaDinamica, setListaDinamica] = useState([]);
  const [grupos, setGrupos] = useState([])
  const [tipos, setTipos] = useState(["Texto","Check","Lista"])

  useEffect(() => {
    obterListaDeGrupos();
  }, []);

  useEffect(() => {
    setListaDinamica(lista);
  }, [lista]);

  function idChange(id){
    const idExclusao = id;
    setIdExclusao(idExclusao);
    //console.log(idExclusao);
  }

  function editar(data){
    //console.log(data);
    lista.atualizar(data);
  }

  function excluirAtributo(){
    //console.log(`deletando Atributo: ${idExclusao}`);
    AtributosAPI.excluirAtributos(idExclusao).then((resposta) =>{
      //console.log(resposta);
      //console.log("deletado");
      lista.obterListaDeAtributos();
    })
    .catch((err) => {
      //console.log(err);
    })
  }

  function obterGrupo(item){
    let lista = grupos.filter((grupo) =>{
      if(grupo.in_grupo_id == item.in_grupoinformacao_id) return true
    });
    try{
      return lista[0].nv_nome_grupo;
    }
    catch{
      return false;
    }
  }

  async function obterListaDeGrupos(){
    try{
        let lista = await GruposAPI.obterListaGrupo();
        setGrupos(lista)
        //console.log(lista);
    } catch(error){
        console.error(error);
        throw error;
    }
}
  
  return(
        <>
          {/* Pode virar componente */}
          <table id="tblsetor" className="table table-responsive">
            <thead>
              <tr>
                <td>GRUPO</td>
                <td>CAMPO</td>
                <td>TIPO</td>
                <td>DETALHES</td>
                <td>ASSISTENTE</td>
                <td>FAMÍLIA</td>
                <td>&nbsp;</td>
              </tr>
            </thead>
            <tbody>
              {
                listaDinamica.lista?.map((item, index) => {
                  if (grupos && obterGrupo(item)){
                    return(
                      <tr className="linhaheight" key={index}> 
                        <td>{obterGrupo(item)}</td>
                        <td>{item.nv_descricao_informacao}</td>
                        <td>{tipos[item.in_tipocontrole_id - 1]}</td>
                        <td>{item.nv_detalhe_informacao}</td>
                        <td>{item.in_assistente == 1? "✓":" "}</td>
                        <td>{item.in_familiar == 1? "✓":" "}</td>
                        <td style={{ textAlign: 'right' }}>

                          <Link to={{pathname:'/atributos_ronda', state: {item}}}>
                            <button
                              type="button" 
                              className="templatemo-black-button"
                              onClick={() => editar(item)}
                              >
                              <i className="fas fa-edit fa-fw"></i>                            
                            </button>
                          </Link>
                          
                          <button 
                            type="button" 
                            className="templatemo-black-button"
                            data-toggle="modal"
                            data-target="#modalExcluir"
                            onClick={() => idChange(item.in_informacao_id)}>
                            <i className="fas fa-trash fa-fw"></i>
                          </button>
                          
                        </td>
                      </tr>
                    )
                  }
                })
              }
            </tbody>
          </table>
          
          <div
            className="modal "
            id="modalExcluir"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                {/*Header*/}
                <div className="modal-header">
                  <h4 className="modal-title" id="myModalLabel">
                    Confirmar a exclusão do Atributo?
                  </h4>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                {/*Footer*/}
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-outline-primary"
                    data-dismiss="modal"
                    // onClick={() => deletePost(App.in_empresa_id)}
                  >
                    Cancelar
                  </button>
                  <button 
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => excluirAtributo()}>Confirmar</button>
                </div>
              </div>
            </div>
          </div>

        </>
    )
}

export default TabelaAtributos;