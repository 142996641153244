import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import CardAcomodacao from '../../Components/CardAcomodacao';

import SetorAPI from '../../Services/API/SetorAPI';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import AtendimentoAPI from '../../Services/API/AtendimentoAPI';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import { useHistory } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';

const PlantaoMedPage = () => {
  const [idSetor, setIdSetor] = useState();
  const [listaSetor, setListaSetor] = useState();
  const [listaAcomodacao, setListaAcomodacao] = useState([]);
  const [listaAtendimento, setListaAtendimento] = useState([]);
  const [listaPessoa, setListaPessoa] = useState([]);

  const history = useHistory();

  useEffect(() => {
    obterListaSetor();
  }, []);

  useEffect(() => {
    if (listaSetor) {
      setIdSetor(listaSetor[0].in_setor_id);
    }
  }, [listaSetor]);

  useEffect(() => {
    obterListaAcomodacao();
  }, [idSetor]);

  // useEffect(() => {
  //   let listaAtendimentoTemp = [];
  //   Array.isArray(listaAcomodacao) &&
  //     listaAcomodacao.map(async (acomodacao, index) => {
  //       let atendimento = await AtendimentoAPI.obterAtendimentoPorId(
  //         acomodacao.in_atendimento_id
  //       );
  //       listaAtendimentoTemp = listaAtendimentoTemp.concat(atendimento);
  //       setListaAtendimento([...listaAtendimentoTemp]);
  //     });
  // }, [listaAcomodacao]);

  // useEffect(() => {
  //   let listaPessoaTemp = [];
  //   Array.isArray(listaAtendimento) &&
  //     listaAtendimento.map(async (atendimento, index) => {
  //       let pessoa = await PessoaFisicaAPI.obterPessoaPorId(
  //         atendimento.in_paciente_id
  //       );
  //       listaPessoaTemp = listaPessoaTemp.concat(pessoa);
  //       setListaPessoa(listaPessoaTemp);
  //     });
  // }, [listaAtendimento]);

  async function obterListaSetor() {
    try {
      let lista = await SetorAPI.obterListaSetor();
      setListaSetor(lista);
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  async function obterListaAcomodacao() {
    try {
      let lista = await AcomodacaoAPI.obterListaAcomodacaoPorSetor(2, idSetor);
      if(lista) lista.sort((a, b) => a.in_acomodacao_id > b.in_acomodacao_id ? 1 : -1);
      setListaAcomodacao(lista);
    } catch (error) {
      // //console.log('Erro na listagem de acomodaçao ->', error);
      history.push({ pathname: '/login' });
    }
  }

  function changeId(event) {
    setIdSetor(event.target.value);
  }

  return (
    <>
      <h2 className="margin-bottom-10">
        Plantão Médico
        <div className="btn-home">
          <a href="po_main">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      <div className="row form-group">
        <div className="col-lg-4 col-md-4 form-group">
          <label>Setor</label>
          <select className="form-control" value={idSetor} onChange={changeId}>
            <option value="">---</option>
            {listaSetor &&
              listaSetor.map((setor, index) => {
                return (
                  <option key={index} value={setor.in_setor_id}>
                    {setor.nv_nome_setor}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          {Array.isArray(listaAcomodacao) &&
            listaAcomodacao.map((acomodacao, index) => {
              // let atendimento = listaAtendimento.filter(
              //   (atendimento) =>
              //     atendimento.in_atendimento_id == acomodacao.in_atendimento_id
              // );

              //console.log('!!! ', atendimento);

              // if (atendimento.length > 0) {
                // let pessoa = listaPessoa.filter(
                //   (pessoa) =>
                //     pessoa.in_pessoafisica_id == atendimento[0].in_paciente_id
                // );
                // //console.log({ atendimento });
                // //console.log(pessoa);

                //console.log('Atendimento : ');
                let updatedURL;
                let idAtendimento = acomodacao.in_atendimento_id;

                if (idAtendimento !== undefined) {
                  updatedURL = RotasURL.plantaoMedico.atendimento.replace(
                    ':idAtendimento',
                    idAtendimento
                  );
                  //console.log({ updatedURL });
                }

                return (
                  <Link
                    to={{
                      pathname: updatedURL,
                      // state: { acomodacao, idSetor },
                    }}
                    key={index}
                  >
                    {/* <Link
                    to={{
                      pathname: 'po_plantao_rotina',
                      state: { acomodacao, idSetor },
                    }}
                  > */}
                    <CardAcomodacao acomodacao={acomodacao} />
                  </Link>
                );
              }
            )}
        </div>
      </div>
    </>
  );
};

export default PlantaoMedPage;
