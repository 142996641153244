import { Route, Redirect } from 'react-router-dom';

//TODO: Obter da SessionStorage
function isLoggedIn() {
  return true;
}

const RotaPublicaComLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;
  const { restricted } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isLoggedIn() && restricted ? (
          <Redirect to="/" />
        ) : (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        )
      }
    />
  );
};

export default RotaPublicaComLayout;
