import React from 'react';
// import MainAdmDashboard from 'Components/MainDashboard/MainAdmDashboard';
import MainOperDashboard from 'Components/MainDashboard/MainOperDashboard';

const POMainPage = () => {
  return (
    <>
      <MainOperDashboard />
    </>
  );
};

export default POMainPage;
