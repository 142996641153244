import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import TabelaEspecialidade from '../../Components/Tabela/TabelaEspecialidade';

import UsuarioAPI from '../../Services/API/UsuarioAPI';

import { RotasURL } from 'RoutesURL';
import EspecialidadeAPI from 'Services/API/EspecialidadeAPI';

const EspecialidadePage = () => {
    const [listaInicial, setListaInicial] = useState([]);
    const [listaFiltrada, setListaFiltrada] = useState([]);
    const [listaAtualEspecialidade, setListaAtualEspecialidade] = useState([]);

    const [numeroRegistros, setNumeroRegistros] = useState(5);
    const [paginaAtual, setPaginaAtual] = useState(1);
    const [maxPagina, setMaxPagina] = useState();

    const [filtro, setFiltro] = useState('');

    //useEffects
        //Quando carregar a pagina
    useEffect(() => {
        obterListaDeEspecialidades();
    }, []);

        //Quando o filtro é alterado
    useEffect(() => {
        filtraEspecialidade();
    }, [filtro, listaInicial]);

    useEffect(() => {
        setPaginaAtual('1');
    }, [maxPagina]);

    useEffect(() => {
        controlaPagina();
    }, [numeroRegistros, paginaAtual, listaFiltrada]);

    //Funções onChange
    function registrosChange(event) {
        setNumeroRegistros(event.target.value);
    }

    function FiltroChange(event) {
        setFiltro(event.target.value);
    }

    async function obterListaDeEspecialidades(){
        try{
            console.log('tentando obter lista de especialidades');
            let lista = await EspecialidadeAPI.obterListaEspecialidade();
            setListaAtualEspecialidade(lista);
            setListaInicial(lista);
        }catch(error){
            console.log("Erro ao carregar Especialidades");
        }
    }

    function filtraEspecialidade(){
        let listaTemp = listaInicial.filter((especialidade) =>
            especialidade.nome_Especialidade.toLowerCase().includes(filtro.toLowerCase())
        );
        setListaFiltrada(listaTemp);
    }

    function controlaPagina() {
        let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
        let ultimoIndice = paginaAtual * numeroRegistros;
        var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
        setMaxPagina(numPaginas);
        if (
          listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
          numeroRegistros
        ) {
          setListaAtualEspecialidade(listaFiltrada.slice(primeiroIndice));
        } else {
          setListaAtualEspecialidade(listaFiltrada.slice(primeiroIndice, ultimoIndice));
        }
    }

    function somarPagina() {
        let proximaPagina = parseInt(paginaAtual) + 1;
        console.log(paginaAtual);
        console.log(proximaPagina);
        setPaginaAtual(proximaPagina);
    }

    function diminuiPagina() {
        let proximaPagina = paginaAtual - 1;
        setPaginaAtual(proximaPagina);
    }

    return(
        <>
            <h2 className='margin-bottom-10'>
                Especialidade
                <div className='btn-home'>
                    <a href='index.html' title='Home'>
                        <i className='fas fa-home fa-fw'/>
                    </a>
                </div>
            </h2>

            <NavLink to={RotasURL.especialidade.novo} title='Especialidade'>
                <button className='templatemo-green-button margin-bottom-10'>
                    Nova Especialidade
                </button>
            </NavLink>

            <div id="tblsetor_wrapper" className="dataTables_wrapper no-footer">
          {/* <!-- Registros por página -->
            // Pode virar componente */}
          <div
            className="dataTables_length"
            id="tblsetor_length"
            style={{ float: 'left' }}
          >
            <label>
              Mostrando
              <select
                name="tblsetor_length"
                aria-controls="tblsetor"
                className="input-select"
                value={numeroRegistros}
                onChange={registrosChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{' '}
              registros por página
            </label>
          </div>

          <div
            id="tblsetor_filter"
            className="dataTables_filter"
            style={{ float: 'right' }}
          >
            <label>
              Localizar:{' '}
              <input
                type="search"
                name="filtro"
                className="input-search"
                placeholder=""
                value={filtro}
                onChange={FiltroChange}
                aria-controls="tblsetor"
              />
            </label>
          </div>

          <TabelaEspecialidade
            lista={listaAtualEspecialidade}
            obterListaDeEspecialidades={obterListaDeEspecialidades}
          />

          <div className="row form-group">
              <div
                  className="dataTables_info info_paginas"
                  id="tblsetor_info"
                  role="status"
                  aria-live="polite"
              >
              {paginaAtual > 1 && (
                  <button
                      className="templatemo-white-button"
                      onClick={diminuiPagina}
                  >
                      &lt;
                  </button>
              )}
              Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
              {paginaAtual < maxPagina && (
                <button className="templatemo-white-button" onClick={somarPagina}>
                  &gt;
                </button>
              )}
            </div>
          </div>
        </div>
        </>
    )
}

export default EspecialidadePage;