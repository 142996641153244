import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

import axios from 'axios';
import { BackendApiURL } from '../../settings';

const base = 'RotinaMedica/items';

// const usuarioLogado = 1;
let usuarioLogado = 0;
const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 0;
};

const RotinaMedicaAPI = {
  obterListaRotina: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  obterListaRotinaPorAtendimento: async function (pAtendimento) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listarporatendimento?pUsuario=${usuarioLogado}&pAtendimento=${pAtendimento}`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
          // throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  salvarRotina: async function (Json) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;
      //console.log('Url Post: ', url);

      const response = await post(url, Json).then((response) => {
        //console.log(response);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  prepararRotina: async function (Json) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/preparar-nova-rotina?pUsuario=${usuarioLogado}`;
      // //console.log('Url Post: ', url);

      //testar
      const pParams = {
        pUsuario: usuarioLogado,
      };

      const response = await post(url, Json, null, headers)
        .then((response) => {
          //console.log(response);
          return response.data;
        })
        .catch((err) => {
          throw err;
        });

      return response;
    } catch (error) {
      //console.log(error);
      // return null;
      throw error;
    }
  },

  atualizarRotina: async function (Json, id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/atualizar-respostas-rotina?pUsuario=${usuarioLogado}&pRotinaId=${id}`;
      // //console.log('@@@ Url Post: ', url);
      // console.log('@@@ Json enviado: ', { Json });
      // console.log('@@@ Json enviado: ', JSON.stringify(Json));

      const response = await post(url, Json).then((response) => {
        //console.log('@@@ Resposta da Atualizacao dos campos: ', response);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  consultarRespostas: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultar-respostas-rotina?pUsuario=${usuarioLogado}&pRotinaId=${id}`;
      // //console.log('URL: ', url);

      const retorno = await post(url)
        .then((response) => {
          //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  copiarRotina: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/copiar-rotina?pUsuario=${usuarioLogado}&pRotinaId=${id}`;
      // //console.log('URL: ', url);

      const retorno = await post(url)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  cabecalhoRotina: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/obter-cabecalho?pUsuario=${usuarioLogado}&pAtendimento=${id}`;
      // //console.log('URL: ', url);

      const retorno = await get(url)
        .then((response) => {
          //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  consultarRotina: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/consultar?pUsuario=${usuarioLogado}&pRotinaId=${id}`;
      // //console.log('URL: ', url);

      const retorno = await get(url)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //console.log('Error consultarRotina -> ', error);
          // return null;
          throw Error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  listarMedicamentos: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/CadMatMed/items/medicamentos/listar?pUsuario=${usuarioLogado}&pAtivo=1`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  salvarMedicamentos: async function (JSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/CadMatMed/items/salvar?pUsuario=${usuarioLogado}&pAtivo=1`;
      // //console.log('URL: ', url);

      const retorno = await post(url, JSON, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  salvarMedicamentosNovo: async function (JSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      // const url = `/CadMatMed/items/salvar?pUsuario=${usuarioLogado}&pAtivo=1`;
      const url = `/CadMatMed/items/medicamentos/salvar?pUsuario=${usuarioLogado}&pAtivo=1`;
      // //console.log('URL: ', url);

      const retorno = await post(url, JSON, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return err;
      throw err;
    }
  },

  salvarMedComDiluicao: async function (JSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      // const url = `/CadMatMed/items/salvar?pUsuario=${usuarioLogado}&pAtivo=1`;
      const url = `/CadMatMed/items/medicamentos/salvar-com-diluicao?pUsuario=${usuarioLogado}&pAtivo=1`;
      // //console.log('URL: ', url);

      const retorno = await post(url, JSON, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  listarDispositivos: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/CadMatMed/items/dispositivos/listar?pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (error) {}
  },

  obterAnexosAtendimento: async function (pAtendimento) {
    // //console.log('obterAnexosAtendimento. Atendimento - ', pAtendimento);

    if (pAtendimento == null) {
      //console.log('RotinaMedicaAPI.obterAnexosAtendimento - atendimento nulo');
      return null;
    }

    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/RotinaMedica/items/listar-arquivos-db-atendimento?pAtendimento=${pAtendimento}&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url)
        .then((response) => {
          // //console.log('Response Anexos -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  atualizaItemRotinaByItem: function (rotinaId, infoId, observacao) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/RotinaMedica/items/atualizar-item-rotina?pRotinaId=${rotinaId}&in_informacao_id=${infoId}&valor=${observacao}&pUsuario=${usuarioLogado}`;

      const parametros = {
        pRotina: rotinaId,
        in_informacao_id: infoId,
        valor: observacao,
      };

      const retorno = post(url, null, null, headers)
        .then((response) => {
          return response;
        })
        .catch((error) => {
          return null;
        });

      return retorno;
    } catch (err) {
      return null;
    }
  },

  baixarAnexosAtendimento: function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `${BackendApiURL}/${base}/baixar-arquivo-ronda?gd_rotinaupload_id=${id}&pUsuario=${usuarioLogado}`;
      // console.log('!!!', url);
      const retorno = axios
        .get(url, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream',
            // 'Accept': 'application/json',
          },
        })
        .then((response) => {
          // console.log('!!!', response);
          return response;
        })
        .catch((error) => {
          // console.log('!!!', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  salvarAnexosAtendimento: async function (pAtendimento, pFile) {
    ////console.log('salvarAnexosAtendimento. Atendimento - ', pAtendimento);
    //event.preventDefault();

    if (pAtendimento == null) {
      return null;
    }

    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        accept: '*/*',
      };

      const formData = new FormData();
      formData.append('files', pFile);

      const url = `/RotinaMedica/items/subir-arquivos-db?pAtendimento=${pAtendimento}&pUsuario=${usuarioLogado}`;

      const retorno = await post(url, formData, null, headers)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          return null;
        });

      //console.log('Retorno: ', retorno);
      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  obterRespostasUltimaRotina: async function (pAtendimento) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/obter-ultimas-respostas-plantao-medico?pUsuario=${usuarioLogado}&pAtendimento=${pAtendimento}`;
      // http://localhost:5001/api/RotinaMedica/items/obter-ultimas-respostas-plantao-medico?pAtendimento=71&pUsuario=1

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('@@@ Response Ultimas Respostas -> ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      //console.log('CATCH:  ', err);
      return null;
    }
  },

  obterInformacoesDefault: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/obter-informacoes-default?pUsuario=${usuarioLogado}`;
      // http://localhost:5001/api/RotinaMedica/items/obter-ultimas-respostas-plantao-medico?pAtendimento=71&pUsuario=1

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('@@@ Response Informacoes Default -> ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      //console.log('CATCH:  ', err);
      return null;
    }
  },

  finalizarConduta: async function (id, jsonData, resposta, finalizar) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/finalizar-conduta-rotina?pUsuario=${usuarioLogado}&pRotinaId=${id}&pResposta=${resposta}&pFinaliza=${finalizar}`;
      console.log('URL: ', url);

      const retorno = await post(url, jsonData, null, headers)
        .then((response) => {
          console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      return null;
    }
  },

  listarEspecialidades: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar-especialidades?pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          return null;
        });

      return retorno;
    } catch (error) {}
  },
};

export default RotinaMedicaAPI;
