import React from 'react';

import MainAdmDashboard from 'Components/MainDashboard/MainAdmDashboard';
import MainOperDashboard from 'Components/MainDashboard/MainOperDashboard';

const PAMainPage = () => {
  return (
    <>
      <MainAdmDashboard />
    </>
  );
};

export default PAMainPage;
