import React from 'react';

const CardAcomodacao = (props) => {

  let rotinaHoje = false;

  let CurrentUTCDateTime = new Date();
  CurrentUTCDateTime.setHours(CurrentUTCDateTime.getHours() - 3);

  const hojeISO = CurrentUTCDateTime.toISOString().slice(0, 10);
  const dt_ultima_rotina_lib = props.acomodacao?.dt_ultima_rotina_lib?.slice(0, 10) || "";
  // console.log(`${hojeISO} / ${dt_ultima_rotina_lib}`)

  if (dt_ultima_rotina_lib === hojeISO) {
    rotinaHoje = true;
    // console.log("Tem rotina hoje")
    // console.log(props.acomodacao)
  }

  return (
    <>
      {/* {console.log(props)} */}
      <div className="col-xxl-1 col-xl-2 col-lg-3 col-md-4 col-sm-6 col-xs-12 box-home">
        <div className={rotinaHoje ? 'box-mov-2' : 'box-mov'}>
          <div className={rotinaHoje ? 'icon-mov-2' : 'icon-mov'}>
            <i className="fa fa-bed fa-fw" />
          </div>
          <div className={rotinaHoje ? 'content-mov-2' : 'content-mov'}>
            <strong>{props.acomodacao.nv_nome_acomodacao}</strong>
            {/* <div>{props.pessoa[0] && props.pessoa[0].nv_nome_pessoafisica}</div> */}
            <div>{props.acomodacao.nv_nome_paciente}</div>
            {props.acomodacao.in_atendimento_id ? (
              <>
                <div>Atendimento: {props.acomodacao.in_atendimento_id}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CardAcomodacao;
