import React, { useEffect, useState } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import TabelaUsuario from '../../Components/Tabela/TabelaUsuario';

import UsuarioAPI from '../../Services/API/UsuarioAPI';

import { RotasURL } from 'RoutesURL';

const UsuarioPage = () => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtualUsuario, setListaAtualUsuario] = useState([]);

  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();

  const [filtro, setFiltro] = useState('');

  const history = useHistory();

  useEffect(() => {
    obterListaDeUsuarios();
  }, []);

  useEffect(() => {
    filtraUsuario();
  }, [filtro, listaInicial]);

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    ////console.log(numeroRegistros);
    ////console.log(listaAtual.length);
    // //console.log('pos filtro');
    // //console.log(listaFiltrada);
    controlaPagina();
  }, [numeroRegistros, paginaAtual, listaFiltrada]);

  async function obterListaDeUsuarios() {
    try {
      let lista = await UsuarioAPI.obterListaUsuario();
      setListaAtualUsuario(lista);
      setListaInicial(lista);
      //console.log(lista);
    } catch (error) {
      console.error(error);
      history.push({ pathname: RotasURL.pa_main });
      // throw error;
    }
  }

  function filtraUsuario() {
    let listaTemp = listaInicial.filter((usuario) =>
      usuario.nv_login.toLowerCase().includes(filtro.toLowerCase())
    );
    setListaFiltrada(listaTemp);
    ////console.log(filtro);
  }

  const FiltroChange = (event) => {
    const valor = event.target.value;
    setFiltro(valor);
    ////console.log(filtro);
  };

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  function controlaPagina() {
    // //console.log('controla page');
    // //console.log(listaFiltrada);
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;
    var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtualUsuario(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtualUsuario(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  return (
    <>
        <h2 className="margin-bottom-10">
          Usuário
          <div className="btn-home">
            <a href="index.html" title="Home">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
          {/*<!--Home-->*/}
        </h2>

        {/* <!--Botão--> */}
        <div>
          <NavLink to={RotasURL.pessoaFisica.escolher_pf} title="Usuário">
            <button
              type="submit"
              className="templatemo-green-button margin-bottom-10"
            >
              Novo Usuário
            </button>
          </NavLink>
        </div>

        <div id="tblsetor_wrapper" className="dataTables_wrapper no-footer">
          {/* <!-- Registros por página -->
            // Pode virar componente */}
          <div
            className="dataTables_length"
            id="tblsetor_length"
            style={{ float: 'left' }}
          >
            <label>
              Mostrando
              <select
                name="tblsetor_length"
                aria-controls="tblsetor"
                className="input-select"
                value={numeroRegistros}
                onChange={registrosChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{' '}
              registros por página
            </label>
          </div>

          <div
            id="tblsetor_filter"
            className="dataTables_filter"
            style={{ float: 'right' }}
          >
            <label>
              Localizar:{' '}
              <input
                type="search"
                name="filtro"
                className="input-search"
                placeholder=""
                value={filtro}
                onChange={FiltroChange}
                aria-controls="tblsetor"
              />
            </label>
          </div>

          <TabelaUsuario
            lista={listaAtualUsuario}
            obterListaDeUsuarios={obterListaDeUsuarios}
          />
          
          <div className="row form-group">
              <div
                  className="dataTables_info info_paginas"
                  id="tblsetor_info"
                  role="status"
                  aria-live="polite"
              >
              {paginaAtual > 1 && (
                  <button
                      className="templatemo-white-button"
                      onClick={diminuiPagina}
                  >
                      &lt;
                  </button>
              )}
              Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
              {paginaAtual < maxPagina && (
                <button className="templatemo-white-button" onClick={somarPagina}>
                  &gt;
                </button>
              )}
            </div>
          </div>
        </div>
    </>
  );
};

export default UsuarioPage;
