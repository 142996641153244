// let CORE_API_URL = "https://exporondabackend.azurewebsites.net/api";
let CORE_API_URL = "http://localhost:53184/api";

if (process.env.REACT_APP_BASE?.toUpperCase() === "PRODUCAO") {
  CORE_API_URL = "https://rondabackend.azurewebsites.net/api";
}

if (process.env.REACT_APP_BASE?.toUpperCase() === "DESENV") {
  CORE_API_URL = "https://exporondabackend.azurewebsites.net/api";
}

if (process.env.REACT_APP_BASE?.toUpperCase() === "LOCAL") {
  CORE_API_URL = "http://localhost:53184/api";
}

export const PERFIL_ENUM = {
  admin: 1,
  operacional: 2,
};

// export let BackendApiURL = BUILD_DATA.CORE_API_URL;
export let BackendApiURL = CORE_API_URL;
