import React from 'react';

import IconLogo from 'Components/IconLogo/IconLogo';
import SidebarOperacional from 'Components/Sidebar/SidebarOperacional';

const LayoutOperacional = (props) => {
  const { children } = props;

  return (
    <>
      <div className="templatemo-flex-row">
        {/* <!-- Lateral --> */}
        <div className="templatemo-sidebar">
          <header className="templatemo-site-header">
            {/* <!--div className="square"></div--> */}
            <IconLogo />
            {/* <!--svg className="logo-LG" alt="Logo Gestão de Leitos">Gestão de Leitos</svg--> */}
            <h1 id="h1-painel">Painel Operacional</h1>
          </header>

          <div className="mobile-menu-icon">
            <i className="fas fa-bars"></i>
          </div>

          {/* <!--Menu Lateral--> */}
          <SidebarOperacional />
        </div>

        {/* <!-- Main content --> */}
        <div className="templatemo-content col-1 light-gray-bg">
          {/* <!--Fundo principal--> */}
          <div className="templatemo-content-container">
            <div className="templatemo-content-widget black-bg">{children}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LayoutOperacional;
