import * as yup from 'yup';
export const schema = yup.object().shape({
  nv_nome_acomodacao: yup
    .string()
    .required('*Campo obrigatório.')
    .min(5, 'Deve ter no mínimo 5 caracteres.')
    .max(50, 'Deve ter no máximo 50 caracteres.'),
  nv_codexterno_acomodacao: yup.string(),
  in_empresa_id: yup.string(),
  in_setor_id: yup.string(),
  nv_cod_isolamento: yup.string(),
  //tp_acomodacao: yup.number(),
});
