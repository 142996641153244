import * as yup from 'yup';

export const schema = yup.object().shape({
  nv_nome_pessoafisica: yup.string(),
  nv_cpf_pessoafisica: yup.string(),
  nv_ddd_pessoafisica: yup.string(),
  nv_telefone_pessoafisica: yup.string(),
  dt_nascimento_pessoafisica: yup.string(),
  in_paciente: yup.string(),
});
