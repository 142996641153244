export function formataDataBR(stringDate) {
  if (stringDate === undefined) return '';
  if (stringDate === null) return '';

  try {
    let novadata = new Date(stringDate).toLocaleDateString('pt-br');
    return novadata;
  } catch (error) {
    return '';
  }
}
