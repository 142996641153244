import { Link } from 'react-router-dom';
import CardAcomodacao from '../CardAcomodacao';

// import CardAcomodacao from '../../../../Components/CardAcomodacao';

const ListaCardAcomodacao = ({
  listaAcomodacao,
  // listaAtendimento,
  // listaPessoa,
  // idSetor,
  pathname,
  linkState,
}) => {
  return (
    <>
      {Array.isArray(listaAcomodacao) &&
        listaAcomodacao.map((acomodacao, index) => {
          // let atendimento = listaAtendimento.filter(
          //   (atendimento) =>
          //     atendimento.in_atendimento_id == acomodacao.in_atendimento_id
          // );
          // if (atendimento[0]) {
          //   let pessoa = listaPessoa.filter(
          //     (pessoa) =>
          //       pessoa.in_pessoafisica_id == atendimento[0].in_paciente_id
          //   );

            let updatedPath = pathname.replace(
              ':idAtendimento',
              acomodacao.in_atendimento_id
            );

            // //console.log({ atendimento });
            // //console.log({ updatedPath });

            return (
              <Link
                to={{
                  pathname: updatedPath,
                  state: { acomodacao, ...linkState },
                }}
              >
                {/* <CardAcomodacao acomodacao={acomodacao} pessoa={pessoa} /> */}
                <CardAcomodacao acomodacao={acomodacao} />
              </Link>
            );
          }
        )}
    </>
  );
};

export default ListaCardAcomodacao;
