import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import LayoutOperacional from '../../Components/Layout/Operacional';
import CardAcomodacao from '../../Components/CardAcomodacao';

import SetorAPI from '../../Services/API/SetorAPI';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import AtendimentoAPI from '../../Services/API/AtendimentoAPI';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AltaPage = () => {
  const [idSetor, setIdSetor] = useState();
  const [listaSetor, setListaSetor] = useState();
  const [listaAcomodacao, setListaAcomodacao] = useState([]);
  const [listaAtendimento, setListaAtendimento] = useState([]);
  const [listaPessoa, setListaPessoa] = useState([]);
  const [atendimento, setAtendimento] = useState();
  const [atualizando, setAtualizando] = useState(false);

  const [prescricao, setPrescricao] = useState('');
  const [data, setData] = useState();

  const history = useHistory();

  useEffect(() => {
    obterListaSetor();
  }, []);

  useEffect(() => {
    if (listaSetor) {
      //console.log(listaSetor[0].in_setor_id);
      setIdSetor(listaSetor[0].in_setor_id);
    }
  }, [listaSetor]);

  useEffect(() => {
    obterListaAcomodacao();
  }, [idSetor]);

  // useEffect(() => {
  //   //console.log(listaPessoa);
  //   let listaAtendimentoTemp = [];
  //   Array.isArray(listaAcomodacao) &&
  //     listaAcomodacao.map(async (acomodacao, index) => {
  //       let atendimento = await AtendimentoAPI.obterAtendimentoPorId(
  //         acomodacao.in_atendimento_id
  //       );
  //       listaAtendimentoTemp = listaAtendimentoTemp.concat(atendimento);
  //       setListaAtendimento([...listaAtendimentoTemp]);
  //     });
  // }, [listaAcomodacao]);

  // useEffect(() => {
  //   let listaPessoaTemp = [];
  //   //console.log(listaAtendimento);
  //   //console.log(Array.isArray(listaAtendimento));
  //   Array.isArray(listaAtendimento) &&
  //     listaAtendimento.map(async (atendimento, index) => {
  //       //console.log('dentro do map de atendimento: ', atendimento);
  //       let pessoa = await PessoaFisicaAPI.obterPessoaPorId(
  //         atendimento.in_paciente_id
  //       );
  //       listaPessoaTemp = listaPessoaTemp.concat(pessoa);
  //       setListaPessoa(listaPessoaTemp);
  //     });
  // }, [listaAtendimento]);

  async function obterListaSetor() {
    try {
      let lista = await SetorAPI.obterListaSetor();
      setListaSetor(lista);
      //console.log('Setores: ', lista);
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  async function obterListaAcomodacao() {
    //console.log('test');
    try {
      //console.log('obtendo lista de acomodao com id: ', idSetor);
      let lista = await AcomodacaoAPI.obterListaAcomodacaoPorSetor(2, idSetor);
      // //console.log('Acomodacao:', lista);
      if(lista) lista.sort((a, b) => a.in_acomodacao_id > b.in_acomodacao_id ? 1 : -1);
      setListaAcomodacao(lista);
    } catch (error) {
      // //console.log('Erro na listagem de acomodaçao ->', error);
      history.push({ pathname: '/login' });
    }
  }

  async function obterAlta(id) {
    //console.log(id);
    setData("");
    setPrescricao("");
    setAtendimento(id);
    try {
      //console.log('obtendo alta');
      let altaTemp = await AtendimentoAPI.consultarAltaMedica(id);
      setData(altaTemp[altaTemp.length - 1].dt_data_alta.substring(0, 10));
      setPrescricao(altaTemp[altaTemp.length - 1].nv_prescricao_alta);
      if (altaTemp) {
        setAtualizando(true);
      }
    } catch (error) {
      //console.log('Não possui alta');
    }
  }

  function changeId(event) {
    setListaPessoa([]);
    setListaAcomodacao(null);
    setIdSetor(event.target.value);
  }

  function changePrescricao(event) {
    setPrescricao(event.target.value);
  }

  function changeData(event) {
    setData(event.target.value);
  }

  async function onSubmit() {
    if (!data) {
      alert('É necessário preencher a data.');
    } else {
      try {
        if (atualizando) {
          await AtendimentoAPI.excluirAltaMedica(atendimento);
        }

        await AtendimentoAPI.salvarAltaMedica(atendimento, data, prescricao)
          .then(() => {
            alert('Previsão de Alta médica salva com sucesso.');
          })
          .catch((err) => {
            throw err;
          });
        
      } catch (error) {
        //console.log(error);
        // throw error;
        alert('Ocorreu algum erro ao salvar alta médica.', error);
      }
    }
  }

  return (
    <>
      <h2 className="margin-bottom-10">
        Alta de Pacientes
        <div className="btn-home">
          <a href="po_main">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      <div className="row form-group">
        <div className="col-lg-4 col-md-4 form-group">
          <label>Setor</label>
          <select className="form-control" value={idSetor} onChange={changeId}>
            {listaSetor &&
              listaSetor.map((setor, index) => {
                return (
                  <option key={index} value={setor.in_setor_id}>
                    {setor.nv_nome_setor}
                  </option>
                );
              })}
          </select>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          {Array.isArray(listaAcomodacao) &&
            listaAcomodacao.map((acomodacao, index) => {
              // let tempAtendimento = listaAtendimento.filter(
              //   (atendimento) =>
              //     atendimento.in_atendimento_id == acomodacao.in_atendimento_id
              // );

              // let pessoa = listaPessoa.filter(
              //   (pessoa) =>
              //     pessoa.in_pessoafisica_id == tempAtendimento[0].in_paciente_id
              // );

              return (
                <a
                  data-toggle="modal"
                  data-target="#modalPaciente"
                  style={{ cursor: 'pointer' }}
                  onClick={() => obterAlta(acomodacao.in_atendimento_id)}
                >
                  <CardAcomodacao acomodacao={acomodacao} />
                </a>
              );
            })}
        </div>
      </div>

      <div
        className="modal"
        id="modalPaciente"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="myModalLabel">
                Confirmar Alta Médica
              </h4>
              <button
                type="buttom"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>

            <div className="row">
              <label style={{ marginLeft: 15 }}>
                Data de Previsão da Alta Médica:
                <div className="form-group">
                  <input
                    className="form-control"
                    type="date"
                    value={data}
                    onChange={changeData}
                  ></input>
                </div>
              </label>

              <div className="form-group" style={{ marginLeft: 15 }}>
                <textarea
                  className="form-control"
                  type="text"
                  placeholder="Prescrição de Alta"
                  value={prescricao}
                  onChange={changePrescricao}
                ></textarea>
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-primary"
                data-dismiss="modal"
              >
                Cancelar
              </button>
              <button
                className="btn btn-primary"
                onClick={onSubmit}
                data-dismiss="modal"
              >
                Confirmar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AltaPage;
