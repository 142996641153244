import React from 'react';
import { HashRouter, BrowserRouter, Switch } from 'react-router-dom';

// Páginas
// import Acomodacao_nova from './Pages/Acomodacao_nova/NovaAcomodacaoPage';
//import Acomodacao_nova from './Pages/Acomodacao_nova/NovaAcomodacaoPage';
// import Setor_novo from './Pages/Setor_novo/NovoSetorPage';
// import Empresa_nova from './Pages/Empresa_nova/novaEmpresaPage';
//import Atributos_ronda from './Pages/Atributos_ronda';
//import Incluir_pessoa from './Pages/Incluir_pessoa/incluirPessoaPage';
import EsqueceuSenha from './Pages/Login/EsqueceuSenhaPage';

import po_rotina_ultima from './Pages/po_rotina_ultima/UltimaRotinaMedica';
//import po_rotinas from './Pages/po_rotinas';

// Novas Rotas
import Login from './Pages/Login/LoginPage';
import PAMainPage from './Pages/pa_main/PAMainPage';
import POMainPage from './Pages/po_main/POMainPage';
// # Acomodação
import Acomodacao from 'Pages/Acomodacao/AcomodacaoPage';
import Acomodacao_nova from 'Pages/Acomodacao/NovaAcomodacaoPage';

// Especialidade
import Especialidade from './Pages/Especialidade/EspecialidadePage';
import CreateEspecialidade from './Pages/Especialidade/CreateEspecialidadePage';

// # Setor
import Setor from './Pages/Setor/SetorPage';
import Setor_novo from './Pages/Setor/NovoSetorPage';
// # Empresa
import Empresa from './Pages/Empresa/empresaPage';
import Empresa_nova from './Pages/Empresa/novaEmpresaPage';
// # Perfil
import Perfil from './Pages/Perfil/PerfilPage';
import Perfil_novo from './Pages/Perfil/Perfil_novoPage';
// # Usuario
import Usuario from './Pages/Usuario/UsuarioPage';
import Usuario_novo from './Pages/Usuario/NovoUsuarioPage';
import PessoaFisica from './Pages/PessoaFisica/PessoaFisicaPage';
import PessoaFisicaNova from './Pages/PessoaFisica/IncluirPessoaPage';
import EscolherPessoaFisicaPage from './Pages/Usuario/EscolherPessoaFisicaPage';
// # Admissão e Movimentação
import po_admissao_paciente from './Pages/po_admissao_paciente/AdmissaoPage';
import po_movimentacao_paciente from './Pages/po_movimentacao_paciente/MovimentacaoPage';
import po_movimentacao_paciente_2 from './Pages/po_movimentacao_paciente_2/MovimentacaoDestinoPage';
// # Grupos Ronda
import Grupos_ronda from './Pages/Grupos_ronda/GrupoPage';
import Grupos_ronda_novo from './Pages/Grupos_ronda_novo/Grupo_novoPage';
// # Atributos Ronda
import Atributos_ronda from './Pages/Atributos_ronda/AtributosPage';
// # Rotina Médica
import po_rotina_medica from './Pages/po_rotina_medica/RotinaMedicaMainPage';
import po_rotina_medica_atendimento2 from './Pages/po_rotina_medica/RotinaMedicaAtendimentoPage2';
import po_rotina_nova2 from './Pages/po_rotina_nova/NovaRotinaMedicaPage2';
// import po_rotina_medica_atendimento from './Pages/po_rotina_medica/RotinaMedicaAtendimentoPage';
// import po_rotina_nova from './Pages/po_rotina_nova/NovaRotinaMedicaPage';
// # Plantão Médico
import po_plantao_medico from './Pages/po_plantao_medico/PlantaoMedPage';
import po_plantao_rotina from './Pages/po_plantao_rotina/PlantaoRotPage';
// # Alta Médica
import po_alta_medica from './Pages/po_alta_medica/AltaPage';
// # Liberação de Leito
import po_liberacao_leito from './Pages/po_liberacao_leito/liberacaoPage';

// Rotas
import {
  RotaPrivadaComLayout,
  RotaPublicaComLayout,
  RotaSemLayout,
} from 'Components/Rotas';

// Endereço das Rotas
import { RotasURL } from './RoutesURL';

// Layouts
import LayoutAdministrativo from 'Components/Layout/Administrativo';
import LayoutOperacional from 'Components/Layout/Operacional';
import PaginaNaoEncontrada from 'Pages/PaginaNaoEncontrada/PaginaNaoEncontrada';

const Routes = () => {
  return (
    <HashRouter>
      <Switch>
        {/* Login */}
        <RotaSemLayout exact path={RotasURL.login} component={Login} />
        <RotaSemLayout
          exact
          path={RotasURL.novaSenha}
          component={EsqueceuSenha}
        />

        {/* Empresa */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.empresa.index}
          component={Empresa}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.empresa.nova}
          component={Empresa_nova}
          layout={LayoutAdministrativo}
        />

        {/* Setor */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.setor.index}
          component={Setor}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.setor.novo}
          component={Setor_novo}
          layout={LayoutAdministrativo}
        />

        {/* Acomodação */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.acomodacao.index}
          component={Acomodacao}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.acomodacao.nova}
          component={Acomodacao_nova}
          layout={LayoutAdministrativo}
        />

        {/* Especialidade */}
        <RotaPrivadaComLayout 
          exact
          path={RotasURL.especialidade.index}
          component={Especialidade}
          layout={LayoutAdministrativo}/>
        <RotaPrivadaComLayout 
          exact
          path={RotasURL.especialidade.novo}
          component={CreateEspecialidade}
          layout={LayoutAdministrativo}/>

        {/* Usuario */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.usuario.index}
          component={Usuario}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.usuario.novo}
          component={Usuario_novo}
          layout={LayoutAdministrativo}
        />

        {/* Pessoa Fisica */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.pessoaFisica.index}
          component={PessoaFisica}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.pessoaFisica.novo}
          component={PessoaFisicaNova}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.pessoaFisica.escolher_pf}
          component={EscolherPessoaFisicaPage}
          layout={LayoutAdministrativo}
        />

        {/* Perfil */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.perfil.index}
          component={Perfil}
          layout={LayoutAdministrativo}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.perfil.novo}
          component={Perfil_novo}
          layout={LayoutAdministrativo}
        />

        {/* Grupos Ronda */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.grupoRonda.index}
          component={Grupos_ronda}
          layout={LayoutAdministrativo}
        />

        {/* Atributos Ronda */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.atributosRonda.index}
          component={Atributos_ronda}
          layout={LayoutAdministrativo}
        />

        {/* Admissão Paciente */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.admissaoPaciente.index}
          component={po_admissao_paciente}
          layout={LayoutOperacional}
        />

        {/* Movimentação Paciente */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.movimentacaoPaciente.origem}
          component={po_movimentacao_paciente}
          layout={LayoutOperacional}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.movimentacaoPaciente.destino}
          component={po_movimentacao_paciente_2}
          layout={LayoutOperacional}
        />

        {/* Rotina Médica */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.rotinaMedica.index}
          component={po_rotina_medica}
          layout={LayoutOperacional}
        />
        {/* <RotaPrivadaComLayout
          exact
          path={RotasURL.rotinaMedica.lista}
          component={po_rotina_medica_atendimento}
          layout={LayoutOperacional}
        /> */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.rotinaMedica.atendimento}
          component={po_rotina_medica_atendimento2}
          layout={LayoutOperacional}
        />
        {/* <RotaPrivadaComLayout
          exact
          path={RotasURL.rotinaMedica.nova}
          component={po_rotina_nova}
          layout={LayoutOperacional}
        /> */}
        <RotaPrivadaComLayout
          path={RotasURL.rotinaMedica.details}
          component={po_rotina_nova2}
          layout={LayoutOperacional}
        />

        {/* Plantão Médico */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.plantaoMedico.index}
          component={po_plantao_medico}
          layout={LayoutOperacional}
        />
        <RotaPrivadaComLayout
          exact
          path={RotasURL.plantaoMedico.atendimento}
          component={po_plantao_rotina}
          layout={LayoutOperacional}
        />

        {/* Alta Médica  */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.altaMedica.index}
          component={po_alta_medica}
          layout={LayoutOperacional}
        />

        {/* Liberação do Leito  */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.liberacaoLeito.index}
          component={po_liberacao_leito}
          layout={LayoutOperacional}
        />

        {/* Página Principal (Operacional) */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.po_main}
          component={POMainPage}
          layout={LayoutOperacional}
        />
        {/* Página Principal (Administrativo) */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.pa_main}
          component={PAMainPage}
          layout={LayoutAdministrativo}
        />

        {/* / */}
        <RotaPrivadaComLayout
          exact
          path={RotasURL.index}
          component={POMainPage}
          layout={LayoutOperacional}
        />

        {/* Página não encontrada - 404 */}
        <RotaSemLayout path="*" component={PaginaNaoEncontrada} />
      </Switch>
    </HashRouter>
  );
};

export default Routes;

/*
export default () => {
  return (
    <HashRouter>
      <Switch>
        <Route path="/acomodacao" component={Acomodacao} />
        <Route path="/" exact component={Main} />
        <Route path="/login" component={Login} />
        <Route path="/esqueceu_senha" component={EsqueceuSenha} />
        <Route path="/acomodacao_nova" component={Acomodacao_nova} />
        <Route path="/atributos_ronda" component={Atributos_ronda} />
        <Route path="/empresa" component={Empresa} />
        <Route path="/empresa_nova" component={Empresa_nova} />
        <Route path="/grupo" component={Grupos_ronda} />
        <Route path="/grupo_novo" component={Grupos_ronda_novo} />
        <Route path="/pessoaFisica" component={PessoaFisica} />
        <Route path="/pessoaFisicaNova" component={PessoaFisicaNova} />
        <Route path="/perfil" component={Perfil} />
        <Route path="/perfil_novo" component={Perfil_novo} />
        <Route path="/po_admissao_paciente" component={po_admissao_paciente} />
        <Route path="/po_alta_medica" component={po_alta_medica} />
        <Route path="/po_liberacao_leito" component={po_liberacao_leito} />
        <Route path="/po_main" component={po_main} />
        <Route
          path="/po_movimentacao_paciente"
          component={po_movimentacao_paciente}
        />
        <Route
          path="/po_movimentacao_paciente_2"
          component={po_movimentacao_paciente_2}
        />
        <Route path="/po_plantao_medico" component={po_plantao_medico} />
        <Route path="/po_plantao_rotina" component={po_plantao_rotina} />

        <Route
          exact={true}
          path="/po_rotina_medica"
          component={po_rotina_medica}
        />
        <Route
          path="/po_rotina_lista"
          component={po_rotina_medica_atendimento}
        />

        <Route path="/po_rotina_nova" component={po_rotina_nova} />
        <Route path="/po_rotina_ultima" component={po_rotina_ultima} />
        <Route path="/po_rotinas" component={po_rotinas} />
        <Route path="/setor" component={Setor} />
        <Route path="/setor_novo" component={Setor_novo} />
        <Route path="/usuario" component={Usuario} />
        <Route path="/escolher_pf" component={EscolherPessoaFisicaPage} />
        <Route path="/usuario_novo" component={Usuario_novo} />
      </Switch>
    </HashRouter>
  );
};
*/
