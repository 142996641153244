import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import LayoutOperacional from '../../Components/Layout/Operacional';
import CardAcomodacao from '../../Components/CardAcomodacao';

import SetorAPI from '../../Services/API/SetorAPI';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import AtendimentoAPI from '../../Services/API/AtendimentoAPI';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RotasURL } from 'RoutesURL';


const MovimentacaoPage = () => {
  const [idSetor, setIdSetor] = useState();
  const [listaSetor, setListaSetor] = useState();
  const [listaAcomodacao, setListaAcomodacao] = useState([]);
  const [listaAtendimento, setListaAtendimento] = useState([]);
  const [listaPessoa, setListaPessoa] = useState([]);

  const history = useHistory();

  useEffect(() => {
    obterListaSetor();
  }, []);

  useEffect(() => {
    //console.log('UE listasetor');
    if (listaSetor) {
      //console.log('lista setor nao vazia');
      //console.log(listaSetor[0].in_setor_id);
      setIdSetor(listaSetor[0].in_setor_id);
    }
  }, [listaSetor]);

  useEffect(() => {
    obterListaAcomodacao();
  }, [idSetor]);

  // useEffect(() => {
  //   //console.log('UE listaAcomodacao inicio');
  //   let listaAtendimentoTemp = [];
  //   Array.isArray(listaAcomodacao) &&
  //     listaAcomodacao.map(async (acomodacao, index) => {
  //       let atendimento = await AtendimentoAPI.obterAtendimentoPorId(
  //         acomodacao.in_atendimento_id
  //       );
  //       listaAtendimentoTemp = listaAtendimentoTemp.concat(atendimento);
  //       setListaAtendimento([...listaAtendimentoTemp]);
  //     });
  // }, [listaAcomodacao]);

  // useEffect(() => {
  //   let listaPessoaTemp = [];
  //   //console.log(listaAtendimento);
  //   //console.log(Array.isArray(listaAtendimento));
  //   Array.isArray(listaAtendimento) &&
  //     listaAtendimento.map(async (atendimento, index) => {
  //       //console.log('dentro do map de atendimento: ', atendimento);
  //       let pessoa = await PessoaFisicaAPI.obterPessoaPorId(
  //         atendimento.in_paciente_id
  //       );
  //       listaPessoaTemp = listaPessoaTemp.concat(pessoa);
  //       setListaPessoa(listaPessoaTemp);
  //     });
  // }, [listaAtendimento]);

  async function obterListaSetor() {
    try {
      let lista = await SetorAPI.obterListaSetor();
      setListaSetor(lista);
      //console.log('Setores: ', lista);
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  async function obterListaAcomodacao() {
    try {
      //console.log('obtendo lista de acomodao com id: ', idSetor);
      let lista = await AcomodacaoAPI.obterListaAcomodacaoPorSetor(2, idSetor);
      console.log('Acomodacao:', lista);
      if(lista) lista.sort((a, b) => a.in_acomodacao_id > b.in_acomodacao_id ? 1 : -1);
      setListaAcomodacao(lista);
    } catch (error) {
      // throw error;
      history.push({ pathname: RotasURL.login });
      // //console.log('Erro na listagem de acomodaçao ->', error);
    }
  }

  function changeId(event) {
    setIdSetor(event.target.value);
  }

  return (
    <>
        <h2 className="margin-bottom-10">
          Movimentação Paciente
          <div className="btn-home">
            <a href="po_main">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
        </h2>

        <div className="row form-group">
          <div className="col-lg-4 col-md-4 form-group">
            <label>Setor Origem</label>
            <select
              className="form-control"
              value={idSetor}
              onChange={changeId}
            >
              <option>---</option>
              {listaSetor &&
                listaSetor.map((setor, index) => {
                  return (
                    <option key={index} value={setor.in_setor_id}>
                      {setor.nv_nome_setor}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {Array.isArray(listaAcomodacao) &&
              listaAcomodacao.map((acomodacao, index) => {
                // let atendimento = listaAtendimento.filter(
                //   (atendimento) =>
                //     atendimento.in_atendimento_id ==
                //     acomodacao.in_atendimento_id
                // );
                // let pessoa = listaPessoa.filter(
                //   (pessoa) =>
                //     pessoa.in_pessoafisica_id == atendimento[0]?.in_paciente_id
                // );
                //console.log(pessoa);
                return (
                  <Link
                    to={{
                      pathname: RotasURL.movimentacaoPaciente.destino,
                      state: { acomodacao, idSetor },
                    }}
                  >
                    <CardAcomodacao acomodacao={acomodacao}/>
                  </Link>
                );
              })}
          </div>
        </div>
    </>
  );
};

export default MovimentacaoPage;
