import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { isLoggedIn } from 'Services/Auth/Auth';

//TODO: Obter da SessionStorage
function checkLoggedIn() {
  console.log("logincheck");
  return true;
}

const RotaPrivadaComLayout = (props) => {
  const { layout: Layout, component: Component, ...rest } = props;

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        isLoggedIn() ? (
          <Layout>
            <Component {...matchProps} />
          </Layout>
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default RotaPrivadaComLayout;
