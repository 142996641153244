import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import LoadingIcons from 'react-loading-icons';

import AcessoAPI from '../../Services/API/AcessoAPI';

import { storeLoginSession, logOut } from 'Services/Auth/Auth';

const LoginPage = (props) => {
  const [login, setLogin] = useState();
  const [senha, setSenha] = useState();
  const [erroLogin, setErroLogin] = useState(false);
  const [carregandoLogin, setCarregandoLogin] = useState(false);

  const history = useHistory();

  useEffect(() => {
    logOut();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function changeLogin(event) {
    setLogin(event.target.value);
  }

  function changeSenha(event) {
    setSenha(event.target.value);
  }

  function autenticado({ token, user }) {
    // //console.log(token);
    // //console.log(user);

    storeLoginSession(user, token);

    history.push({ pathname: '/' });
  }

  function changeErroLogin() {
    setErroLogin(false);
  }

  const onSubmit = async () => {
    setCarregandoLogin(true);
    let usuario = await AcessoAPI.validaUsuario(login, senha);
    
    
    if (!usuario) {
      setErroLogin(true);
    } else {
      autenticado(usuario);
      setErroLogin(false);
    }

    // //console.log('USUARIO', usuario);

    // //console.log(usuario[0]);
    // if (!(usuario == 'UsuarioInvalido')) {
    //   autenticado(usuario);
    //   setErroLogin(false);
    // } else setErroLogin(true);
    setCarregandoLogin(false);
  };

  return (
    <>
      <div className="content-first col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="container-login col-lg-3 col-md-4 col-sm-6 col-xs-10">
          <img className="logo-ronda-login" src="icon/logo-ronda.svg" />
          <h1 className="titulo-login">Bem-vindo!</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formLine">
              <i className="fas fa-user" />
              <input
                className="input-login"
                data-val="true"
                data-val-required="Login de preenchimento obrigatório"
                placeholder="Usuário"
                type="text"
                value={login}
                disabled={carregandoLogin}
                onChange={changeLogin}
              />
            </div>
            <div className="formLine">
              <i className="fas fa-unlock-alt" />
              <input
                className="input-login"
                data-val="true"
                data-val-required="Senha de preenchimento obrigatório."
                id="senha"
                name="Login.senha"
                placeholder="Senha"
                type="password"
                value={senha}
                disabled={carregandoLogin}
                onChange={changeSenha}
              />
            </div>

            {!carregandoLogin ? 
              <button className="btn-login" onClick={onSubmit}>
                Acessar
              </button> :
              <LoadingIcons.Puff/>}
          </form>
          <br />
          <br />
          <Link to={'/esqueceu_senha'}>
            <p>Esqueci minha senha!</p>
          </Link>
        </div>
      </div>

      {erroLogin && (
        <div
          className="modal show"
          id="loginFalho"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          tabIndex={-1}
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title">Login e/ou senha inválido(s)</h3>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="close"
                  onClick={changeErroLogin}
                >
                  <span aria-hidden="true">x</span>
                </button>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                  onClick={changeErroLogin}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
