import axios from 'axios';
import { BackendApiURL } from '../../settings';

let BASE_HEADERS = {
  'Content-Type': 'application/json',
};

function returnAxiosInstance() {
  return axios.create({
    baseURL: BackendApiURL,
    headers: BASE_HEADERS,
  });
}

export function get(url, pHeaders = null) {
  // checkHeader();
  const axios = returnAxiosInstance();

  let headers = BASE_HEADERS;

  if (pHeaders != null) {
    headers = { ...BASE_HEADERS, ...pHeaders };
  }

  return axios.get(url, { headers });
}

export function getWithParams(url, params, pHeaders = null) {
  const axios = returnAxiosInstance();

  let headers = BASE_HEADERS;

  if (pHeaders != null) {
    headers = { ...BASE_HEADERS, ...pHeaders };
  }

  return axios.get(url, { params: params, headers });
}

export function post(url, payload, pParams, pHeaders = null) {
  const axios = returnAxiosInstance();

  let params = pParams || null;

  let headers = BASE_HEADERS;

  if (pHeaders != null) {
    headers = { ...BASE_HEADERS, ...pHeaders };
  }

  const config = { params: params, headers };

  // console.log({ config });

  return axios.post(url, payload, config);
}

export function DELETE(url, id, usuario, pagina, pHeaders = null) {
  const axios = returnAxiosInstance();

  let headers = BASE_HEADERS;

  if (pHeaders != null) {
    headers = { ...BASE_HEADERS, ...pHeaders };
  }

  return axios.delete(
    `${url}?pId${pagina}ToExcluir=${id}&pUsuario=${usuario}`,
    { headers }
  );
}

export function DELETE_simples(url, pHeaders = null) {
  const axios = returnAxiosInstance();

  let headers = BASE_HEADERS;

  if (pHeaders != null) {
    headers = { ...BASE_HEADERS, ...pHeaders };
  }

  return axios.delete(`${url}`, { headers });
}
