import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './acomodacaoSchema';

import EmpresaAPI from 'Services/API/EmpresaAPI';
import SetorAPI from 'Services/API/SetorAPI';
import AcomodacaoAPI from 'Services/API/AcomodacaoAPI';

import { RotasURL } from 'RoutesURL';

const NovaAcomodacaoPage = (props) => {
  const [listaAtualEmpresa, setListaAtualEmpresa] = useState();
  const [listaAtualSetor, setListaAtualSetor] = useState();
  const [atualizando, setAtualizando] = useState(true);
  const [idEmpresa, setIdEmpresa] = useState();
  const [idSetor, setIdSetor] = useState();
  const [acomodacao, setAcomodacao] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const history = useHistory();

  //--------------Lista de Empresas---------------------//
  useEffect(() => {
    obterListaDeEmpresas();
  }, []);

  useEffect(() => {
    if (listaAtualEmpresa) {
      console.log(listaAtualEmpresa)
      if (!props.location.state) {
        setAtualizando(false);
        setIdEmpresa(listaAtualEmpresa[0].in_empresa_id);
        obterListaDeSetores(listaAtualEmpresa[0].in_empresa_id);
        //console.log('vazio');
        //console.log(props);
      } else {
        atualizaAcomodacao();
        //console.log(acomodacao);
      }
    }
  }, [listaAtualEmpresa]);

  const onSubmit = (dataJSON) => {
    const data = dataJSON;
    //console.log(data);
    data.in_empresa_id = idEmpresa;
    data.in_setor_id = idSetor;
    if (atualizando) data.in_acomodacao_id = acomodacao.in_acomodacao_id;
    AcomodacaoAPI.salvarAcomodacao(data)
      .then((resposta) => {
        //console.log(resposta);
        //console.log('ok');
        history.push(RotasURL.acomodacao.index);
      })
      .catch((err) => {
        //console.log(err);
        // history.push({ pathname: RotasURL.login });
      });
  };

  async function obterListaDeEmpresas() {
    try {
      let lista = await EmpresaAPI.obterListaEmpresa();
      setListaAtualEmpresa(lista);
      ////console.log(lista);
    } catch (error) {
      console.error(error);
      history.push({ pathname: RotasURL.login });
      // throw error;
    }
  }

  //-------------Lista de Setores-------------//
  useEffect(() => {
    if (idEmpresa) obterListaDeSetores(idEmpresa);
  }, [idEmpresa]);

  useEffect(() => {
    if (listaAtualSetor) {
      if (!props.location.state) {
        setAtualizando(false);
        setIdSetor(listaAtualSetor[0].in_setor_id);
        //console.log('vazio');
        //console.log(props);
      } else {
        atualizaAcomodacao();
        //console.log(acomodacao);
      }
    }
  }, [listaAtualSetor]);

  // // const onSubmit = (dataJSON) => {
  // //   const data = dataJSON;
  // //   //console.log(data);
  // //   data.in_setor_id = idSetor;
  // //   if (atualizando) data.in_acomodacao_id = acomodacao.in_acomodacao_id;
  // //   AcomodacaoAPI.salvarAcomodacao(data)
  // //     .then((resposta) => {
  // //       //console.log(resposta);
  // //       //console.log('ok');
  // //       history.push('/acomodacao');
  // //     })
  // //     .catch((err) => {
  // //       //console.log(err);
  // //     });
  // // };

  async function obterListaDeSetores(id) {
    try {
      console.log(id)
      let lista = await SetorAPI.obterListaSetorPorEmpresa(id);
      setListaAtualSetor(lista);
      setIdSetor(lista[0].in_setor_id);
      ////console.log(lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function changeId(event) {
    setIdEmpresa(event.target.value);
    //setIdSetor(event.target.value);
  }

  function changeId2(event) {
    //setIdEmpresa(event.target.value);
    setIdSetor(event.target.value);
  }

  function atualizaAcomodacao() {
    setAcomodacao(props.location.state.item);
    setIdEmpresa(props.location.state.item.in_empresa_id);
    setIdSetor(props.location.state.item.in_setor_id);
    setAtualizando('true');
  }

  return (
    <>
      <h2 className="margin-bottom-10">
        <Link to={RotasURL.acomodacao.index}>
          <i className="fa fa-arrow-alt-circle-left fa-fw" />
        </Link>
        {atualizando ? 'Atualizar Acomodação' : 'Nova Acomodação'}

        <div className="btn-home">
          <a href="">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      {(acomodacao || !atualizando) && (
        <form
          className="templatemo-login-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row form-group">
            <div className="col-lg-8 col-md-8 col-sm-6 form-group">
              <label>Nome da Acomodação</label>
              <input
                type="text"
                className="form-control"
                defaultValue={acomodacao && acomodacao.nv_nome_acomodacao}
                placeholder="Nome da Acomodação"
                {...register('nv_nome_acomodacao', {})}
              />
              <p className="error-message">
                {errors.nv_nome_acomodacao?.message}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 form-group">
              <label>Código Externo</label>
              <input
                type="text"
                className="form-control"
                defaultValue={acomodacao && acomodacao.nv_codexterno_acomodacao}
                placeholder="Código Externo"
                {...register('nv_codexterno_acomodacao', {})}
              />
              <p className="error-message">
                {errors.nv_codexterno_acomodacao?.message}
              </p>
            </div>
          </div>

          {/* <div className="row form-group"> */}
          <div className="col-lg-4 col-md-4 form-group">
            <label>Empresa</label>
            <select
              className="form-control"
              defaultValue={idEmpresa}
              onChange={changeId}
            >
              {listaAtualEmpresa &&
                listaAtualEmpresa.map((empresa, index) => {
                  return (
                    <option value={empresa.in_empresa_id} key={index}>
                      {empresa.nv_nome_empresa}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* </div> */}

          {/* ADICIONAR PARTE DE SETOR AÍ */}

          {/* <div className="row form-group"> */}
          <div className="col-lg-4 col-md-4 form-group">
            <label>Setor</label>
            <select
              className="form-control"
              value={idSetor}
              onChange={changeId2}
            >
              {Array.isArray(listaAtualSetor) &&
                listaAtualSetor.map((setor, index) => {
                  return (
                    <option value={setor.in_setor_id} key={index}>
                      {setor.nv_nome_setor}
                    </option>
                  );
                })}
            </select>
          </div>
          {/* </div> */}

          <div className="row form-group">
            <div className="col-lg-2 col-md-2 form-group">
              <label>Isolamento</label>
              <select
                className="form-control"
                defaultValue={acomodacao && acomodacao.nv_cod_isolamento}
                {...register('nv_cod_isolamento', {})}
              >
                <option value="S"> Sim </option>
                <option value="N"> Não </option>
              </select>
              <p className="error-message">
                {errors.nv_cod_isolamento?.message}
              </p>
            </div>
          </div>

          <div className="row form-group">
            <div className="form-group text-left">
              <button type="reset" className="templatemo-white-button">
                Apagar
              </button>
              <a href="/acomodacao">
                <button type="submit" className="templatemo-blue-button">
                  Salvar
                </button>
              </a>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default NovaAcomodacaoPage;
