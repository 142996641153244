import * as yup from 'yup';

export const schema = yup.object().shape({
  nv_nome_empresa: yup.string().required('*Campo obrigatório.'),
  nv_codexterno_empresa: yup.string(),
  nv_endereco_empresa: yup.string().required('*Campo obrigatório.'),
  nv_numero_empresa: yup.string().required('*Campo obrigatório.'),
  nv_complemento_empresa: yup.string(),
  nv_cep_empresa: yup.string().required('*Campo obrigatório.'),
  nv_bairro_empresa: yup.string().required('*Campo obrigatório.'),
  nv_cidade_empresa: yup.string().required('*Campo obrigatório.'),
  nv_estado_empresa: yup.string().required('*Campo obrigatório.'),
  nv_contato_empresa: yup.string().required('*Campo obrigatório.'),
  nv_cgc_empresa: yup.string().required('*Campo obrigatório.'),
  nv_cnes_empresa: yup.string().required('*Campo obrigatório.'),
  nv_inscricaomunicipal_empresa: yup.string(),
  nv_inscricaoestadual_empresa: yup.string(),
  nv_ativo: yup.string().required('*Campo obrigatório.'),
});
