import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './setorSchema';

import EmpresaAPI from '../../Services/API/EmpresaAPI';
import SetorAPI from '../../Services/API/SetorAPI';
import { RotasURL } from 'RoutesURL';

const NovoSetorPage = (props) => {
  const [listaAtualEmpresa, setListaAtualEmpresa] = useState();
  const [atualizando, setAtualizando] = useState(true);
  const [idEmpresa, setIdEmpresa] = useState();
  const [setor, setSetor] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const history = useHistory();

  useEffect(() => {
    obterListaDeEmpresas();
  }, []);

  useEffect(() => {
    if (listaAtualEmpresa) {
      if (!props.location.state) {
        setAtualizando(false);
        setIdEmpresa(listaAtualEmpresa[0].in_empresa_id);
        //console.log('vazio');
        //console.log(props);
      } else {
        atualizaSetor();
        //console.log(setor);
      }
    }
  }, [listaAtualEmpresa]);

  const onSubmit = async (dataJSON) => {
    const data = dataJSON;
    //console.log(data);
    data.in_empresa_id = idEmpresa;
    if (atualizando) data.in_setor_id = setor.in_setor_id;

    await SetorAPI.salvarSetor(data)
      .then((resposta) => {
        // //console.log(resposta);
        // //console.log('ok');
        // history.push('/setor');
        history.push(RotasURL.setor.index);
      })
      .catch((err) => {
        // //console.log(err);
        alert(err);
      });
  };

  async function obterListaDeEmpresas() {
    try {
      let lista = await EmpresaAPI.obterListaEmpresa();
      setListaAtualEmpresa(lista);
      ////console.log(lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  function changeId(event) {
    setIdEmpresa(event.target.value);
  }

  function atualizaSetor() {
    setSetor(props.location.state.item);
    setIdEmpresa(props.location.state.item.in_empresa_id);
    setAtualizando('true');
  }

  return (
    <>
      <h2 className="margin-bottom-10">
        <Link to={RotasURL.setor.index}>
          <i className="fa fa-arrow-alt-circle-left fa-fw" />
        </Link>
        {atualizando ? 'Atualizar Setor' : 'Novo Setor'}

        <div className="btn-home">
          <a href="">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      {(setor || !atualizando) && (
        <form
          className="templatemo-login-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row form-group">
            <div className="col-lg-8 col-md-8 col-sm-6 form-group">
              <label>Nome do Setor</label>
              <input
                type="text"
                className="form-control"
                defaultValue={setor && setor.nv_nome_setor}
                placeholder="Nome do Setor"
                {...register('nv_nome_setor', {})}
              />
              <p className="error-message">{errors.nv_nome_setor?.message}</p>
            </div>
            <div className="col-lg-4 col-md-4 form-group">
              <label>Código Externo</label>
              <input
                type="text"
                className="form-control"
                defaultValue={setor && setor.nv_codexterno_setor}
                placeholder="Nome do Setor"
                {...register('nv_codexterno_setor', {})}
              />
              <p className="error-message">
                {errors.nv_codexterno_setor?.message}
              </p>
            </div>
          </div>

          <div className="row form-group">
            <div className="col-lg-4 col-md-4 form-group">
              <label>Empresa</label>
              <select
                className="form-control"
                defaultValue={idEmpresa}
                onChange={changeId}
              >
                {listaAtualEmpresa.map((empresa, index) => {
                  return (
                    <option value={empresa.in_empresa_id} key={index}>
                      {empresa.nv_nome_empresa}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>

          <div className="form-group text-left">
            <button type="reset" className="templatemo-white-button">
              Apagar
            </button>
            <button type="submit" className="templatemo-blue-button">
              Salvar
            </button>
          </div>
        </form>
      )}
    </>
  );
};

export default NovoSetorPage;
