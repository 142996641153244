import React from 'react';
import './App.css';
import Routes from './Routes';

export default () => {
  console.log("Base: ", process.env.REACT_APP_BASE)
  // console.log("Node env: ", process.env.NODE_ENV)

  return (
    <>
      <Routes />
    </>
  )
}