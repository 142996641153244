import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import { RotasURL } from 'RoutesURL';


const TabelaPessoaUsuario = (lista) => {
  const [idExclusao, setIdExclusao] = useState();

  const history = useHistory();

  useEffect(() => {
    //console.log(lista);
  }, []);

  function idChange(id) {
    const idExclusao = id;
    setIdExclusao(idExclusao);
    //console.log(idExclusao);
  }

  function excluirPessoa() {
    //console.log(`deletando pessoa: ${idExclusao}`);
    PessoaFisicaAPI.excluirPessoa(idExclusao)
      .then((resposta) => {
        //console.log(resposta);
        //console.log('deletado');
        lista.obterListaDePessoas();
      })
      .catch((err) => {
        //console.log(err);
        history.push({ pathname: '/login' });
      });
  }

  return (
    <>
      {/* Pode virar componente */}
      <table id="tblpessoa" className="table table-responsive">
        <thead>
          <tr>
            <td>NOME</td>
            <td>&nbsp;</td>
          </tr>
        </thead>
        <tbody>
          {lista.lista.map((item, index) => {
            //console.log(item)
            return (
              <tr className="linhaheight" key={index}>
                <td>{item.nv_nome_pessoafisica}</td>
                <td style={{ textAlign: 'right' }}>
                  <Link to={{ pathname: RotasURL.usuario.novo, state: { item } }}>
                    <button type="button" className="templatemo-black-button">
                      <i className="fas fa-edit fa-fw"></i>
                    </button>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TabelaPessoaUsuario;
