import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { getUser, getToken } from 'Services/Auth/Auth';
import PerfilAPI from 'Services/API/PerfilAPI';
import { isLoggedIn, logOut } from 'Services/Auth/Auth';
import { RotasURL } from 'RoutesURL';

const SidebarOperacional = () => {
  const history = useHistory();
  const [isAdmin, setIsAdmin] = useState(false);

  async function obterPerfilUsuario() {
    let userSession = getUser();
    let userPerfilId = userSession.in_perfil_id;
    let userPerfil = null;

    let perfilTempList = [];
    // //console.log(userSession);
    // //console.log(userPerfilId);

    let listaPerfil = await PerfilAPI.obterListaPerfil()
      .then((response) => {
        // //console.log(response);
        perfilTempList = response.filter((p) => {
          // //console.log(p);
          if (p.in_perfil_id === userPerfilId) {
            // //console.log('foi');
            userPerfil = p;
          }
        });
      })
      .catch((err) => {
        throw err;
      });

    // userPerfil = perfilTempList;
    // //console.log(userPerfil);

    if (userPerfil?.in_tipoperfil_id === 1) {
      setIsAdmin(true);
    }
  }

  useEffect(() => {
    let seUsuarioLogado = isLoggedIn();
    if (!seUsuarioLogado) {
      // //console.log('Não Logado!');
      logOut();
      history.push({ pathname: RotasURL.login });
    } else {
      obterPerfilUsuario();
    }
  }, []);

  return (
    <>
      <nav className="templatemo-left-nav">
        <ul>
          {/* <!--Admissão de Paciente--> */}
          <li>
            <NavLink
              to={RotasURL.admissaoPaciente.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-hospital fa-fw"></i>Admissão de Paciente
            </NavLink>
          </li>

          {/* <!--Movimentação de Paciente--> */}
          <li>
            <NavLink
              to={RotasURL.movimentacaoPaciente.origem}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-arrows-alt fa-fw"></i>Movimentação de Paciente
            </NavLink>
          </li>

          {/* <!--Rotina Médica--> */}
          <li>
            <NavLink
              to={RotasURL.rotinaMedica.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-user-circle fa-fw"></i>Rotina Médica
            </NavLink>
          </li>

          {/* <!--Plantão Médico--> */}
          <li>
            <NavLink
              to={RotasURL.plantaoMedico.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-fingerprint fa-fw"></i>Plantão Médico
            </NavLink>
          </li>

          {/* <!--Alta Médica--> */}
          <li>
            <NavLink
              to={RotasURL.altaMedica.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-stream fa-fw"></i>Alta Médica
            </NavLink>
          </li>

          {/* <!--Liberação de leito--> */}
          <li>
            <NavLink
              to={RotasURL.liberacaoLeito.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-bed fa-fw"></i>Liberação de Leito
            </NavLink>
          </li>

          {/* <!--Painel Administrativo--> */}
          {isAdmin && (
            <li>
              <NavLink to="/pa">
                {/* <i className="fa fa-bed fa-fw"></i>Painel Administrativo */}
                <i className="fa fa-dice-d6 fa-fw"></i>Painel Administrativo
              </NavLink>
            </li>
          )}

          {/* <!--Sair--> */}
          <li>
            <NavLink to="/login">
              <i className="fa fa-sign-out-alt fa-fw"></i>Sair
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default SidebarOperacional;
