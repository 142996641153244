import React, { useEffect, useState } from 'react';
import TabelaRotinaMedica from 'Components/Tabela/TabelaRotinaMedica';
import RotinaMedicaAPI from 'Services/API/RotinaMedicaAPI';
import { useHistory, useParams, Link } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';
import { v4 as uuid } from 'uuid';
import LoadingIcons from 'react-loading-icons';
import AtendimentoAPI from 'Services/API/AtendimentoAPI';
import { formataDataBR } from 'utils';

const RotinaMedicaAtendimentoPage = (props) => {
  const [listaInicial, setListaInicial] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [listaAtual, setListaAtual] = useState([]);

  // const [listaDeRotinasIniciadas, setListaDeRotinasIniciadas] = useState([]);
  const [seTemRotinaIniciada, setSeTemRotinaIniciada] = useState(false);

  const [numeroRegistros, setNumeroRegistros] = useState(5);
  const [paginaAtual, setPaginaAtual] = useState(1);
  const [maxPagina, setMaxPagina] = useState();
  const [filtro, setFiltro] = useState('');
  const [atendimento, setAtendimento] = useState(null);

  const [comentarios, setComentarios] = useState([]);
  const [paginaPronta, setPaginaPronta] = useState(false);

  const history = useHistory();
  let { idAtendimento } = useParams();

  useEffect(() => {
    setAtendimento(idAtendimento);
    // obterListaDeRotinas();
  }, []);

  useEffect(() => {
    listaAtual.map((rotina) => {
      //console.log('ROTINA: ', rotina);
      if (rotina.in_status_ronda == 1) {
        //console.log('TEM ROTINA INICIADA');
        setSeTemRotinaIniciada(true);
        return rotina;
      }
    });
  });

  useEffect(() => {
    (async function() {
      obterListaDeRotinas().then(() => setPaginaPronta(true));
    })();
  }, [atendimento]);

  useEffect(() => {
    //console.log({ seTemRotinaIniciada });
  }, [seTemRotinaIniciada]);

  useEffect(() => {
    setPaginaAtual(1);
  }, [maxPagina]);

  useEffect(() => {
    filtraPerfil();
    //console.log(filtro);
  }, [filtro, listaInicial]);

  useEffect(() => {
    let primeiroIndice = (paginaAtual - 1) * numeroRegistros;
    let ultimoIndice = paginaAtual * numeroRegistros;

    if (numeroRegistros)
      var numPaginas = Math.ceil(listaFiltrada.length / numeroRegistros);
    setMaxPagina(numPaginas);
    if (
      listaFiltrada.slice((paginaAtual - 1) * numeroRegistros).length <
      numeroRegistros
    ) {
      setListaAtual(listaFiltrada.slice(primeiroIndice));
    } else {
      setListaAtual(listaFiltrada.slice(primeiroIndice, ultimoIndice));
    }
  }, [numeroRegistros, paginaAtual, listaFiltrada]);

  useEffect(() => {
    setPaginaAtual(() => 1);
  }, [numeroRegistros]);

  const obterListaDeRotinas = async () => {
    try {
      let obs = await AtendimentoAPI.listarAtendimentoObservacao(atendimento);
      setComentarios(obs);

      if (atendimento !== null) {
        let lista = await RotinaMedicaAPI.obterListaRotinaPorAtendimento(
          atendimento
        );

        if (lista !== null) {
          if (lista !== 'ListaRotinaMedica_Vazia') {
            setListaFiltrada(() => lista);

            setListaInicial(() =>
              lista.filter(
                (rotina) =>
                  rotina.in_atendimento_id == atendimento &&
                  rotina.in_status_ronda != 0
              )
            );
            setListaAtual(() => lista);
          }
        }
      }
    } catch (error) {
      console.error(error);
      // throw error;
    }
  };

  function onOverlay() {
    document.getElementById('overlay').style.display = 'block';
  }

  function offOverlay() {
    document.getElementById('overlay').style.display = 'none';
  }

  function filtraPerfil() {
    // console.log(filtro);
    let listaTemp = listaInicial.filter((rotina) =>
      rotina.dt_ronda.includes(filtro)
    );
    setListaFiltrada(listaTemp);
    // console.log(listaTemp);
  }

  function somarPagina() {
    let proximaPagina = paginaAtual + 1;
    setPaginaAtual(proximaPagina);
  }

  function registrosChange(event) {
    setNumeroRegistros(event.target.value);
  }

  const FiltroChange = (event) => {
    const valor = event.target.value;
    setFiltro(valor);
  };

  function diminuiPagina() {
    let proximaPagina = paginaAtual - 1;
    setPaginaAtual(proximaPagina);
  }

  async function GoToLastUnfinishedRotina() {
    onOverlay();
    let lista = await RotinaMedicaAPI.obterListaRotinaPorAtendimento(
      atendimento
    );
    let iniciadas = lista.filter((rotina) => rotina.in_status_ronda == 1);
    let ultimaRotinaIniciadaID = iniciadas[0];
    // console.log({ ultimaRotinaIniciadaID });

    let updatedURL = RotasURL.rotinaMedica.details.replace(
      ':idAtendimento',
      atendimento
    );
    updatedURL = updatedURL.replace(
      ':idRotina',
      ultimaRotinaIniciadaID.gd_rotina_id
    );
    // console.log({ updatedURL });

    history.push({ pathname: updatedURL });
  }

  async function handleRotinaCreation() {
    onOverlay();
    // Se tiver alguma rotina finalizada, copiar ela
    var algumaFinalizada = listaAtual.some((rotina) => {
      return rotina.in_status_ronda == 2; // 2 = finalizado
    });
    //console.log({ listaAtual });

    if (algumaFinalizada) {
      // Copia ultima finalizada
      let lista = await RotinaMedicaAPI.obterListaRotinaPorAtendimento(
        atendimento
      );

      let finalizadas = lista.filter((rotina) => rotina.in_status_ronda == 2);
      //console.log({ finalizadas });
      let ultimaRotina = finalizadas[0];
      // Clona a ultima finalizada
      if (ultimaRotina) {
        let novaRotinaID = await RotinaMedicaAPI.copiarRotina(
          ultimaRotina.gd_rotina_id
        );
        // console.log('NOVA ROTINA CLONADA: ', novaRotinaID);
        let updatedURL = RotasURL.rotinaMedica.details.replace(
          ':idAtendimento',
          atendimento
        );
        updatedURL = updatedURL.replace(':idRotina', novaRotinaID);

        history.push({ pathname: updatedURL });
      }
    } else {
      //criar uma do total zero
      createNewRotinaMedicaFromScratch();
    }
  }

  async function createNewRotinaMedicaFromScratch() {
    const unique_id = uuid();

    let rotinaPayload = {
      gd_rotina_id: unique_id,
      in_atendimento_id: atendimento,
      in_medico_responsavel_id: 1,
    };

    await RotinaMedicaAPI.prepararRotina(rotinaPayload)
      .then((response) => {
        // console.log('Unique ID: ', unique_id);
        // console.log('ID Resposta: ', response);

        let updatedURL = RotasURL.rotinaMedica.details.replace(
          ':idAtendimento',
          atendimento
        );
        updatedURL = updatedURL.replace(
          ':idRotina',
          rotinaPayload.gd_rotina_id
        );

        history.push({
          pathname: updatedURL,
          state: { atendimento: atendimento },
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function BotaoNovaRotinaFromZero() {
    return (
      <div>
        <a>
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
            onClick={() => handleRotinaCreation()}
            disabled={!paginaPronta}
          >
            Nova Rotina
          </button>
        </a>
      </div>
    );
  }

  function BotaoNovaRotina() {
    return (
      <div>
        <a data-toggle="modal" data-target="#modalNovaPagina">
          <button
            type="submit"
            className="templatemo-green-button margin-bottom-10"
            disabled={!paginaPronta}
          >
            Nova Rotina
          </button>
        </a>
      </div>
    );
  }

  return (
    <>
      <div id="overlay"></div>
      {/* <!--Título da Página--> */}
      <h2 className="margin-bottom-10">
        <Link to={RotasURL.rotinaMedica.index}>
          <i className="fa fa-arrow-alt-circle-left fa-fw" />
        </Link>
        Rotina Médica
        <div className="btn-home">
          <a href="">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12">
        {/* <!--Botão--> */}
        <div>
          {seTemRotinaIniciada ? (
            <BotaoNovaRotina />
          ) : (
            <BotaoNovaRotinaFromZero />
          )}
        </div>

        <div id="tblsetor_wrapper" className="dataTables_wrapper no-footer">
          <div
            className="dataTables_length"
            id="tblsetor_length"
            style={{ float: 'left' }}
          >
            <label>
              Mostrando
              <select
                name="tblsetor_length"
                aria-controls="tblsetor"
                className="input-select"
                value={numeroRegistros}
                onChange={registrosChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>{' '}
              registros por página
            </label>
          </div>

          <div
            id="tblsetor_filter"
            className="dataTables_filter"
            style={{ float: 'right' }}
          >
            <label>
              Localizar:{' '}
              <input
                type="search"
                className="input-search"
                placeholder="Data"
                aria-controls="tblsetor"
                value={filtro}
                onChange={FiltroChange}
              />
            </label>
          </div>

          <TabelaRotinaMedica
            lista={listaAtual}
            obterListaDeRotinas={obterListaDeRotinas}
          />

          <div className="row form-group">
            <div
              className="dataTables_info info_paginas"
              id="tblsetor_info"
              role="status"
              aria-live="polite"
            >
              {paginaAtual > 1 && (
                <button
                  className="templatemo-white-button"
                  onClick={diminuiPagina}
                >
                  &lt;
                </button>
              )}
              Mostrando página {paginaAtual} {maxPagina && ` de ${maxPagina}`}
              {paginaAtual < maxPagina && (
                <button
                  className="templatemo-white-button"
                  onClick={somarPagina}
                >
                  &gt;
                </button>
              )}
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="modalNovaPagina"
          tabIndex="-1"
          role="dialog"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Deseja continuar apartir da rotina não finalizada?
                </h4>
                <button
                  type="buttom"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">x</span>
                </button>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  onClick={handleRotinaCreation}
                  data-dismiss="modal"
                >
                  Nova Rotina
                </button>
                <button
                  className="btn btn-primary"
                  onClick={GoToLastUnfinishedRotina}
                  data-dismiss="modal"
                >
                  Continuar onde parei
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12">
        <div className="plantao-rotina-container">
          <h3 style={{ color: '#00a99d', fontWeight: 'bold', padding: '10px' }}>
            Recomendações:
          </h3>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 box-home">
            <div className="box-rotina-nova">
              {/*  */}
              {Array.isArray(comentarios) && comentarios[0] ? (
                comentarios.map((item, index) => {
                  return (
                    <>
                      <div
                        className="title-box-rotina"
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <div>{item.nv_nome_pessoafisica}</div>
                        <div style={{ color: '#9A9A9A' }}>
                          {formataDataBR(item.dataInclusao)}
                        </div>
                      </div>
                      <div className="content-box-rotina">
                        <div
                          style={{
                            paddingTop: '3px',
                            paddingBottom: '3px',
                            paddingLeft: '1px',
                            paddingRight: '1px',
                            margin: '2px',
                          }}
                        >
                          {item.observacao}
                        </div>
                      </div>
                      <br></br>
                    </>
                  );
                })
              ) : (
                <div>Não existem recomendações para este atendimento.</div>
              )}

              {/*  */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RotinaMedicaAtendimentoPage;
