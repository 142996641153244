import * as yup from 'yup';

export const schema = yup.object().shape({
    
    "INTERN.DIAS_INTERNACAO_CTI": yup.string(),
    "INTERN.DIAGNOSTICO_INTERNACAO": yup.string(),
    "INTERN.DIAGNOSTICOS_SECUNDARIOS": yup.string(),
    "INTERN.DIAS_ANTIBIOTICOS": yup.string(),
    "INTERN.INVASOES": yup.string(),
    "IDENT.ALTURA": yup.string(),
    "IDENT.PESO_KG": yup.string(),
    "INT24H.BH": yup.string(),
    "INT24.DIURESE": yup.string(),
    "INT24.DIURESE_UNIDADE": yup.string(),
    "INT24.FEBRE": yup.string(),
    "INT24.FEBRE_EPISODIOS": yup.string(),
    "INT24.FEBRE_MAXIMA": yup.string(),
    "INT24.INTERCORRENCIAS": yup.string(),
    "CARDIO.AMINAS": null,
    "CARDIO.MED_HABITUAIS": yup.string(),
    "CARDIO.PARECER_CONDUTAS": yup.string(),
    "CARDIO.EXAMES_MONITORIZACAO": yup.string(),
    "RESPIRATORIO.OXIGENIOTERAPIA": null,
    "RESPIRATORIO.GASOMETRIA": yup.string(),
    "RESPIRATORIO.MED_HABITUAIS": yup.string(),
    "RESPIRATORIO.PARECER_CONDUTAS": yup.string(),
    "RESPIRATORIO.EXAMES_MONITORIZACAO": yup.string(),
    "NEUROLOGICO.SEDACAO": yup.string(),
    "NEUROLOGICO.COMA": yup.string(),
    "NEUROLOGICO.DELIRIUM": yup.string(),
    "NEUROLOGICO.DOR": yup.string(),
    "NEUROLOGICO.DROGAS": null,
    "NEUROLOGICO.MED_HABITUAIS": yup.string(),
    "NEUROLOGICO.PARECER_CONDUTAS": yup.string(),
    "NEUROLOGICO.EXAMES_MONITORIZACAO": yup.string(),
    "INFECCIOSO.CULTURAS": null,
    "INFECCIOSO.ANTIBIOTICOS": null,
    "INFECCIOSO.MED_HABITUAIS": yup.string(),
    "INFECCIOSO.PARECER_CONDUTAS": yup.string(),
    "INFECCIOSO.EXAMES_MONITORIZACAO": yup.string(),
    "RENAL.DIALITICO": null,
    "RENAL.MED_HABITUAIS": yup.string(),
    "RENAL.PARECER_CONDUTAS": yup.string(),
    "RENAL.EXAMES_MONITORIZACAO": yup.string(),
    "NUTRI.DIETAS": null,
    "NUTRI.MED_HABITUAIS": yup.string(),
    "NUTRI.PARECER_CONDUTAS": yup.string(),
    "NUTRI.EXAMES_MONITORIZACAO": yup.string(),
    "OUTROS.MED_HABITUAIS": yup.string(),
    "OUTROS.PARECER_CONDUTAS": yup.string(),
    "OUTROS.EXAMES_MONITORIZACAO": yup.string(),
    "RESUMO.PARECER_CONDUTAS": yup.string(),
    "METAS.METAS": null,
        
    nv_ativo: yup.number().integer(),
    dt_data_inclusao: yup.string(),
    dt_data_alteracao: yup.string(),
    dt_data_exclusao: yup.string(),
    in_usu_inclusao: yup.number().integer(),
    in_usu_alteracao: yup.number().integer(),
    in_usu_exclusao: yup.number().integer(),
})