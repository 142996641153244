import React from 'react';

import IconLogo from './logo-ronda.png';

export default () => (
  <img
    src={IconLogo}
    className="logo-LG"
    alt="Logo Ronda"
    style={{ width: '170px' }}
  />
);
