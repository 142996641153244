import { get, getWithParams, post, DELETE, DELETE_simples } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'PessoaFisicaContato/items';
// const usuarioLogado = 1;
let usuarioLogado = 0;
const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const ContatosAPI = {
  salvarContato: async function (contatoJSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;
      const retorno = await post(url, contatoJSON, null, headers)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          return null;
        });
      return retorno;
    } catch (error) {
      return null;
    }
  },
};

export default ContatosAPI;
