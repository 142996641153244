import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Atendimento/items';

// TODO: Obter usuário logado
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const AdmissaoAPI = {
  salvarAdmissao: async function (admissaoJson) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;

      const response = await post(url, admissaoJson).then((response) => {
        //console.log(response);
        return response;
      });

      return response.data;
      
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  salvarAlergia: async function (Json) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvaralergia?pUsuario=${usuarioLogado}`;

      const response = await post(url, Json).then((response) => {
        //console.log(response);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  salvarDiagnostico: async function (Json) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvardiagnostico?pUsuario=${usuarioLogado}`;

      const response = await post(url, Json).then((response) => {
        //console.log(response);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

};

export default AdmissaoAPI;
