import React, { useEffect, useState } from 'react';

const TabelaMedicoAssistente = (props) => {
  const [lista, setLista] =useState([]);
  
  useEffect(() =>{
    let listaTemp = props.lista.filter((item)=>item.in_assistente == 1)
    setLista(listaTemp);
  }, [props]);

  return (
    <>
      <table className="table  modal-table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CRM</th>
          </tr>
        </thead>
        <tbody>
          {lista.map((item, index) => {
            return (
              <tr
                data-dismiss="modal"
                key={index}
                className="linha-tabela"
                onClick={() => props.nameClick2(item)}
              >
                <td>{item.nv_nome_pessoafisica}</td>
                <td>{item.nv_crm_pessoafisica}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default TabelaMedicoAssistente;
