import React from 'react';

import { NavLink } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';

const MainAdmDashboard = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.empresa.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fas fa-hospital"></i>
                  </div>
                  <div>Hospital</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.setor.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-arrows-alt fa-fw"></i>
                  </div>
                  <div>Setor</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.perfil.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-user-circle fa-fw"></i>
                  </div>
                  <div>Perfil</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.usuario.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-fingerprint fa-fw"></i>
                  </div>
                  <div>Usuário</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.pessoaFisica.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-stream fa-fw"></i>
                  </div>
                  <div>Pessoa Física</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.acomodacao.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-bed fa-fw"></i>
                  </div>
                  <div>Acomodação</div>
                </div>
              </div>
            </NavLink>
          </div>

          {/* <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.grupoRonda.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-cog fa-fw"></i>
                  </div>
                  <div>Grupo Ronda</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink exact to={RotasURL.atributosRonda.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-dice-d6 fa-fw"></i>
                  </div>
                  <div>Atributos Ronda</div>
                </div>
              </div>
            </NavLink>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default MainAdmDashboard;
