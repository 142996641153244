import React, { useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';

import { getUser, getToken } from 'Services/Auth/Auth';
import PerfilAPI from 'Services/API/PerfilAPI';

import { isLoggedIn, logOut } from 'Services/Auth/Auth';
import { RotasURL } from 'RoutesURL';

const SidebarAdministrativo = () => {
  const history = useHistory();

  async function obterPerfilUsuario() {
    let userSession = getUser();
    let userPerfilId = userSession.in_perfil_id;
    let userPerfil = null;

    let perfilTempList = [];
    // //console.log(userSession);
    // //console.log(userPerfilId);

    let listaPerfil = await PerfilAPI.obterListaPerfil()
      .then((response) => {
        // //console.log(response);
        perfilTempList = response.filter((p) => {
          // //console.log(p);
          if (p.in_perfil_id === userPerfilId) {
            userPerfil = p;
          }
        });
      })
      .catch((err) => {
        throw err;
      });

    // userPerfil = perfilTempList;
    //console.log(userPerfil);

    if (userPerfil?.in_tipoperfil_id != 1) {
      // 2 Operacional
      history.push({ pathname: RotasURL.po_main });
    }
  }

  useEffect(() => {
    // let seUsuarioLogado = isLoggedIn();
    let seUsuarioLogado = true;
    if (!seUsuarioLogado) {
      // //console.log('Não Logado!');
      logOut();
      history.push({ pathname: RotasURL.login });
    } else {
      // //console.log('Logado!');
      obterPerfilUsuario();
    }
  }, []);

  return (
    <>
      <nav className="templatemo-left-nav">
        <ul>
          {/* <!--Empresa--> */}
          <li>
            <NavLink to={RotasURL.empresa.index} activeClassName="menu-ativo">
              <i className="fa fa-hospital fa-fw"></i>Hospital
            </NavLink>
          </li>

          {/* <!--Setor--> */}
          <li>
            <NavLink to={RotasURL.setor.index} activeClassName="menu-ativo">
              <i className="fa fa-arrows-alt fa-fw"></i>Setor
            </NavLink>
          </li>

          {/* <!--Perfil--> */}
          <li>
            <NavLink to={RotasURL.perfil.index} activeClassName="menu-ativo">
              <i className="fa fa-user-circle fa-fw"></i>Perfil
            </NavLink>
          </li>

          {/* <!--Usuário--> */}
          <li>
            <NavLink to={RotasURL.usuario.index} activeClassName="menu-ativo">
              <i className="fa fa-fingerprint fa-fw"></i>Usuário
            </NavLink>
          </li>

          {/* <!--Pessoa Fisica--> */}
          <li>
            <NavLink
              to={RotasURL.pessoaFisica.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-stream fa-fw"></i>Pessoa Física
            </NavLink>
          </li>

          {/* <!--Acomodação--> */}
          <li>
            <NavLink
              to={RotasURL.acomodacao.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-bed fa-fw"></i>Acomodação
            </NavLink>
          </li>

          {/* Especialidade */}
          <li>
            <NavLink
              to={RotasURL.especialidade.index}
              activeClassName="menu-ativo"
            >
              {/* <i className='fa fa-bed fa-fw' />Especialidade */}
              <i className="fa fa-user-md"></i>Especialidade
            </NavLink>
          </li>

          {/* <!--Grupo Ronda--> */}
          {/* <li>
            <NavLink
              to={RotasURL.grupoRonda.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-cog fa-fw"></i>Grupo Ronda
            </NavLink>
          </li> */}

          {/* <!--Atributos Ronda--> */}
          {/* <li>
            <NavLink
              to={RotasURL.atributosRonda.index}
              activeClassName="menu-ativo"
            >
              <i className="fa fa-dice-d6 fa-fw"></i>Atributos Ronda
            </NavLink>
          </li> */}

          {/* <!--Painel Operacional--> */}
          <li>
            <NavLink to={RotasURL.po_main} activeClassName="menu-ativo">
              <i className="fa fa-dice-d6 fa-fw"></i>Painel Operacional
            </NavLink>
          </li>

          {/* <!--Sair--> */}
          <li>
            <NavLink to="/login">
              <i className="fa fa-sign-out-alt fa-fw"></i>Sair
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default SidebarAdministrativo;
