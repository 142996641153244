import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import LayoutOperacional from '../../Components/Layout/Operacional';
import CardAcomodacaoLivre from '../../Components/CardAcomodacaoLivre';

import SetorAPI from '../../Services/API/SetorAPI';
import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import AtendimentoAPI from '../../Services/API/AtendimentoAPI';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import MovimentacaoAPI from '../../Services/API/MovimentacaoAPI';
import { RotasURL } from 'RoutesURL';

const MovimentacaoDestinoPage = (props) => {
  const [idSetor, setIdSetor] = useState();
  const [idDestino, setIdDestino] = useState();
  const [listaSetor, setListaSetor] = useState();
  const [listaAcomodacao, setListaAcomodacao] = useState([]);

  const [motivo, setMotivo] = useState('');

  const history = useHistory();

  useEffect(() => {
    obterListaSetor();
    let idSetorTemp = props.location.state.idSetor;
    setIdSetor(idSetorTemp);
    //console.log('Movimentacao destino setor:', idSetorTemp);
  }, []);

  useEffect(() => {
    idSetor && obterListaAcomodacao();
  }, [idSetor]);

  async function obterListaSetor() {
    try {
      let lista = await SetorAPI.obterListaSetor();
      setListaSetor(lista);
      //console.log('Setores: ', lista);
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  async function obterListaAcomodacao() {
    try {
      //console.log('obtendo lista de acomodao com id: ', idSetor);
      let lista = await AcomodacaoAPI.obterListaAcomodacaoPorSetor(1, idSetor);
      // //console.log('Acomodacao:', lista);
      if(lista) lista.sort((a, b) => a.in_acomodacao_id > b.in_acomodacao_id ? 1 : -1);
      setListaAcomodacao(lista);
    } catch (error) {
      // //console.log('Erro na listagem de acomodaçao ->', error);
      history.push({ pathname: RotasURL.login });
    }
  }

  function changeId(event) {
    setIdSetor(event.target.value);
  }

  function setDestino(acomodacao) {
    setIdDestino(acomodacao.in_acomodacao_id);
    //console.log(idDestino);
  }

  function changeMotivo(event) {
    setMotivo(event.target.value);
  }

  function onSubmit() {
    const data = {};
    let idOrigem = props.location.state.acomodacao.in_acomodacao_id;
    let idAtendimento = props.location.state.acomodacao.in_atendimento_id;
    data.in_atendimento_id = idAtendimento;
    data.in_acomodacaoorigem_id = idOrigem;
    data.in_acomodacaodestino_id = idDestino;
    data.nv_motivo_movimentacao = motivo;
    //console.log(data);
    MovimentacaoAPI.salvarMovimentacao(
      idAtendimento,
      idOrigem,
      idDestino,
      motivo
    )
      .then((resposta) => {
        //console.log('API: ', resposta);
        history.push(RotasURL.movimentacaoPaciente.origem);
      })
      .catch((error) => {
        //console.log('ERRO: ', error);
      });
  }

  return (
    <>
        <h2 className="margin-bottom-10">
          Movimentação Paciente
          <div className="btn-home">
            <a href="po_main">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
        </h2>

        <div className="row form-group">
          <div className="col-lg-4 col-md-4 form-group">
            <label>Setor Origem</label>
            <select
              className="form-control"
              value={idSetor}
              onChange={changeId}
            >
              {listaSetor &&
                listaSetor.map((setor, index) => {
                  return (
                    <option key={index} value={setor.in_setor_id}>
                      {setor.nv_nome_setor}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            {Array.isArray(listaAcomodacao) &&
              listaAcomodacao.map((acomodacao, index) => {
                return (
                  <a
                    href="#"
                    data-toggle="modal"
                    data-target="#modalPaciente"
                    onClick={() => setDestino(acomodacao)}
                  >
                    <CardAcomodacaoLivre acomodacao={acomodacao} />
                  </a>
                );
              })}
          </div>
        </div>

        <div
          className="modal"
          id="modalPaciente"
          tabIndex="-1"
          role="dialog"
          aria-aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" id="myModalLabel">
                  Confirmação de Movimentação
                </h4>
                <button
                  type="buttom"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">x</span>
                </button>
              </div>

              <div className="modal-body">
                <div className="form-group">
                  <textarea
                    className="form-control"
                    type="text"
                    placeholder="Motivo da Transferência"
                    value={motivo}
                    onChange={changeMotivo}
                  ></textarea>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-primary"
                  data-dismiss="modal"
                >
                  Cancelar
                </button>
                <button
                  className="btn btn-primary"
                  onClick={onSubmit}
                  data-dismiss="modal"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default MovimentacaoDestinoPage;
