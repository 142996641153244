import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import EspecialidadeAPI from 'Services/API/EspecialidadeAPI';

import { RotasURL } from 'RoutesURL';

const TabelaUsuario = (props) => {
    const [idExclusao, setIdExclusao] = useState();

    useEffect(() => {
        // //console.log(lista);
    }, []);

    function changeId(id){
        setIdExclusao(id);
    }

    function excluirEspecialidade(){
        //console.log(`deletando setor: ${idExclusao}`)
        EspecialidadeAPI.excluirEspecialidade(idExclusao).then((resposta) => {
            alert("Especialidade excluída com sucesso");
            props.obterListaDeEspecialidades(); 
        }).catch((error) => {
            alert("Erro ao excluir especialidade");
        })
    }
  
  return (
        <>
            {/* Pode virar componente */}
            <table id="tblsetor" className="table table-responsive">
                <thead>
                    <tr>
                        <td>NOME</td>
                        <td>&nbsp;</td>
                    </tr>
                </thead>
                <tbody>
                {
                    props && props.lista.map((item, index) => {
                    //console.log(item)
                    return(
                        <tr className="linhaheight" key={index}>
                        <td>{item.nome_Especialidade}</td>
                        <td style={{ textAlign: 'right' }}>
                            <Link to={{pathname: RotasURL.especialidade.novo, state: {item}}}>
                            <button type="button" className="templatemo-black-button">
                                <i className="fas fa-edit fa-fw"></i>
                            </button>
                            </Link>
                            <button 
                                type="button" 
                                className="templatemo-black-button" 
                                data-toggle="modal"
                                data-target="#modalExcluir"
                                onClick={()=>changeId(item.id_Especialidade)}
                            >
                                <i className="fas fa-trash fa-fw"></i>
                            </button>
                        </td>
                        </tr>
                    )
                    })
                }
                </tbody>
            </table>
          
            <div
                className="modal "
                id="modalExcluir"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        {/*Header*/}
                        <div className="modal-header">
                            <h4 className="modal-title" id="myModalLabel">
                                Confirmar a exclusão da Especialidade?
                            </h4>
                            <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                    {/*Footer*/}
                        <div className="modal-footer">
                            <button
                                type="button"
                                className="btn btn-outline-primary"
                                data-dismiss="modal"
                                // onClick={() => deletePost(App.in_empresa_id)}
                            >
                                Cancelar
                            </button>
                            <button
                                className="btn btn-primary"
                                data-dismiss="modal"
                                onClick={() => excluirEspecialidade()}
                            >
                                Confirmar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TabelaUsuario;