import { useState, useEffect } from 'react';

//import { ComboSetores, ListaCardAcomodacao } from './components';
import ComboSetores from '../../Components/ComboSetores/ComboSetores';
import ListaCardAcomodacao from '../../Components/ListaCardAcomodacao/ListaCardAcomodacao';

import AcomodacaoAPI from '../../Services/API/AcomodacaoAPI';
import AtendimentoAPI from '../../Services/API/AtendimentoAPI';
import PessoaFisicaAPI from '../../Services/API/PessoaFisicaAPI';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { RotasURL } from 'RoutesURL';

const RotinaMedicaMainPage = () => {
  const [idSetor, setIdSetor] = useState();
  const [listaSetor, setListaSetor] = useState();

  const [listaAcomodacao, setListaAcomodacao] = useState([]);
  const [listaAtendimento, setListaAtendimento] = useState([]);
  const [listaPessoa, setListaPessoa] = useState([]);

  const history = useHistory();

  useEffect(() => {
    // //console.log('UE listasetor');
    if (listaSetor) {
      // //console.log('lista setor nao vazia');
      //console.log(listaSetor[0].in_setor_id);
      setIdSetor(listaSetor[0].in_setor_id);
    }
  }, [listaSetor]);

  useEffect(() => {
    obterListaAcomodacao();
  }, [idSetor]);

  // useEffect(() => {
  //   let listaAtendimentoTemp = [];
  //   Array.isArray(listaAcomodacao) &&
  //     listaAcomodacao.map(async (acomodacao, index) => {
  //       let atendimento = await AtendimentoAPI.obterAtendimentoPorId(
  //         acomodacao.in_atendimento_id
  //       );
  //       listaAtendimentoTemp = listaAtendimentoTemp.concat(atendimento);
  //       setListaAtendimento([...listaAtendimentoTemp]);
  //     });
  // }, [listaAcomodacao]);

  // useEffect(() => {
  //   let listaPessoaTemp = [];
  //   //console.log(listaAtendimento);
  //   //console.log(Array.isArray(listaAtendimento));
  //   Array.isArray(listaAtendimento) &&
  //     listaAtendimento.map(async (atendimento, index) => {
  //       //console.log('dentro do map de atendimento: ', atendimento);
  //       let pessoa = await PessoaFisicaAPI.obterPessoaPorId(
  //         atendimento.in_paciente_id
  //       );
  //       listaPessoaTemp = listaPessoaTemp.concat(pessoa);
  //       setListaPessoa(listaPessoaTemp);
  //     });
  // }, [listaAtendimento]);

  async function obterListaAcomodacao() {
    try {
      // //console.log('obtendo lista de acomodao com id: ', idSetor);
      let lista = await AcomodacaoAPI.obterListaAcomodacaoPorSetor(2, idSetor);
      console.log(lista)
      if (lista !== null) {
        lista.sort((a, b) => a.in_acomodacao_id > b.in_acomodacao_id ? 1 : -1);
        setListaAcomodacao(lista);
      }
    } catch (error) {
      // //console.log('Erro na listagem de acomodaçao ->', error);
      // alert('Ax');
      // history.push({ pathname: RotasURL.login });
    }
  }

  function setSetorFromComponent(valorRecebido) {
    setIdSetor(valorRecebido);
  }

  return (
    <>
      <h2 className="margin-bottom-10">
        Ronda
        <div className="btn-home">
          <a href="po_main">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
      </h2>

      <div className="row form-group">
        <div className="col-lg-4 col-md-4 form-group">
          <ComboSetores retornoSetor={setSetorFromComponent} />
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <ListaCardAcomodacao
            listaAcomodacao={listaAcomodacao}
            // listaAtendimento={listaAtendimento}
            // listaPessoa={listaPessoa}
            // idSetor={idSetor}
            // // pathname={RotasURL.rotinaMedica.lista}
            pathname={RotasURL.rotinaMedica.atendimento}
            linkState={{ idSetor }}
          />
        </div>
      </div>
    </>
  );
};

export default RotinaMedicaMainPage;
