import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import AcessoAPI from '../../Services/API/AcessoAPI';

const EsqueceuSenhaPage = (props) => {
  const [email, setEmail] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function changeEmail(event) {
    setEmail(event.target.value);
  }

  const onSubmit = async () => {
    //console.log(email);
    await AcessoAPI.esqueceuSenha(email);
  };

  return (
    <>
      <div className="content-first col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div className="container-login col-lg-3 col-md-4 col-sm-6 col-xs-10">
          <img className="logo-ronda-login" src="icon/logo-ronda.svg" />
          <h1 className="titulo-login">Esqueci minha senha</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="formLine">
              <i className="fas fa-user" />
              <input
                className="input-login"
                data-val="true"
                data-val-required="E-mail de preenchimento obrigatório"
                placeholder="E-mail"
                type="text"
                value={email}
                onChange={changeEmail}
              />
            </div>
            
            <button className="btn-login" data-toggle="modal" data-target="#modalObrigado">
              Enviar
            </button>
            <br/>
            <br/>
            <a href='/'>
                Retornar
            </a>
          </form>
          <br />
          <br />
        </div>
      </div>
      <div className="modal" id="modalObrigado" tabIndex="-1" role="dialog">
          <div className='modal-dialog' role='document'>
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className='modal-title'>Senha Redefinida</h4>
                    <button type='buttom' className='close' data-dismiss="modal">
                        <span>x</span>
                    </button>
                </div>

                <div className='modal-body'>
                    Obrigado!<br/>
                    Caso o email informada conste no sistema você receberá em seu email uma nova senha para acesso.
                </div>
            </div>
          </div>
        
      </div>
    </>
  );
};

export default EsqueceuSenhaPage;
