import { get, getWithParams, post, DELETE} from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Informacao/items';

let usuarioLogado = 0;
const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const AtributosAPI = {
    obterListaAtributos: async function () {
        try {
          obterUsuarioLogado();
          let token = getToken();
          let headers = {
              Authorization: `Bearer ${token}`,
          };

          const url = `/${base}/listar?pAtivo=1&pUsuario=${usuarioLogado}`;
          // //console.log('URL: ', url);
    
          const retorno = await get(url)
            .then((response) => {
              // //console.log('Response -> ', response);
              return response.data;
            })
            .catch((error) => {
              // //console.log('Error -> ', error);
              return null;
            });
    
          return retorno;
        } catch (err) {
          // //console.log('CATCH:  ', err);
          return null;
        }
      },
    
    salvarAtributos: async function (atributosJson) {
        try{
          obterUsuarioLogado();
          let token = getToken();
          let headers = {
              Authorization: `Bearer ${token}`,
          };

          const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;

          const response = await post(url, atributosJson).then((response) => {
            //console.log(response);
            return response;
          });
        } catch (error) {
          //console.log(error);
          return null;
        }
      },

    excluirAtributos: async function(id){
        try{
          obterUsuarioLogado();
          let token = getToken();
          let headers = {
              Authorization: `Bearer ${token}`,
          };

          const url = `/${base}/excluir`;
    
          const response = await DELETE(url, id, usuarioLogado, "Informacao").then((response) => {
            //console.log(response);
            //console.log(`api deletado ${id}`)
            return response;
          })
        } catch(error){
          //console.log(error);
          return null
        }
      },
};

export default AtributosAPI;
