import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './usuarioSchema';
import LayoutAdministrativo from '../../Components/Layout/Administrativo';

import PerfilAPI from '../../Services/API/PerfilAPI';
import UsuarioAPI from '../../Services/API/UsuarioAPI';
import PessoaFisicaAPI from 'Services/API/PessoaFisicaAPI';
import EspecialidadeAPI from 'Services/API/EspecialidadeAPI';

import { RotasURL } from 'RoutesURL';

const NovoSetorPage = (props) => {
  const [atualizando, setAtualizando] = useState(true);
  const [usuario, setUsuario] = useState();
  const [funcao, setFuncao] = useState(0);
  const [listaPerfil, setListaPerfil] = useState();
  const [listaEspecialidades, setListaEspecialidades] = useState([]);
  const [especialidade, setEspecialidade] = useState();
  const [disableEspecialista, setDisableEspecialista] = useState(true);

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const history = useHistory();

  useEffect(() => {
    obterListaPerfil();
    obterListaEspecialidade();
  }, []);

  useEffect(() => {
    if (!props.location.state.item.nv_login) {
      setAtualizando(false);
      //console.log(props);
      setUsuario(props.location.state.item)
    } else {
      atualizaUsuario();
      //console.log(props.location.state.item);
    }
  }, [listaPerfil]);

  useEffect(() => {
    //console.log(listaPerfil);
  }, [listaPerfil]);

  useEffect(() => {
    if(listaPerfil){
      let perfilSelecionado = listaPerfil.filter((perfil) => perfil.in_perfil_id == funcao);
      console.log(perfilSelecionado);
      //checa se o perfil escolhido é um especialista
      if(perfilSelecionado[0].in_tipoperfil_id == 4){
        setDisableEspecialista(false);
      }else{
        setEspecialidade(0);
        setDisableEspecialista(true);
      }
    }
  }, [funcao])

  const onSubmit = (dataJSON) => {
    const data = dataJSON;
    data.in_pessoa_id = usuario.in_pessoafisica_id;
    data.in_perfil_id = parseInt(funcao);
    data.nv_senha = '123';
    if(atualizando){
      data.in_usuario_id = props.location.state.item.in_usuario_id;
    }
    if(especialidade != 0){
      data.in_especialidade_id = especialidade;
    }
    console.log(data);
    UsuarioAPI.salvaUsuario(data).then((resposta) => {
      alert("O cadastro foi finalizado com sucesso.");
    }).catch((error) => {
      erroLogin(error);
    })
  };

  function erroLogin(error){
    alert("Erro durante o cadastro");
  }

  function changeFuncao(event) {
    //console.log(event.target.value);
    setFuncao(event.target.value);
    console.log(event.target.value);
  }

  function changeEspecialidade(event) {
    setEspecialidade(event.target.value);
    console.log(event.target.value);
  }

  async function atualizaUsuario() {
    let user = await PessoaFisicaAPI.obterPessoaPorId(props.location.state.item.in_pessoa_id)
    //console.log(user[0]);
    setUsuario(user[0]);
    console.log(props.location.state.item)
    setFuncao(props.location.state.item.in_perfil_id);
    setEspecialidade(props.location.state.item.in_especialidade_id);
    setValue('nv_login', props.location.state.item.nv_login, {
      shouldValidate: true,
    });
    setAtualizando('true');
  }

  async function obterListaPerfil() {
    try {
      let lista = await PerfilAPI.obterListaPerfil();
      // //console.log(lista);
      if (lista !== null) {
        setListaPerfil(lista);
      }
    } catch (error) {
      //console.log(error);
      history.push({ pathname: RotasURL.login });
    }
  }

  async function obterListaEspecialidade(){
    let especialidadesTemp = await EspecialidadeAPI.obterListaEspecialidade();
    setListaEspecialidades(especialidadesTemp);
  }

  return (
    <>
        <h2 className="margin-bottom-10">
          <Link to={RotasURL.usuario.index}>
            <i className="fa fa-arrow-alt-circle-left fa-fw" />
          </Link>
          {atualizando ? 'Atualizar Usuário' : 'Novo Usuário'}

          <div className="btn-home">
            <a href="">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
        </h2>

        
          <form
            className="templatemo-login-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row form-group">
              <div className="col-lg-4 col-md-3 form-group">
                <label>Nome Pessoa</label>
                <input
                  type="text"
                  className="form-control"
                  value={usuario && usuario.nv_nome_pessoafisica}
                  placeholder="Digite o nome"
                  disabled={atualizando}
                />
              </div>

              <div className="col-lg-4 col-md-4 form-group">
                <label>Perfil</label>
                <select
                  className="form-control"
                  
                  value={funcao}
                  onChange={changeFuncao}
                >
                  <option value = '0'> --- </option>
                  {listaPerfil &&
                    listaPerfil.map((perfil, index) => {
                      return (
                        <option value={perfil.in_perfil_id} key={index}>
                          {perfil.nv_nome_perfil}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>

            <div className="row form-group">
              <div className="col-lg-4 col-md-4 form-group">
                <label>Login</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Login"
                  value={atualizando? props.location.state.item.nv_login : null}
                  {...register('nv_login', {})}
                  disabled={atualizando}
                />
                <p className="error-message">{errors.nv_login?.message}</p>
              </div>

              <div className="col-lg-4 col-md-4 form-group">
                <label>Especialidade</label>
                <select
                  className="form-control"
                  disabled={disableEspecialista}
                  value={especialidade}
                  onChange={changeEspecialidade}
                >
                  <option value = '0'> --- </option>
                  {listaEspecialidades &&
                    listaEspecialidades.map((especialidade, index) => {
                      return (
                        <option value={especialidade.id_Especialidade} key={index}>
                          {especialidade.nome_Especialidade}
                        </option>
                      );
                    })}
                </select>
              </div>
              
            </div>

            <div className="form-group text-left">
              <button type="reset" className="templatemo-white-button">
                Apagar
              </button>
              <button type="submit" className="templatemo-blue-button">
                Salvar
              </button>
            </div>
          </form>
        
    </>
  );
};

export default NovoSetorPage;
