import React from 'react';

import { NavLink } from 'react-router-dom';
import { RotasURL } from 'RoutesURL';

const MainOperDashboard = () => {
  return (
    <>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink to={RotasURL.admissaoPaciente.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fas fa-hospital"></i>
                  </div>
                  <div>Admissão de Paciente</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink to={RotasURL.movimentacaoPaciente.origem}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-arrows-alt fa-fw"></i>
                  </div>
                  <div>Movimentação Paciente</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink to={RotasURL.rotinaMedica.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-user-circle fa-fw"></i>
                  </div>
                  <div>Rotina Médica</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink to={RotasURL.plantaoMedico.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-fingerprint fa-fw"></i>
                  </div>
                  <div>Plantão Médico</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink to={RotasURL.altaMedica.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-stream fa-fw"></i>
                  </div>
                  <div>Alta Médica</div>
                </div>
              </div>
            </NavLink>
          </div>

          <div className="col-lg-2 col-md-3 col-sm-6 col-xs-12 box-home">
            <NavLink to={RotasURL.liberacaoLeito.index}>
              <div className="inter-box">
                <div className="inter-box-content">
                  <div>
                    <i className="fa fa-bed fa-fw"></i>
                  </div>
                  <div>Liberação de Leito</div>
                </div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default MainOperDashboard;
