import { useState, useEffect } from 'react';

import SetorAPI from '../../Services/API/SetorAPI';

const ComboSetores = (props) => {
  const [idSetor, setIdSetor] = useState();
  const [listaSetor, setListaSetor] = useState();

  useEffect(() => {
    obterListaSetor();
  }, []);

  useEffect(() => {
    if(listaSetor){
      props.retornoSetor(listaSetor[0].in_setor_id);
      setIdSetor(listaSetor[0].in_setor_id);
    }
  }, [listaSetor])

  async function obterListaSetor() {
    try {
      let lista = await SetorAPI.obterListaSetor();
      setListaSetor(lista);
    } catch (error) {
      throw error;
    }
  }

  function changeId(event) {
    props.retornoSetor(event.target.value);
    setIdSetor(event.target.value);
  }

  return (
    <>
      <label>Setor Origem</label>
      <select className="form-control" value={idSetor} onChange={changeId}>
        <option value="">---</option>
        {listaSetor &&
          listaSetor.map((setor, index) => {
            return (
              <option key={index} value={setor.in_setor_id}>
                {setor.nv_nome_setor}
              </option>
            );
          })}
      </select>
    </>
  );
};

export default ComboSetores;
