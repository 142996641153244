import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './perfilSchema';
import { RotasURL } from 'RoutesURL';
import PerfilAPI from '../../Services/API/PerfilAPI';

const Perfil_novoPage = (props) => {
  //export default function App(props) {
  const [atualizando, setAtualizando] = useState(true);
  const [idPerfil, setIdPerfil] = useState();
  const [perfil, setPerfil] = useState();
  const [listaTipoPerfil, setListaTipoPerfil] = useState([]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const history = useHistory();

  const onSubmit = (dataJSON) => {
    const data = dataJSON;
    //console.log(data);
    if (atualizando) {
      data.in_perfil_id = idPerfil;
    }
    //console.log(data);

    PerfilAPI.salvarPerfil(data)
      .then((resposta) => {
        // //console.log(resposta);
        // //console.log('certo');
        history.push(RotasURL.perfil.index);
      })
      .catch((err) => {
        //console.log(err);
        history.push({ pathname: RotasURL.login });
      });
  };

  useEffect(() => {
    if (!props.location.state) {
      setAtualizando(false);
      // //console.log('vazio');
      // //console.log(props);
    } else {
      atualizaPerfil();
      //console.log(perfil);
    }
    obterListaTipoPerfil();
  }, []);

  async function obterListaTipoPerfil(){
    try{
      let lista = await PerfilAPI.obterListaTipoPerfil();
      setListaTipoPerfil(lista);
    }catch(err){
      //console.log(err);
      throw err;
    }
  }

  function atualizaPerfil() {
    setPerfil(props.location.state.item);
    setIdPerfil(props.location.state.item.in_perfil_id);
    setAtualizando(true);
  }

  return (
    <>
        {/* <!--Título da Página--> */}
        <h2 className="margin-bottom-10">
          <Link to={RotasURL.perfil.index}>
            <i className="fa fa-arrow-alt-circle-left fa-fw" />
          </Link>
          {atualizando ? 'Atualizar Perfil' : 'Novo Perfil'}
          <div className="btn-home">
            <a href="index.html" title="Home">
              <i className="fas fa-home fa-fw"></i>
            </a>
          </div>
        </h2>

        {(perfil || !atualizando) && (
          <form
            className="templatemo-login-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="row form-group">
              <div className="col-lg-4 col-md-4 form-group">
                <label>Nome do Perfil</label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={perfil && perfil.nv_nome_perfil}
                  placeholder="Nome do Perfil"
                  {...register('nv_nome_perfil', {})}
                />
                <p className="error-message">
                  {errors.nv_nome_perfil?.message}
                </p>
              </div>
              <div className="col-lg-2 col-md-2 form-group">
                <label>Tipo de Perfil</label>
                <select
                  className="form-control"
                  defaultValue={perfil && perfil.in_tipoperfil_id}
                  {...register('in_tipoperfil_id', {})}
                >
                  {Array.isArray(listaTipoPerfil) && listaTipoPerfil.map((tPerfil, index) => {
                    return(
                      <option value={tPerfil.in_tipoperfil_id} key={index}>
                        {tPerfil.nv_descricao_tipoperfil}
                      </option>
                    )
                  })}
                </select>
                <p className="error-message">
                  {errors.in_tipoperfil_id?.message}
                </p>
              </div>
            </div>

            <div className="form-group text-left">
              <button type="reset" className="templatemo-white-button">
                {atualizando ? 'Corrigir' : 'Apagar'}
              </button>
              <a href="/perfil">
                <button type="submit" className="templatemo-blue-button">
                  Salvar
                </button>
              </a>
            </div>
          </form>
        )}
    </>
  );
};

export default Perfil_novoPage;
