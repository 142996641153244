import React, { useEffect, useState } from 'react';
import TabelaAtributos from '../../Components/Tabela/TabelaAtributos';
import AtributosAPI from '../../Services/API/AtributosAPI';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './atributosSchema';
import GruposAPI from '../../Services/API/GruposAPI';

const AtributosPage = () => {
  const [listaAtual, setListaAtual] = useState([]);
  const [listaFiltrada, setListaFiltrada] = useState([]);
  const [atualizando, setAtualizando] = useState(false);
  const [idAtributos, setIdAtributos] = useState();
  const [Atributos, setAtributos] = useState();
  const [listaGrupos, setListaGrupos] = useState();
  const [grupo, setGrupo] = useState('0');
  const [tipo, setTipo] = useState('1');
  const [aCheck, setACheck] = useState(false);
  const [fCheck, setFCheck] = useState(false);

  const [tiposControle] = useState(['Texto', 'Check', 'Lista']);

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const obterListaDeAtributos = async () => {
    try {
      let lista = await AtributosAPI.obterListaAtributos();
      setListaAtual(lista);
      //console.log(lista);
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const atualizar = (data) => {
    setAtributos(data);
    setIdAtributos(data.in_informacao_id);
    setGrupo(data.in_grupoinformacao_id);
    setTipo(data.in_tipocontrole_id);
    if (data.in_assistente == 1) setACheck(true);
    else setACheck(false);
    if (data.in_familiar == 1) setFCheck(true);
    else setFCheck(false);
    //console.log(data.in_grupoinformacao_id);
    //resolve a questão de defaultValue não renderizando no primeiro load
    setValue('nv_descricao_informacao', data.nv_descricao_informacao, {
      shouldValidate: true,
    });
    setValue('nv_detalhe_informacao', data.nv_detalhe_informacao, {
      shouldValidate: true,
    });
  };

  useEffect(() => {
    obterListaDeAtributos();
    obterListaDeGrupos();
  }, []);

  const onSubmit = async (dataJSON) => {
    //console.log('test');
    const data = dataJSON;
    //console.log(data);
    if (atualizando) {
      data.in_informacao_id = idAtributos;
    }
    //console.log(data);
    data.in_grupoinformacao_id = grupo;
    data.in_tipocontrole_id = tipo;
    if (aCheck) data.in_assistente = 1;
    else data.in_assistente = 0;
    if (fCheck) data.in_familiar = 1;
    else data.in_familiar = 0;

    await AtributosAPI.salvarAtributos(data)
      .then((resposta) => {
        //console.log(resposta);
        //console.log('certo');
      })
      .catch((err) => {
        //console.log(err);
      });
    obterListaDeAtributos();
  };

  function changeGrupo(event) {
    setGrupo(event.target.value);
    let listaTemp = listaAtual.filter((atributo) => {
      if (event.target.value == atributo.in_grupoinformacao_id) return true;
    });
    setListaFiltrada(listaTemp);
  }

  useEffect(() => {
    //console.log('chegou aqui ------------------', listaAtual);
    let listaTemp = listaAtual.filter((atributo) => {
      if (grupo == atributo.in_grupoinformacao_id) return true;
    });
    //console.log(listaTemp);
    setListaFiltrada(listaTemp);
  }, [listaAtual, grupo]);

  function changeTipo(event) {
    setTipo(event.target.value);
  }

  function changeACheck(event) {
    if (event.target.value == 'false') setACheck(true);
    else setACheck(false);
  }

  function changeFCheck(event) {
    if (event.target.value == 'false') setFCheck(true);
    else setFCheck(false);
  }

  async function obterListaDeGrupos() {
    try {
      let lista = await GruposAPI.obterListaGrupo();
      setListaGrupos(lista);
      //console.log(lista);
      setGrupo(lista[0].in_grupo_id); //permite que a lista de grupos funcione assim que a pagina é gerada
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async function obterGrupo(pGrupoId) {
    let grupo = await GruposAPI.obterGrupoPorId(pGrupoId);
    // //console.log('Grupo: ', grupo);
    return grupo[0];
  }

  const limpar = () => {
    let grupoTemp = grupo;
    reset();
    //console.log(grupoTemp);
    obterListaDeGrupos();
  };

  function enviarJSON() {
    let atributosGrupoSchema = {};
    let schemaUrl = 'http://json-schema.org/draft-04/schema#';
    let type = 'object';
    let properties = {};
    let required = [];
    let groupId = null;
    let atributosGrupoSchemaString = null;

    listaFiltrada.forEach((atributo) => {
      // //console.log('==> ', atributo);

      let novoAtributo = {};
      novoAtributo['type'] = tiposControle[atributo['in_tipocontrole_id'] - 1];
      novoAtributo['details'] = atributo['nv_detalhe_informacao'];
      novoAtributo['assistantCheck'] = atributo['in_assistente'];
      novoAtributo['familyCheck'] = atributo['in_familiar'];

      properties[atributo['nv_descricao_informacao']] = novoAtributo;

      groupId = atributo['in_grupoinformacao_id'];
      // //console.log('   --- >', novoAtributo);
    });

    // //console.log('***', properties);

    (async () => {
      let retorno = '';
      await obterGrupo(groupId)
        .then((data) => {
          atributosGrupoSchema['$schema'] = schemaUrl;
          atributosGrupoSchema['type'] = type;
          atributosGrupoSchema['properties'] = properties;
          atributosGrupoSchema['required'] = required;
          atributosGrupoSchema['group'] = data.nv_nome_grupo;

          //console.log(atributosGrupoSchema);
          atributosGrupoSchemaString = JSON.stringify(atributosGrupoSchema);
          //console.log(atributosGrupoSchemaString);
        })
        .catch((err) => console.error('Errado: ', err));

      if (atributosGrupoSchemaString != null) {
        await GruposAPI.salvarInformacoesGrupo(
          groupId,
          atributosGrupoSchemaString
        )
          .then((resposta) => {
            //console.log(resposta);
            //console.log('certo');
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    })().catch((err) => {
      console.error(err);
    });
  }

  useEffect(() => {
    if (Atributos) {
      //console.log(Atributos);
      setAtualizando(true);
    } else {
      //console.log(Atributos);
    }
  }, [Atributos]);

  return (
    <>
      {/* <!--Título da Página--> */}
      <h2 className="margin-bottom-10">
        {atualizando ? 'Atualizar Atributo' : 'Atributos Ronda'}
        <div className="btn-home">
          <a href="index.html" title="Home">
            <i className="fas fa-home fa-fw"></i>
          </a>
        </div>
        {/*<!--Home-->*/}
      </h2>

      {(Atributos || !atualizando) && (
        <form
          className="templatemo-login-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="row form-group">
            <div className="col-lg-4 col-md-4 form-group">
              <label>Grupo</label>
              <select
                className="form-control"
                value={grupo}
                onChange={changeGrupo}
              >
                {listaGrupos &&
                  listaGrupos.map((grupo, index) => {
                    return (
                      <option value={grupo.in_grupo_id} key={index}>
                        {grupo.nv_nome_grupo}
                      </option>
                    );
                  })}
              </select>
              <p className="error-message">
                {errors.in_grupoinformacao_id?.message}
              </p>
            </div>
            <div className="col-lg-6 col-md-6 form-group">
              <label>Campo</label>
              <input
                type="text"
                className="form-control"
                defaultValue=""
                placeholder="Campo"
                {...register('nv_descricao_informacao', {})}
              />
              <p className="error-message">
                {errors.nv_descricao_informacao?.message}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 form-group">
              <label>Tipo</label>
              <select
                className="form-control"
                value={tipo}
                onChange={changeTipo}
              >
                <option value="1">Texto</option>
                <option value="2">Check</option>
                <option value="3">Lista</option>
              </select>
              <p className="error-message">
                {errors.in_tipocontrole_id?.message}
              </p>
            </div>
            <div className="col-lg-4 col-md-4 form-group">
              <label htmlFor="inputLastName">Detalhe</label>
              <input
                type="text"
                className="form-control"
                defaultValue=""
                placeholder="Detalhes"
                {...register('nv_detalhe_informacao', {})}
              />
              <p className="error-message">
                {errors.nv_detalhe_informacao?.message}
              </p>
            </div>

            <div className="col-lg-4 col-md-4 form-group">
              <br></br>
              <div className="col-lg-4 col-md-4 form-group form-check">
                <input
                  value={aCheck}
                  onChange={changeACheck}
                  type="checkbox"
                  id="check1"
                  name="check"
                  checked={aCheck}
                />
                <label htmlFor="check1">Assistente</label>
              </div>
              <div className="col-lg-4 col-md-4 form-group form-check">
                <input
                  value={fCheck}
                  onChange={changeFCheck}
                  type="checkbox"
                  id="check2"
                  name="check"
                  checked={fCheck}
                />
                <label htmlFor="check2">Família</label>
              </div>
              <div className="formLine">
                <span
                  className="field-validation-valid text-danger-yellow"
                  data-valmsg-for="Setor.codExterno_Setor"
                  data-valmsg-replace="true"
                ></span>
              </div>
            </div>
          </div>

          <div className="form-group text-left">
            <button
              type="reset"
              className="templatemo-white-button"
              onClick={() => limpar()}
            >
              Limpar
            </button>
            <button type="submit" className="templatemo-blue-button">
              Salvar
            </button>
          </div>
        </form>
      )}

      <br></br>
      <br></br>

      <TabelaAtributos
        lista={listaFiltrada}
        obterListaDeAtributos={obterListaDeAtributos}
        atualizar={atualizar}
        grupos={listaGrupos}
      />

      <br></br>
      <br></br>

      <div className="form-group text-left">
        <button className="templatemo-blue-button" onClick={() => enviarJSON()}>
          Aplicar Alterações
        </button>
      </div>
    </>
  );
};

export default AtributosPage;
