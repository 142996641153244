import React, {useEffect, useState} from 'react';

const CardAcomodacaoLivre = (props) => {

    return(
        <>
        {console.log(props)}
            <div className='col-lg-2 col-md-3 col-sm-4 col-xs-6 box-home'>
                    <div className='box-mov-2'>
                        <div className='icon-mov-2'>
                            <i className='fa fa-bed fa-fw'/>
                        </div>
                        <div className='content-mov-2'>
                            <strong>{props.acomodacao.nv_nome_acomodacao}</strong>
                            <div>Livre</div>
                        </div>
                    </div>
            </div>
        </>
    )
}

export default CardAcomodacaoLivre;