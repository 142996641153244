import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'SegUsuario/items';
// const usuarioLogado = 1;
let usuarioLogado = 0;

const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const SetorAPI = {
  obterListaUsuario: async function () {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pAtivo=1`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  salvaUsuario: async function (usuarioJSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;

      const response = await post(url, usuarioJSON, null, headers)
        .then((response) => {
          // //console.log(response);
          return response;
        })
        .catch((err) => {
          // //console.log('#@# CATCH ', err);
          throw err;
        });
    } catch (error) {
      // //console.log('ERRO AO SALVAR USUARIO ', error);
      // return null;
      throw error;
    }
  },

  obterTeste: function () {
    //console.log('teste');
  },

  excluirUsario: async function(idToDelete){
    try{
        obterUsuarioLogado();
        let token = getToken();
        let headers = {
            Authorization: `Bearer ${token}`
        }

        const url = `/${base}/excluir`

        const response = await DELETE(
            url,
            idToDelete,
            usuarioLogado,
            'SegUsuario',
            headers
        ).then((response) => {
            //console.log(response);

        })
    }catch(error){
        //console.log(error);
        return null;
    }
  }
};

export default SetorAPI;
