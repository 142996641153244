import { get, getWithParams, post, DELETE } from './_http';
import { getUser, getToken } from 'Services/Auth/Auth';

const base = 'Setor/items';

// const usuarioLogado = 1;
let usuarioLogado = 0;
const obterUsuarioLogado = () => {
  let usuarioSession = getUser();
  if (usuarioSession) {
    usuarioLogado = usuarioSession.in_usuario_id;
  }
  return 1;
};

const SetorAPI = {
  obterListaSetor: async function () {
    // //console.log('USUARIO LOGADO: ', usuarioLogado());
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listar?pAtivo=1&pUsuario=${usuarioLogado}`;
      // //console.log('URL: ', url);

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log('Response -> ', response);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error -> ', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      // //console.log('CATCH:  ', err);
      // return null;
      throw err;
    }
  },

  obterListaSetorPorEmpresa: async function (empresaID) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/listarsetorempresa?pAtivo=1&pIdEmpresa=${empresaID}&pUsuario=${usuarioLogado}`;

      const retorno = await get(url, headers)
        .then((response) => {
          // //console.log(url);
          return response.data;
        })
        .catch((error) => {
          // //console.log('Error ->', error);
          // return null;
          throw error;
        });

      return retorno;
    } catch (err) {
      throw err;
    }
  },

  salvarSetor: async function (setorJSON) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/salvar?pUsuario=${usuarioLogado}`;
      // //console.log(url);
      // //console.log(JSON.stringify(setorJSON));

      const resposta = await post(url, setorJSON, null, headers)
        .then((response) => {
          //console.log(response);
          return response;
        })
        .catch((err) => {
          if (err.response.data !== undefined) {
            throw err.response.data;
          }

          throw err;
        });

      return resposta;
    } catch (error) {
      throw error;
    }
  },

  excluirSetor: async function (id) {
    try {
      obterUsuarioLogado();
      let token = getToken();
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      const url = `/${base}/excluir`;

      const response = await DELETE(
        url,
        id,
        usuarioLogado,
        'Setor',
        headers
      ).then((response) => {
        //console.log(response);
        //console.log(`api deletado ${id}`);
        return response;
      });
    } catch (error) {
      //console.log(error);
      return null;
    }
  },

  obterTeste: function () {
    //console.log('teste');
  },
};

export default SetorAPI;
