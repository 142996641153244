import * as yup from 'yup';

export const schema = yup.object().shape({
  in_grupo_id: yup.number().integer(), //.required('*Campo obrigatório.'),
  nv_nome_grupo: yup
    .string()
    .required('*Campo obrigatório.')
    .min(1, 'Deve ter no mínimo 1 caracter.')
    .max(20, 'Deve ter no máximo 20 caracteres.'),
  nv_ativo: yup.number().integer(),
  dt_data_inclusao: yup.string(),
  dt_data_alteracao: yup.string(),
  dt_data_exclusao: yup.string(),
  in_usu_inclusao: yup.number().integer(),
  in_usu_alteracao: yup.number().integer(),
  in_usu_exclusao: yup.number().integer(),
});
