import React, { useState } from 'react'

import styles from './RadioButtonAdultoPediatrico.module.css'

const RadioButtonAdultoPediatrico = ({ tipoAdultoPediatrico, onChangeTipo }) => {

  return (
    <>
      <label>Tipo de internação</label>
      <div className={styles.container}>
        <input type="radio" id="adulto" name="ie_adulto_pediatrico" value="Adulto" 
          onChange={e => onChangeTipo(e.target.value)} 
          checked={tipoAdultoPediatrico == "Adulto"} 
        />
        <label htmlFor="adulto">Adulto</label>

        <input type="radio" id="pediatrico" name="ie_adulto_pediatrico" value="Pediatrico" 
          onChange={e => onChangeTipo(e.target.value)} 
          checked={tipoAdultoPediatrico == "Pediatrico"} 
        />
        <label htmlFor="pediatrico">Pediátrico</label>
      </div>
    </>
  )
}

export default RadioButtonAdultoPediatrico;